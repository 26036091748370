import { useParams } from "react-router-dom";
import Quotation from "pages/quotations/manage/Quotation";

// using Proxy Page to remount when route to same route location but different param
function QuotationProxy() {
  const { mode, quotationId } = useParams();
  return <Quotation key={`${mode}/${quotationId}`} />;
}

export default QuotationProxy;
