import PropTypes from "prop-types";

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import AddIcon from "@mui/icons-material/Add";

import MDBox from "components/atoms/MDBox";
import MDButton from "components/atoms/MDButton";
import MDTypography from "components/atoms/MDTypography";

import FormInnerFieldArray from "components/molecules/Formik/FormInnerFieldArray";
import FormAutocompleteFast from "components/molecules/Formik/FormAutocompleteFast";
import FormRadioGroup from "components/molecules/Formik/FormRadioGroup";

import { serviceTeamsForm } from "pages/quotations/manage/schemas/form";
import { serviceTeamsInitialValues } from "pages/quotations/manage/schemas/initialValues";
import { serviceTeamsValidation } from "pages/quotations/manage/schemas/validations";

import { serviceTypesForm } from "pages/quotations/manage/schemas/form";
import { serviceTypesInitialValues } from "pages/quotations/manage/schemas/initialValues";
import { serviceTypesValidation } from "pages/quotations/manage/schemas/validations";

import {
  serviceTeamNameOptions,
  serviceTypeNameOptions,
  paymentTypeOptions,
  defaultPaymentType,
} from "pages/quotations/manage/schemas/setups";

function InternalReference({
  mainForm,
  mainFormField,
  modeDisabled,
  defaultReadOnly,
  dispatchMainError,
}) {
  const { serviceTeams, serviceTypes, paymentType } = mainFormField;

  // destructure from Service Teams Form for current section
  const { formId: serviceTeamsFormId, formField: serviceTeamsFormField } =
    serviceTeamsForm;
  const { serviceTeamName } = serviceTeamsFormField;

  // destructure from Service Types Form for current section
  const { formId: serviceTypesFormId, formField: serviceTypesFormField } =
    serviceTypesForm;
  const { serviceTypeName } = serviceTypesFormField;

  return (
    <Card id="internal-reference" sx={{ overflow: "visible" }}>
      <MDBox m={3}>
        <MDBox mb={3}>
          <MDTypography variant="h5">Internal Reference</MDTypography>
        </MDBox>
        <MDBox>
          <Grid
            container
            rowSpacing={{ xs: 1, sm: 2 }}
            columnSpacing={{ xs: 1, sm: 3 }}
          >
            <Grid item xs={12} sm={6}>
              <FormInnerFieldArray
                editable={false}
                showTitle={false}
                form={mainForm}
                field={serviceTeams}
                max={20}
                disabled={defaultReadOnly || modeDisabled}
                dispatchError={dispatchMainError}
                itemInitialValues={serviceTeamsInitialValues}
                itemValidation={serviceTeamsValidation}
                itemKeyField={serviceTeamName} // TODO: change to uuid
                itemDisplayFields={[serviceTeamName]}
                itemForm={(formik, disabledField, disabledAdd) => {
                  return (
                    /* not using <Form> because cannot have form within another form (Customer.js),
                      in order not to trigger validation of sub formik when submitting main formik */
                    <MDBox id={serviceTeamsFormId} autoComplete="off">
                      <MDBox
                        bgColor="white"
                        borderRadius="lg"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="baseline"
                        flexWrap="nowrap"
                      >
                        <MDBox flexBasis="100%">
                          <FormAutocompleteFast
                            size="small"
                            form={formik}
                            field={serviceTeamName}
                            options={serviceTeamNameOptions}
                            disabled={disabledField}
                          />
                        </MDBox>
                        {!disabledAdd && (
                          <MDBox ml={2}>
                            <MDButton
                              size="small"
                              onClick={formik.handleSubmit}
                              color="success"
                              iconOnly
                            >
                              <AddIcon />
                            </MDButton>
                          </MDBox>
                        )}
                      </MDBox>
                    </MDBox>
                  );
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormInnerFieldArray
                editable={false}
                showTitle={false}
                form={mainForm}
                field={serviceTypes}
                max={20}
                disabled={defaultReadOnly || modeDisabled}
                dispatchError={dispatchMainError}
                itemInitialValues={serviceTypesInitialValues}
                itemValidation={serviceTypesValidation}
                itemKeyField={serviceTypeName} // TODO: change to uuid
                itemDisplayFields={[serviceTypeName]}
                itemForm={(formik, disabledField, disabledAdd) => {
                  return (
                    /* not using <Form> because cannot have form within another form (Customer.js),
                      in order not to trigger validation of sub formik when submitting main formik */
                    <MDBox id={serviceTypesFormId} autoComplete="off">
                      <MDBox
                        bgColor="white"
                        borderRadius="lg"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="baseline"
                        flexWrap="nowrap"
                      >
                        <MDBox flexBasis="100%">
                          <FormAutocompleteFast
                            size="small"
                            form={formik}
                            field={serviceTypeName}
                            options={serviceTypeNameOptions}
                            disabled={disabledField}
                          />
                        </MDBox>
                        {!disabledAdd && (
                          <MDBox ml={2}>
                            <MDButton
                              size="small"
                              onClick={formik.handleSubmit}
                              color="success"
                              iconOnly
                            >
                              <AddIcon />
                            </MDButton>
                          </MDBox>
                        )}
                      </MDBox>
                    </MDBox>
                  );
                }}
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <MDTypography variant="body3" fontWeight="bold">
                {paymentType.label}
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={10}>
              <FormRadioGroup
                row
                showTitle={false}
                form={mainForm}
                field={paymentType}
                options={paymentTypeOptions}
                defaultValue={defaultPaymentType}
                disabled={defaultReadOnly || modeDisabled}
              />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Card>
  );
}

InternalReference.propTypes = {
  mainForm: PropTypes.object.isRequired,
  mainFormField: PropTypes.object.isRequired,
  modeDisabled: PropTypes.bool.isRequired,
  defaultReadOnly: PropTypes.bool.isRequired,
};

export default InternalReference;
