import { Text, View } from "@react-pdf/renderer";
import styles from "pages/salesorders/pdf/styles";

function Subject({ values }) {
  return (
    <View style={[styles.containerCol, styles.section]}>
      <Text style={[styles.emailContent, { fontWeight: "bold" }]}>
        {values.salesorderTitle}
      </Text>
      <Text style={styles.emailContent}>{values.salesorderMessage} </Text>
    </View>
  );
}

export default Subject;
