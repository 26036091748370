import { useState, useEffect, useCallback } from "react";

import PropTypes from "prop-types";

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import MDBox from "components/atoms/MDBox";
import MDTypography from "components/atoms/MDTypography";

import FormTextFieldFast from "components/molecules/Formik/FormTextFieldFast";

import { useCollection } from "hooks/useCollection";

const customersQueries = {
  whereQueries: [
    {
      field: "deletedAt",
      condition: "==",
      value: null,
    },
    {
      field: "approvedAt",
      condition: "!=",
      value: null,
    },
  ],
};

function BillingInformation({
  mainForm,
  mainFormField,
  modeDisabled,
  defaultReadOnly,
  dispatchMainError,
}) {
  const { values, setFieldValue } = mainForm;
  const {
    customerId,
    customerName,
    customerAddress,
    billingContactName,
    billingContactLandlineNumber,
    billingContactMobileNumber,
    billingContactEmail,
    reminderToEmails,
  } = mainFormField;

  const [customerOptions, setCustomerOptions] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [billingContactOptions, setBillingContactOptions] = useState([]);
  const [selectedBillingContact, setSelectedBillingContact] = useState(null);

  const { collectionData: customersData } = useCollection(
    "customers",
    customersQueries
  );

  const resetCustomer = useCallback(() => {
    setSelectedCustomer(null);
    setFieldValue(customerId.name, "");
    setFieldValue(customerName.name, "");
    setFieldValue(customerAddress.name, "");
  }, [customerAddress.name, customerId.name, customerName.name, setFieldValue]);

  const resetBillingContact = useCallback(() => {
    setSelectedBillingContact(null);
    setBillingContactOptions([]);
    setFieldValue(billingContactName.name, "");
    setFieldValue(billingContactLandlineNumber.name, "");
    setFieldValue(billingContactMobileNumber.name, "");
    setFieldValue(billingContactEmail.name, "");
  }, [
    billingContactEmail.name,
    billingContactLandlineNumber.name,
    billingContactMobileNumber.name,
    billingContactName.name,
    setFieldValue,
  ]);

  useEffect(() => {
    try {
      const customers = customersData;
      setCustomerOptions(customers);

      // reset the existing customer when that customer is no longer in the selectable list
      if (!defaultReadOnly && !customers.includes(selectedCustomer)) {
        resetCustomer();
        resetBillingContact();
      }

      // if existing selected value is available
      if (defaultReadOnly) {
        const selectedCustomer = customers.find(
          (customer) => customer.id === values.customerId
        );
        if (selectedCustomer) {
          setSelectedCustomer(selectedCustomer);
          setBillingContactOptions(selectedCustomer.data.billingContacts);
        }
      }
    } catch (err) {
      dispatchMainError(err);
    }
  }, [
    customersData,
    defaultReadOnly,
    dispatchMainError,
    resetBillingContact,
    resetCustomer,
    selectedCustomer,
    values.contactPersonEmail,
    values.customerId,
    values.deliverySiteName,
  ]);

  useEffect(() => {
    try {
      if (!defaultReadOnly && !values.customerName) {
        setSelectedCustomer(null);
        setSelectedBillingContact(null);
      }
    } catch (err) {
      dispatchMainError(err);
    }
  }, [defaultReadOnly, dispatchMainError, values.customerName]);

  return (
    <Card id="billing-information" sx={{ overflow: "visible" }}>
      <MDBox m={3}>
        <MDBox mb={3}>
          <MDTypography variant="h5">Billing Information</MDTypography>
        </MDBox>
        <MDBox>
          <Grid container mb={2} columnSpacing={{ xs: 1, sm: 6 }}>
            <Grid item xs={12} sm={6}>
              <Grid container mb={6} rowSpacing={{ xs: 1, sm: 3 }}>
                <Grid item xs={12} sm={12}>
                  <Autocomplete
                    size="small"
                    value={selectedCustomer}
                    getOptionLabel={(option) => option.data.customerName}
                    onChange={(e, customer) => {
                      setSelectedCustomer(customer);
                      resetBillingContact();
                      // update options
                      setBillingContactOptions(customer.data.billingContacts);
                      // update customer info value
                      setFieldValue(customerId.name, customer.id);
                      setFieldValue(
                        customerName.name,
                        customer.data.customerName
                      );
                      setFieldValue(
                        customerAddress.name,
                        `${customer.data.customerAddress}, Singapore ${customer.data.customerPostalCode}`
                      );
                    }}
                    options={customerOptions}
                    disabled={defaultReadOnly || modeDisabled}
                    renderInput={(params) => (
                      <TextField {...params} label="Step 1: Select Customer" />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Autocomplete
                    size="small"
                    value={selectedBillingContact}
                    getOptionLabel={(option) => option.billingContactName}
                    onChange={(e, billingContact) => {
                      setSelectedBillingContact(billingContact);
                      if (billingContact) {
                        setFieldValue(
                          billingContactName.name,
                          billingContact.billingContactName
                        );
                        setFieldValue(
                          billingContactLandlineNumber.name,
                          billingContact.billingContactLandlineNumber
                        );
                        setFieldValue(
                          billingContactMobileNumber.name,
                          billingContact.billingContactMobileNumber
                        );
                        setFieldValue(
                          billingContactEmail.name,
                          billingContact.billingContactEmail
                        );
                        setFieldValue(
                          reminderToEmails.name,
                          values.reminderToEmails.push(
                            billingContact.billingContactEmail
                          )
                        );
                      }
                    }}
                    options={billingContactOptions}
                    disabled={modeDisabled}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Step 2: Select Billing Contact"
                      />
                    )}
                  />
                </Grid>
              </Grid>

              <Grid container mb={2} rowSpacing={{ xs: 1, sm: 1 }}>
                <Grid item xs={12} sm={12}>
                  <FormTextFieldFast
                    notTouchable={true}
                    form={mainForm}
                    field={billingContactName}
                    variant="standard"
                    disabled={modeDisabled}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormTextFieldFast
                    form={mainForm}
                    field={customerName}
                    variant="standard"
                    disabled={modeDisabled}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormTextFieldFast
                    notTouchable={true}
                    form={mainForm}
                    field={billingContactLandlineNumber}
                    variant="standard"
                    disabled={modeDisabled}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormTextFieldFast
                    notTouchable={true}
                    form={mainForm}
                    field={billingContactMobileNumber}
                    variant="standard"
                    disabled={modeDisabled}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormTextFieldFast
                    notTouchable={true}
                    form={mainForm}
                    field={billingContactEmail}
                    variant="standard"
                    disabled={modeDisabled}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormTextFieldFast
                    form={mainForm}
                    field={customerAddress}
                    variant="standard"
                    disabled={modeDisabled}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Card>
  );
}

BillingInformation.propTypes = {
  mainForm: PropTypes.object.isRequired,
  mainFormField: PropTypes.object.isRequired,
  modeDisabled: PropTypes.bool.isRequired,
  defaultReadOnly: PropTypes.bool.isRequired,
};

export default BillingInformation;
