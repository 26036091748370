import { Link } from "react-router-dom";
import Card from "@mui/material/Card";

import MDButton from "components/atoms/MDButton";
import MDBox from "components/atoms/MDBox";

import ErrorSnackbar from "components/molecules/Notifications/ErrorSnackbar";
import SuccessSnackbar from "components/molecules/Notifications/SuccessSnackbar";

import DashboardLayout from "components/templates/DashboardLayout";
import Topnav from "components/organisms/Topnav";
import DashboardFooter from "components/organisms/Footer/DashboardFooter";
import PermissionDenied from "components/organisms/PermissionDenied";
import InvalidOperation from "components/organisms/InvalidOperation";
import IncompleteOperation from "components/organisms/IncompleteOperation";
import SubmissionProgress from "components/organisms/SubmissionProgress";

import DataTable from "components/molecules/Tables/DataTable";

import { useRecordsManager } from "pages/customers/records/hooks/useRecordsManager";

import { AllowedTo } from "react-abac";
import { Permission } from "models/abac";

function DataTables() {
  const { columns, rows, hiddenColumns, response } = useRecordsManager();

  return (
    <DashboardLayout>
      <Topnav title="Customer Records" />
      <AllowedTo
        perform={Permission.READ_ALL_CUSTOMERS}
        no={() => <PermissionDenied />}
      />
      <MDBox pt={3} pb={3}>
        {response.isPending && <SubmissionProgress />}
        {response.error && (
          <>
            {response.error?.name === "OperationInvalidError" && (
              <InvalidOperation />
            )}
            {response.error?.name === "OperationIncompleteError" && (
              <IncompleteOperation />
            )}
            <ErrorSnackbar
              title="Customer Records"
              dateTime=""
              message={response.error?.message}
              autoDismiss
            />
          </>
        )}
        {response.success && (
          <SuccessSnackbar
            title="Customer Records"
            dateTime=""
            message={response.success}
            autoDismiss
          />
        )}
        {response.error?.name !== "OperationInvalidError" && (
          <Card>
            <MDBox m={3}>
              <DataTable
                table={{
                  columns: columns,
                  rows: rows,
                }}
                hiddenColumns={hiddenColumns}
                canGlobalSearch
                canColumnSearch
                canColumnSort
                actionMenu={
                  <MDBox>
                    <Link to="/customers/manage/new">
                      <MDButton variant="gradient" color="success">
                        + New Customer
                      </MDButton>
                    </Link>
                  </MDBox>
                }
              />
            </MDBox>
          </Card>
        )}
      </MDBox>
      <DashboardFooter />
    </DashboardLayout>
  );
}

export default DataTables;
