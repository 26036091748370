import * as Yup from "yup";
import form, {
  toEmailsForm,
  ccEmailsForm,
  bccEmailsForm,
  emailAttachmentsForm,
} from "pages/purchaseorders/pdf/schemas/form";

const {
  formField: {
    poPaymentEmailSubject,
    poPaymentEmailContent,
    goodsReceivedRemarks,
    fromName,
    fromEmail,
    toEmails,
    ccEmails,
    bccEmails,
    emailSubject,
    emailContent,
    emailAttachments,
  },
} = form;

const noValidation = Yup.object().shape({});

const sendValidations = Yup.object().shape({
  [fromName.name]: Yup.string()
    .required(fromName.errorMsg)
    .max(200, fromName.invalidMsg),
  [fromEmail.name]: Yup.string()
    .required(fromEmail.errorMsg)
    .max(200, fromEmail.invalidMsg),
  [toEmails.name]: Yup.array()
    // .min(1, toEmails.min1Msg)
    .max(20, toEmails.max20Msg),
  [ccEmails.name]: Yup.array()
    // .min(1, ccEmails.min1Msg)
    .max(20, ccEmails.max20Msg),
  [bccEmails.name]: Yup.array()
    // .min(1, bccEmails.min1Msg)
    .max(20, bccEmails.max20Msg),
  [emailSubject.name]: Yup.string()
    .required(emailSubject.errorMsg)
    .max(200, emailSubject.invalidMsg),
  [emailContent.name]: Yup.string()
    .required(emailContent.errorMsg)
    .max(20000, emailContent.invalidMsg),
  [emailAttachments.name]: Yup.array().max(20, emailAttachments.max20Msg),
});

const goodsReceivedValidations = Yup.object().shape({
  [goodsReceivedRemarks.name]: Yup.string()
    .required(goodsReceivedRemarks.errorMsg)
    .max(200, goodsReceivedRemarks.invalidMsg),
});

const completeValidations = Yup.object().shape({
  [poPaymentEmailSubject.name]: Yup.string()
    .required(poPaymentEmailSubject.errorMsg)
    .max(200, poPaymentEmailSubject.invalidMsg),
  [poPaymentEmailContent.name]: Yup.string()
    .required(poPaymentEmailContent.errorMsg)
    .max(20000, poPaymentEmailContent.invalidMsg),
});

const {
  formField: { toEmail },
} = toEmailsForm;

const toEmailsValidation = Yup.object().shape({
  [toEmail.name]: Yup.string()
    .required(toEmail.errorMsg)
    .max(200, toEmail.invalidMsg)
    .email(toEmail.invalidMsg),
});

const {
  formField: { ccEmail },
} = ccEmailsForm;

const ccEmailsValidation = Yup.object().shape({
  [ccEmail.name]: Yup.string()
    .required(ccEmail.errorMsg)
    .max(200, ccEmail.invalidMsg)
    .email(ccEmail.invalidMsg),
});

const {
  formField: { bccEmail },
} = bccEmailsForm;

const bccEmailsValidation = Yup.object().shape({
  [bccEmail.name]: Yup.string()
    .required(bccEmail.errorMsg)
    .max(200, bccEmail.invalidMsg)
    .email(bccEmail.invalidMsg),
});

const {
  formField: { emailAttachmentFile },
} = emailAttachmentsForm;

const emailAttachmentsValidation = Yup.object().shape({
  [emailAttachmentFile.name]: Yup.mixed()
    .nullable()
    .required(emailAttachmentFile.errorMsg)
    .test(
      "fileSize",
      emailAttachmentFile.invalidMsg,
      (value) => !value || (value && value.size <= 5 * 1024 * 1024)
    ),
});

export {
  sendValidations as default,
  noValidation,
  toEmailsValidation,
  ccEmailsValidation,
  bccEmailsValidation,
  emailAttachmentsValidation,
  goodsReceivedValidations,
  completeValidations,
};
