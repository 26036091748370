import { gstPercentage } from "pages/invoices/manage/schemas/setups";

const form = {
  formId: "invoice-form",
  formField: {
    invoiceTerms: {
      name: "invoiceTerms",
      label: "Terms",
      type: "text",
      errorMsg: "Terms is required.",
      invalidMsg: "Terms is invalid.",
    },
    reminderIntervalDays: {
      name: "reminderIntervalDays",
      label: "Interval Days",
      type: "text",
      errorMsg: "Interval Days is required.",
      invalidMsg: "Interval Days is invalid.",
    },
    reminderFromName: {
      name: "reminderFromName",
      label: "Sender Name",
      type: "text",
      errorMsg: "Sender Name is required.",
      invalidMsg: "Sender Name is invalid.",
    },
    reminderFromEmail: {
      name: "reminderFromEmail",
      label: "Sender Email",
      type: "text",
      errorMsg: "Sender Email is required.",
      invalidMsg: "Sender Email is invalid.",
    },
    reminderToEmails: {
      name: "reminderToEmails",
      label: "Recipient Emails",
      type: "box",
      min1Msg: "Must have at least 1 Recipient Email.",
      max20Msg: "Must have at most 20 Recipient Emails.",
    },
    reminderCcEmails: {
      name: "reminderCcEmails",
      label: "Cc Emails",
      type: "box",
      max20Msg: "Must have at most 20 CC Emails.",
    },
    reminderBccEmails: {
      name: "reminderBccEmails",
      label: "Bcc Emails",
      type: "box",
      max20Msg: "Must have at most 20 BCC Emails.",
    },
    reminderEmailSubject: {
      name: "reminderEmailSubject",
      label: "Subject",
      type: "text",
      errorMsg: "Subject is required.",
      invalidMsg: "Subject is invalid.",
    },
    reminderEmailContent: {
      name: "reminderEmailContent",
      label: "Content",
      type: "text",
      errorMsg: "Content is required.",
      invalidMsg: "Content is invalid.",
    },
    invoicingType: {
      name: "invoicingType",
      label: "Invoicing Type",
      type: "text",
      errorMsg: "Invoicing Type is required.",
      invalidMsg: "Invoicing Type is invalid.",
    },
    quotationRefForInvoice: {
      name: "quotationRefForInvoice",
      label: "Quote Ref",
      type: "text",
      errorMsg: "Quote Ref is required.",
      invalidMsg: "Quote Ref is invalid.",
    },
    purchaseorderRefForInvoice: {
      name: "purchaseorderRefForInvoice",
      label: "CPO Ref",
      type: "text",
      errorMsg: "CPO Ref is required.",
      invalidMsg: "CPO Ref is invalid.",
    },
    servicereportRefForInvoice: {
      name: "servicereportRefForInvoice",
      label: "SR Ref",
      type: "text",
      errorMsg: "SR Ref is required.",
      invalidMsg: "SR Ref is invalid.",
    },
    deliverySiteAddress: {
      name: "deliverySiteAddress",
      label: "Delivery Site Address",
      type: "text",
      errorMsg: "Delivery Site Address is required.",
      invalidMsg: "Delivery Site Address is invalid.",
    },
    salesorderReference: {
      name: "salesorderReference",
      label: "Sales Order Reference",
      type: "text",
      errorMsg: "Sales Order Reference is required.",
      invalidMsg: "Sales Order Reference is invalid.",
    },
    invoicingReference: {
      name: "invoicingReference",
      label: "Invoicing Reference",
      type: "text",
      errorMsg: "Invoicing Reference is required.",
      invalidMsg: "Invoicing Reference is invalid.",
    },
    invoiceReceiptingDate: {
      name: "invoiceReceiptingDate",
      label: "Receipt Date",
      type: "text",
      errorMsg: "Receipt Date is required.",
      invalidMsg: "Receipt Date is invalid.",
    },
    invoiceClosingDate: {
      name: "invoiceClosingDate",
      label: "Invoice Closing Date",
      type: "text",
      errorMsg: "Invoice Closing Date is required.",
      invalidMsg: "Invoice Closing Date is invalid.",
    },
    receiptingReference: {
      name: "receiptingReference",
      label: "Receipting Reference",
      type: "text",
      errorMsg: "Receipting Reference is required.",
      invalidMsg: "Receipting Reference is invalid.",
    },
    closingReference: {
      name: "closingReference",
      label: "Closing Reference",
      type: "text",
      errorMsg: "Closing Reference is required.",
      invalidMsg: "Closing Reference is invalid.",
    },
    paymentModes: {
      name: "paymentModes",
      label: "Payment Modes",
      type: "box",
      min1Msg: "Must have at least 1 Payment Mode.",
      max20Msg: "Must have at most 20 Payment Modes.",
    },
    invoiceRemarks: {
      name: "invoiceRemarks",
      label: "Remarks",
      type: "text",
      errorMsg: "Remarks is required.",
      invalidMsg: "Remarks is invalid.",
    },
    billingContactName: {
      name: "billingContactName",
      label: "Attention Person Name",
      type: "text",
      errorMsg: "Attention Person Name is required.",
      invalidMsg: "Attention Person Name is invalid.",
    },
    billingContactLandlineNumber: {
      name: "billingContactLandlineNumber",
      label: "Office / Home Contact Number",
      type: "text",
      errorMsg: "At least one contact number is required.",
      invalidMsg: "Office / Home Contact Number is invalid.",
    },
    billingContactMobileNumber: {
      name: "billingContactMobileNumber",
      label: "Mobile Contact Number",
      type: "text",
      errorMsg: "At least one contact number is required.",
      invalidMsg: "Mobile Contact Number is invalid.",
    },
    billingContactEmail: {
      name: "billingContactEmail",
      label: "Email",
      type: "text",
      errorMsg: "Email is required.",
      invalidMsg: "Email is invalid.",
    },
    serviceTeams: {
      name: "serviceTeams",
      label: "Service Teams",
      type: "box",
      min1Msg: "Must have at least 1 Service Team.",
      max20Msg: "Must have at most 20 Service Teams.",
    },
    serviceTypes: {
      name: "serviceTypes",
      label: "Service Types",
      type: "box",
      min1Msg: "Must have at least 1 Service Type.",
      max20Msg: "Must have at most 20 Service Types.",
    },
    paymentType: {
      name: "paymentType",
      label: "Payment Type",
      type: "text",
      errorMsg: "Payment Type is required.",
      invalidMsg: "Payment Type is invalid.",
    },
    invoiceDate: {
      name: "invoiceDate",
      label: "Date Issued",
      type: "text",
      errorMsg: "Date Issued is required.",
      invalidMsg: "Date Issued is invalid.",
    },
    invoiceNumber: {
      name: "invoiceNumber",
      label: "Invoice Number",
      type: "text",
      errorMsg: "Invoice Number is required.",
      invalidMsg: "Invoice Number is invalid.",
    },
    customerId: {
      name: "customerId",
      label: "Customer Id",
      type: "text",
      errorMsg: "Customer Id is required.",
      invalidMsg: "Customer Id is invalid.",
    },
    customerName: {
      name: "customerName",
      label: "Customer Name",
      type: "text",
      errorMsg: "Organization / Individual Name is required.",
      invalidMsg: "Organization / Individual Name is invalid.",
    },
    customerAddress: {
      name: "customerAddress",
      label: "Address",
      type: "text",
      errorMsg: "Address is required.",
      invalidMsg: "Address is invalid.",
    },
    invoiceTitle: {
      name: "invoiceTitle",
      label: "Invoice Title",
      type: "text",
      errorMsg: "Invoice Title is required.",
      invalidMsg: "Invoice Title is invalid.",
    },
    invoiceMessage: {
      name: "invoiceMessage",
      label: "Invoice Message",
      type: "text",
      errorMsg: "Invoice Message is required.",
      invalidMsg: "Invoice Message is invalid.",
    },
    invoiceItems: {
      name: "invoiceItems",
      label: "Invoice Items",
      type: "box",
      min1Msg: "Must have at least 1 Invoice Item.",
      max100Msg: "Must have at most 100 Invoice Items.",
    },
    invoiceDiscountType: {
      name: "invoiceDiscountType",
      label: "Discount Type",
      type: "text",
      errorMsg: "Discount Type is required.",
      invalidMsg: "Discount Type is invalid.",
    },
    invoiceGrossTotal: {
      name: "invoiceGrossTotal",
      label: "Gross Total",
      type: "text",
      errorMsg: "Gross Total is required.",
      invalidMsg: "Gross Total is invalid.",
    },
    invoiceDiscountTotal: {
      name: "invoiceDiscountTotal",
      label: "Discount",
      type: "text",
      errorMsg: "Discount Total is required.",
      invalidMsg: "Discount Total is invalid.",
    },
    invoiceBeforeGstTotal: {
      name: "invoiceBeforeGstTotal",
      label: "Total Amount Before GST",
      type: "text",
      errorMsg: "Total Amount Before GST is required.",
      invalidMsg: "Total Amount  Before GST is invalid.",
    },
    invoiceNetBeforeGstTotal: {
      name: "invoiceNetBeforeGstTotal",
      label: "Total Net Amount Before GST",
      type: "text",
      errorMsg: "Total Net Amount Before GST is required.",
      invalidMsg: "Total Net Amount  Before GST is invalid.",
    },
    invoiceGstAmount: {
      name: "invoiceGstAmount",
      label: `${gstPercentage}% GST`,
      type: "text",
      errorMsg: "GST Amount is required.",
      invalidMsg: "GST Amount is invalid.",
    },
    invoiceAfterGstTotal: {
      name: "invoiceAfterGstTotal",
      label: "Final Amount Inclusive of GST",
      type: "text",
      errorMsg: "Final Amount Inclusive of GST is required.",
      invalidMsg: "Final Amount Inclusive of GST is invalid.",
    },
    invoiceDeposit: {
      name: "invoiceDeposit",
      label: "Deposit",
      type: "text",
      errorMsg: "Deposit is required.",
      invalidMsg: "Deposit is invalid.",
    },
    invoiceAdditionalDiscount: {
      name: "invoiceAdditionalDiscount",
      label: "Additional Discount",
      type: "text",
      errorMsg: "Additional Discount is required.",
      invalidMsg: "Additional Discount is invalid.",
    },
    invoiceBalanceDue: {
      name: "invoiceBalanceDue",
      label: "Balance Due",
      type: "text",
      errorMsg: "Balance Due is required.",
      invalidMsg: "Balance Due is invalid.",
    },
    beginingStatement: {
      name: "beginingStatement",
      label: "Begining Statement",
      type: "text",
      errorMsg: "Begining Statement is required.",
      invalidMsg: "Begining Statement is invalid.",
    },
    installationPeriod: {
      name: "installationPeriod",
      label: "Installation Period",
      type: "text",
      errorMsg: "Installation Period is required",
      invalidMsg: "Installation Period is invalid",
    },
    installationStartDate: {
      name: "installationStartDate",
      label: "Start Date",
      type: "text",
      errorMsg: "Start Date is required.",
      invalidMsg: "Start Date is invalid.",
    },
    installationEndDate: {
      name: "installationEndDate",
      label: "End Date",
      type: "text",
      errorMsg: "End Date is required.",
      invalidMsg: "End Date is invalid.",
    },
    paymentTerm: {
      name: "paymentTerm",
      label: "Payment Term",
      type: "text",
      errorMsg: "Payment Term is required.",
      invalidMsg: "Payment Term is invalid.",
    },
    validity: {
      name: "validity",
      label: "Validity ",
      type: "text",
      errorMsg: "is required",
      invalidMsg: "is invalid",
    },
    exclusions: {
      name: "exclusions",
      label: "Exclusions",
      type: "box",
      min1Msg: "Must have at least 1 Exclusion.",
      max50Msg: "Must have at most 50 Exclusions.",
    },
    note: {
      name: "note",
      label: "Note",
      type: "text",
      errorMsg: "Note is required",
      invalidMsg: "Note is invalid",
    },
    endingStatement: {
      name: "endingStatement",
      label: "Ending Statement",
      type: "text",
      errorMsg: "Ending Statement is required.",
      invalidMsg: "Ending Statement is invalid.",
    },
    invoicingAttachments: {
      name: "invoicingAttachments",
      label: "Attachments",
      type: "box",
      min1Msg: "Must have at least 1 Attachment.",
      max20Msg: "Must have at most 20 Attachments.",
    },
    supportingAttachments: {
      name: "supportingAttachments",
      label: "Supporting Attachments",
      type: "box",
      min1Msg: "Must have at least 1 Attachment.",
      max20Msg: "Must have at most 20 Attachments.",
    },
    receiptingAttachments: {
      name: "receiptingAttachments",
      label: "Attachments",
      type: "box",
      errorMsg:
        "Either Receipting Reference or Receipting Attachment is required.",
      min1Msg: "Must have at least 1 Attachment.",
      max20Msg: "Must have at most 20 Attachments.",
    },
    closingAttachments: {
      name: "closingAttachments",
      label: "Attachments",
      type: "box",
      errorMsg: "Either Closing Reference or Closing Attachment is required.",
      min1Msg: "Must have at least 1 Attachment.",
      max20Msg: "Must have at most 20 Attachments.",
    },
  },
};

const paymentModesForm = {
  formId: "payment-modes-form",
  formField: {
    paymentModeName: {
      name: "paymentModeName",
      label: "Payment Mode",
      type: "text",
      errorMsg: "Payment Mode is required.",
      invalidMsg: "Payment Mode is invalid",
    },
  },
};

const serviceTeamsForm = {
  formId: "service-teams-form",
  formField: {
    serviceTeamName: {
      name: "serviceTeamName",
      label: "Service Team",
      type: "text",
      errorMsg: "Service Team is required.",
      invalidMsg: "Service Team is invalid",
    },
  },
};

const serviceTypesForm = {
  formId: "service-types-form",
  formField: {
    serviceTypeName: {
      name: "serviceTypeName",
      label: "Service Type",
      type: "text",
      errorMsg: "Service Type is required.",
      invalidMsg: "Service Type is invalid",
    },
  },
};

const invoicingAttachmentsForm = {
  formId: "invoicing-attachments-form",
  formField: {
    invoicingAttachmentFile: {
      name: "invoicingAttachmentFile",
      label: "Attachment File",
      type: "file",
      errorMsg: "Attachment File is required.",
      invalidMsg: "File size must be less than 5 MB.",
    },
    attachmentPath: {
      name: "attachmentPath",
      label: "Attachment Path",
      type: "text",
    },
    attachmentName: {
      name: "attachmentName",
      label: "Attachment Path",
      type: "text",
    },
    attachmentURL: {
      name: "attachmentURL",
      label: "Attachment Path",
      type: "text",
    },
  },
};

const supportingAttachmentsForm = {
  formId: "supporting-attachments-form",
  formField: {
    supportingAttachmentFile: {
      name: "supportingAttachmentFile",
      label: "Attachment File",
      type: "file",
      errorMsg: "Attachment File is required.",
      invalidMsg: "File size must be less than 5 MB.",
    },
    attachmentPath: {
      name: "attachmentPath",
      label: "Attachment Path",
      type: "text",
    },
    attachmentName: {
      name: "attachmentName",
      label: "Attachment Path",
      type: "text",
    },
    attachmentURL: {
      name: "attachmentURL",
      label: "Attachment Path",
      type: "text",
    },
  },
};

const receiptingAttachmentsForm = {
  formId: "receipting-attachments-form",
  formField: {
    receiptingAttachmentFile: {
      name: "receiptingAttachmentFile",
      label: "Attachment File",
      type: "file",
      errorMsg: "Attachment File is required.",
      invalidMsg: "File size must be less than 5 MB.",
    },
    attachmentPath: {
      name: "attachmentPath",
      label: "Attachment Path",
      type: "text",
    },
    attachmentName: {
      name: "attachmentName",
      label: "Attachment Path",
      type: "text",
    },
    attachmentURL: {
      name: "attachmentURL",
      label: "Attachment Path",
      type: "text",
    },
  },
};

const closingAttachmentsForm = {
  formId: "closing-attachments-form",
  formField: {
    closingAttachmentFile: {
      name: "closingAttachmentFile",
      label: "Attachment File",
      type: "file",
      errorMsg: "Attachment File is required.",
      invalidMsg: "File size must be less than 5 MB.",
    },
    attachmentPath: {
      name: "attachmentPath",
      label: "Attachment Path",
      type: "text",
    },
    attachmentName: {
      name: "attachmentName",
      label: "Attachment Path",
      type: "text",
    },
    attachmentURL: {
      name: "attachmentURL",
      label: "Attachment Path",
      type: "text",
    },
  },
};

const exclusionsForm = {
  formId: "exclusions-form",
  formField: {
    exclusionClause: {
      name: "exclusionClause",
      label: "Exclusion Clause",
      type: "text",
      errorMsg: "Exclusion Clause is required.",
      invalidMsg: "Exclusion Clause is invalid.",
    },
  },
};

const invoiceItemsForm = {
  formId: "invoice-items-form",
  formField: {
    itemOrdinal: {
      name: "itemOrdinal",
      label: "Ordinal",
      type: "text",
      errorMsg: "Ordinal is required.",
      invalidMsg: "Ordinal is invalid.",
    },
    itemNo: {
      name: "itemNo",
      label: "No.",
      type: "text",
      errorMsg: "Required.",
      invalidMsg: "Invalid.",
    },
    itemSubNo: {
      name: "itemSubNo",
      label: "Sub No.",
      type: "text",
      errorMsg: "Required.",
      invalidMsg: "Invalid.",
    },
    itemServiceType: {
      name: "itemServiceType",
      label: "Service Type",
      type: "text",
      errorMsg: "Required.",
      invalidMsg: "Invalid.",
    },
    itemDescription: {
      name: "itemDescription",
      label: "Description",
      type: "text",
      errorMsg: "Required.",
      invalidMsg: "Invalid.",
    },
    itemQuantity: {
      name: "itemQuantity",
      label: "Quantity",
      type: "text",
      errorMsg: "Required.",
      invalidMsg: "Invalid.",
    },
    itemUnitCost: {
      name: "itemUnitCost",
      label: "Unit Cost",
      type: "text",
      errorMsg: "Required.",
      invalidMsg: "Invalid.",
    },
    itemGrossAmount: {
      name: "itemGrossAmount",
      label: "Gross Amount",
      type: "text",
      errorMsg: "Required.",
      invalidMsg: "Invalid.",
    },
    itemDiscount: {
      name: "itemDiscount",
      label: "Discount",
      type: "text",
      errorMsg: "Required.",
      invalidMsg: "Invalid.",
    },
    itemNetAmount: {
      name: "itemNetAmount",
      label: "Net Amount",
      type: "text",
      errorMsg: "Required.",
      invalidMsg: "Invalid.",
    },
  },
};

export {
  form as default,
  paymentModesForm,
  serviceTeamsForm,
  serviceTypesForm,
  invoicingAttachmentsForm,
  supportingAttachmentsForm,
  receiptingAttachmentsForm,
  closingAttachmentsForm,
  exclusionsForm,
  invoiceItemsForm,
};
