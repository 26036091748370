import { useCallback } from "react";

import { pdf, Document, Page, View } from "@react-pdf/renderer";
import DocumentHeader from "pages/invoices/pdf/components/DocumentHeader";
import AuditTrail from "pages/invoices/pdf/components/AuditTrail";
import BillingInformation from "pages/invoices/pdf/components/BillingInformation";
import PriceQuote from "pages/invoices/pdf/components/PriceQuote";
import PaymentMode from "pages/invoices/pdf/components/PaymentMode";
import DocumentFooter from "pages/invoices/pdf/components/DocumentFooter";
import styles from "pages/invoices/pdf/styles";
import { useFile } from "hooks/useFile";

export const useInvoicePdfManager = () => {
  const { getFileRef, uploadFile } = useFile();

  const pdfDoc = (data) => (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={{ marginBottom: 15 }} fixed>
          <DocumentHeader />
          <AuditTrail values={data} />
        </View>
        <View style={styles.body}>
          <BillingInformation values={data} />
          <PriceQuote values={data} />
        </View>
        <View style={styles.body} break>
          <PaymentMode values={data} />
        </View>
        <View style={{ marginTop: 15 }} fixed>
          <DocumentFooter />
        </View>
      </Page>
    </Document>
  );

  const generateInvoicePdf = useCallback(
    async (data) => {
      try {
        const filePath = `Invoice-GA-INV-${data.invoiceNumber}.pdf`;

        let invoiceFileRef = null;

        try {
          invoiceFileRef = await getFileRef(filePath);
        } catch (err) {
          console.log(err);
        }

        if (!invoiceFileRef) {
          // const salesorder =
          //   !!data.salesorderReference &&
          //   (await retrieveDoc("salesorders", data.salesorderReference));

          // const quotation =
          //   !!salesorder?.data?.quotationReference &&
          //   (await retrieveDoc(
          //     "quotations",
          //     salesorder?.data?.quotationReference
          //   ));

          // const purchaseorderReference = !!salesorder?.data
          //   ?.purchaseorderReference
          //   ? salesorder?.data?.purchaseorderReference
          //   : "-";

          // const quotationNumber = !!quotation?.data?.quotationNumber
          //   ? quotation?.data?.quotationRevision === "0"
          //     ? `GA-Q-${quotation?.data?.quotationNumber}`
          //     : `GA-Q-${quotation?.data?.quotationNumber}R${quotation?.data?.quotationRevision}`
          //   : "-";

          const blob = await pdf(pdfDoc(data)).toBlob();
          const uploadedFileRef = await uploadFile(filePath, blob);

          invoiceFileRef = uploadedFileRef;
        }

        return invoiceFileRef;
      } catch (err) {
        throw err;
      }
    },
    [getFileRef, uploadFile]
  );

  return {
    generateInvoicePdf,
  };
};
