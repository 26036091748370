import { Text, View } from "@react-pdf/renderer";

import styles from "pages/salesorders/pdf/styles";

function AuditTrail({ values }) {
  return (
    <View
      style={[styles.containerRow, styles.spaceBetween, { marginTop: 10 }]}
      fixed
    >
      <Text fixed>Date: {values.salesorderDate}</Text>
      <Text style={{ fontWeight: "bold" }} fixed>
        SALES ORDER REF:{" "}
        <Text style={styles.samewidth}>GA-SO-{values.salesorderNumber}</Text>
      </Text>
    </View>
  );
}

export default AuditTrail;
