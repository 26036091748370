const emailSubjectOptions = [""];

const defaultSubject = emailSubjectOptions[0];

const emailContentOptions = [""];

const defaultContent = emailContentOptions[0];

export {
  emailSubjectOptions,
  defaultSubject,
  emailContentOptions,
  defaultContent,
};
