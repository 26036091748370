import form, {
  serviceTeamsForm,
  serviceTypesForm,
  commencementAttachmentsForm,
  depositAttachmentsForm,
  discountAttachmentsForm,
  completionAttachmentsForm,
  cancellationAttachmentsForm,
  exclusionsForm,
  salesItemsForm,
} from "pages/salesorders/manage/schemas/form";

import {
  defaultWorkorderNumber,
  defaultWorkStartDate,
  defaultWorkingDays,
  defaultCommencementType,
  defaultSalesorderDate,
  defaultSalesorderNumber,
  defaultSalesorderMessage,
  defaultBeginingStatement,
  defaultPaymentTerm,
  defaultValidity,
  defaultExclusions,
  defaultNote,
  defaultEndingStatement,
  defaultInstallationPeriod,
  defaultDiscountType,
  defaultPaymentType,
} from "pages/salesorders/manage/schemas/setups";

const {
  formField: {
    workorderNumber,
    workStartDate,
    workingDays,
    workRemarks,
    commencementType,
    verbalAgreement,
    pendingPO,
    quotationReference,
    purchaseorderReference,
    quotationRefForInvoice,
    completionReference,
    cancellationReference,
    serviceTeams,
    serviceTypes,
    paymentType,
    salesorderDate,
    salesorderNumber,
    contactPersonName,
    contactPersonLandlineNumber,
    contactPersonMobileNumber,
    contactPersonEmail,
    customerId,
    customerName,
    customerAddress,
    deliverySiteName,
    deliverySiteAddress,
    salesorderTitle,
    salesorderMessage,
    salesItems,
    salesDiscountType,
    salesGrossTotal,
    salesDiscountTotal,
    salesBeforeGstTotal,
    salesNetBeforeGstTotal,
    salesGstAmount,
    salesAfterGstTotal,
    salesDeposit,
    depositReference,
    salesAdditionalDiscount,
    discountReference,
    beginingStatement,
    installationPeriod,
    installationStartDate,
    installationEndDate,
    paymentTerm,
    validity,
    exclusions,
    note,
    endingStatement,
    commencementAttachments,
    depositAttachments,
    discountAttachments,
    completionAttachments,
    cancellationAttachments,
  },
} = form;

const initialValues = {
  [workorderNumber.name]: defaultWorkorderNumber,
  [workStartDate.name]: defaultWorkStartDate,
  [workingDays.name]: defaultWorkingDays,
  [workRemarks.name]: "",
  [commencementType.name]: defaultCommencementType,
  [verbalAgreement.name]: false,
  [pendingPO.name]: false,
  [quotationReference.name]: "",
  [purchaseorderReference.name]: "",
  [quotationRefForInvoice.name]: "",
  [completionReference.name]: "",
  [cancellationReference.name]: "",
  [serviceTeams.name]: [],
  [serviceTypes.name]: [],
  [paymentType.name]: defaultPaymentType,
  [salesorderDate.name]: defaultSalesorderDate,
  [salesorderNumber.name]: defaultSalesorderNumber,
  [contactPersonName.name]: "",
  [contactPersonLandlineNumber.name]: "",
  [contactPersonMobileNumber.name]: "",
  [contactPersonEmail.name]: "",
  [customerId.name]: "",
  [customerName.name]: "",
  [contactPersonEmail.name]: "",
  [customerAddress.name]: "",
  [deliverySiteName.name]: "",
  [deliverySiteAddress.name]: "",
  [salesorderTitle.name]: "",
  [salesorderMessage.name]: defaultSalesorderMessage,
  [salesItems.name]: [],
  [salesDiscountType.name]: defaultDiscountType,
  [salesGrossTotal.name]: "0.00",
  [salesDiscountTotal.name]: "0.00",
  [salesBeforeGstTotal.name]: "0.00",
  [salesNetBeforeGstTotal.name]: "0.00",
  [salesGstAmount.name]: "0.00",
  [salesAfterGstTotal.name]: "0.00",
  [salesDeposit.name]: "0.00",
  [depositReference.name]: "",
  [salesAdditionalDiscount.name]: "0.00",
  [discountReference.name]: "",
  [beginingStatement.name]: defaultBeginingStatement,
  [installationPeriod.name]: defaultInstallationPeriod,
  [installationStartDate.name]: null,
  [installationEndDate.name]: null,
  [paymentTerm.name]: defaultPaymentTerm,
  [validity.name]: defaultValidity,
  [exclusions.name]: defaultExclusions,
  [note.name]: defaultNote,
  [endingStatement.name]: defaultEndingStatement,
  [depositAttachments.name]: [],
  [discountAttachments.name]: [],
  [commencementAttachments.name]: [],
  [completionAttachments.name]: [],
  [cancellationAttachments.name]: [],
};

const {
  formField: { serviceTeamName },
} = serviceTeamsForm;

const serviceTeamsInitialValues = {
  [serviceTeamName.name]: "",
};

const {
  formField: { serviceTypeName },
} = serviceTypesForm;

const serviceTypesInitialValues = {
  [serviceTypeName.name]: "",
};

const {
  formField: { commencementAttachmentFile },
} = commencementAttachmentsForm;

const commencementAttachmentsInitialValues = {
  [commencementAttachmentFile.name]: "",
};

const {
  formField: { depositAttachmentFile },
} = depositAttachmentsForm;

const depositAttachmentsInitialValues = {
  [depositAttachmentFile.name]: "",
};

const {
  formField: { discountAttachmentFile },
} = discountAttachmentsForm;

const discountAttachmentsInitialValues = {
  [discountAttachmentFile.name]: "",
};

const {
  formField: { completionAttachmentFile },
} = completionAttachmentsForm;

const completionAttachmentsInitialValues = {
  [completionAttachmentFile.name]: "",
};

const {
  formField: { cancellationAttachmentFile },
} = cancellationAttachmentsForm;

const cancellationAttachmentsInitialValues = {
  [cancellationAttachmentFile.name]: "",
};

const {
  formField: { exclusionClause },
} = exclusionsForm;

const exclusionsInitialValues = {
  [exclusionClause.name]: "",
};

const {
  formField: {
    itemOrdinal,
    itemNo,
    itemSubNo,
    itemServiceType,
    itemDescription,
    itemServiceTeam,
    itemQuantity,
    itemUnitCost,
    itemGrossAmount,
    itemDiscount,
    itemNetAmount,
  },
} = salesItemsForm;

const salesItemsInitialValues = {
  [itemOrdinal.name]: "",
  [itemNo.name]: "",
  [itemSubNo.name]: "",
  [itemServiceType.name]: "",
  [itemDescription.name]: "",
  [itemServiceTeam.name]: "",
  [itemQuantity.name]: "1",
  [itemUnitCost.name]: "",
  [itemGrossAmount.name]: "0.00",
  [itemDiscount.name]: "0",
  [itemNetAmount.name]: "0.00",
};

export {
  initialValues as default,
  serviceTeamsInitialValues,
  serviceTypesInitialValues,
  commencementAttachmentsInitialValues,
  depositAttachmentsInitialValues,
  discountAttachmentsInitialValues,
  completionAttachmentsInitialValues,
  cancellationAttachmentsInitialValues,
  exclusionsInitialValues,
  salesItemsInitialValues,
};
