import PropTypes from "prop-types";

import { useCallback, useEffect, useState } from "react";

import TextField from "@mui/material/TextField";

import { useDebouncedCallback } from "use-debounce";

const INPUT_DELAY = 500;

function DebounceTextField({ value, onChange, ...rest }) {
  const [innerValue, setInnerValue] = useState("");

  useEffect(() => {
    if (value) {
      setInnerValue(value);
    } else {
      setInnerValue("");
    }
  }, [value]);

  const debouncedHandleOnChange = useDebouncedCallback((event) => {
    if (onChange) {
      onChange(event);
    }
  }, INPUT_DELAY);

  const handleOnChange = useCallback(
    (event) => {
      event.persist();

      const newValue = event.target.value;
      setInnerValue(newValue);
      debouncedHandleOnChange(event);
    },
    [debouncedHandleOnChange]
  );

  return <TextField value={innerValue} onChange={handleOnChange} {...rest} />;
}

// Setting default values for the props of DebounceTextField
DebounceTextField.defaultProps = {
  label: "",
};

// typechecking props for DebounceTextField
DebounceTextField.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default DebounceTextField;
