import { useEffect, useState } from "react";

import PropTypes from "prop-types";

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

import AddIcon from "@mui/icons-material/Add";

import MDBox from "components/atoms/MDBox";
import MDButton from "components/atoms/MDButton";
import MDTypography from "components/atoms/MDTypography";

import FormAutocompleteFast from "components/molecules/Formik/FormAutocompleteFast";
import FormInnerFieldArray from "components/molecules/Formik/FormInnerFieldArray";
import FormRadioGroup from "components/molecules/Formik/FormRadioGroup";
import FormDatePicker from "components/molecules/Formik/FormDatePicker";

import { exclusionsForm } from "pages/quotations/manage/schemas/form";
import { exclusionsInitialValues } from "pages/quotations/manage/schemas/initialValues";
import { exclusionsValidation } from "pages/quotations/manage/schemas/validations";

import parse from "date-fns/parse";

import {
  beginingStatementOptions,
  paymentTermOptions,
  validityOptions,
  noteOptions,
  endingStatementOptions,
  installationPeriodOptions,
  exclusionClauseOptions,
  defaultInstallationPeriod,
} from "pages/quotations/manage/schemas/setups";

function TermsAndConditions({
  mainForm,
  mainFormField,
  modeDisabled,
  dispatchMainError,
}) {
  const { values: mainFormValues, setFieldValue: setMainFormFieldValue } =
    mainForm;
  const {
    beginingStatement,
    installationPeriod,
    installationStartDate,
    installationEndDate,
    paymentTerm,
    validity,
    exclusions,
    note,
    endingStatement,
  } = mainFormField;

  // destructure from Exclusions Form for current section
  const { formId: exclusionsFormId, formField: exclusionsFormField } =
    exclusionsForm;
  const { exclusionClause } = exclusionsFormField;

  const [durationDisabled, setDurationDisabled] = useState(false);

  useEffect(() => {
    try {
      mainFormValues.installationPeriod === "TBC" && setDurationDisabled(true);
      mainFormValues.installationPeriod === "Duration" &&
        setDurationDisabled(false);
    } catch (err) {
      dispatchMainError(err);
    }
  }, [dispatchMainError, mainFormValues.installationPeriod]);

  return (
    <Card id="terms-and-conditions" sx={{ overflow: "visible" }}>
      <MDBox m={3}>
        <MDBox mb={3}>
          <MDTypography variant="h5">Terms and Conditions</MDTypography>
        </MDBox>
        <MDBox>
          <Grid
            container
            rowSpacing={{ xs: 1, sm: 2 }}
            columnSpacing={{ xs: 1, sm: 3 }}
          >
            <Grid item xs={12} sm={12}>
              <FormAutocompleteFast
                freeSolo
                form={mainForm}
                field={beginingStatement}
                options={beginingStatementOptions}
                disabled={modeDisabled}
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <MDTypography variant="body3" fontWeight="bold">
                {installationPeriod.label}
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={2}>
              <FormRadioGroup
                row
                showTitle={false}
                form={mainForm}
                field={installationPeriod}
                options={installationPeriodOptions}
                defaultValue={defaultInstallationPeriod}
                onChange={(event, value) => {
                  if (value === "TBC") {
                    setMainFormFieldValue(installationStartDate.name, null);
                    setMainFormFieldValue(installationEndDate.name, null);
                    setMainFormFieldValue(installationPeriod.name, value);
                    setDurationDisabled(true);
                  } else {
                    setMainFormFieldValue(installationPeriod.name, value);
                    setDurationDisabled(false);
                  }
                }}
                disabled={modeDisabled}
              />
            </Grid>
            <Grid item xs={12} sm={8}>
              <MDBox display="flex" alignItems="baseline" flexWrap="nowrap">
                <FormDatePicker
                  form={mainForm}
                  field={installationStartDate}
                  inputFormat={"dd/MM/yyyy"}
                  disabled={modeDisabled}
                  readOnly={durationDisabled}
                  minDate={new Date()}
                  {...(mainFormValues.installationEndDate &&
                  mainFormValues.installationEndDate.length > 0
                    ? {
                        maxDate: parse(
                          mainFormValues.installationEndDate,
                          "dd/MM/yyyy",
                          new Date()
                        ),
                      }
                    : {})}
                />
                <FormDatePicker
                  form={mainForm}
                  field={installationEndDate}
                  inputFormat={"dd/MM/yyyy"}
                  disabled={modeDisabled}
                  readOnly={durationDisabled}
                  minDate={
                    mainFormValues.installationStartDate &&
                    mainFormValues.installationStartDate.length > 0
                      ? parse(
                          mainFormValues.installationStartDate,
                          "dd/MM/yyyy",
                          new Date()
                        )
                      : new Date()
                  }
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} sm={2}>
              <MDTypography variant="body3" fontWeight="bold">
                {paymentTerm.label}
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={10}>
              <FormAutocompleteFast
                freeSolo
                form={mainForm}
                field={paymentTerm}
                options={paymentTermOptions}
                disabled={modeDisabled}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <MDTypography variant="body3" fontWeight="bold">
                {validity.label}
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={10}>
              <FormAutocompleteFast
                freeSolo
                form={mainForm}
                field={validity}
                options={validityOptions}
                disabled={modeDisabled}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <MDTypography variant="body3" fontWeight="bold">
                {exclusions.label}
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={10}>
              <FormInnerFieldArray
                editable={false}
                sortable={true}
                showTitle={false}
                form={mainForm}
                field={exclusions}
                max={20}
                disabled={modeDisabled}
                dispatchError={dispatchMainError}
                itemInitialValues={exclusionsInitialValues}
                itemValidation={exclusionsValidation}
                itemKeyField={exclusionClause} // TODO: change to uuid
                itemDisplayFields={[exclusionClause]}
                itemForm={(formik, disabledField, disabledAdd) => {
                  return (
                    /* not using <Form> because cannot have form within another form (Customer.js),
                      in order not to trigger validation of sub formik when submitting main formik */
                    <MDBox id={exclusionsFormId} autoComplete="off">
                      <MDBox
                        bgColor="white"
                        borderRadius="lg"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="baseline"
                        flexWrap="nowrap"
                      >
                        <MDBox flexBasis="100%">
                          <FormAutocompleteFast
                            freeSolo
                            size="small"
                            form={formik}
                            field={exclusionClause}
                            options={exclusionClauseOptions}
                            disabled={disabledField}
                          />
                        </MDBox>
                        {!disabledAdd && (
                          <MDBox ml={2}>
                            <MDButton
                              size="small"
                              onClick={formik.handleSubmit}
                              color="success"
                              iconOnly
                            >
                              <AddIcon />
                            </MDButton>
                          </MDBox>
                        )}
                      </MDBox>
                    </MDBox>
                  );
                }}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <MDTypography variant="body3" fontWeight="bold">
                {note.label}
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={10}>
              <FormAutocompleteFast
                freeSolo
                form={mainForm}
                field={note}
                options={noteOptions}
                disabled={modeDisabled}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormAutocompleteFast
                freeSolo
                form={mainForm}
                field={endingStatement}
                options={endingStatementOptions}
                disabled={modeDisabled}
              />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Card>
  );
}

TermsAndConditions.propTypes = {
  mainForm: PropTypes.object.isRequired,
  mainFormField: PropTypes.object.isRequired,
  modeDisabled: PropTypes.bool.isRequired,
};

export default TermsAndConditions;
