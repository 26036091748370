import { useEffect, useState, useReducer, useCallback } from "react";

import { useNavigate } from "react-router-dom";

import { useAuthContext } from "context/AuthContext";
import { useDocument } from "hooks/useDocument";
import { useCollection } from "hooks/useCollection";

import Tooltip from "@mui/material/Tooltip";
import MDBox from "components/atoms/MDBox";
import MDButton from "components/atoms/MDButton";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import PrintIcon from "@mui/icons-material/Print";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import EmailIcon from "@mui/icons-material/Email";
import DeleteIcon from "@mui/icons-material/Delete";
import CircularProgress from "@mui/material/CircularProgress";

import BlockIcon from "@mui/icons-material/Block";
import PaymentsIcon from "@mui/icons-material/Payments";
import VerifiedIcon from "@mui/icons-material/Verified";
import CancelIcon from "@mui/icons-material/Cancel";
import ContentCopy from "@mui/icons-material/ContentCopyOutlined";

import { AllowedTo, useAbac } from "react-abac";
import { Permission } from "models/abac";

import {
  columnOptions,
  defaultPurchaseorderColumns,
} from "pages/purchaseorders/manage/schemas/setups";

import { parse } from "json2csv";

import currency from "currency.js";

import _ from "lodash";

const purchaseordersQueries = {
  whereQueries: [
    {
      field: "deletedAt",
      condition: "==",
      value: null,
    },
  ],
};

const usersQueries = {
  whereQueries: [
    {
      field: "deletedAt",
      condition: "==",
      value: null,
    },
  ],
};

let initialState = {
  isPending: false,
  data: null,
  success: null,
  error: null,
};

const schemaReducer = (state, action) => {
  switch (action.type) {
    case "DISMISS":
      return initialState;
    case "IS_PENDING":
      return {
        isPending: true,
        data: null,
        success: null,
        error: null,
      };
    case "EXPORTED_PURCHASEORDERS":
      return {
        isPending: false,
        data: action.payload,
        success: "Successfully exported the purchase orders.",
        error: null,
      };
    case "APPROVED_PURCHASEORDER":
      return {
        isPending: false,
        data: action.payload,
        success: `Successfully approved the purchaseorder, ${action.payload.purchaseorderNumber}R${action.payload.purchaseorderRevision}.`,
        error: null,
      };
    case "REJECTED_PURCHASEORDER":
      return {
        isPending: false,
        data: action.payload,
        success: `Successfully rejected the purchaseorder, ${action.payload.purchaseorderNumber}R${action.payload.purchaseorderRevision}.`,
        error: null,
      };
    case "VOID_PURCHASEORDER":
      return {
        isPending: false,
        data: action.payload,
        success: `Successfully void the purchaseorder, ${action.payload.purchaseorderNumber}R${action.payload.purchaseorderRevision}.`,
        error: null,
      };
    case "COPIED_PURCHASEORDER":
      return {
        isPending: false,
        data: action.payload,
        success: `Successfully void the purchaseorder, ${action.payload.purchaseorderNumber}R${action.payload.purchaseorderRevision}.`,
        error: null,
      };
    case "ERROR":
      return {
        isPending: false,
        data: null,
        success: null,
        error: action.error,
      };
    default:
      return state;
  }
};

export const useRecordsManager = () => {
  const [response, dispatch] = useReducer(schemaReducer, initialState);
  const [hiddenColumns, setHiddenColumns] = useState([]);
  const [userData, setUserData] = useState(null);
  const [isDataReady, setIsDataReady] = useState(false);
  const [isUnmounted, setIsUnmounted] = useState(false);
  const [isChanged, setIsChanged] = useState(0);

  const { user } = useAuthContext();
  const { userHasPermissions } = useAbac();

  const { updateDoc, retrieveDoc, deleteDoc, serverTimestamp } = useDocument();
  const { collectionData: purchaseordersData } = useCollection(
    "purchaseorders",
    purchaseordersQueries
  );
  const { collectionData: usersData } = useCollection("users", usersQueries);

  const dispatchIfNotUnmounted = useCallback(
    (action) => {
      if (!isUnmounted) {
        dispatch(action);
      }
    },
    [isUnmounted]
  );

  const dispatchDismiss = useCallback(
    () => dispatchIfNotUnmounted({ type: "DISMISS" }),
    [dispatchIfNotUnmounted]
  );

  const dispatchError = useCallback(
    (err) => {
      console.error(err);
      if (
        !["PermissionDeniedError", "OperationInvalidError"].includes(err.name)
      ) {
        err.message = "The operation couldn't be completed";
        err.name = "OperationIncompleteError";
        // TODO: send error stack to server
      }
      dispatchIfNotUnmounted({
        type: "ERROR",
        error: err,
      });
    },
    [dispatchIfNotUnmounted]
  );

  const navigate = useNavigate();

  const toPresentationValue = (data) => {
    try {
      const toDateString = (value) => {
        return !!value ? value.toDate().toLocaleDateString("en-Sg") : "-";
      };

      const toDisplayName = (userId) => {
        const user = usersData.find((document) => document.id === userId);
        return userId ? (user ? user.data.displayName : "Unknown") : "-";
      };

      const toAmountString = (value) => {
        return value ? currency(value).format() : "-";
      };

      data.purchaseorderDateText = toDateString(data.purchaseorderDate);
      data.approvedAtText = toDateString(data.approvedAt);
      data.createdAtText = toDateString(data.createdAt);
      data.modifiedAtText = toDateString(data.modifiedAt);
      data.deletedAtText = toDateString(data.deletedAt);
      data.sentAtText = toDateString(data.sentAt);
      data.voidAtText = toDateString(data.voidAt);
      data.copyAtText = toDateString(data.copyAt);
      data.poPaymentEmailSentAtText = toDateString(data.poPaymentEmailSentAt);

      data.approvedByText = toDisplayName(data.approvedBy);
      data.createdByText = toDisplayName(data.createdBy);
      data.modifiedByText = toDisplayName(data.modifiedBy);
      data.deletedByText = toDisplayName(data.deletedBy);
      data.sentByText = toDisplayName(data.sentBy);
      data.voidByText = toDisplayName(data.voidBy);
      data.copyByText = toDisplayName(data.copyBy);
      data.poPaymentEmailSentByText = toDisplayName(data.poPaymentEmailSentBy);
      data.poCancelEmailSentByText = toDisplayName(data.poCancelEmailSentBy);

      data.purchaseorderAfterGstTotalText = toAmountString(
        data.purchaseorderAfterGstTotal
      );
      data.purchaseorderBeforeGstTotalText = toAmountString(
        data.purchaseorderBeforeGstTotalText
      );
      data.purchaseorderDiscountTotalText = toAmountString(
        data.purchaseorderDiscountTotal
      );
      data.purchaseorderGrossTotalText = toAmountString(
        data.purchaseorderGrossTotal
      );
      data.purchaseorderGstAmountText = toAmountString(
        data.purchaseorderGstAmount
      );
      data.purchaseorderFinalTotal = toAmountString(
        data.purchaseorderFinalTotal
      );

      data.purchaseorderNumberRevision = `GA-PO-${data.purchaseorderNumber}R${data.purchaseorderRevision}`;

      const isDraft = data.purchaseorderNumber === "---------";
      const isApproved = data.approvedByText !== "-";
      const isSent = data.sentByText !== "-";

      if (!isDraft) {
        // condition check follow the process flow, earlier at top, later at bottom
        if (!isApproved) {
          data.status = "Pending Approval";
        }
        if (isApproved) {
          data.status = "Approved";
        }
        if (isSent) {
          data.status = "Purchase Order Sent";
        }
      } else {
        data.status = "-";
      }

      return data;
    } catch (err) {
      dispatchError(err);
    }
  };

  const setupTable = useCallback(async () => {
    const userData = user && (await retrieveDoc("users", user.uid));
    const preferredPurchaseorderColumnsKey =
      userData.data?.preferredPurchaseorderColumns?.map((elem) => elem.key);
    const defaultPurchaseorderColumnsKey = defaultPurchaseorderColumns.map(
      (elem) => elem.key
    );

    const selectedColumns =
      preferredPurchaseorderColumnsKey ?? defaultPurchaseorderColumnsKey;
    const allColumnsKey = columnOptions.map((elem) => elem.key);
    const unselectedColumns = allColumnsKey.filter(
      (columnKey) => !selectedColumns.includes(columnKey)
    );

    setUserData(userData);
    setHiddenColumns(unselectedColumns);
    setIsDataReady(true);
  }, [retrieveDoc, user]);

  useEffect(() => {
    try {
      setupTable();
    } catch (err) {
      dispatchError(err);
    }
    return () => {
      setIsUnmounted(true);
    };
  }, [dispatchError, setupTable, isChanged]);

  const handleUserPreference = async (newdata) => {
    try {
      await updateDoc("users", user.uid, {
        preferredPurchaseorderColumns: newdata.preferredPurchaseorderColumns,
        modifiedAt: serverTimestamp(),
        modifiedBy: user.uid,
      });
      setIsChanged(isChanged + 1);
    } catch (err) {
      dispatchError(err);
    }
  };

  const handleApprove = async (collectionPath, documentId) => {
    try {
      dispatchIfNotUnmounted({ type: "IS_PENDING" });

      console.log(
        "handleApprove-> userHasPermissions(Permission.APPROVE_PURCHASEORDER): " +
          userHasPermissions(Permission.APPROVE_PURCHASEORDER)
      );
      if (userHasPermissions(Permission.APPROVE_PURCHASEORDER)) {
        const updatedDoc = await updateDoc(collectionPath, documentId, {
          approvedAt: serverTimestamp(),
          approvedBy: user.uid,
        });
        dispatchIfNotUnmounted({
          type: "APPROVED_PURCHASEORDER",
          payload: updatedDoc.data,
        });
      } else {
        let error = new Error(
          "Permission Denied. You are not allowed to approve purchaseorder."
        );
        error.name = "PermissionDeniedError";
        throw error;
      }
    } catch (err) {
      dispatchError(err);
    }
  };

  const handleReject = async (collectionPath, documentId) => {
    try {
      dispatchIfNotUnmounted({ type: "IS_PENDING" });
      console.log(
        "handleReject-> userHasPermissions(Permission.REJECT_PURCHASEORDER): " +
          userHasPermissions(Permission.REJECT_PURCHASEORDER)
      );
      if (userHasPermissions(Permission.REJECT_PURCHASEORDER)) {
        const deletedDoc = await deleteDoc(collectionPath, documentId, {
          deletedAt: serverTimestamp(),
          deletedBy: user.uid,
        });
        dispatchIfNotUnmounted({
          type: "REJECTED_PURCHASEORDER",
          payload: deletedDoc.data,
        });
      } else {
        let error = new Error(
          "Permission Denied. You are not allowed to reject purchaseorder."
        );
        error.name = "PermissionDeniedError";
        throw error;
      }
    } catch (err) {
      dispatchError(err);
    }
  };

  const handleVoid = async (collectionPath, documentId) => {
    try {
      dispatchIfNotUnmounted({ type: "IS_PENDING" });

      if (userHasPermissions(Permission.VOID_PURCHASEORDER)) {
        const updatedDoc = await updateDoc(collectionPath, documentId, {
          voidAt: serverTimestamp(),
          voidBy: user.uid,
        });
        dispatchIfNotUnmounted({
          type: "VOID_PURCHASEORDER",
          payload: updatedDoc.data,
        });
      } else {
        let error = new Error(
          "Permission Denied. You are not allowed to void the purchaseorder."
        );
        error.name = "PermissionDeniedError";
        throw error;
      }
    } catch (err) {
      dispatchError(err);
    }
  };

  const handleCopy = async (collectionPath, documentId) => {
    try {
      dispatchIfNotUnmounted({ type: "IS_PENDING" });

      if (userHasPermissions(Permission.COPY_PURCHASEORDER)) {
        const updatedDoc = await updateDoc(collectionPath, documentId, {
          copyAt: serverTimestamp(),
          copyBy: user.uid,
        });
        dispatchIfNotUnmounted({
          type: "COPIED_PURCHASEORDER",
          payload: updatedDoc.data,
        });
        navigate(`/purchaseorders/manage/copy/` + documentId);
      } else {
        let error = new Error(
          "Permission Denied. You are not allowed to void the purchaseorder."
        );
        error.name = "PermissionDeniedError";
        throw error;
      }
    } catch (err) {
      dispatchError(err);
    }
  };

  const toExportValue = (data) => {
    try {
      const toDateString = (value) => {
        return value ? value.toDate().toLocaleDateString("en-Sg") : "-";
      };

      const toDisplayName = (userId) => {
        const user = usersData.find((document) => document.id === userId);
        return userId ? (user ? user.data.displayName : "Unknown") : "-";
      };

      let typeofExpense = data.typeofBusinessPurchase;
      if (data.typeofPurchase === "Admin") {
        typeofExpense = data.typeofAdminPurchase;
      }

      let gstAmount = data.purchaseorderGstAmount;
      let afterGstTotal = data.purchaseorderAfterGstTotal;
      if (data.gstOption === "No GST") {
        gstAmount = 0;
        afterGstTotal = 0;
      }

      return {
        createdBy: toDisplayName(data.createdBy),
        modifiedBy: toDisplayName(data.modifiedBy),
        purchaseorderDate: toDateString(data.purchaseorderDate),
        purchaseorderNumber: data.purchaseorderNumber,
        supplierReference: data.supplierReference,
        SOReference: data.SOReference,
        expenseType: data.typeofPurchase,
        typeofExpense: typeofExpense,
        purchaseDescription: data.purchaseDescription,
        supplierName: data.supplierName,
        contactPersonName: data.contactPersonName,
        purchaseorderDiscountType: data.purchaseorderDiscountType,
        gstOption: data.gstOption,
        purchaseorderGrossTotal: data.purchaseorderGrossTotal,
        purchaseorderDiscountTotal: data.purchaseorderDiscountTotal,
        purchaseorderBeforeGstTotal: data.purchaseorderBeforeGstTotal,
        purchaseorderGstAmount: gstAmount,
        purchaseorderAfterGstTotal: afterGstTotal,
        voidAt: toDateString(data.voidAt),
        voidBy: toDisplayName(data.voidBy),
        cancelEmailSentAt: toDateString(data.poCancelEmailSentAt),
        cancelEmailSentBy: toDisplayName(data.poCancelEmailSentBy),
        receivedAt: data.goodsReceivedAt,
        receivedBy: data.goodsReceivedBy,
        paymentEmailSentAt: toDateString(data.poPaymentEmailSentAt),
        paymentEmailSentBy: toDisplayName(data.poPaymentEmailSentBy),
      };
    } catch (err) {
      dispatchError(err);
    }
  };

  const handleExport = async (permission) => {
    try {
      dispatchIfNotUnmounted({ type: "IS_PENDING" });
      if (userHasPermissions(permission)) {
        const fields = [
          "createdBy",
          "modifiedBy",
          "purchaseorderDate",
          "purchaseorderNumber",
          "supplierReference",
          "SOReference",
          "expenseType",
          "typeofExpense",
          "purchaseDescription",
          "supplierName",
          "contactPersonName",
          "purchaseorderDiscountType",
          "gstOption",
          "purchaseorderGrossTotal",
          "purchaseorderDiscountTotal",
          "purchaseorderBeforeGstTotal",
          "purchaseorderGstAmount",
          "purchaseorderAfterGstTotal",
          "voidAt",
          "voidBy",
          "cancelEmailSentAt",
          "cancelEmailSentBy",
          "receivedAt",
          "receivedBy",
          "paymentEmailSentAt",
          "paymentEmailSentBy",
        ];
        const opts = { fields };

        const data =
          purchaseordersData &&
          purchaseordersData.map((onepurchaseorder) =>
            toExportValue(onepurchaseorder.data)
          );

        try {
          const csv = parse(data, opts);
          let csvContent = `data:text/csv;charset=utf-8,${encodeURIComponent(
            csv
          )}`;
          const encodedUri = csvContent;
          window.open(encodedUri);
        } catch (err) {
          console.error(err);
        }
        dispatchIfNotUnmounted({
          type: "EXPORTED_PURCHASEORDERS",
          payload: null,
        });
      } else {
        let error = new Error(
          "Permission Denied. You are not allowed to export purchase orders."
        );
        error.name = "PermissionDeniedError";
        throw error;
      }
    } catch (err) {
      dispatchError(err);
    }
  };

  const rows =
    purchaseordersData &&
    Object.entries(_.groupBy(purchaseordersData, "data.purchaseorderNumber"))
      .flatMap((group) => {
        // find if any sent purchaseorder revision of the purchaseorder number
        const sentPurchaseOrder = group[1].find(
          (element) => element.data.sentAt !== null
        );
        if (sentPurchaseOrder) {
          return [sentPurchaseOrder]; // only the sent purchaseorder revision
        } else {
          return group[1]; // all purchaseorder revisions of the purchaseorder number
        }
      })
      .map((purchaseorder) => {
        return toPresentationValue({
          id: purchaseorder.id,
          ...purchaseorder.data,
        });
      });

  const tooltipCell = (accessor) => {
    return (
      <Tooltip title={accessor ?? "-"}>
        <MDBox>
          {accessor ? accessor.substring(0, 30) : "-"}
          {accessor && accessor.length > 30 ? "..." : ""}
        </MDBox>
      </Tooltip>
    );
  };

  const columns = [
    {
      Header: "Id",
      Footer: "Id",
      accessor: "id",
    },
    {
      Header: "Purchase Order Number",
      Footer: "Purchase Order Number",
      accessor: "purchaseorderNumber",
    },
    {
      Header: "Purchase Order Revision",
      Footer: "Purchase Order Revision",
      accessor: "purchaseorderRevision",
    },
    {
      Header: "Purchase Order",
      Footer: "Purchase Order",
      accessor: "purchaseorderNumberRevision",
      Cell: ({ cell }) => {
        const purchaseorderNumberRevision =
          cell.row.values.purchaseorderNumberRevision;
        return purchaseorderNumberRevision !== "GA-PO-000000-00R0" ? (
          purchaseorderNumberRevision
        ) : (
          <MDBox display="flex">
            <CircularProgress color="info" size={24} />
          </MDBox>
        );
      },
    },
    {
      Header: "PO Date",
      Footer: "PO Date",
      accessor: "purchaseorderDateText",
    },
    {
      Header: "Description",
      Footer: "Description",
      accessor: "purchaseDescription",
      Cell: ({ cell }) => {
        const purchaseDescription = cell.row.values.purchaseDescription;
        return tooltipCell(purchaseDescription);
      },
    },
    {
      Header: "Supplier",
      Footer: "Supplier",
      accessor: "supplierName",
      Cell: ({ cell }) => {
        const supplierName = cell.row.values.supplierName;
        return tooltipCell(supplierName);
      },
    },
    {
      Header: "Splr Ref",
      Footer: "Splr Ref",
      accessor: "supplierReference",
      Cell: ({ cell }) => {
        const supplierReference = cell.row.values.supplierReference;
        return tooltipCell(supplierReference);
      },
    },

    {
      Header: "Cntct Pers",
      Footer: "Cntct Pers",
      accessor: "contactPersonName",
      Cell: ({ cell }) => {
        const contactPersonName = cell.row.values.contactPersonName;
        return tooltipCell(contactPersonName);
      },
    },
    {
      Header: "LL No.",
      Footer: "LL No.",
      accessor: "contactPersonLandlineNumber",
      Cell: ({ cell }) => {
        const contactPersonLandlineNumber =
          cell.row.values.contactPersonLandlineNumber;
        return tooltipCell(contactPersonLandlineNumber);
      },
    },
    {
      Header: "Mobile No.",
      Footer: "Mobile No.",
      accessor: "contactPersonMobileNumber",
      Cell: ({ cell }) => {
        const contactPersonMobileNumber =
          cell.row.values.contactPersonMobileNumber;
        return tooltipCell(contactPersonMobileNumber);
      },
    },
    {
      Header: "Email",
      Footer: "Email",
      accessor: "contactPersonEmail",
      Cell: ({ cell }) => {
        const contactPersonEmail = cell.row.values.contactPersonEmail;
        return tooltipCell(contactPersonEmail);
      },
    },
    {
      Header: "Dlv TBC",
      Footer: "Dlv TBC",
      accessor: "deliveryDateDesc",
      Cell: ({ cell }) => {
        const deliveryDateDesc = cell.row.values.deliveryDateDesc;
        return tooltipCell(deliveryDateDesc);
      },
    },
    {
      Header: "Dlv Date",
      Footer: "Dlv Date",
      accessor: "deliveryDate",
    },
    {
      Header: "Dlv Site",
      Footer: "Dlv Site",
      accessor: "deliverySiteName",
      Cell: ({ cell }) => {
        const deliverySiteName = cell.row.values.deliverySiteName;
        return tooltipCell(deliverySiteName);
      },
    },
    {
      Header: "Dlv Site Address",
      Footer: "Dlv Site Address",
      accessor: "deliverySiteAddress",
      Cell: ({ cell }) => {
        const deliverySiteAddress = cell.row.values.deliverySiteAddress;
        return tooltipCell(deliverySiteAddress);
      },
    },
    {
      Header: "Created At",
      Footer: "Created At",
      accessor: "createdAtText",
    },
    {
      Header: "Created By",
      Footer: "Created By",
      accessor: "createdByText",
      Cell: ({ cell }) => {
        const createdByText = cell.row.values.createdByText;
        return tooltipCell(createdByText);
      },
    },
    {
      Header: "Modified At",
      Footer: "Modified At",
      accessor: "modifiedAtText",
    },
    {
      Header: "Modified By",
      Footer: "Modified By",
      accessor: "modifiedByText",
      Cell: ({ cell }) => {
        const modifiedByText = cell.row.values.modifiedByText;
        return tooltipCell(modifiedByText);
      },
    },
    {
      Header: "SO Ref",
      Footer: "SO Ref",
      accessor: "SOReference",
    },
    {
      Header: "Currency",
      Footer: "Currency",
      accessor: "purchaseorderCurrency",
    },
    {
      Header: "Term",
      Footer: "Term",
      accessor: "purchaseorderTerm",
    },
    {
      Header: "Pu Type",
      Footer: "Pu Type",
      accessor: "typeofPurchase",
    },
    {
      Header: "Biz Pu Type",
      Footer: "Biz Pu Type",
      accessor: "typeofBusinessPurchase",
    },
    {
      Header: "Adm Pu Type",
      Footer: "Adm Pu Type",
      accessor: "typeofAdminPurchase;",
    },

    {
      Header: "Disc Type",
      Footer: "Disc Type",
      accessor: "purchaseorderDiscountType",
      Cell: ({ cell }) => {
        const purchaseorderDiscountType =
          cell.row.values.purchaseorderDiscountType;
        return tooltipCell(purchaseorderDiscountType);
      },
    },
    {
      Header: "GST Option",
      Footer: "GST Option",
      accessor: "gstOption",
    },
    {
      Header: "Ttl Amt",
      Footer: "Ttl Amt",
      accessor: "purchaseorderGrossTotal",
    },
    {
      Header: "Ttl Amt",
      Footer: "Ttl Amt",
      accessor: "purchaseorderGrossTotalText",
    },
    {
      Header: "Discount",
      Footer: "Discount",
      accessor: "purchaseorderDiscountTotalText",
    },
    {
      Header: "Ttl w/o GST",
      Footer: "Ttl w/o GST",
      accessor: "purchaseorderBeforeGstTotalText",
    },
    {
      Header: "GST Amt",
      Footer: "GST Amt",
      accessor: "purchaseorderGstAmountText",
    },
    {
      Header: "Fnl w/ Amt",
      Footer: "Fnl w/ Amt",
      accessor: "purchaseorderAfterGstTotalText",
    },
    {
      Header: "Fnl w/o Amt",
      Footer: "Fnl w/o Amt",
      accessor: "purchaseorderFinalTotalText",
    },
    {
      Header: "Aprved At",
      Footer: "Aprved At",
      accessor: "approvedAtText",
    },
    {
      Header: "Aprved By",
      Footer: "Aprved By",
      accessor: "approvedByText",
      Cell: ({ cell }) => {
        const approvedByText = cell.row.values.approvedByText;
        const isApproved = approvedByText !== "-";
        const purchaseorderNumber = cell.row.values.purchaseorderNumber;
        const isDraft = purchaseorderNumber === "---------";
        return !isDraft && !isApproved ? (
          <>
            <AllowedTo perform={Permission.APPROVE_PURCHASEORDER}>
              <MDButton
                variant="gradient"
                color="success"
                iconOnly
                onClick={() =>
                  handleApprove("purchaseorders", cell.row.values.id, user.uid)
                }
              >
                <ThumbUpIcon />
              </MDButton>
              &nbsp;&nbsp;
              <MDButton
                variant="gradient"
                color="error"
                iconOnly
                onClick={() =>
                  handleReject("purchaseorders", cell.row.values.id, user.uid)
                }
              >
                <ThumbDownIcon />
              </MDButton>
            </AllowedTo>
          </>
        ) : (
          <>{approvedByText}</>
        );
      },
    },
    {
      Header: "Sent At",
      Footer: "Sent At",
      accessor: "sentAtText",
    },
    {
      Header: "Sent By",
      Footer: "Sent By",
      accessor: "sentByText",
      Cell: ({ cell }) => {
        const approvedAtText = cell.row.values.approvedAtText;
        const isApproved = approvedAtText !== "-";
        const sentAtText = cell.row.values.sentAtText;
        const isSent = sentAtText !== "-";
        const sentByText = cell.row.values.sentByText;
        return isApproved && !isSent ? (
          <>
            <AllowedTo perform={Permission.SEND_PURCHASEORDER}>
              <MDButton
                variant="gradient"
                color="success"
                iconOnly
                onClick={() =>
                  navigate(`/purchaseorders/pdf/send/${cell.row.values.id}`)
                }
              >
                <EmailIcon />
              </MDButton>
            </AllowedTo>
          </>
        ) : (
          <>{sentByText}</>
        );
      },
    },
    {
      Header: "Status",
      Footer: "Status",
      accessor: "status",
    },
    {
      Header: "Pymt Inf At",
      Footer: "Pymt Inf At",
      accessor: "poPaymentEmailSentAtText",
    },
    {
      Header: "Pymt Inf By",
      Footer: "Pymt Inf By",
      accessor: "poPaymentEmailSentByText",
    },

    {
      Header: "Void At",
      Footer: "Void At",
      accessor: "voidAtText",
    },
    {
      Header: "Void By",
      Footer: "Void By",
      accessor: "voidByText",
      Cell: ({ cell }) => {
        const voidByText = cell.row.values.voidByText;
        return tooltipCell(voidByText);
      },
    },

    {
      Header: "Cancel Date",
      Footer: "Cancel Date",
      accessor: "poCancelDate",
    },
    {
      Header: "Cancel Email Sent By",
      Footer: "Cencel Email Sent By",
      accessor: "poCancelEmailSentByText",
      Cell: ({ cell }) => {
        const poCancelEmailSentByText = cell.row.values.poCancelEmailSentByText;
        return tooltipCell(poCancelEmailSentByText);
      },
    },
    {
      Header: "Cancel Remarks",
      Footer: "Cancel Remarks",
      accessor: "poCancelRemarks",
      Cell: ({ cell }) => {
        const poCancelRemarks = cell.row.values.poCancelRemarks;
        return tooltipCell(poCancelRemarks);
      },
    },
    {
      Header: "Gds Rcvd At",
      Footer: "Gds Rcvd At",
      accessor: "goodsReceivedAt",
    },
    {
      Header: "Gds Rcvd By",
      Footer: "Gds Rcvd By",
      accessor: "goodsReceivedBy",
      Cell: ({ cell }) => {
        const goodsReceivedBy = cell.row.values.goodsReceivedBy;
        return tooltipCell(goodsReceivedBy);
      },
    },
    {
      Header: "Copy At",
      Footer: "Copy At",
      accessor: "copyAtText",
    },
    {
      Header: "Copy By",
      Footer: "Copy By",
      accessor: "copyByText",
      Cell: ({ cell }) => {
        const copyByText = cell.row.values.copyByText;
        return tooltipCell(copyByText);
      },
    },
    {
      Header: "Deleted At",
      Footer: "Deleted At",
      accessor: "deletedAtText",
    },
    {
      Header: "Deleted By",
      Footer: "Deleted By",
      accessor: "deletedByText",
      Cell: ({ cell }) => {
        const deletedByText = cell.row.values.deletedByText;
        return tooltipCell(deletedByText);
      },
    },
    {
      Header: "Completion Actions",
      Footer: "Completion Actions",
      accessor: "completionActions",
      Cell: ({ cell }) => {
        const sentAtText = cell.row.values.sentAtText;
        const isSent = sentAtText !== "-";
        const poPaymentEmailSentAtText =
          cell.row.values.poPaymentEmailSentAtText;
        const isPaymentEmailSent = poPaymentEmailSentAtText !== "-";
        const poCancelEmailSentByText = cell.row.values.poCancelEmailSentByText;
        const isCancelEmailSent = poCancelEmailSentByText !== "-";
        const goodsReceivedAtText = cell.row.values.goodsReceivedAtText;
        const isGoodsReceived = goodsReceivedAtText !== "-";
        const voidAtText = cell.row.values.voidAtText;
        const isVoid = voidAtText !== "-";

        return isSent ? (
          isVoid ? (
            <>Purchase Order Voided</>
          ) : isCancelEmailSent ? (
            <>Purchase Order Cancelled </>
          ) : isGoodsReceived && isPaymentEmailSent ? (
            <>Goods Received, Payment Made</>
          ) : isGoodsReceived ? (
            <>
              Goods Received &nbsp;
              <MDButton
                variant="gradient"
                color="success"
                iconOnly
                onClick={() =>
                  navigate(`/purchaseorders/pdf/complete/${cell.row.values.id}`)
                }
              >
                <PaymentsIcon />
              </MDButton>
            </>
          ) : isPaymentEmailSent ? (
            <>
              Payment Made &nbsp;
              <MDButton
                variant="gradient"
                color="success"
                iconOnly
                onClick={() =>
                  navigate(`/purchaseorders/pdf/receive/${cell.row.values.id}`)
                }
              >
                <VerifiedIcon />
              </MDButton>
            </>
          ) : (
            <>
              <MDButton
                variant="gradient"
                color="success"
                iconOnly
                onClick={() =>
                  handleVoid("purchaseorders", cell.row.values.id, user.uid)
                }
              >
                <BlockIcon />
              </MDButton>
              &nbsp;
              <MDButton
                variant="gradient"
                color="success"
                iconOnly
                onClick={() =>
                  navigate(`/purchaseorders/pdf/cancel/${cell.row.values.id}`)
                }
              >
                <CancelIcon />
              </MDButton>
              &nbsp;
              <MDButton
                variant="gradient"
                color="success"
                iconOnly
                onClick={() =>
                  navigate(`/purchaseorders/pdf/receive/${cell.row.values.id}`)
                }
              >
                <VerifiedIcon />
              </MDButton>
              &nbsp;
              <MDButton
                variant="gradient"
                color="success"
                iconOnly
                onClick={() =>
                  navigate(`/purchaseorders/pdf/complete/${cell.row.values.id}`)
                }
              >
                <PaymentsIcon />
              </MDButton>
            </>
          )
        ) : (
          <>{isSent}</>
        );
      },
    },
    {
      Header: "Actions",
      Footer: "Actions",
      accessor: "actions",
      disableFilters: true,
      disableGlobalFilter: true,
      disableSortBy: true,
      Cell: ({ cell }) => {
        const purchaseorderNumber = cell.row.values.purchaseorderNumber;
        const isDraft = purchaseorderNumber === "---------";
        const sentAtText = cell.row.values.sentAtText;
        const isSent = sentAtText !== "-";
        const voidAtText = cell.row.values.voidAtText;
        const isVoid = voidAtText !== "-";
        const copyAtText = cell.row.values.copyAtText;
        const isCopy = copyAtText !== "-";
        return (
          <>
            {isVoid && !isCopy && (
              <AllowedTo perform={Permission.COPY_PURCHASEORDER}>
                <MDButton
                  variant="gradient"
                  color="info"
                  iconOnly
                  onClick={() =>
                    handleCopy("purchaseorders", cell.row.values.id, user.uid)
                  }
                >
                  <ContentCopy />
                </MDButton>
                &nbsp;&nbsp;
              </AllowedTo>
            )}
            {isSent && (
              <AllowedTo perform={Permission.READ_PURCHASEORDER}>
                <MDButton
                  variant="gradient"
                  color="info"
                  iconOnly
                  onClick={() =>
                    navigate(`/purchaseorders/pdf/print/${cell.row.values.id}`)
                  }
                >
                  <PrintIcon />
                </MDButton>
                &nbsp;&nbsp;
              </AllowedTo>
            )}
            {!isSent && (
              <AllowedTo perform={Permission.READ_PURCHASEORDER}>
                <MDButton
                  variant="gradient"
                  color="info"
                  iconOnly
                  onClick={() =>
                    navigate(
                      `/purchaseorders/manage/view/${cell.row.values.id}`
                    )
                  }
                >
                  <VisibilityIcon />
                </MDButton>
                &nbsp;&nbsp;
              </AllowedTo>
            )}
            {!isSent && (
              <AllowedTo perform={Permission.UPDATE_PURCHASEORDER}>
                <MDButton
                  variant="gradient"
                  color="info"
                  iconOnly
                  onClick={() =>
                    isDraft
                      ? navigate(
                          `/purchaseorders/manage/draft/${cell.row.values.id}`
                        )
                      : navigate(
                          `/purchaseorders/manage/edit/${cell.row.values.id}`
                        )
                  }
                >
                  <EditIcon />
                </MDButton>
                &nbsp;&nbsp;
              </AllowedTo>
            )}
            <AllowedTo perform={Permission.DELETE_PURCHASEORDER}>
              <MDButton
                variant="gradient"
                color="warning"
                iconOnly
                onClick={() =>
                  navigate(
                    `/purchaseorders/manage/delete/${cell.row.values.id}`
                  )
                }
              >
                <DeleteIcon />
              </MDButton>
            </AllowedTo>
          </>
        );
      },
    },
  ];

  return {
    columns,
    rows,
    hiddenColumns,
    response,
    userData,
    handleExport,
    handleUserPreference,
    dispatchDismiss,
    dispatchError,
    isDataReady,
  };
};
