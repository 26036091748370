// Don't handle exception in this React Hook to allow exception bubbling
// Handle exception at upwards level:
//   send error log back to server
//   show generic error message on snackbar to inform user to report to admin

import { useCallback } from "react";

export const useQuotationActionManager = () => {
  const getActionState = useCallback((data) => {
    const isDraft = data.quotationNumber === "---------";

    const actions = [
      {
        name: "created",
        time: data.createdAt ? data.createdAt.toMillis() : null,
      },
      {
        name: "modified",
        time: data.modifiedAt ? data.modifiedAt.toMillis() : null,
      },
      {
        name: "approved",
        time: data.approvedAt ? data.approvedAt.toMillis() : null,
      },
      {
        name: "rejected",
        time: data.rejectedAt ? data.rejectedAt.toMillis() : null,
      },
      { name: "sent", time: data.sentAt ? data.sentAt.toMillis() : null },
      {
        name: "revised",
        time: data.revisedAt ? data.revisedAt.toMillis() : null,
      },
      {
        name: "converted",
        time: data.convertedAt ? data.convertedAt.toMillis() : null,
      },
      {
        name: "deleted",
        time: data.deletedAt ? data.deletedAt.toMillis() : null,
      },
    ];

    const times = actions
      .map((action) => action.time)
      .filter((time) => time !== null);

    const latestAction = actions.find(
      (action) => action.time === Math.max(...times)
    );

    const canApprove =
      !isDraft &&
      ["created", "modified", "revised"].includes(latestAction.name);
    const canReject =
      !isDraft &&
      ["created", "modified", "approved", "sent", "revised"].includes(
        latestAction.name
      );
    const canSend =
      !isDraft && ["approved", "sent"].includes(latestAction.name);
    const canRevise = !isDraft && ["sent"].includes(latestAction.name);
    const canConvert = !isDraft && ["sent"].includes(latestAction.name);
    const canPrint =
      !isDraft && !["created", "modified"].includes(latestAction.name);
    const canEdit =
      isDraft ||
      (!isDraft &&
        ["created", "modified", "approved", "rejected", "revised"].includes(
          latestAction.name
        ));
    const canDelete = true;

    return {
      latestAction,
      canApprove,
      canReject,
      canSend,
      canRevise,
      canConvert,
      canDelete,
      canEdit,
      canPrint,
    };
  }, []);

  return {
    getActionState,
  };
};
