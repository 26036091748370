import React from "react";
import { Text, View, Image } from "@react-pdf/renderer";
import styles from "pages/invoices/pdf/styles";
import qrcode from "assets/images/paynow/qrcode.png";

const modeChequePayment = (
  <View style={[styles.containerCol]}>
    <Text style={styles.indentContent}>
      Cheque should be made payable to{" "}
      <Text style={styles.bold}>GATE Pte Ltd</Text>.
    </Text>
    <Text style={styles.indentContent}>
      Kindly indicate invoice number on back of the cheque.
    </Text>
  </View>
);
const modeBankTransfer = (
  <View style={[styles.containerCol]}>
    <Text style={[styles.indentContent, styles.bold]}>DBS Bank</Text>
    <Text style={styles.indentContent}>Bank SWIFT Code: DBSS SGSG</Text>
    <Text style={styles.indentContent}>
      Account Name: <Text style={styles.bold}> GATE Pte Ltd</Text>
    </Text>
    <Text style={styles.indentContent}>
      Account No: <Text style={styles.bold}>072-654552-0</Text>
    </Text>
    <Text style={styles.indentContent}>
      Kindly indicate invoice number under remarks.
    </Text>
  </View>
);
const modePayNow = (
  <View
    style={[
      styles.containerRow,
      styles.indentContent,
      { alignItems: "center" },
    ]}
  >
    <Image style={[{ height: "80" }]} src={qrcode} />
    <View style={styles.containerCol}>
      <View style={[styles.containerCol, styles.container]}>
        <Text style={styles.indentContent}>1. Log into Internet Banking</Text>
        <Text style={styles.indentContent}>
          2. Select <Text style={styles.bold}>Scan to Pay</Text> or PayNow{" "}
          <Text style={styles.bold}>UEN 201119238GSGD</Text>
        </Text>
        <Text style={styles.indentContent}>3. Enter invoice number</Text>
        <Text style={styles.indentContent}>
          4. WhatsApp confirmation to <Text style={styles.bold}>9090 8382</Text>
        </Text>
      </View>
    </View>
  </View>
);

function PaymentMode({ values }) {
  return (
    <>
      <View style={[styles.containerCol, { flexGrow: 99 }]}>
        <View style={[styles.containerCol, styles.section]}>
          <Text style={[styles.emailContent, styles.pmheader]}>
            Payment Modes
          </Text>

          {!!values.paymentModes &&
            values.paymentModes.map((mode, index) => {
              let modeDetail = "";
              switch (mode.paymentModeName) {
                case "Cheque Payment":
                  modeDetail = modeChequePayment;
                  break;
                case "Bank Transfer":
                  modeDetail = modeBankTransfer;
                  break;
                case "PayNow":
                  modeDetail = modePayNow;
                  break;
                default:
                  modeDetail = <></>;
              }
              return (
                <>
                  <View style={styles.emailContent}></View>
                  <View
                    key={mode.paymentModeName}
                    style={[
                      styles.containerCol,
                      styles.container,
                      styles.indentContent,
                    ]}
                  >
                    <Text style={[styles.emailContent, { fontWeight: "bold" }]}>
                      {`${index + 1}. ${mode.paymentModeName}`}
                    </Text>
                    {modeDetail}
                  </View>
                </>
              );
            })}
        </View>

        <View style={[styles.containerCol, styles.section]}>
          {/* TODO:: move gate company details into configurable parameter in Admin dashboard */}
          <Text style={styles.emailContent}>
            If you have any queries, please contact Accounts Department at
          </Text>
          <Text style={styles.emailContent}>
            Email: accounts@gifted-school.com
          </Text>
          <Text style={styles.emailContent}>
            Whatsapp message only: 9090 8382
          </Text>
        </View>
        <View style={[styles.containerCol, styles.section]}>
          <Text style={styles.emailContent}>
            Thank you for your support and kind attention.
          </Text>
        </View>
      </View>
      <View style={[styles.containerCol, { flexGrow: 1 }]}>
        <View style={[styles.containerCol, styles.section]}>
          <Text style={styles.emailContent}>
            This is a computer generated invoice. No signature is required.
          </Text>
        </View>
      </View>
    </>
  );
}

export default PaymentMode;
