import PropTypes from "prop-types";

import { useEffect, useCallback, useState } from "react";

import Card from "@mui/material/Card";

import MDBox from "components/atoms/MDBox";
import MDTypography from "components/atoms/MDTypography";
import MDButton from "components/atoms/MDButton";
import PrintIcon from "@mui/icons-material/Print";
import CircularProgress from "@mui/material/CircularProgress";

import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import printJS from "print-js";

import { useInvoicePdfManager } from "pages/invoices/hooks/useInvoicePdfManager";

import "pages/invoices/pdf/styles/styles.css";
function InvoicePreview({
  mainForm,
  mainFormField,
  mode,
  response,
  dispatchMainError,
}) {
  const { setFieldValue: setMainFormFieldValue } = mainForm;
  const { emailAttachments } = mainFormField;
  const { generateInvoicePdf } = useInvoicePdfManager();

  const [fileRef, setFileRef] = useState(null);
  const [numPages, setNumPages] = useState(null);

  const getInvoicePdf = useCallback(async () => {
    const invoiceFileRef = await generateInvoicePdf(response.data);
    setFileRef(invoiceFileRef);

    const emailAtts = response.data?.supportingAttachments
      ? [
          {
            attachmentPath: invoiceFileRef.path,
            attachmentName: invoiceFileRef.name,
            attachmentURL: invoiceFileRef.url,
          },
          ...response.data.supportingAttachments,
        ]
      : [
          {
            attachmentPath: invoiceFileRef.path,
            attachmentName: invoiceFileRef.name,
            attachmentURL: invoiceFileRef.url,
          },
        ];

    if (mode === "send") {
      setMainFormFieldValue(emailAttachments.name, emailAtts);
    }
  }, [
    emailAttachments.name,
    generateInvoicePdf,
    mode,
    response.data,
    setMainFormFieldValue,
  ]);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  useEffect(() => {
    try {
      getInvoicePdf();
    } catch (err) {
      dispatchMainError(err);
    }
  }, [dispatchMainError, getInvoicePdf, mode]);

  return (
    <Card id="invoice-preview" sx={{ overflow: "visible" }}>
      <MDBox m={3}>
        {mode === "print" && (
          <MDBox mb={-5} display="flex" justifyContent="flex-end">
            <MDButton
              variant="gradient"
              color="success"
              onClick={() => printJS(fileRef?.url)}
            >
              <PrintIcon /> &nbsp; Print
            </MDButton>
          </MDBox>
        )}
        <MDBox mb={3}>
          <MDTypography variant="h5">Invoice Preview</MDTypography>
        </MDBox>

        <MDBox
          height="65vh"
          overflow="auto"
          justifyContent="center"
          className="all-page-container"
        >
          <Document
            file={fileRef?.url}
            options={{ workerSrc: "/pdf.worker.js" }}
            loading={<CircularProgress />}
            noData={<CircularProgress />}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            {Array.from(new Array(numPages), (el, index) => (
              <Page key={`page_${index + 1}`} pageNumber={index + 1} />
            ))}
          </Document>
        </MDBox>
      </MDBox>
    </Card>
  );
}

InvoicePreview.propTypes = {
  mode: PropTypes.string.isRequired,
};

export default InvoicePreview;
