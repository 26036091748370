const emailSubjectOptions = ["Payment:"];

const defaultSubject = emailSubjectOptions[0];

const emailContentOptions = [
  `Dear Sir/Madam

Please find attached invoice for your payment. (Refer to invoice for payment remarks)

Do send us the screenshot of payment confirmation once completed. 

Invoice No:
Payment due date:
Date of visit:
Description of Work:


Please do not hesitate to contact us if you have any queries.
Thank you for your continued support and we look forward to hearing from you soon.`,
  `Dear Sir/Madam

Please find attached invoice(s) for your payment. (Refer to invoice(s) for payment remarks)

Do send us the screenshot of payment confirmation once completed. 

S/N    	Invoice No	    Location of Work	    Description of Work
1			
2			
3			
4			
5			


Please do not hesitate to contact us if you have any queries.
Thank you for your continued support and we look forward to hearing from you soon.`,
  `Dear Sir/Madam

Please find attached invoice(s) for your payment. (Refer to invoice for payment remarks)

Do send us the screenshot of payment confirmation once completed. We will be mailing the originals to Address_________ by tomorrow. 

Kindly provide your new mailing address if our record is outdated.

S/N    	Invoice No    	Location of Work    	Description of Work
1			
2			
3			
4			
5			


Please do not hesitate to contact us if you have any queries.
Thank you for your continued support and we look forward to hearing from you soon.`,
];

const defaultContent = emailContentOptions[0];

export {
  emailSubjectOptions,
  defaultSubject,
  emailContentOptions,
  defaultContent,
};
