import { useState, useRef } from "react";

import PropTypes from "prop-types";

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import { TransitionGroup } from "react-transition-group";

import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import VisibilityIcon from "@mui/icons-material/Visibility";

import MDBox from "components/atoms/MDBox";
import MDButton from "components/atoms/MDButton";
import MDTypography from "components/atoms/MDTypography";
import FormField from "components/molecules/FormField";

import { Formik } from "formik";

import { emailAttachmentsForm } from "pages/salesorders/pdf/schemas/form";
import { emailAttachmentsInitialValues } from "pages/salesorders/pdf/schemas/initialValues";
import { emailAttachmentsValidation } from "pages/salesorders/pdf/schemas/validations";

import { useFile } from "hooks/useFile";

import { v4 as uuidv4 } from "uuid";

function Attachments({
  mainForm,
  mainFormField,
  modeDisabled,
  dispatchMainError,
}) {
  const [selectedAttachmentIndex, setSelectedAttachmentIndex] = useState(null);
  const [choosenFile, setChoosenFile] = useState(null);
  const inputFile = useRef();
  // const [isEditing, setIsEditing] = useState(false);

  const { uploadFile, deleteFile /*,dispatchError*/ } = useFile();
  // destructure from Main Form for entire Sales Order
  const { values: mainFormValues, setFieldValue: setMainFormFieldValue } =
    mainForm;
  const { emailAttachments } = mainFormField;

  // destructure from Attachments Form for current section
  const {
    formId: emailAttachmentsFormId,
    formField: emailAttachmentsFormField,
  } = emailAttachmentsForm;
  const { emailAttachmentFile } = emailAttachmentsFormField;

  function renderItem(
    emailAttachment,
    index,
    selectedIndex,
    handleRemove,
    handleListItemClick
  ) {
    return (
      <>
        <ListItem
          secondaryAction={
            !modeDisabled && (
              <>
                <IconButton
                  size="small"
                  color="error"
                  disabled={index !== selectedIndex}
                  onClick={() => handleRemove(index)}
                >
                  <DeleteIcon />
                </IconButton>
              </>
            )
          }
          disablePadding
        >
          <ListItemButton
            selected={selectedIndex === index}
            onClick={() => handleListItemClick(index)}
          >
            <IconButton
              edge="start"
              size="small"
              color="info"
              disabled={index !== selectedIndex}
              href={emailAttachment.attachmentURL}
              target="_blank"
              rel="noopener"
            >
              <VisibilityIcon />
            </IconButton>
            <ListItemText
              sx={{ ml: 1 }}
              primary={emailAttachment.attachmentName}
            />
          </ListItemButton>
        </ListItem>
      </>
    );
  }

  const handleAttachmentListItemClick = (index) => {
    try {
      setSelectedAttachmentIndex(index);
    } catch (err) {
      dispatchMainError(err);
    }
  };

  const handleRemoveAttachment = async (index) => {
    try {
      await deleteFile(mainFormValues.emailAttachments[index].attachmentPath);
      mainFormValues.emailAttachments.splice(index, 1);
      setMainFormFieldValue(
        emailAttachments.name,
        mainFormValues.emailAttachments
      );
    } catch (err) {
      dispatchMainError(err);
    }
  };

  const submitAttachment = async (values, { resetForm, setSubmitting }) => {
    try {
      if (choosenFile) {
        const uploadedFile = await uploadFile(
          `${uuidv4()}/${choosenFile.name}`,
          choosenFile
        );

        values.attachmentPath = uploadedFile.path;
        values.attachmentName = uploadedFile.name;
        values.attachmentURL = uploadedFile.url;
        if (
          !mainFormValues.emailAttachments
            .map((element) => element.attachmentURL)
            .includes(values.attachmentURL)
        ) {
          setMainFormFieldValue(emailAttachments.name, [
            ...mainFormValues.emailAttachments,
            values,
          ]);
        }
      }
      setChoosenFile(null);
      inputFile.current.value = "";
      resetForm({ values: emailAttachmentsInitialValues });
      setSubmitting(false);
    } catch (err) {
      dispatchMainError(err);
      setSubmitting(false);
    }
  };

  return (
    <Card id="attachments" sx={{ overflow: "visible" }}>
      <MDBox m={3}>
        <MDBox mb={3}>
          <MDTypography variant="h5">Attachments</MDTypography>
        </MDBox>
        <MDBox>
          <Grid
            container
            rowSpacing={{ xs: 1, sm: 2 }}
            columnSpacing={{ xs: 1, sm: 3 }}
          >
            <Grid item xs={12} sm={6}>
              <Formik
                initialValues={emailAttachmentsInitialValues}
                validationSchema={emailAttachmentsValidation}
                onSubmit={submitAttachment}
              >
                {({
                  values,
                  errors,
                  touched,
                  isSubmitting,
                  handleSubmit,
                  setFieldValue: setSectionFormFieldValue,
                }) => (
                  <MDBox id={emailAttachmentsFormId} autoComplete="off">
                    <MDBox p={1} bgColor="light" borderRadius="lg">
                      <MDBox
                        bgColor="white"
                        borderRadius="lg"
                        p={1}
                        mb={1}
                        display="flex"
                        justifyContent="space-between"
                        alignItems="baseline"
                        flexWrap="nowrap"
                      >
                        <MDBox flexBasis="100%">
                          <TextField
                            inputRef={inputFile}
                            size="small"
                            type="file"
                            fullWidth
                            onChange={(e) => {
                              const file = e.currentTarget.files[0];
                              if (file) {
                                setSectionFormFieldValue(
                                  emailAttachmentFile.name,
                                  file
                                );
                                if (file.size < 5 * 1024 * 1024) {
                                  setChoosenFile(file);
                                }
                              }
                            }}
                            disabled={modeDisabled}
                          />
                          <FormField
                            type={emailAttachmentFile.type}
                            name={emailAttachmentFile.name}
                          />
                        </MDBox>
                        {!modeDisabled && (
                          <MDBox ml={2}>
                            <MDButton
                              size="small"
                              onClick={handleSubmit}
                              color="success"
                              disabled={
                                (!!mainFormValues.emailAttachments &&
                                  mainFormValues.emailAttachments.length >=
                                    20) ||
                                isSubmitting
                              }
                              iconOnly
                            >
                              <AddIcon />
                            </MDButton>
                          </MDBox>
                        )}
                      </MDBox>
                      <MDBox bgColor="white" borderRadius="lg">
                        <List dense>
                          <TransitionGroup>
                            {mainFormValues.emailAttachments.map(
                              (emailAttachment, index) => (
                                <Collapse key={emailAttachment.attachmentURL}>
                                  {renderItem(
                                    emailAttachment,
                                    index,
                                    selectedAttachmentIndex,
                                    handleRemoveAttachment,
                                    handleAttachmentListItemClick
                                  )}
                                </Collapse>
                              )
                            )}
                          </TransitionGroup>
                        </List>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                )}
              </Formik>
              <FormField
                type={emailAttachments.type}
                label={emailAttachments.label}
                name={emailAttachments.name}
              />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Card>
  );
}

Attachments.propTypes = {
  mainForm: PropTypes.object.isRequired,
  mainFormField: PropTypes.object.isRequired,
  modeDisabled: PropTypes.bool.isRequired,
};

export default Attachments;
