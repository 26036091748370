import { Text, View, Image } from "@react-pdf/renderer";
import logo from "assets/images/gate/logo.png";
import styles from "pages/purchaseorders/pdf/styles";

function Header() {
  return (
    <View
      style={[
        styles.containerRow,
        styles.spaceBetween,
        { alignItems: "center" },
      ]}
      fixed
    >
      <Image style={styles.logo} src={logo} fixed />
      <View style={styles.containerCol} fixed>
        <View
          style={[
            styles.containerRow,
            styles.spaceBetween,
            styles.bottomLine,
            { alignItems: "flex-end" },
          ]}
          fixed
        >
          <Text
            style={[styles.container, styles.header, { marginBottom: "2px" }]}
            fixed
          >
            GATE PTE LTD
          </Text>
          <Text style={styles.container} fixed>
            Business Reg No. 201119238G
          </Text>
          <Text style={styles.container} fixed>
            GST No. 201119238G
          </Text>
        </View>
        <View style={styles.container} fixed>
          <Text style={styles.header2} fixed>
            Address: Blk 53 Ubi Ave 1 #01-51 Paya Ubi Industrial Park Singapore
            408934
          </Text>
          <View
            style={[styles.containerRow, styles.spaceBetween, { marginTop: 5 }]}
            fixed
          >
            <Text style={styles.header2} fixed>
              Tel: 6296 2002
            </Text>
            <Text style={styles.header2} fixed>
              Fax: 6296 2112
            </Text>
            <Text style={styles.header2} fixed>
              Email: services@gifted-school.com
            </Text>
            <Text style={styles.header2} fixed>
              Website: www.gifted-school.com
            </Text>
          </View>
        </View>
      </View>
    </View>
  );
}

export default Header;
