import { Text, View } from "@react-pdf/renderer";
import styles from "pages/invoices/pdf/styles";

function BillingInformation({ values }) {
  const invoiceDetails = [
    { key: "Date", value: values.invoiceDate },
    { key: "Terms", value: values.invoiceTerms },
    { key: "Quote Ref.", value: values.quotationRefForInvoice },
    { key: "CPO Ref.", value: values.purchaseorderRefForInvoice },
    { key: "SR Ref.", value: values.servicereportRefForInvoice },
    { key: "Delivery Site", value: values.deliverySiteAddress },
    { key: "Remarks", value: values.invoiceRemarks },
  ];
  return (
    <View style={[styles.containerRow, styles.section]}>
      <View style={[styles.containerCol, { flexBasis: "48%" }]}>
        <View
          style={[
            styles.tablebody,
            styles.topmost,
            styles.leftmost,
            { flexGrow: 1 },
          ]}
        >
          <Text style={[styles.tablebodytext, { fontWeight: "bold" }]}>
            Attention
          </Text>
          <Text style={styles.tablebodytext}>{values.billingContactName}</Text>
        </View>
        <View
          style={[
            styles.tablebody,
            styles.topmost,
            styles.leftmost,
            { flexGrow: 1 },
          ]}
        >
          <Text style={[styles.tablebodytext, { fontWeight: "bold" }]}>
            Contact / Email
          </Text>
          {values.billingContactMobileNumber && (
            <Text style={styles.tablebodytext}>
              {values.billingContactMobileNumber}
            </Text>
          )}
          {values.billingContactLandlineNumber && (
            <Text style={styles.tablebodytext}>
              {values.billingContactLandlineNumber}
            </Text>
          )}
          {values.billingContactEmail && (
            <Text style={styles.tablebodytext}>
              {values.billingContactEmail}
            </Text>
          )}
        </View>
        <View
          style={[
            styles.tablebody,
            styles.topmost,
            styles.leftmost,
            { flexGrow: 1 },
          ]}
        >
          <Text style={[styles.tablebodytext, { fontWeight: "bold" }]}>
            Company Name
          </Text>
          <Text style={styles.tablebodytext}>{values.customerName}</Text>
        </View>
        <View
          style={[
            styles.tablebody,
            styles.topmost,
            styles.leftmost,
            styles.bottommost,
            { flexGrow: 1 },
          ]}
        >
          <Text style={[styles.tablebodytext, { fontWeight: "bold" }]}>
            Company Billing Address
          </Text>
          <Text style={styles.tablebodytext}>{values.customerAddress}</Text>
        </View>
      </View>
      <View style={[{ flexBasis: "4%" }]}></View>
      <View style={[styles.containerCol, { flexBasis: "48%" }]}>
        <View
          style={[
            styles.tablebody,
            styles.topmost,
            styles.leftmost,
            styles.bottommost,
            { flexGrow: 1 },
          ]}
        >
          <Text
            style={[styles.title, { fontWeight: "bold", textAlign: "right" }]}
          >
            TAX INVOICE
          </Text>
        </View>
        {invoiceDetails.map(({ key, value }, index) => {
          const styleQuoteRef = key === "Quote Ref." ? [styles.samewidth] : [];
          const styleBottommost =
            index === invoiceDetails.length - 1 ? [styles.bottommost] : [];
          return (
            <View key={key} style={[styles.containerRow, { flexGrow: 1 }]}>
              <Text
                style={[
                  styles.tablebody,
                  styles.leftmost,
                  styles.tablebodytext,
                  ...styleBottommost,
                  { flexBasis: "30%", fontWeight: "bold" },
                ]}
              >
                {key}
              </Text>
              <Text
                style={[
                  styles.tablebody,
                  styles.tablebodytext,
                  ...styleQuoteRef,
                  ...styleBottommost,
                  { flexBasis: "70%" },
                ]}
              >
                {value}
              </Text>
            </View>
          );
        })}
      </View>
    </View>
  );
}

export default BillingInformation;
