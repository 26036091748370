import PropTypes from "prop-types";

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

import MDBox from "components/atoms/MDBox";
import MDTypography from "components/atoms/MDTypography";
import FormTextFieldFast from "components/molecules/Formik/FormTextFieldFast";
import FormAutocompleteFast from "components/molecules/Formik/FormAutocompleteFast";

import { quotationMessageOptions } from "pages/quotations/manage/schemas/setups";

function Subject({ mainForm, mainFormField, modeDisabled }) {
  const { quotationTitle, quotationMessage } = mainFormField;

  return (
    <Card id="subject" sx={{ overflow: "visible" }}>
      <MDBox m={3}>
        <MDBox mb={3}>
          <MDTypography variant="h5">Subject</MDTypography>
        </MDBox>
        <MDBox>
          <Grid
            container
            rowSpacing={{ xs: 1, sm: 2 }}
            columnSpacing={{ xs: 1, sm: 3 }}
          >
            <Grid item xs={12} sm={12}>
              <FormTextFieldFast
                form={mainForm}
                field={quotationTitle}
                variant="standard"
                disabled={modeDisabled}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormAutocompleteFast
                freeSolo
                form={mainForm}
                field={quotationMessage}
                options={quotationMessageOptions}
                disabled={modeDisabled}
              />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Card>
  );
}

Subject.propTypes = {
  mainForm: PropTypes.object.isRequired,
  mainFormField: PropTypes.object.isRequired,
  modeDisabled: PropTypes.bool.isRequired,
};

export default Subject;
