import PropTypes from "prop-types";

import { useState } from "react";

import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { TransitionGroup } from "react-transition-group";
import Collapse from "@mui/material/Collapse";

import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";

import MDBox from "components/atoms/MDBox";
import MDTypography from "components/atoms/MDTypography";

import { Formik, ErrorMessage, FieldArray } from "formik";

function FormInnerFieldArray({
  form,
  field,
  max,
  disabled,
  dispatchError,
  itemInitialValues,
  itemValidation,
  itemKeyField,
  itemDisplayFields,
  itemForm,
  editable,
  removable,
  sortable,
  showTitle,
}) {
  const { values } = form;
  const fieldArrayValue = values[field.name];

  const [selectedIndex, setSelectedIndex] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [itemFormValues, setItemFormValues] = useState(itemInitialValues);

  const handleListItemClick = (index) => {
    try {
      !disabled && !isEditing && setSelectedIndex(index);
      disabled && setItemFormValues(fieldArrayValue[index]);
    } catch (err) {
      dispatchError(err);
    }
  };

  function renderItem(item, index, remove, swap, handleSubmit, resetForm) {
    const itemDisplay = itemDisplayFields
      .map((displayField) => {
        return item[displayField.name];
      })
      .join(" - ");

    return (
      <>
        <ListItem
          secondaryAction={
            !disabled &&
            removable && (
              <>
                <IconButton
                  size="small"
                  color="error"
                  disabled={index !== selectedIndex || isEditing}
                  onClick={() => {
                    remove(index);
                    setItemFormValues(itemInitialValues);
                    setSelectedIndex(null);
                    resetForm();
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </>
            )
          }
          disablePadding
        >
          <ListItemButton
            selected={selectedIndex === index}
            onClick={() => handleListItemClick(index)}
          >
            {!disabled && editable && (
              <>
                <IconButton
                  edge="start"
                  size="small"
                  color="success"
                  disabled={index !== selectedIndex || isEditing}
                  onClick={() => startEditing(index)}
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  edge="start"
                  size="small"
                  color="success"
                  disabled={index !== selectedIndex || !isEditing}
                  onClick={handleSubmit}
                >
                  <SaveIcon />
                </IconButton>
                <IconButton
                  edge="start"
                  size="small"
                  color="warning"
                  disabled={index !== selectedIndex || !isEditing}
                  onClick={() => cancelEditing()}
                >
                  <DoNotDisturbIcon />
                </IconButton>
              </>
            )}
            {!disabled && sortable && (
              <>
                <IconButton
                  edge="start"
                  size="small"
                  color="info"
                  disabled={index !== selectedIndex || index === 0 || isEditing}
                  onClick={() => swap(index, index - 1)}
                >
                  <ArrowCircleUpIcon />
                </IconButton>
                <IconButton
                  edge="start"
                  size="small"
                  color="info"
                  disabled={
                    index !== selectedIndex ||
                    index === fieldArrayValue.length - 1 ||
                    isEditing
                  }
                  onClick={() => swap(index, index + 1)}
                >
                  <ArrowCircleDownIcon />
                </IconButton>
              </>
            )}
            <ListItemText
              sx={{ ml: 1 }}
              //primary={sortable ? `${index + 1}. ${itemDisplay}` : itemDisplay}
              primary={itemDisplay}
            />
          </ListItemButton>
        </ListItem>
      </>
    );
  }

  const startEditing = (index) => {
    try {
      setItemFormValues(fieldArrayValue[index]);
      setIsEditing(true);
    } catch (err) {
      dispatchError(err);
    }
  };

  const cancelEditing = () => {
    try {
      setItemFormValues(itemInitialValues);
      setIsEditing(false);
    } catch (err) {
      dispatchError(err);
    }
  };

  const submitItem = (values, actions, arrayHelpers) => {
    const { setSubmitting } = actions;
    const { push, replace } = arrayHelpers;

    try {
      if (isEditing) {
        replace(selectedIndex, values);
        setIsEditing(false);
      } else {
        push(values);
      }
      setItemFormValues(itemInitialValues);
      setSelectedIndex(null);
      actions.resetForm();
      setSubmitting(false);
    } catch (err) {
      dispatchError(err);
      setSubmitting(false);
    }
  };

  return (
    <>
      <FieldArray name={field.name}>
        {(arrayHelpers) => {
          return (
            /* The Formik below is the item form for the array field */
            <Formik
              enableReinitialize
              initialValues={itemFormValues}
              validationSchema={itemValidation}
              onSubmit={(values, actions) =>
                submitItem(values, actions, arrayHelpers)
              }
            >
              {(formik) => {
                const disabledField =
                  disabled ||
                  (!!fieldArrayValue &&
                    fieldArrayValue.length >= max &&
                    !isEditing);
                const disabledAdd =
                  disabled ||
                  (!!fieldArrayValue && fieldArrayValue.length >= max) ||
                  isEditing ||
                  formik.isSubmitting;
                return (
                  <MDBox p={1} bgColor="light" borderRadius="lg">
                    {showTitle && (
                      <MDBox
                        variant="gradient"
                        bgColor="info"
                        borderRadius="lg"
                        coloredShadow="info"
                        mt={-2}
                        mx={2}
                        mb={2}
                        textAlign="center"
                      >
                        <MDTypography
                          variant="body3"
                          color="white"
                          fontWeight="medium"
                        >
                          {field.label}
                        </MDTypography>
                      </MDBox>
                    )}
                    <MDBox p={1} mb={1} bgColor="white" borderRadius="lg">
                      {itemForm(formik, disabledField, disabledAdd)}
                    </MDBox>
                    <MDBox bgColor="white" borderRadius="lg">
                      <List dense>
                        <TransitionGroup>
                          {!!fieldArrayValue &&
                            fieldArrayValue.map((item, index) => (
                              <Collapse key={item[itemKeyField.name]}>
                                {renderItem(
                                  item,
                                  index,
                                  arrayHelpers.remove,
                                  arrayHelpers.swap,
                                  formik.handleSubmit,
                                  formik.resetForm
                                )}
                              </Collapse>
                            ))}
                        </TransitionGroup>
                      </List>
                    </MDBox>
                  </MDBox>
                );
              }}
            </Formik>
          );
        }}
      </FieldArray>
      <MDBox mt={0.75}>
        <MDTypography
          component="div"
          variant="caption"
          color="error"
          fontWeight="regular"
          sx={{ whiteSpace: "pre-wrap" }}
        >
          <ErrorMessage name={field.name}></ErrorMessage>
        </MDTypography>
      </MDBox>
    </>
  );
}

// Setting default values for the props of FormInnerFieldArray
FormInnerFieldArray.defaultProps = {
  editable: true,
  removable: true,
  sortable: false,
  showTitle: true,
};

// typechecking props for FormLeftFieldArray
FormInnerFieldArray.propTypes = {
  form: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  max: PropTypes.number,
  disabled: PropTypes.bool,
  dispatchError: PropTypes.func,
  itemInitialValues: PropTypes.object,
  itemValidation: PropTypes.object,
  itemKeyField: PropTypes.object,
  itemDisplayFields: PropTypes.array,
  itemForm: PropTypes.func,
  editable: PropTypes.bool,
  removable: PropTypes.bool,
  sortable: PropTypes.bool,
  showTitle: PropTypes.bool,
};

export default FormInnerFieldArray;
