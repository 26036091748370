import { useState, useEffect } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

import MDBox from "components/atoms/MDBox";
import MDTypography from "components/atoms/MDTypography";

import { useMuiContext } from "context/MuiContext";

const sidenavFixItems = [
  {
    icon: "payment",
    label: "Invoice Details",
    href: "invoice-details",
  },
  {
    icon: "receipt",
    label: "Billing Information",
    href: "billing-information",
  },
  {
    icon: "notifications_none",
    label: "Reminder Details",
    href: "reminder-details",
  },
  {
    icon: "content_paste_search",
    label: "Audit Trail",
    href: "audit-trail",
  },
  {
    icon: "preview",
    label: "Internal Reference",
    href: "internal-reference",
  },
  { icon: "attach_money", label: "Price Quote", href: "price-quote" },
];

function Sidenav({ mode }) {
  const [sideNavPosition, setSideNavPosition] = useState();
  const [sidenavItems, setSidenavItems] = useState(sidenavFixItems);
  const { fixedNavbar, scrollPaddingTop, darkMode } = useMuiContext();

  useEffect(() => {
    if (fixedNavbar) {
      setSideNavPosition(scrollPaddingTop);

      switch (mode) {
        case "new":
        case "convert":
        case "edit":
          setSidenavItems([
            {
              icon: "request_page",
              label: "Invoicing",
              href: "invoicing",
            },
            ...sidenavFixItems,
          ]);
          break;
        case "receipt":
          setSidenavItems([
            {
              icon: "paid",
              label: "Receipting",
              href: "receipting",
            },
            ...sidenavFixItems,
          ]);
          break;
        case "close":
          setSidenavItems([
            {
              icon: "credit_score",
              label: "Closing",
              href: "closing",
            },
            ...sidenavFixItems,
          ]);
          break;
        default:
          setSidenavItems([
            {
              icon: "request_page",
              label: "Invoicing",
              href: "invoicing",
            },
            {
              icon: "payment",
              label: "Receipting",
              href: "receipting",
            },
            {
              icon: "credit_score",
              label: "Closing",
              href: "closing",
            },
            ...sidenavFixItems,
          ]);
      }
    } else {
      setSideNavPosition("1%");
    }
  }, [fixedNavbar, mode, scrollPaddingTop]);

  const renderSidenavItems = sidenavItems.map(({ icon, label, href }, key) => {
    const itemKey = `item-${key}`;

    return (
      <MDBox key={itemKey} component="li" pt={key === 0 ? 0 : 1}>
        <MDTypography
          component="a"
          href={`#${href}`}
          variant="button"
          fontWeight="regular"
          sx={({
            borders: { borderRadius },
            functions: { pxToRem },
            palette: { light },
            transitions,
          }) => ({
            display: "flex",
            alignItems: "center",
            borderRadius: borderRadius.md,
            padding: `${pxToRem(10)} ${pxToRem(16)}`,
            transition: transitions.create("background-color", {
              easing: transitions.easing.easeInOut,
              duration: transitions.duration.shorter,
            }),

            "&:hover": {
              backgroundColor: light.main,
            },
          })}
        >
          <MDBox mr={1.5} lineHeight={1} color={darkMode ? "white" : "dark"}>
            <Icon fontSize="small">{icon}</Icon>
          </MDBox>
          {label}
        </MDTypography>
      </MDBox>
    );
  });

  return (
    <Card
      sx={{
        borderRadius: ({ borders: { borderRadius } }) => borderRadius.lg,
        position: "sticky",
        top: sideNavPosition,
      }}
    >
      <MDBox
        component="ul"
        display="flex"
        flexDirection="column"
        p={2}
        m={0}
        sx={{ listStyle: "none" }}
      >
        {renderSidenavItems}
      </MDBox>
    </Card>
  );
}

export default Sidenav;
