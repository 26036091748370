import { gstPercentage } from "pages/salesorders/manage/schemas/setups";

const form = {
  formId: "salesorder-form",
  formField: {
    workorderNumber: {
      name: "workorderNumber",
      label: "Work Order Number",
      type: "text",
      errorMsg: "Work Order Number is required.",
      invalidMsg: "Work Order Number is invalid.",
    },
    workStartDate: {
      name: "workStartDate",
      label: "Start Date",
      type: "text",
      errorMsg: "Start Date is required.",
      invalidMsg: "Start Date is invalid.",
    },
    workingDays: {
      name: "workingDays",
      label: "Working Days",
      type: "text",
      errorMsg: "Working Days is required.",
      invalidMsg: "Working Days is invalid.",
    },
    workRemarks: {
      name: "workRemarks",
      label: "Remarks",
      type: "text",
      errorMsg: "Remarks is required.",
      invalidMsg: "Remarks Days is invalid.",
    },
    commencementType: {
      name: "commencementType",
      label: "Commencement Type",
      type: "text",
      errorMsg: "Commencement Type is required.",
      invalidMsg: "Commencement Type is invalid.",
    },
    verbalAgreement: {
      name: "verbalAgreement",
      label: "Verbal Agreement",
      type: "text",
      errorMsg: "Verbal Agreement is required.",
      invalidMsg: "Verbal Agreement is invalid.",
    },
    pendingPO: {
      name: "pendingPO",
      label: "Pending PO",
      type: "text",
      errorMsg: "Pending PO is required.",
      invalidMsg: "Pending PO is invalid.",
    },
    quotationReference: {
      name: "quotationReference",
      label: "Quotation Reference",
      type: "text",
      errorMsg: "Quotation Reference is required.",
      invalidMsg: "Quotation Reference is invalid.",
    },
    purchaseorderReference: {
      name: "purchaseorderReference",
      label: "CPO Ref",
      type: "text",
      errorMsg: "CPO Ref is required.",
      invalidMsg: "CPO Ref is invalid.",
    },
    quotationRefForInvoice: {
      name: "quotationRefForInvoice",
      label: "Quote Ref",
      type: "text",
      errorMsg: "Quote Ref is required.",
      invalidMsg: "Quote Ref is invalid.",
    },
    workCompletionDate: {
      name: "workCompletionDate",
      label: "Completion Date",
      type: "text",
      errorMsg: "Completion Date is required.",
      invalidMsg: "Completion Date is invalid.",
    },
    workCancellationDate: {
      name: "workCancellationDate",
      label: "Cancellation Date",
      type: "text",
      errorMsg: "Cancellation Date is required.",
      invalidMsg: "Cancellation Date is invalid.",
    },
    completionReference: {
      name: "completionReference",
      label: "SR Ref",
      type: "text",
      errorMsg: "SR Ref is required.",
      invalidMsg: "SR Ref is invalid.",
    },
    cancellationReference: {
      name: "cancellationReference",
      label: "Cancellation Reference",
      type: "text",
      errorMsg: "Cancellation Reference is required.",
      invalidMsg: "Cancellation Reference is invalid.",
    },
    serviceTeams: {
      name: "serviceTeams",
      label: "Service Teams",
      type: "box",
      min1Msg: "Must have at least 1 Service Team.",
      max20Msg: "Must have at most 20 Service Teams.",
    },
    serviceTypes: {
      name: "serviceTypes",
      label: "Service Types",
      type: "box",
      min1Msg: "Must have at least 1 Service Type.",
      max20Msg: "Must have at most 20 Service Types.",
    },
    paymentType: {
      name: "paymentType",
      label: "Payment Type",
      type: "text",
      errorMsg: "Payment Type is required.",
      invalidMsg: "Payment Type is invalid.",
    },
    salesorderDate: {
      name: "salesorderDate",
      label: "Date Issued",
      type: "text",
      errorMsg: "Date Issued is required.",
      invalidMsg: "Date Issued is invalid.",
    },
    salesorderNumber: {
      name: "salesorderNumber",
      label: "Sales Order Number",
      type: "text",
      errorMsg: "Sales Order Number is required.",
      invalidMsg: "Sales Order Number is invalid.",
    },
    contactPersonName: {
      name: "contactPersonName",
      label: "Attention Person Name",
      type: "text",
      errorMsg: "Attention Person Name is required.",
      invalidMsg: "Attention Person Name is invalid.",
    },
    contactPersonLandlineNumber: {
      name: "contactPersonLandlineNumber",
      label: "Office / Home Contact Number",
      type: "text",
      errorMsg: "At least one contact number is required.",
      invalidMsg: "Office / Home Contact Number is invalid.",
    },
    contactPersonMobileNumber: {
      name: "contactPersonMobileNumber",
      label: "Mobile Contact Number",
      type: "text",
      errorMsg: "At least one contact number is required.",
      invalidMsg: "Mobile Contact Number is invalid.",
    },
    contactPersonEmail: {
      name: "contactPersonEmail",
      label: "Email",
      type: "text",
      errorMsg: "Email is required.",
      invalidMsg: "Email is invalid.",
    },
    customerId: {
      name: "customerId",
      label: "Customer Id",
      type: "text",
      errorMsg: "Customer Id is required.",
      invalidMsg: "Customer Id is invalid.",
    },
    customerName: {
      name: "customerName",
      label: "Customer Name",
      type: "text",
      errorMsg: "Organization / Individual Name is required.",
      invalidMsg: "Organization / Individual Name is invalid.",
    },
    customerAddress: {
      name: "customerAddress",
      label: "Address",
      type: "text",
      errorMsg: "Address is required.",
      invalidMsg: "Address is invalid.",
    },
    deliverySiteName: {
      name: "deliverySiteName",
      label: "Delivery Site Name",
      type: "text",
      errorMsg: "Delivery Site Name is required.",
      invalidMsg: "Delivery Site Name is invalid.",
    },
    deliverySiteAddress: {
      name: "deliverySiteAddress",
      label: "Delivery Site Address",
      type: "text",
      errorMsg: "Delivery Site Address is required.",
      invalidMsg: "Delivery Site Address is invalid.",
    },
    salesorderTitle: {
      name: "salesorderTitle",
      label: "Sales Order Title",
      type: "text",
      errorMsg: "Sales Order Title is required.",
      invalidMsg: "Sales Order Title is invalid.",
    },
    salesorderMessage: {
      name: "salesorderMessage",
      label: "Sales Order Message",
      type: "text",
      errorMsg: "Sales Order Message is required.",
      invalidMsg: "Sales Order Message is invalid.",
    },
    salesItems: {
      name: "salesItems",
      label: "Sales Items",
      type: "box",
      min1Msg: "Must have at least 1 Sales Item.",
      max100Msg: "Must have at most 100 Sales Items.",
    },
    salesDiscountType: {
      name: "salesDiscountType",
      label: "Discount Type",
      type: "text",
      errorMsg: "Discount Type is required.",
      invalidMsg: "Discount Type is invalid.",
    },
    salesGrossTotal: {
      name: "salesGrossTotal",
      label: "Gross Total",
      type: "text",
      errorMsg: "Gross Total is required.",
      invalidMsg: "Gross Total is invalid.",
    },
    salesDiscountTotal: {
      name: "salesDiscountTotal",
      label: "Discount",
      type: "text",
      errorMsg: "Discount Total is required.",
      invalidMsg: "Discount Total is invalid.",
    },
    salesBeforeGstTotal: {
      name: "salesBeforeGstTotal",
      label: "Total Amount Before GST",
      type: "text",
      errorMsg: "Total Amount Before GST is required.",
      invalidMsg: "Total Amount  Before GST is invalid.",
    },
    salesNetBeforeGstTotal: {
      name: "salesNetBeforeGstTotal",
      label: "Total Net Amount Before GST",
      type: "text",
      errorMsg: "Total Net Amount Before GST is required.",
      invalidMsg: "Total Net Amount  Before GST is invalid.",
    },
    salesGstAmount: {
      name: "salesGstAmount",
      label: `${gstPercentage}% GST`,
      type: "text",
      errorMsg: "GST Amount is required.",
      invalidMsg: "GST Amount is invalid.",
    },
    salesAfterGstTotal: {
      name: "salesAfterGstTotal",
      label: "Final Amount Inclusive of GST",
      type: "text",
      errorMsg: "Final Amount Inclusive of GST is required.",
      invalidMsg: "Final Amount Inclusive of GST is invalid.",
    },
    salesDeposit: {
      name: "salesDeposit",
      label: "Deposit",
      type: "text",
      errorMsg: "Deposit is required.",
      invalidMsg: "Deposit is invalid.",
    },
    depositReference: {
      name: "depositReference",
      label: "Deposit Reference",
      type: "text",
      errorMsg: "Deposit Reference is required.",
      invalidMsg: "Deposit Reference is invalid.",
    },
    salesAdditionalDiscount: {
      name: "salesAdditionalDiscount",
      label: "Additional Discount",
      type: "text",
      errorMsg: "Additional Discount is required.",
      invalidMsg: "Additional Discount is invalid.",
    },
    discountReference: {
      name: "discountReference",
      label: "Discount Reference",
      type: "text",
      errorMsg: "Discount Reference is required.",
      invalidMsg: "Discount Reference is invalid.",
    },
    beginingStatement: {
      name: "beginingStatement",
      label: "Begining Statement",
      type: "text",
      errorMsg: "Begining Statement is required.",
      invalidMsg: "Begining Statement is invalid.",
    },
    installationPeriod: {
      name: "installationPeriod",
      label: "Installation Period",
      type: "text",
      errorMsg: "Installation Period is required",
      invalidMsg: "Installation Period is invalid",
    },
    installationStartDate: {
      name: "installationStartDate",
      label: "Start Date",
      type: "text",
      errorMsg: "Start Date is required.",
      invalidMsg: "Start Date is invalid.",
    },
    installationEndDate: {
      name: "installationEndDate",
      label: "End Date",
      type: "text",
      errorMsg: "End Date is required.",
      invalidMsg: "End Date is invalid.",
    },
    paymentTerm: {
      name: "paymentTerm",
      label: "Payment Term",
      type: "text",
      errorMsg: "Payment Term is required.",
      invalidMsg: "Payment Term is invalid.",
    },
    validity: {
      name: "validity",
      label: "Validity ",
      type: "text",
      errorMsg: "is required",
      invalidMsg: "is invalid",
    },
    exclusions: {
      name: "exclusions",
      label: "Exclusions",
      type: "box",
      min1Msg: "Must have at least 1 Exclusion.",
      max50Msg: "Must have at most 50 Exclusions.",
    },
    note: {
      name: "note",
      label: "Note",
      type: "text",
      errorMsg: "Note is required",
      invalidMsg: "Note is invalid",
    },
    endingStatement: {
      name: "endingStatement",
      label: "Ending Statement",
      type: "text",
      errorMsg: "Ending Statement is required.",
      invalidMsg: "Ending Statement is invalid.",
    },
    commencementAttachments: {
      name: "commencementAttachments",
      label: "Attachments",
      type: "box",
      min1Msg: "Must have at least 1 Attachment.",
      max20Msg: "Must have at most 20 Attachments.",
    },
    depositAttachments: {
      name: "depositAttachments",
      label: "Attachments",
      type: "box",
      errorMsg: "Either Deposit Reference or Deposit Attachment is required.",
      min1Msg: "Must have at least 1 Attachment.",
      max20Msg: "Must have at most 20 Attachments.",
    },
    discountAttachments: {
      name: "discountAttachments",
      label: "Attachments",
      type: "box",
      errorMsg: "Either Discount Reference or Discount Attachment is required.",
      min1Msg: "Must have at least 1 Attachment.",
      max20Msg: "Must have at most 20 Attachments.",
    },
    completionAttachments: {
      name: "completionAttachments",
      label: "Attachments",
      type: "box",
      errorMsg: "Completion Attachment is required.",
      min1Msg: "Must have at least 1 Attachment.",
      max20Msg: "Must have at most 20 Attachments.",
    },
    cancellationAttachments: {
      name: "cancellationAttachments",
      label: "Attachments",
      type: "box",
      errorMsg:
        "Either Cancellation Reference or Cancellation Attachment is required.",
      min1Msg: "Must have at least 1 Attachment.",
      max20Msg: "Must have at most 20 Attachments.",
    },
  },
};

const serviceTeamsForm = {
  formId: "service-teams-form",
  formField: {
    serviceTeamName: {
      name: "serviceTeamName",
      label: "Service Team",
      type: "text",
      errorMsg: "Service Team is required.",
      invalidMsg: "Service Team is invalid",
    },
  },
};

const serviceTypesForm = {
  formId: "service-types-form",
  formField: {
    serviceTypeName: {
      name: "serviceTypeName",
      label: "Service Type",
      type: "text",
      errorMsg: "Service Type is required.",
      invalidMsg: "Service Type is invalid",
    },
  },
};

const commencementAttachmentsForm = {
  formId: "commencement-attachments-form",
  formField: {
    commencementAttachmentFile: {
      name: "commencementAttachmentFile",
      label: "Attachment File",
      type: "file",
      errorMsg: "Attachment File is required.",
      invalidMsg: "File size must be less than 5 MB.",
    },
    attachmentPath: {
      name: "attachmentPath",
      label: "Attachment Path",
      type: "text",
    },
    attachmentName: {
      name: "attachmentName",
      label: "Attachment Path",
      type: "text",
    },
    attachmentURL: {
      name: "attachmentURL",
      label: "Attachment Path",
      type: "text",
    },
  },
};

const depositAttachmentsForm = {
  formId: "deposit-attachments-form",
  formField: {
    depositAttachmentFile: {
      name: "depositAttachmentFile",
      label: "Attachment File",
      type: "file",
      errorMsg: "Attachment File is required.",
      invalidMsg: "File size must be less than 5 MB.",
    },
    attachmentPath: {
      name: "attachmentPath",
      label: "Attachment Path",
      type: "text",
    },
    attachmentName: {
      name: "attachmentName",
      label: "Attachment Path",
      type: "text",
    },
    attachmentURL: {
      name: "attachmentURL",
      label: "Attachment Path",
      type: "text",
    },
  },
};

const discountAttachmentsForm = {
  formId: "discount-attachments-form",
  formField: {
    discountAttachmentFile: {
      name: "discountAttachmentFile",
      label: "Attachment File",
      type: "file",
      errorMsg: "Attachment File is required.",
      invalidMsg: "File size must be less than 5 MB.",
    },
    attachmentPath: {
      name: "attachmentPath",
      label: "Attachment Path",
      type: "text",
    },
    attachmentName: {
      name: "attachmentName",
      label: "Attachment Path",
      type: "text",
    },
    attachmentURL: {
      name: "attachmentURL",
      label: "Attachment Path",
      type: "text",
    },
  },
};

const completionAttachmentsForm = {
  formId: "completion-attachments-form",
  formField: {
    completionAttachmentFile: {
      name: "completionAttachmentFile",
      label: "Attachment File",
      type: "file",
      errorMsg: "Attachment File is required.",
      invalidMsg: "File size must be less than 5 MB.",
    },
    attachmentPath: {
      name: "attachmentPath",
      label: "Attachment Path",
      type: "text",
    },
    attachmentName: {
      name: "attachmentName",
      label: "Attachment Path",
      type: "text",
    },
    attachmentURL: {
      name: "attachmentURL",
      label: "Attachment Path",
      type: "text",
    },
  },
};

const cancellationAttachmentsForm = {
  formId: "cancellation-attachments-form",
  formField: {
    cancellationAttachmentFile: {
      name: "cancellationAttachmentFile",
      label: "Attachment File",
      type: "file",
      errorMsg: "Attachment File is required.",
      invalidMsg: "File size must be less than 5 MB.",
    },
    attachmentPath: {
      name: "attachmentPath",
      label: "Attachment Path",
      type: "text",
    },
    attachmentName: {
      name: "attachmentName",
      label: "Attachment Path",
      type: "text",
    },
    attachmentURL: {
      name: "attachmentURL",
      label: "Attachment Path",
      type: "text",
    },
  },
};

const exclusionsForm = {
  formId: "exclusions-form",
  formField: {
    exclusionClause: {
      name: "exclusionClause",
      label: "Exclusion Clause",
      type: "text",
      errorMsg: "Exclusion Clause is required.",
      invalidMsg: "Exclusion Clause is invalid.",
    },
  },
};

const salesItemsForm = {
  formId: "sales-items-form",
  formField: {
    itemOrdinal: {
      name: "itemOrdinal",
      label: "Ordinal",
      type: "text",
      errorMsg: "Ordinal is required.",
      invalidMsg: "Ordinal is invalid.",
    },
    itemNo: {
      name: "itemNo",
      label: "No.",
      type: "text",
      errorMsg: "Required.",
      invalidMsg: "Invalid.",
    },
    itemSubNo: {
      name: "itemSubNo",
      label: "Sub No.",
      type: "text",
      errorMsg: "Required.",
      invalidMsg: "Invalid.",
    },
    itemServiceType: {
      name: "itemServiceType",
      label: "Service Type",
      type: "text",
      errorMsg: "Required.",
      invalidMsg: "Invalid.",
    },
    itemDescription: {
      name: "itemDescription",
      label: "Description",
      type: "text",
      errorMsg: "Required.",
      invalidMsg: "Invalid.",
    },
    itemServiceTeam: {
      name: "itemServiceTeam",
      label: "Service Team",
      type: "text",
      errorMsg: "Required.",
      invalidMsg: "Invalid.",
    },
    itemQuantity: {
      name: "itemQuantity",
      label: "Quantity",
      type: "text",
      errorMsg: "Required.",
      invalidMsg: "Invalid.",
    },
    itemUnitCost: {
      name: "itemUnitCost",
      label: "Unit Cost",
      type: "text",
      errorMsg: "Required.",
      invalidMsg: "Invalid.",
    },
    itemGrossAmount: {
      name: "itemGrossAmount",
      label: "Gross Amount",
      type: "text",
      errorMsg: "Required.",
      invalidMsg: "Invalid.",
    },
    itemDiscount: {
      name: "itemDiscount",
      label: "Discount",
      type: "text",
      errorMsg: "Required.",
      invalidMsg: "Invalid.",
    },
    itemNetAmount: {
      name: "itemNetAmount",
      label: "Net Amount",
      type: "text",
      errorMsg: "Required.",
      invalidMsg: "Invalid.",
    },
  },
};

export {
  form as default,
  serviceTeamsForm,
  serviceTypesForm,
  commencementAttachmentsForm,
  depositAttachmentsForm,
  discountAttachmentsForm,
  completionAttachmentsForm,
  cancellationAttachmentsForm,
  exclusionsForm,
  salesItemsForm,
};
