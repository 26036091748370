import PropTypes from "prop-types";

import { useState, useEffect } from "react";

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";

import MDBox from "components/atoms/MDBox";
import MDButton from "components/atoms/MDButton";
import MDTypography from "components/atoms/MDTypography";
import FormField from "components/molecules/FormField";

import DebounceAutocomplete from "components/molecules/Debounce/DebounceAutocomplete";
import DebounceTextField from "components/molecules/Debounce/DebounceTextField";

import { Formik } from "formik";

import FormRadioGroup from "components/molecules/Formik/FormRadioGroup";
import FormAutocompleteFast from "components/molecules/Formik/FormAutocompleteFast";
import FormTextFieldFast from "components/molecules/Formik/FormTextFieldFast";
import FormTextField from "components/molecules/Formik/FormTextField";

import { invoiceItemsForm } from "pages/invoices/manage/schemas/form";
import { invoiceItemsInitialValues } from "pages/invoices/manage/schemas/initialValues";
import { invoiceItemsValidation } from "pages/invoices/manage/schemas/validations";

import currency from "currency.js";

import {
  invoiceItemServiceOptions,
  invoiceItemServiceTypeOptions,
  discountTypeOptions,
  defaultDiscountType,
  gstPercentage,
} from "pages/invoices/manage/schemas/setups";

function PriceQuote({
  mainForm,
  mainFormField,
  modeDisabled,
  defaultReadOnly,
  dispatchMainError,
}) {
  const { values: mainFormValues, setFieldValue: setMainFormFieldValue } =
    mainForm;
  const {
    invoiceItems,
    invoiceDiscountType,
    invoiceGrossTotal,
    invoiceDiscountTotal,
    invoiceBeforeGstTotal,
    invoiceGstAmount,
    invoiceAfterGstTotal,
    invoiceDeposit,
    invoiceAdditionalDiscount,
    invoiceNetBeforeGstTotal,
    invoiceBalanceDue,
  } = mainFormField;

  // destructure from Invoice Items Form for current section
  const { formId: invoiceItemsFormId, formField: invoiceItemsFormField } =
    invoiceItemsForm;
  const {
    itemOrdinal,
    itemNo,
    itemSubNo,
    itemServiceType,
    itemDescription,
    itemQuantity,
    itemUnitCost,
    itemGrossAmount,
    itemDiscount,
    itemNetAmount,
  } = invoiceItemsFormField;

  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [showAddForm, setShowAddForm] = useState(false);
  const [itemizedDiscount, setItemizedDiscount] = useState(true);
  const [discountTotalType, setDiscountTotalType] = useState("$");
  const [discountTotalValue, setDiscountTotalValue] = useState("");
  const [invoiceItemStatementOptions, setInvoiceItemStatementOptions] =
    useState([]);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    try {
      mainFormValues.invoiceDiscountType === "Itemized" &&
        setItemizedDiscount(true);
      mainFormValues.invoiceDiscountType === "Non-Itemized" &&
        setItemizedDiscount(false);
    } catch (err) {
      dispatchMainError(err);
    }
  }, [dispatchMainError, mainFormValues.invoiceDiscountType]);

  const handleTableRowClick = (index, setSectionFormFieldValue) => {
    try {
      !defaultReadOnly &&
        !modeDisabled &&
        !isEditing &&
        setSelectedIndex(index);
    } catch (err) {
      dispatchMainError(err);
    }
  };

  const handleAddInvoiceItem = () => {
    try {
      setSelectedIndex(-1);
      setShowAddForm(true);
      setIsEditing(true);
    } catch (err) {
      dispatchMainError(err);
    }
  };

  const publishInvoiceItem = (index, setSectionFormFieldValue) => {
    try {
      const invoiceItem = mainFormValues.invoiceItems[index];
      setSectionFormFieldValue(itemOrdinal.name, invoiceItem.itemOrdinal);
      setSectionFormFieldValue(itemNo.name, invoiceItem.itemNo);
      setSectionFormFieldValue(itemSubNo.name, invoiceItem.itemSubNo);
      setSectionFormFieldValue(
        itemServiceType.name,
        invoiceItem.itemServiceType
      );
      setSectionFormFieldValue(
        itemDescription.name,
        invoiceItem.itemDescription
      );
      setSectionFormFieldValue(itemQuantity.name, invoiceItem.itemQuantity);
      setSectionFormFieldValue(itemUnitCost.name, invoiceItem.itemUnitCost);
      setSectionFormFieldValue(
        itemGrossAmount.name,
        invoiceItem.itemGrossAmount
      );
      setSectionFormFieldValue(itemDiscount.name, invoiceItem.itemDiscount);
      setSectionFormFieldValue(itemNetAmount.name, invoiceItem.itemNetAmount);
    } catch (err) {
      dispatchMainError(err);
    }
  };

  const handleRemoveInvoiceItem = (index) => {
    try {
      mainFormValues.invoiceItems.splice(selectedIndex, 1);
      setMainFormFieldValue(invoiceItems.name, mainFormValues.invoiceItems);

      handleCalculateTotalPrice(
        mainFormValues.invoiceItems,
        mainFormValues.invoiceDiscountTotal,
        mainFormValues.invoiceAdditionalDiscount
      );
    } catch (err) {
      dispatchMainError(err);
    }
  };

  const handleEditInvoiceItem = (index, setSectionFormFieldValue) => {
    try {
      publishInvoiceItem(index, setSectionFormFieldValue);
      setIsEditing(true);
    } catch (err) {
      dispatchMainError(err);
    }
  };

  const handleCancelEditingInvoiceItem = (resetForm) => {
    try {
      resetForm({ values: invoiceItemsInitialValues });
      setSelectedIndex(-1);
      setShowAddForm(false);
      setIsEditing(false);
    } catch (err) {
      dispatchMainError(err);
    }
  };

  const handleMoveUpInvoiceItem = (event, index) => {
    try {
      event.stopPropagation();
      !isEditing && setSelectedIndex(index - 1);
      setSelectedIndex(index - 1);
      const items = mainFormValues.invoiceItems;
      [items[index - 1].itemOrdinal, items[index].itemOrdinal] = [
        index,
        index - 1,
      ];
      [items[index - 1], items[index]] = [items[index], items[index - 1]];
      setMainFormFieldValue(invoiceItems.name, items);
    } catch (err) {
      dispatchMainError(err);
    }
  };

  const handleMoveDownInvoiceItem = (event, index) => {
    try {
      event.stopPropagation();
      !isEditing && setSelectedIndex(index + 1);
      const items = mainFormValues.invoiceItems;
      [items[index].itemOrdinal, items[index + 1].itemOrdinal] = [
        index + 1,
        index,
      ];
      [items[index], items[index + 1]] = [items[index + 1], items[index]];
      setMainFormFieldValue(invoiceItems.name, items);
    } catch (err) {
      dispatchMainError(err);
    }
  };

  const handleCalculateBalanceDue = (deposit, afterGstTotal) => {
    try {
      const currAfterGstTotal = currency(afterGstTotal, {
        symbol: "",
        separator: "",
      });
      const currDeposit = currency(deposit, {
        symbol: "",
        separator: "",
      });
      const currBalanceDue = currAfterGstTotal.subtract(currDeposit);

      const formattedInvoiceDeposit = currDeposit.format();
      const formattedBalanceDue = currBalanceDue.format();

      setMainFormFieldValue(invoiceDeposit.name, formattedInvoiceDeposit);
      setMainFormFieldValue(invoiceBalanceDue.name, formattedBalanceDue);
    } catch (err) {
      dispatchMainError(err);
    }
  };

  const handleCalculateDiscountTotal = (
    discountTotalType,
    discountTotalValue
  ) => {
    try {
      const grossTotal = currency(mainFormValues.invoiceGrossTotal, {
        symbol: "",
        separator: "",
      });
      let discountTotalAmount = "0.00";
      if (discountTotalValue && !isNaN(discountTotalValue)) {
        const discountTotal = currency(discountTotalValue, {
          symbol: "",
          separator: "",
        });
        if (discountTotalType === "$") {
          discountTotalAmount = discountTotal.format();
          setMainFormFieldValue(invoiceDiscountTotal.name, discountTotalAmount);
        }
        if (discountTotalType === "%") {
          discountTotalAmount = grossTotal
            .multiply(discountTotal / 100)
            .format();
          setMainFormFieldValue(invoiceDiscountTotal.name, discountTotalAmount);
        }
      }
      return discountTotalAmount;
    } catch (err) {
      dispatchMainError(err);
    }
  };

  const handleCalculateTotalPrice = (
    invoiceItems,
    discount = "0.00",
    additionalDiscount = "0.00"
  ) => {
    try {
      const postSalesDiscount = currency(additionalDiscount, {
        symbol: "",
        separator: "",
      });

      if (itemizedDiscount) {
        const grossTotal = invoiceItems.reduce(
          (total, item) => {
            return currency(item.itemGrossAmount, {
              symbol: "",
              separator: "",
            }).add(total);
          },
          currency(0, {
            symbol: "",
            separator: "",
          })
        );
        const beforeGstTotal = invoiceItems.reduce(
          (total, item) => {
            return currency(item.itemNetAmount, {
              symbol: "",
              separator: "",
            }).add(total);
          },
          currency(0, {
            symbol: "",
            separator: "",
          })
        );
        const discountTotal = grossTotal.subtract(beforeGstTotal);

        const netBeforeGstTotal = beforeGstTotal.subtract(postSalesDiscount);
        const gstAmount = netBeforeGstTotal.multiply(gstPercentage / 100);
        const afterGstTotal = netBeforeGstTotal.add(gstAmount);

        const formattedGrossTotal = grossTotal.format();
        const formattedDiscountTotal = discountTotal.format();
        const formattedBeforeGstTotal = beforeGstTotal.format();
        const formattedNetBeforeGstTotal = netBeforeGstTotal.format();
        const formattedGstAmount = gstAmount.format();
        const formattedAfterGstTotal = afterGstTotal.format();
        setMainFormFieldValue(invoiceGrossTotal.name, formattedGrossTotal);
        setMainFormFieldValue(
          invoiceDiscountTotal.name,
          formattedDiscountTotal
        );
        setMainFormFieldValue(
          invoiceBeforeGstTotal.name,
          formattedBeforeGstTotal
        );
        setMainFormFieldValue(
          invoiceNetBeforeGstTotal.name,
          formattedNetBeforeGstTotal
        );
        setMainFormFieldValue(invoiceGstAmount.name, formattedGstAmount);
        setMainFormFieldValue(
          invoiceAfterGstTotal.name,
          formattedAfterGstTotal
        );

        handleCalculateBalanceDue(
          mainFormValues.invoiceDeposit,
          formattedAfterGstTotal
        );
      }
      if (!itemizedDiscount) {
        const grossTotal = invoiceItems.reduce(
          (total, item) => {
            return currency(item.itemNetAmount, {
              symbol: "",
              separator: "",
            }).add(total);
          },
          currency(0, {
            symbol: "",
            separator: "",
          })
        );

        const discountTotal = currency(discount, {
          symbol: "",
          separator: "",
        });

        const beforeGstTotal = grossTotal.subtract(discountTotal);
        const netBeforeGstTotal = beforeGstTotal.subtract(postSalesDiscount);
        const gstAmount = netBeforeGstTotal.multiply(gstPercentage / 100);
        const afterGstTotal = netBeforeGstTotal.add(gstAmount);

        const formattedGrossTotal = grossTotal.format();
        const formattedDiscountTotal = discountTotal.format();
        const formattedBeforeGstTotal = beforeGstTotal.format();
        const formattedNetBeforeGstTotal = netBeforeGstTotal.format();
        const formattedGstAmount = gstAmount.format();
        const formattedAfterGstTotal = afterGstTotal.format();
        setMainFormFieldValue(invoiceGrossTotal.name, formattedGrossTotal);
        setMainFormFieldValue(
          invoiceDiscountTotal.name,
          formattedDiscountTotal
        );
        setMainFormFieldValue(
          invoiceBeforeGstTotal.name,
          formattedBeforeGstTotal
        );
        setMainFormFieldValue(
          invoiceNetBeforeGstTotal.name,
          formattedNetBeforeGstTotal
        );
        setMainFormFieldValue(invoiceGstAmount.name, formattedGstAmount);
        setMainFormFieldValue(
          invoiceAfterGstTotal.name,
          formattedAfterGstTotal
        );
      }
    } catch (err) {
      dispatchMainError(err);
    }
  };

  const handleCalculateItemPrice = (
    quantity,
    unitCost,
    discount,
    setSectionFormFieldValue
  ) => {
    try {
      if (quantity && !isNaN(quantity) && quantity === "0") {
        // reset unit cost, gross amount, discount, net amount to zero, use this item as title
        setSectionFormFieldValue(itemUnitCost.name, "0.00");
        setSectionFormFieldValue(itemGrossAmount.name, "0.00");
        setSectionFormFieldValue(itemDiscount.name, "0");
        setSectionFormFieldValue(itemNetAmount.name, "0.00");
      } else if (quantity && !isNaN(quantity) && unitCost && !isNaN(unitCost)) {
        const grossAmount = calculateGrossAmount(quantity, unitCost);
        setSectionFormFieldValue(itemGrossAmount.name, grossAmount);

        if (
          discount &&
          !isNaN(discount) &&
          grossAmount &&
          !isNaN(grossAmount)
        ) {
          const netAmount = calculateNetAmount(grossAmount, discount);
          setSectionFormFieldValue(itemNetAmount.name, netAmount);
        }
      }
    } catch (err) {
      dispatchMainError(err);
    }
  };

  const calculateGrossAmount = (quantity, unitCost) => {
    try {
      return currency(unitCost, {
        symbol: "",
        separator: "",
      })
        .multiply(quantity)
        .format();
    } catch (err) {
      dispatchMainError(err);
    }
  };

  const calculateNetAmount = (grossAmount, discount) => {
    try {
      return currency(grossAmount, {
        symbol: "",
        separator: "",
      })
        .multiply((100 - discount) / 100)
        .format();
    } catch (err) {
      dispatchMainError(err);
    }
  };

  const submitInvoiceItem = (values, { resetForm, setSubmitting }) => {
    try {
      if (selectedIndex !== -1 && isEditing) {
        // Edit existing item
        const currentInvoiceItems = mainFormValues.invoiceItems;
        currentInvoiceItems[selectedIndex] = values;
        handleCalculateTotalPrice(
          currentInvoiceItems,
          mainFormValues.invoiceDiscountTotal,
          mainFormValues.invoiceAdditionalDiscount
        );
        setMainFormFieldValue(invoiceItems.name, currentInvoiceItems);
        setIsEditing(false);
      } else if (selectedIndex === -1 && isEditing) {
        // Add new item
        const newIndex = mainFormValues.invoiceItems.length;
        values.itemOrdinal = newIndex;
        const currentInvoiceItems = [...mainFormValues.invoiceItems, values];
        handleCalculateTotalPrice(
          currentInvoiceItems,
          mainFormValues.invoiceDiscountTotal,
          mainFormValues.salesAdditionalDiscount
        );
        setShowAddForm(false);
        setIsEditing(false);
        setMainFormFieldValue(invoiceItems.name, currentInvoiceItems);
      } else {
        // recalculate
      }
      resetForm({ values: invoiceItemsInitialValues });
    } catch (err) {
      dispatchMainError(err);
      setSubmitting(false);
    }
  };

  const renderItem = (selectedIndex, formik, invoiceItem, index = -1) => {
    const {
      values,
      handleChange,
      handleSubmit,
      setFieldValue: setSectionFormFieldValue,
      resetForm,
    } = formik;
    return (
      <TableRow
        key={`invoice-item-${index}`}
        selected={selectedIndex === index}
        onClick={() => handleTableRowClick(index, setSectionFormFieldValue)}
      >
        <TableCell align="center">
          <MDBox display="flex" alignItems="center" columnGap="5px">
            {!modeDisabled && (
              <>
                <MDBox>
                  {index > -1 && (
                    <IconButton
                      edge="start"
                      size="small"
                      color="success"
                      disabled={index !== selectedIndex || isEditing}
                      onClick={() =>
                        handleEditInvoiceItem(index, setSectionFormFieldValue)
                      }
                    >
                      <EditIcon />
                    </IconButton>
                  )}
                  <IconButton
                    edge="start"
                    size="small"
                    color="success"
                    disabled={index !== selectedIndex || !isEditing}
                    onClick={handleSubmit}
                  >
                    <SaveIcon />
                  </IconButton>
                  <IconButton
                    edge="start"
                    size="small"
                    color="warning"
                    disabled={index !== selectedIndex || !isEditing}
                    onClick={() => handleCancelEditingInvoiceItem(resetForm)}
                  >
                    <DoNotDisturbIcon />
                  </IconButton>
                </MDBox>
                <MDBox>
                  <IconButton
                    edge="start"
                    size="small"
                    color="info"
                    disabled={
                      index !== selectedIndex || index === 0 || isEditing
                    }
                    onClick={(event) => handleMoveUpInvoiceItem(event, index)}
                  >
                    <ArrowCircleUpIcon />
                  </IconButton>
                  <IconButton
                    edge="start"
                    size="small"
                    color="info"
                    disabled={
                      index !== selectedIndex ||
                      index === mainFormValues.invoiceItems.length - 1 ||
                      isEditing
                    }
                    onClick={(event) => handleMoveDownInvoiceItem(event, index)}
                  >
                    <ArrowCircleDownIcon />
                  </IconButton>
                </MDBox>
              </>
            )}
          </MDBox>
        </TableCell>
        <TableCell align="center">
          {isEditing && index === selectedIndex ? (
            <FormTextFieldFast
              hideStatus={true}
              form={formik}
              field={itemNo}
              variant="standard"
              disabled={modeDisabled}
            />
          ) : (
            invoiceItem.itemNo
          )}
        </TableCell>
        <TableCell align="center">
          {isEditing && index === selectedIndex ? (
            <FormTextFieldFast
              hideStatus={true}
              form={formik}
              field={itemSubNo}
              variant="standard"
              disabled={modeDisabled}
            />
          ) : (
            invoiceItem.itemSubNo
          )}
        </TableCell>
        <TableCell align="left">
          {isEditing && index === selectedIndex ? (
            <>
              <FormAutocompleteFast
                hideStatus={true}
                form={formik}
                field={itemServiceType}
                options={invoiceItemServiceTypeOptions}
                variant="standard"
                onInputChange={(e, value) => {
                  if (invoiceItemServiceTypeOptions.includes(value)) {
                    setSectionFormFieldValue(itemServiceType.name, value);
                    const invoiceItemService = invoiceItemServiceOptions.find(
                      (element) => {
                        return element.serviceType === value;
                      }
                    );
                    setInvoiceItemStatementOptions(
                      invoiceItemService.statements
                    );
                  }
                }}
                disabled={modeDisabled}
              />
              <FormAutocompleteFast
                hideStatus={true}
                freeSolo
                form={formik}
                field={itemDescription}
                options={invoiceItemStatementOptions}
                variant="standard"
                disabled={modeDisabled}
              />
            </>
          ) : (
            invoiceItem.itemDescription
          )}
        </TableCell>
        <TableCell align="center">
          {isEditing && index === selectedIndex ? (
            <FormTextField
              hideStatus={true}
              form={formik}
              field={itemQuantity}
              variant="standard"
              disabled={modeDisabled}
              onChange={(e) => {
                handleChange(e);
                const quantity = e.target.value;
                const unitCost = values.itemUnitCost;
                const discount = values.itemDiscount;
                handleCalculateItemPrice(
                  quantity,
                  unitCost,
                  discount,
                  setSectionFormFieldValue
                );
              }}
            />
          ) : invoiceItem.itemQuantity !== "0" ? (
            invoiceItem.itemQuantity
          ) : (
            ""
          )}
        </TableCell>
        <TableCell align="center">
          {isEditing && index === selectedIndex ? (
            <FormTextField
              hideStatus={true}
              form={formik}
              field={itemUnitCost}
              variant="standard"
              disabled={modeDisabled}
              onChange={(e) => {
                handleChange(e);
                const unitCost = e.target.value;
                const quantity = values.itemQuantity;
                const discount = values.itemDiscount;
                handleCalculateItemPrice(
                  quantity,
                  unitCost,
                  discount,
                  setSectionFormFieldValue
                );
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
            />
          ) : invoiceItem.itemQuantity !== "0" ? (
            currency(invoiceItem.itemUnitCost).format()
          ) : (
            ""
          )}
        </TableCell>
        <TableCell
          align="center"
          sx={itemizedDiscount ? {} : { display: "none" }}
        >
          {isEditing && index === selectedIndex ? (
            <FormTextField
              hideStatus={true}
              form={formik}
              field={itemGrossAmount}
              variant="standard"
              disabled={modeDisabled}
              InputProps={{
                readOnly: true,
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
            />
          ) : invoiceItem.itemQuantity !== "0" ? (
            currency(invoiceItem.itemGrossAmount).format()
          ) : (
            ""
          )}
        </TableCell>
        <TableCell
          align="center"
          sx={itemizedDiscount ? {} : { display: "none" }}
        >
          {isEditing && index === selectedIndex ? (
            <FormTextField
              hideStatus={true}
              form={formik}
              field={itemDiscount}
              variant="standard"
              disabled={modeDisabled}
              onChange={(e) => {
                handleChange(e);
                const quantity = values.itemQuantity;
                const unitCost = values.itemUnitCost;
                const discount = e.target.value;
                handleCalculateItemPrice(
                  quantity,
                  unitCost,
                  discount,
                  setSectionFormFieldValue
                );
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
            />
          ) : invoiceItem.itemQuantity !== "0" ? (
            invoiceItem.itemDiscount + " %"
          ) : (
            ""
          )}
        </TableCell>
        <TableCell align="center">
          {isEditing && index === selectedIndex ? (
            <FormTextField
              hideStatus={true}
              form={formik}
              field={itemNetAmount}
              variant="standard"
              disabled={modeDisabled}
              onChange={(e) => {
                handleChange(e);
                const quantity = values.itemQuantity;
                const unitCost = values.itemUnitCost;
                const discount = e.target.value;
                handleCalculateItemPrice(
                  quantity,
                  unitCost,
                  discount,
                  setSectionFormFieldValue
                );
              }}
              InputProps={{
                readOnly: true,
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
            />
          ) : invoiceItem.itemQuantity !== "0" ? (
            currency(invoiceItem.itemNetAmount).format()
          ) : (
            ""
          )}
        </TableCell>
        <TableCell align="center">
          {!modeDisabled && index > -1 && (
            <IconButton
              edge="start"
              size="small"
              color="error"
              disabled={index !== selectedIndex || isEditing}
              onClick={() => handleRemoveInvoiceItem(index)}
            >
              <DeleteIcon />
            </IconButton>
          )}
        </TableCell>
      </TableRow>
    );
  };

  return (
    <Card id="price-invoice" sx={{ overflow: "visible" }}>
      <MDBox m={3}>
        <MDBox mb={3}>
          <MDTypography variant="h5">Price Quote</MDTypography>
        </MDBox>
        <Grid
          container
          rowSpacing={{ xs: 1, sm: 2 }}
          columnSpacing={{ xs: 1, sm: 3 }}
        >
          <Grid item xs={12} sm={2}>
            <MDTypography variant="body3" fontWeight="bold">
              {invoiceDiscountType.label}
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={10}>
            <FormRadioGroup
              row
              showTitle={false}
              form={mainForm}
              field={invoiceDiscountType}
              options={discountTypeOptions}
              defaultValue={defaultDiscountType}
              onChange={(event) => {
                const value = event.target.value;
                setMainFormFieldValue(invoiceDiscountType.name, value);
                const isItemized = value === "Itemized";
                if (mainFormValues.invoiceItems.length > 0) {
                  if (!isItemized) {
                    const updatedInvoiceItems = mainFormValues.invoiceItems;
                    updatedInvoiceItems.forEach((invoiceItem) => {
                      invoiceItem.itemDiscount = "0";
                      invoiceItem.itemNetAmount = calculateNetAmount(
                        invoiceItem.itemGrossAmount,
                        invoiceItem.itemDiscount
                      );
                    });
                    // reset discount
                    handleCalculateTotalPrice(
                      updatedInvoiceItems,
                      "0.00",
                      "0.00"
                    );
                    setMainFormFieldValue(
                      invoiceItems.name,
                      updatedInvoiceItems
                    );
                  }
                  if (isItemized) {
                    // reset discount
                    handleCalculateTotalPrice(
                      mainFormValues.invoiceItems,
                      "0.00",
                      "0.00"
                    );
                  }
                }
                setItemizedDiscount(isItemized); //set state and rerender
              }}
              disabled={defaultReadOnly || modeDisabled}
            />
          </Grid>
        </Grid>

        <Formik
          initialValues={invoiceItemsInitialValues}
          validationSchema={invoiceItemsValidation}
          onSubmit={submitInvoiceItem}
        >
          {(formik) => (
            <MDBox id={invoiceItemsFormId} autoComplete="off">
              <TableContainer sx={{ boxShadow: "none" }}>
                <Table sx={{ minWidth: "40rem" }}>
                  <TableHead>
                    <TableRow key="head">
                      <TableCell align="center" width="4%" />
                      <TableCell align="center" width="6%">
                        {itemNo.label}
                      </TableCell>
                      <TableCell align="center" width="8%">
                        {itemSubNo.label}
                      </TableCell>
                      <TableCell align="center" width="28%">
                        {itemDescription.label}
                      </TableCell>
                      <TableCell align="center" width="6%">
                        {itemQuantity.label}
                      </TableCell>
                      <TableCell align="center" width="11%">
                        {itemUnitCost.label}
                      </TableCell>
                      <TableCell align="center" width="11%">
                        {itemizedDiscount
                          ? itemGrossAmount.label
                          : "Total Amount"}
                      </TableCell>
                      <TableCell
                        align="center"
                        width="11%"
                        sx={itemizedDiscount ? {} : { display: "none" }}
                      >
                        {itemDiscount.label}
                      </TableCell>
                      <TableCell
                        align="center"
                        width="11%"
                        sx={itemizedDiscount ? {} : { display: "none" }}
                      >
                        {itemNetAmount.label}
                      </TableCell>
                      <TableCell align="center" width="4%" />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {mainFormValues.invoiceItems.map((invoiceItem, index) =>
                      renderItem(selectedIndex, formik, invoiceItem, index)
                    )}
                    {showAddForm && renderItem(selectedIndex, formik)}
                    <TableRow>
                      <TableCell
                        rowSpan={itemizedDiscount ? 6 : 8}
                        colSpan={itemizedDiscount ? 4 : 2}
                        sx={{ verticalAlign: "top" }}
                      >
                        {!defaultReadOnly && !modeDisabled && (
                          <MDButton
                            size="small"
                            onClick={handleAddInvoiceItem}
                            color="success"
                            disabled={
                              mainFormValues.invoiceItems.length >= 100 ||
                              isEditing ||
                              formik.isSubmitting
                            }
                            iconOnly
                          >
                            <AddIcon />
                          </MDButton>
                        )}
                      </TableCell>
                      <TableCell
                        align="right"
                        colSpan={itemizedDiscount ? 2 : 4}
                        sx={{ fontWeight: "bold" }}
                      >
                        Total Amount
                      </TableCell>
                      {itemizedDiscount ? (
                        <>
                          <TableCell align="right" sx={{ fontWeight: "bold" }}>
                            <FormTextField
                              hideLabel={true}
                              form={mainForm}
                              field={invoiceGrossTotal}
                              defaultValue={"0.00"}
                              variant="standard"
                              disabled={modeDisabled}
                              InputProps={{
                                readOnly: true,
                                startAdornment: (
                                  <InputAdornment position="start">
                                    $
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </TableCell>
                          <TableCell align="right" sx={{ fontWeight: "bold" }}>
                            <FormTextField
                              hideLabel={true}
                              form={mainForm}
                              field={invoiceDiscountTotal}
                              defaultValue={"0.00"}
                              variant="standard"
                              disabled={modeDisabled}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    $
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </TableCell>
                          <TableCell align="right" sx={{ fontWeight: "bold" }}>
                            <FormTextField
                              hideLabel={true}
                              form={mainForm}
                              field={invoiceBeforeGstTotal}
                              defaultValue={"0.00"}
                              variant="standard"
                              disabled={modeDisabled}
                              InputProps={{
                                readOnly: true,
                                startAdornment: (
                                  <InputAdornment position="start">
                                    $
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </TableCell>
                        </>
                      ) : (
                        <TableCell align="right" sx={{ fontWeight: "bold" }}>
                          <FormTextField
                            hideLabel={true}
                            form={mainForm}
                            field={invoiceGrossTotal}
                            defaultValue={"0.00"}
                            variant="standard"
                            disabled={modeDisabled}
                            InputProps={{
                              readOnly: true,
                              startAdornment: (
                                <InputAdornment position="start">
                                  $
                                </InputAdornment>
                              ),
                            }}
                          />
                        </TableCell>
                      )}
                    </TableRow>
                    {!itemizedDiscount && (
                      <>
                        <TableRow>
                          <TableCell align="right"></TableCell>
                          <TableCell colSpan={2}>
                            <MDBox
                              display="flex"
                              justifyContent="flex-end"
                              alignItems="center"
                              flexWrap="nowrap"
                            >
                              <DebounceAutocomplete
                                disablePortal
                                size="small"
                                label="Type"
                                value={discountTotalType}
                                options={["$", "%"]}
                                sx={{ width: 80 }}
                                onChange={(event, newValue) => {
                                  const discountTotalAmount =
                                    handleCalculateDiscountTotal(newValue, 0);
                                  handleCalculateTotalPrice(
                                    mainFormValues.invoiceItems,
                                    discountTotalAmount,
                                    mainFormValues.salesAdditionalDiscount
                                  );
                                  setDiscountTotalValue("");
                                  setDiscountTotalType(newValue);
                                }}
                                onInputChange={(e, value) => {
                                  if (["$", "%"].includes(value)) {
                                    setDiscountTotalType(value);
                                  }
                                }}
                                disabled={modeDisabled}
                                readOnly={defaultReadOnly}
                              />
                              <DebounceTextField
                                size="small"
                                label="Amount"
                                value={discountTotalValue}
                                disabled={modeDisabled}
                                InputProps={{
                                  readOnly: defaultReadOnly,
                                }}
                                onChange={(e) => {
                                  const newValue = e.target.value;

                                  const discountTotalAmount =
                                    handleCalculateDiscountTotal(
                                      discountTotalType,
                                      newValue
                                    );
                                  handleCalculateTotalPrice(
                                    mainFormValues.invoiceItems,
                                    discountTotalAmount,
                                    mainFormValues.salesAdditionalDiscount
                                  );
                                  setDiscountTotalValue(newValue);
                                }}
                              />
                            </MDBox>
                          </TableCell>
                          <TableCell align="right" sx={{ fontWeight: "bold" }}>
                            {invoiceDiscountTotal.label}
                          </TableCell>
                          <TableCell align="right" sx={{ fontWeight: "bold" }}>
                            <FormTextField
                              hideLabel={true}
                              form={mainForm}
                              field={invoiceDiscountTotal}
                              defaultValue={"0.00"}
                              variant="standard"
                              disabled={modeDisabled}
                              InputProps={{
                                readOnly: true,
                                startAdornment: (
                                  <InputAdornment position="start">
                                    $
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            align="right"
                            colSpan={4}
                            sx={{ fontWeight: "bold" }}
                          >
                            {invoiceBeforeGstTotal.label}
                          </TableCell>
                          <TableCell align="right" sx={{ fontWeight: "bold" }}>
                            <FormTextField
                              hideLabel={true}
                              hideStatus={true}
                              form={mainForm}
                              field={invoiceBeforeGstTotal}
                              defaultValue={"0.00"}
                              variant="standard"
                              disabled={modeDisabled}
                              InputProps={{
                                readOnly: true,
                                startAdornment: (
                                  <InputAdornment position="start">
                                    $
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </TableCell>
                        </TableRow>
                      </>
                    )}
                    {mainFormValues.invoiceAdditionalDiscount !== "0.00" && (
                      <TableRow>
                        <TableCell
                          align="right"
                          colSpan={4}
                          sx={{ fontWeight: "bold" }}
                        >
                          {invoiceAdditionalDiscount.label}
                        </TableCell>
                        <TableCell align="right" sx={{ fontWeight: "bold" }}>
                          <FormTextField
                            hideLabel={true}
                            hideStatus={true}
                            form={mainForm}
                            field={invoiceAdditionalDiscount}
                            defaultValue={"0.00"}
                            variant="standard"
                            disabled={modeDisabled}
                            InputProps={{
                              readOnly: true,
                              startAdornment: (
                                <InputAdornment position="start">
                                  $
                                </InputAdornment>
                              ),
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    )}
                    <TableRow>
                      <TableCell
                        align="right"
                        colSpan={4}
                        sx={{ fontWeight: "bold" }}
                      >
                        {invoiceGstAmount.label}
                      </TableCell>
                      <TableCell align="right" sx={{ fontWeight: "bold" }}>
                        <FormTextField
                          hideLabel={true}
                          hideStatus={true}
                          form={mainForm}
                          field={invoiceGstAmount}
                          defaultValue={"0.00"}
                          variant="standard"
                          disabled={modeDisabled}
                          InputProps={{
                            readOnly: true,
                            startAdornment: (
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                            ),
                          }}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        align="right"
                        colSpan={4}
                        sx={{ fontWeight: "bold" }}
                      >
                        {invoiceAfterGstTotal.label}
                      </TableCell>
                      <TableCell align="right" sx={{ fontWeight: "bold" }}>
                        <FormTextField
                          hideLabel={true}
                          notTouchable={true}
                          form={mainForm}
                          field={invoiceAfterGstTotal}
                          defaultValue={"0.00"}
                          variant="standard"
                          disabled={modeDisabled}
                          InputProps={{
                            readOnly: true,
                            startAdornment: (
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                            ),
                          }}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        align="right"
                        colSpan={4}
                        sx={{ fontWeight: "bold" }}
                      >
                        {invoiceDeposit.label}
                      </TableCell>
                      <TableCell align="right" sx={{ fontWeight: "bold" }}>
                        <FormTextField
                          hideLabel={true}
                          form={mainForm}
                          field={invoiceDeposit}
                          defaultValue={"0.00"}
                          variant="standard"
                          disabled={modeDisabled}
                          onChange={(e) => {
                            const deposit = e.target.value;
                            handleCalculateBalanceDue(
                              deposit,
                              mainFormValues.invoiceAfterGstTotal
                            );
                          }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                            ),
                          }}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        align="right"
                        colSpan={4}
                        sx={{ fontWeight: "bold" }}
                      >
                        {invoiceBalanceDue.label}
                      </TableCell>
                      <TableCell align="right" sx={{ fontWeight: "bold" }}>
                        <FormTextField
                          hideLabel={true}
                          notTouchable={true}
                          form={mainForm}
                          field={invoiceBalanceDue}
                          defaultValue={"0.00"}
                          variant="standard"
                          disabled={modeDisabled}
                          InputProps={{
                            readOnly: true,
                            startAdornment: (
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                            ),
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </MDBox>
          )}
        </Formik>
        <FormField
          type={invoiceItems.type}
          label={invoiceItems.label}
          name={invoiceItems.name}
        />
      </MDBox>
    </Card>
  );
}

PriceQuote.propTypes = {
  mainForm: PropTypes.object.isRequired,
  mainFormField: PropTypes.object.isRequired,
  modeDisabled: PropTypes.bool.isRequired,
  defaultReadOnly: PropTypes.bool.isRequired,
};

export default PriceQuote;
