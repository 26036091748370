import PropTypes from "prop-types";

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";

import MDBox from "components/atoms/MDBox";
import MDTypography from "components/atoms/MDTypography";
import FormField from "components/molecules/FormField";

import { TextField } from "@mui/material";

import FormDatePicker from "components/molecules/Formik/FormDatePicker";

function AuditTrail({ mainForm, mainFormField, modeDisabled, mode }) {
  const { values, errors, touched } = mainForm;
  const { quotationNumber, quotationRevision, quotationDate } = mainFormField;

  return (
    <Card id="audit-trail" sx={{ overflow: "visible" }}>
      <MDBox m={3}>
        <MDBox mb={3}>
          <MDTypography variant="h5">Audit Trail</MDTypography>
        </MDBox>
        <MDBox>
          <Grid container mb={1} columnSpacing={{ xs: 1, sm: 3 }}>
            <Grid item xs={12} sm={4}>
              <FormDatePicker
                form={mainForm}
                field={quotationDate}
                inputFormat={"dd/MM/yyyy"}
                variant="standard"
                disabled={modeDisabled}
              />
            </Grid>
            <Grid item xs={12} sm={4}></Grid>
            <Grid item xs={12} sm={4}>
              <MDBox display="flex">
                <FormField
                  type={quotationNumber.type}
                  label={quotationNumber.label}
                  name={quotationNumber.name}
                  variant="standard"
                  placeholder={quotationNumber.placeholder}
                  disabled={modeDisabled}
                  error={errors.quotationNumber && touched.quotationNumber}
                  success={
                    !!values.quotationNumber &&
                    values.quotationNumber.length > 0 &&
                    !errors.quotationNumber
                  }
                  sx={
                    mode === "new" || mode === "draft"
                      ? { display: "none" }
                      : {}
                  }
                  InputProps={{
                    readOnly: true,
                    startAdornment: (
                      <InputAdornment position="start">GA-Q-</InputAdornment>
                    ),
                  }}
                />
                <TextField
                  label={quotationNumber.label}
                  defaultValue="Auto Generate"
                  variant="standard"
                  sx={
                    mode === "view" ||
                    mode === "edit" ||
                    mode === "revise" ||
                    mode === "delete"
                      ? { display: "none" }
                      : {}
                  }
                  InputProps={{
                    readOnly: true,
                    startAdornment: (
                      <InputAdornment position="start">GA-Q-</InputAdornment>
                    ),
                  }}
                />
                <FormField
                  type={quotationRevision.type}
                  label={quotationRevision.label}
                  name={quotationRevision.name}
                  variant="standard"
                  placeholder={quotationRevision.placeholder}
                  disabled={modeDisabled}
                  error={errors.quotationRevision && touched.quotationRevision}
                  success={
                    !!values.quotationRevision &&
                    values.quotationRevision.length > 0 &&
                    !errors.quotationRevision
                  }
                  sx={
                    mode === "new" ||
                    mode === "draft" ||
                    mode === "edit" ||
                    mode === "revise"
                      ? { display: "none" }
                      : {}
                  }
                  InputProps={{
                    readOnly: true,
                    startAdornment: (
                      <InputAdornment position="start">R</InputAdornment>
                    ),
                  }}
                />
                <TextField
                  label={quotationRevision.label}
                  defaultValue={
                    mode === "edit" || mode === "revise"
                      ? "Auto Increment"
                      : "Auto Generate"
                  }
                  variant="standard"
                  sx={
                    mode === "view" || mode === "delete"
                      ? { display: "none" }
                      : {}
                  }
                  InputProps={{
                    readOnly: true,
                    startAdornment: (
                      <InputAdornment position="start">R</InputAdornment>
                    ),
                  }}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Card>
  );
}

AuditTrail.propTypes = {
  mainForm: PropTypes.object.isRequired,
  mainFormField: PropTypes.object.isRequired,
  modeDisabled: PropTypes.bool.isRequired,
  mode: PropTypes.string.isRequired,
};

export default AuditTrail;
