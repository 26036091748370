import { gstPercentage } from "pages/quotations/manage/schemas/setups";

const form = {
  formId: "quotation-form",
  formField: {
    serviceTeams: {
      name: "serviceTeams",
      label: "Service Teams",
      type: "box",
      min1Msg: "Must have at least 1 Service Team.",
      max20Msg: "Must have at most 20 Service Teams.",
    },
    serviceTypes: {
      name: "serviceTypes",
      label: "Service Types",
      type: "box",
      min1Msg: "Must have at least 1 Service Type.",
      max20Msg: "Must have at most 20 Service Types.",
    },
    paymentType: {
      name: "paymentType",
      label: "Payment Type",
      type: "text",
      errorMsg: "Payment Type is required.",
      invalidMsg: "Payment Type is invalid.",
    },
    quotationDate: {
      name: "quotationDate",
      label: "Date Issued",
      type: "text",
      errorMsg: "Date Issued is required.",
      invalidMsg: "Date Issued is invalid.",
    },
    quotationNumber: {
      name: "quotationNumber",
      label: "Quotation Number",
      type: "text",
      errorMsg: "Quotation Number is required.",
      invalidMsg: "Quotation Number is invalid.",
    },
    quotationRevision: {
      name: "quotationRevision",
      label: "Quotation Revision",
      type: "text",
      errorMsg: "Quotation Revision is required.",
      invalidMsg: "Quotation Revision is invalid.",
    },
    contactPersonName: {
      name: "contactPersonName",
      label: "Attention Person Name",
      type: "text",
      errorMsg: "Attention Person Name is required.",
      invalidMsg: "Attention Person Name is invalid.",
    },
    contactPersonLandlineNumber: {
      name: "contactPersonLandlineNumber",
      label: "Office / Home Contact Number",
      type: "text",
      errorMsg: "At least one contact number is required.",
      invalidMsg: "Office / Home Contact Number is invalid.",
    },
    contactPersonMobileNumber: {
      name: "contactPersonMobileNumber",
      label: "Mobile Contact Number",
      type: "text",
      errorMsg: "At least one contact number is required.",
      invalidMsg: "Mobile Contact Number is invalid.",
    },
    contactPersonEmail: {
      name: "contactPersonEmail",
      label: "Email",
      type: "text",
      errorMsg: "Email is required.",
      invalidMsg: "Email is invalid.",
    },
    customerId: {
      name: "customerId",
      label: "Customer Id",
      type: "text",
      errorMsg: "Customer Id is required.",
      invalidMsg: "Customer Id is invalid.",
    },
    customerName: {
      name: "customerName",
      label: "Customer Name",
      type: "text",
      errorMsg: "Organization / Individual Name is required.",
      invalidMsg: "Organization / Individual Name is invalid.",
    },
    customerAddress: {
      name: "customerAddress",
      label: "Address",
      type: "text",
      errorMsg: "Address is required.",
      invalidMsg: "Address is invalid.",
    },
    deliverySiteName: {
      name: "deliverySiteName",
      label: "Delivery Site Name",
      type: "text",
      errorMsg: "Delivery Site Name is required.",
      invalidMsg: "Delivery Site Name is invalid.",
    },
    deliverySiteAddress: {
      name: "deliverySiteAddress",
      label: "Delivery Site Address",
      type: "text",
      errorMsg: "Delivery Site Address is required.",
      invalidMsg: "Delivery Site Address is invalid.",
    },
    quotationTitle: {
      name: "quotationTitle",
      label: "Quotation Title",
      type: "text",
      errorMsg: "Quotation Title is required.",
      invalidMsg: "Quotation Title is invalid.",
    },
    quotationMessage: {
      name: "quotationMessage",
      label: "Quotation Message",
      type: "text",
      errorMsg: "Quotation Message is required.",
      invalidMsg: "Quotation Message is invalid.",
    },
    quoteItems: {
      name: "quoteItems",
      label: "Quote Items",
      type: "box",
      min1Msg: "Must have at least 1 Quote Item.",
      max100Msg: "Must have at most 100 Quote Items.",
    },
    quoteDiscountType: {
      name: "quoteDiscountType",
      label: "Discount Type",
      type: "text",
      errorMsg: "Discount Type is required.",
      invalidMsg: "Discount Type is invalid.",
    },
    quoteGrossTotal: {
      name: "quoteGrossTotal",
      label: "Gross Total",
      type: "text",
      errorMsg: "Gross Total is required.",
      invalidMsg: "Gross Total is invalid.",
    },
    quoteDiscountTotal: {
      name: "quoteDiscountTotal",
      label: "Discount",
      type: "text",
      errorMsg: "Discount Total is required.",
      invalidMsg: "Discount Total is invalid.",
    },
    quoteBeforeGstTotal: {
      name: "quoteBeforeGstTotal",
      label: "Total Amount Before GST",
      type: "text",
      errorMsg: "Total Amount Before GST is required.",
      invalidMsg: "Total Amount  Before GST is invalid.",
    },
    quoteGstAmount: {
      name: "quoteGstAmount",
      label: `${gstPercentage}% GST`,
      type: "text",
      errorMsg: "GST Amount is required.",
      invalidMsg: "GST Amount is invalid.",
    },
    quoteAfterGstTotal: {
      name: "quoteAfterGstTotal",
      label: "Final Amount Inclusive of GST",
      type: "text",
      errorMsg: "Final Amount Inclusive of GST is required.",
      invalidMsg: "Final Amount Inclusive of GST is invalid.",
    },
    beginingStatement: {
      name: "beginingStatement",
      label: "Begining Statement",
      type: "text",
      errorMsg: "Begining Statement is required.",
      invalidMsg: "Begining Statement is invalid.",
    },
    installationPeriod: {
      name: "installationPeriod",
      label: "Installation Period",
      type: "text",
      errorMsg: "Installation Period is required",
      invalidMsg: "Installation Period is invalid",
    },
    installationStartDate: {
      name: "installationStartDate",
      label: "Start Date",
      type: "text",
      errorMsg: "Start Date is required.",
      invalidMsg: "Start Date is invalid.",
    },
    installationEndDate: {
      name: "installationEndDate",
      label: "End Date",
      type: "text",
      errorMsg: "End Date is required.",
      invalidMsg: "End Date is invalid.",
    },
    paymentTerm: {
      name: "paymentTerm",
      label: "Payment Term",
      type: "text",
      errorMsg: "Payment Term is required.",
      invalidMsg: "Payment Term is invalid.",
    },
    validity: {
      name: "validity",
      label: "Validity ",
      type: "text",
      errorMsg: "is required",
      invalidMsg: "is invalid",
    },
    exclusions: {
      name: "exclusions",
      label: "Exclusions",
      type: "box",
      min1Msg: "Must have at least 1 Exclusion.",
      max50Msg: "Must have at most 50 Exclusions.",
    },
    note: {
      name: "note",
      label: "Note",
      type: "text",
      errorMsg: "Note is required",
      invalidMsg: "Note is invalid",
    },
    endingStatement: {
      name: "endingStatement",
      label: "Ending Statement",
      type: "text",
      errorMsg: "Ending Statement is required.",
      invalidMsg: "Ending Statement is invalid.",
    },
    quotationAttachments: {
      name: "quotationAttachments",
      label: "Attachments",
      type: "box",
      min1Msg: "Must have at least 1 Attachment.",
      max20Msg: "Must have at most 20 Attachments.",
    },
  },
};

const serviceTeamsForm = {
  formId: "service-teams-form",
  formField: {
    serviceTeamName: {
      name: "serviceTeamName",
      label: "Service Team",
      type: "text",
      errorMsg: "Service Team is required.",
      invalidMsg: "Service Team is invalid",
    },
  },
};

const serviceTypesForm = {
  formId: "service-types-form",
  formField: {
    serviceTypeName: {
      name: "serviceTypeName",
      label: "Service Type",
      type: "text",
      errorMsg: "Service Type is required.",
      invalidMsg: "Service Type is invalid",
    },
  },
};

const quotationAttachmentsForm = {
  formId: "quotation-attachments-form",
  formField: {
    quotationAttachmentFile: {
      name: "quotationAttachmentFile",
      label: "Attachment File",
      type: "box",
      errorMsg: "Attachment File is required.",
      invalidMsg: "File size must be less than 5 MB.",
    },
  },
};

const exclusionsForm = {
  formId: "exclusions-form",
  formField: {
    exclusionClause: {
      name: "exclusionClause",
      label: "Exclusion Clause",
      type: "text",
      errorMsg: "Exclusion Clause is required.",
      invalidMsg: "Exclusion Clause is invalid.",
    },
  },
};

const quoteItemsForm = {
  formId: "quote-items-form",
  formField: {
    itemOrdinal: {
      name: "itemOrdinal",
      label: "Ordinal",
      type: "text",
      errorMsg: "Ordinal is required.",
      invalidMsg: "Ordinal is invalid.",
    },
    itemNo: {
      name: "itemNo",
      label: "No.",
      type: "text",
      errorMsg: "Required.",
      invalidMsg: "Invalid.",
    },
    itemSubNo: {
      name: "itemSubNo",
      label: "Sub No.",
      type: "text",
      errorMsg: "Required.",
      invalidMsg: "Invalid.",
    },
    itemServiceType: {
      name: "itemServiceType",
      label: "Service Type",
      type: "text",
      errorMsg: "Required.",
      invalidMsg: "Invalid.",
    },
    itemDescription: {
      name: "itemDescription",
      label: "Description",
      type: "text",
      errorMsg: "Required.",
      invalidMsg: "Invalid.",
    },
    itemServiceTeam: {
      name: "itemServiceTeam",
      label: "Service Team",
      type: "text",
      errorMsg: "Required.",
      invalidMsg: "Invalid.",
    },
    itemQuantity: {
      name: "itemQuantity",
      label: "Quantity",
      type: "text",
      errorMsg: "Required.",
      invalidMsg: "Invalid.",
    },
    itemUnitCost: {
      name: "itemUnitCost",
      label: "Unit Cost",
      type: "text",
      errorMsg: "Required.",
      invalidMsg: "Invalid.",
    },
    itemGrossAmount: {
      name: "itemGrossAmount",
      label: "Gross Amount",
      type: "text",
      errorMsg: "Required.",
      invalidMsg: "Invalid.",
    },
    itemDiscount: {
      name: "itemDiscount",
      label: "Discount",
      type: "text",
      errorMsg: "Required.",
      invalidMsg: "Invalid.",
    },
    itemNetAmount: {
      name: "itemNetAmount",
      label: "Net Amount",
      type: "text",
      errorMsg: "Required.",
      invalidMsg: "Invalid.",
    },
  },
};

export {
  form as default,
  serviceTeamsForm,
  serviceTypesForm,
  quotationAttachmentsForm,
  exclusionsForm,
  quoteItemsForm,
};
