import { useCallback } from "react";

import { pdf, Document, Page, View } from "@react-pdf/renderer";
import DocumentHeader from "pages/quotations/pdf/components/DocumentHeader";
import Subject from "pages/quotations/pdf/components/Subject";
import AuditTrail from "pages/quotations/pdf/components/AuditTrail";
import CustomerInformation from "pages/quotations/pdf/components/CustomerInformation";
import PriceQuote from "pages/quotations/pdf/components/PriceQuote";
import TermsAndConditions from "pages/quotations/pdf/components/TermsAndConditions";
import Signatory from "pages/quotations/pdf/components/Signatory";
import DocumentFooter from "pages/quotations/pdf/components/DocumentFooter";
import styles from "pages/quotations/pdf/styles";
import { useDocument } from "hooks/useDocument";
import { useFile } from "hooks/useFile";

export const useQuotationPdfManager = () => {
  const { getFileRef, uploadFile } = useFile();
  const { retrieveDoc } = useDocument();

  const pdfDoc = (
    data,
    ownerDisplayName,
    ownerSignature,
    ownerDesignation,
    companyStamp
  ) => (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={{ marginBottom: 15 }} fixed>
          <DocumentHeader />
          <AuditTrail values={data} />
        </View>
        <View style={styles.body}>
          <CustomerInformation values={data} />
          <Subject values={data} />
          <PriceQuote values={data} />
        </View>
        <View style={styles.body} break>
          <TermsAndConditions values={data} />
          <Signatory
            values={data}
            ownerName={ownerDisplayName}
            ownerSignature={ownerSignature}
            ownerDesignation={ownerDesignation}
            companyStamp={companyStamp}
          />
        </View>
        <View style={{ marginTop: 15 }} fixed>
          <DocumentFooter />
        </View>
      </Page>
    </Document>
  );

  const generateQuotationPdf = useCallback(
    async (data) => {
      try {
        const quotationNumberRevision = `${data.quotationNumber}R${data.quotationRevision}`;
        const filePath = `Quotation-GA-Q-${quotationNumberRevision}.pdf`;

        let quotationFileRef = null;

        try {
          quotationFileRef = await getFileRef(filePath);
        } catch (err) {
          console.log(err);
        }

        if (!quotationFileRef) {
          //Quotation owner name
          const createdByUser =
            data.createdBy && (await retrieveDoc("users", data.createdBy));
          const modifiedByUser =
            data.modifiedBy && (await retrieveDoc("users", data.modifiedBy));
          let ownerDisplayName = "";
          let ownerSignature = "";
          let ownerDesignation = "";
          if (modifiedByUser) {
            ownerDisplayName = modifiedByUser?.data.displayName;
            ownerSignature =
              modifiedByUser?.data.signatureAttachments[0]?.attachmentURL;
            ownerDesignation = modifiedByUser?.data.designation;
          } else {
            ownerDisplayName = createdByUser?.data.displayName;
            ownerSignature =
              createdByUser?.data.signatureAttachments[0]?.attachmentURL;
            ownerDesignation = createdByUser?.data.designation;
          }
          // Company stamp
          const company =
            data.createdBy && (await retrieveDoc("companies", "gate"));
          const companyStamp = company?.data.stampAttachments[0]?.attachmentURL;
          const blob = await pdf(
            pdfDoc(
              data,
              ownerDisplayName,
              ownerSignature,
              ownerDesignation,
              companyStamp
            )
          ).toBlob();
          const uploadedFileRef = await uploadFile(filePath, blob);

          quotationFileRef = uploadedFileRef;
        }

        return quotationFileRef;
      } catch (err) {
        throw err;
      }
    },
    [getFileRef, retrieveDoc, uploadFile]
  );

  return {
    generateQuotationPdf,
  };
};
