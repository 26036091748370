import PropTypes from "prop-types";

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";

import MDBox from "components/atoms/MDBox";
import MDTypography from "components/atoms/MDTypography";
import MDButton from "components/atoms/MDButton";

import AddIcon from "@mui/icons-material/Add";

import FormTextFieldFast from "components/molecules/Formik/FormTextFieldFast";
import FormInnerFieldArray from "components/molecules/Formik/FormInnerFieldArray";
import FormAutocompleteFast from "components/molecules/Formik/FormAutocompleteFast";

import { paymentModesForm } from "pages/invoices/manage/schemas/form";
import { paymentModesInitialValues } from "pages/invoices/manage/schemas/initialValues";
import { paymentModesValidation } from "pages/invoices/manage/schemas/validations";

import { paymentModeNameOptions } from "pages/invoices/manage/schemas/setups";

function InvoiceDetails({
  mainForm,
  mainFormField,
  modeDisabled,
  mode,
  dispatchMainError,
}) {
  const { invoiceTerms, paymentModes, invoiceRemarks } = mainFormField;

  // destructure from Payment Modes Form for current section
  const { formId: paymentModesFormId, formField: paymentModesFormField } =
    paymentModesForm;
  const { paymentModeName } = paymentModesFormField;

  return (
    <Card id="invoice-details" sx={{ overflow: "visible" }}>
      <MDBox m={3}>
        <MDBox mb={3}>
          <MDTypography variant="h5">Invoice Details</MDTypography>
        </MDBox>
        <MDBox>
          <Grid
            container
            rowSpacing={{ xs: 1, sm: 2 }}
            columnSpacing={{ xs: 1, sm: 3 }}
          >
            <Grid item xs={12} sm={2}>
              <MDTypography variant="body3" fontWeight="bold">
                {invoiceTerms.label}
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormTextFieldFast
                form={mainForm}
                field={invoiceTerms}
                variant="standard"
                disabled={modeDisabled}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">Days</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}></Grid>

            <Grid item xs={12} sm={2}>
              <MDTypography variant="body3" fontWeight="bold">
                {paymentModes.label}
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormInnerFieldArray
                editable={false}
                showTitle={false}
                form={mainForm}
                field={paymentModes}
                max={20}
                disabled={modeDisabled}
                dispatchError={dispatchMainError}
                itemInitialValues={paymentModesInitialValues}
                itemValidation={paymentModesValidation}
                itemKeyField={paymentModeName} // TODO: change to uuid
                itemDisplayFields={[paymentModeName]}
                itemForm={(formik, disabledField, disabledAdd) => {
                  return (
                    /* not using <Form> because cannot have form within another form (Customer.js),
                      in order not to trigger validation of sub formik when submitting main formik */
                    <MDBox id={paymentModesFormId} autoComplete="off">
                      <MDBox
                        bgColor="white"
                        borderRadius="lg"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="baseline"
                        flexWrap="nowrap"
                      >
                        <MDBox flexBasis="100%">
                          <FormAutocompleteFast
                            size="small"
                            form={formik}
                            field={paymentModeName}
                            options={paymentModeNameOptions}
                            disabled={disabledField}
                          />
                        </MDBox>
                        {!disabledAdd && (
                          <MDBox ml={2}>
                            <MDButton
                              size="small"
                              onClick={formik.handleSubmit}
                              color="success"
                              iconOnly
                            >
                              <AddIcon />
                            </MDButton>
                          </MDBox>
                        )}
                      </MDBox>
                    </MDBox>
                  );
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}></Grid>

            <Grid item xs={12} sm={2}>
              <MDTypography variant="body3" fontWeight="bold">
                {invoiceRemarks.label}
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormTextFieldFast
                multiline
                form={mainForm}
                field={invoiceRemarks}
                variant="standard"
                disabled={modeDisabled}
              />
            </Grid>
            <Grid item xs={12} sm={4}></Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Card>
  );
}

InvoiceDetails.propTypes = {
  mainForm: PropTypes.object.isRequired,
  mainFormField: PropTypes.object.isRequired,
  modeDisabled: PropTypes.bool.isRequired,
  mode: PropTypes.string.isRequired,
};

export default InvoiceDetails;
