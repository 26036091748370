import { Text, View } from "@react-pdf/renderer";
import styles from "pages/purchaseorders/pdf/styles";

function BillingInformation({
  values,
  /*purchaseorderReference,
  quotationNumber,*/
}) {
  return (
    <>
      <View style={[styles.containerRow, styles.section]}>
        <View style={[styles.containerCol, { flexBasis: "100%" }]}>
          <Text
            style={[styles.title, { fontWeight: "bold", textAlign: "right" }]}
          >
            PURCHASE ORDER
          </Text>
        </View>
      </View>
      <View style={[styles.containerRow, styles.section]}>
        <View style={[styles.containerCol, { flexBasis: "48%" }]}>
          <View
            style={[
              styles.tablebody,
              styles.topmost,
              styles.leftmost,
              { flexGrow: 1 },
            ]}
          >
            <Text style={[styles.tablebodytext, { fontWeight: "bold" }]}>
              Vendor's Detail
            </Text>
            <Text style={styles.tablebodytext}>{values.supplierName}</Text>
            <Text style={styles.tablebodytext}>{values.supplierAddress}</Text>
            <Text style={styles.tablebodytext}> </Text>
            <Text style={styles.tablebodytext}>
              Tel: {values.contactPersonLandlineNumber}
            </Text>
            <Text style={styles.tablebodytext}>
              Hp: {values.contactPersonMobileNumber}
            </Text>
            <Text style={styles.tablebodytext}>
              Email: {values.contactPersonEmail}
            </Text>
          </View>
          <View
            style={[
              styles.tablebody,
              styles.topmost,
              styles.leftmost,
              { flexGrow: 1 },
            ]}
          >
            <Text style={[styles.tablebodytext, { fontWeight: "bold" }]}>
              Shipping Address
            </Text>
            <Text style={styles.tablebodytext}>
              {values.deliverySiteAddress}
            </Text>
          </View>
          <View
            style={[
              styles.tablebody,
              styles.topmost,
              styles.leftmost,
              styles.bottommost,
              { flexGrow: 1 },
            ]}
          >
            <Text style={[styles.tablebodytext, { fontWeight: "bold" }]}>
              Billing Details
            </Text>
            <Text style={styles.tablebodytext}>{values.billingDetails}</Text>
          </View>
        </View>

        <View style={[{ flexBasis: "4%" }]}></View>

        <View style={[styles.containerCol, { flexBasis: "48%" }]}>
          <View
            style={[
              styles.tablebodytight,
              styles.topmost,
              styles.leftmost,
              { flexGrow: 1 },
            ]}
          >
            <View style={[styles.containerRow, styles.section]}>
              <View style={[styles.containerCol, { flexBasis: "50%" }]}>
                <Text
                  style={[styles.tablebodytexttight, { fontWeight: "bold" }]}
                >
                  PO Reference
                </Text>
              </View>
              <View style={[styles.containerCol, { flexBasis: "50%" }]}>
                <Text style={[styles.tablebodytexttight]}>
                  {values.purchaseorderRevision === "0"
                    ? `GA-PO-${values.purchaseorderNumber}`
                    : `GA-PO-${values.purchaseorderNumber}R${values.purchaseorderRevision}`}
                </Text>
              </View>
            </View>
          </View>
          <View
            style={[
              styles.tablebodytight,
              styles.topmost,
              styles.leftmost,
              { flexGrow: 1 },
            ]}
          >
            <View style={[styles.containerRow, styles.section]}>
              <View style={[styles.containerCol, { flexBasis: "50%" }]}>
                <Text
                  style={[styles.tablebodytexttight, { fontWeight: "bold" }]}
                >
                  Date
                </Text>
              </View>
              <View style={[styles.containerCol, { flexBasis: "50%" }]}>
                <Text style={[styles.tablebodytexttight]}>
                  {values.purchaseorderDate}
                </Text>
              </View>
            </View>
          </View>
          <View
            style={[
              styles.tablebodytight,
              styles.topmost,
              styles.leftmost,
              { flexGrow: 1 },
            ]}
          >
            <View style={[styles.containerRow, styles.section]}>
              <View style={[styles.containerCol, { flexBasis: "50%" }]}>
                <Text
                  style={[styles.tablebodytexttight, { fontWeight: "bold" }]}
                >
                  Currency
                </Text>
              </View>
              <View style={[styles.containerCol, { flexBasis: "50%" }]}>
                <Text style={[styles.tablebodytexttight]}>
                  {values.purchaseorderCurrency}
                </Text>
              </View>
            </View>
          </View>
          <View
            style={[
              styles.tablebodytight,
              styles.topmost,
              styles.leftmost,
              { flexGrow: 1 },
            ]}
          >
            <View style={[styles.containerRow, styles.section]}>
              <View style={[styles.containerCol, { flexBasis: "50%" }]}>
                <Text
                  style={[styles.tablebodytexttight, { fontWeight: "bold" }]}
                >
                  Term
                </Text>
              </View>
              <View style={[styles.containerCol, { flexBasis: "50%" }]}>
                <Text style={[styles.tablebodytexttight]}>
                  {values.purchaseorderTerm}
                </Text>
              </View>
            </View>
          </View>
          <View
            style={[
              styles.tablebodytight,
              styles.topmost,
              styles.leftmost,
              { flexGrow: 1 },
            ]}
          >
            <View style={[styles.containerRow, styles.section]}>
              <View style={[styles.containerCol, { flexBasis: "50%" }]}>
                <Text
                  style={[styles.tablebodytexttight, { fontWeight: "bold" }]}
                >
                  Supplier Reference
                </Text>
              </View>
              <View style={[styles.containerCol, { flexBasis: "50%" }]}>
                <Text style={[styles.tablebodytexttight]}>
                  {values.supplierReference}{" "}
                </Text>
              </View>
            </View>
          </View>
          <View
            style={[
              styles.tablebodytight,
              styles.topmost,
              styles.leftmost,
              { flexGrow: 1 },
            ]}
          >
            <View style={[styles.containerRow, styles.section]}>
              <View style={[styles.containerCol, { flexBasis: "50%" }]}>
                <Text
                  style={[styles.tablebodytexttight, { fontWeight: "bold" }]}
                >
                  SO Reference
                </Text>
              </View>
              <View style={[styles.containerCol, { flexBasis: "50%" }]}>
                <Text style={[styles.tablebodytexttight]}>
                  {values.SOReference}
                </Text>
              </View>
            </View>
          </View>
          <View
            style={[
              styles.tablebodytight,
              styles.topmost,
              styles.leftmost,
              { flexGrow: 1 },
            ]}
          >
            <View style={[styles.containerRow, styles.section]}>
              <View style={[styles.containerCol, { flexBasis: "50%" }]}>
                <Text
                  style={[styles.tablebodytexttight, { fontWeight: "bold" }]}
                >
                  Delivery Date
                </Text>
              </View>
              <View style={[styles.containerCol, { flexBasis: "50%" }]}>
                {values.deliveryDateDesc === "TBC" ? (
                  <Text style={[styles.tablebodytexttight]}>
                    {values.deliveryDateDesc}
                  </Text>
                ) : (
                  <Text style={[styles.tablebodytexttight]}>
                    {values.deliveryDate}
                  </Text>
                )}
              </View>
            </View>
          </View>
          <View
            style={[
              styles.tablebodytight,
              styles.topmost,
              styles.leftmost,
              { flexGrow: 1 },
            ]}
          >
            <View style={[styles.containerRow, styles.section]}>
              <View style={[styles.containerCol, { flexBasis: "50%" }]}>
                <Text
                  style={[styles.tablebodytexttight, { fontWeight: "bold" }]}
                >
                  Type of Purchase
                </Text>
              </View>
              <View style={[styles.containerCol, { flexBasis: "50%" }]}>
                <Text style={[styles.tablebodytexttight]}>
                  {values.typeofPurchase}:&nbsp;
                  {values.typeofPurchase === "Admin" ? (
                    <Text style={[styles.tablebodytexttight]}>
                      {values.typeofAdminPurchase}
                    </Text>
                  ) : (
                    <Text style={[styles.tablebodytexttight]}>
                      {values.typeofBusinessPurchase}
                    </Text>
                  )}
                </Text>
              </View>
            </View>
          </View>
          <View
            style={[
              styles.tablebodytight,
              styles.topmost,
              styles.leftmost,
              styles.bottommost,
              { flexGrow: 1 },
            ]}
          >
            <View style={[styles.containerRow, styles.section]}>
              <View style={[styles.containerCol, { flexBasis: "50%" }]}>
                <Text
                  style={[styles.tablebodytexttight, { fontWeight: "bold" }]}
                >
                  Description
                </Text>
              </View>
              <View style={[styles.containerCol, { flexBasis: "50%" }]}>
                <Text style={[styles.tablebodytexttight]}>
                  {values.purchaseDescription}
                </Text>
              </View>
            </View>
          </View>
        </View>
      </View>
    </>
  );
}

export default BillingInformation;
