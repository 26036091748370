import PropTypes from "prop-types";

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

import MDBox from "components/atoms/MDBox";
import MDTypography from "components/atoms/MDTypography";

import FormRadioGroup from "components/molecules/Formik/FormRadioGroup";
import FormAttachments from "components/molecules/Formik/FormAttachments";
import FormTextFieldFast from "components/molecules/Formik/FormTextFieldFast";
import FormCheckbox from "components/molecules/Formik/FormCheckbox";

import { commencementAttachmentsForm } from "pages/salesorders/manage/schemas/form";
import { commencementAttachmentsInitialValues } from "pages/salesorders/manage/schemas/initialValues";
import { commencementAttachmentsValidation } from "pages/salesorders/manage/schemas/validations";

import {
  commencementTypeOptions,
  newCommencementTypeOptions,
  convertCommencementTypeOptions,
  defaultCommencementType,
} from "pages/salesorders/manage/schemas/setups";

function Commencement({
  mainForm,
  mainFormField,
  modeDisabled,
  defaultReadOnly,
  mode,
  dispatchMainError,
}) {
  const { values } = mainForm;
  const {
    commencementType,
    verbalAgreement,
    pendingPO,
    purchaseorderReference,
    quotationRefForInvoice,
    commencementAttachments,
  } = mainFormField;

  let commTypeOptions = commencementTypeOptions;
  if (mode === "new") {
    commTypeOptions = newCommencementTypeOptions;
  } else if (mode === "convert") {
    commTypeOptions = convertCommencementTypeOptions;
  } else {
    commTypeOptions = [values.commencementType];
  }

  // destructure from Attachments Form for current section
  const {
    formId: commencementAttachmentsFormId,
    formField: commencementAttachmentsFormField,
  } = commencementAttachmentsForm;
  const { commencementAttachmentFile, attachmentName, attachmentURL } =
    commencementAttachmentsFormField;

  return (
    <Card id="commencement" sx={{ overflow: "visible" }}>
      <MDBox m={3}>
        <MDBox mb={3}>
          <MDTypography variant="h5">Commencement</MDTypography>
        </MDBox>

        <MDBox>
          <Grid
            container
            rowSpacing={{ xs: 1, sm: 2 }}
            columnSpacing={{ xs: 1, sm: 3 }}
          >
            <Grid item xs={12} sm={2}>
              <MDTypography variant="body3" fontWeight="bold">
                {commencementType.label}
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormRadioGroup
                row
                showTitle={false}
                form={mainForm}
                field={commencementType}
                options={commTypeOptions}
                defaultValue={defaultCommencementType}
                disabled={modeDisabled}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              {/* {values.commencementType === "From Quotation" && ( */}
              <FormCheckbox
                form={mainForm}
                field={verbalAgreement}
                variant="standard"
                disabled={modeDisabled || mode === "attach"}
              />
              <FormCheckbox
                form={mainForm}
                field={pendingPO}
                variant="standard"
                disabled={modeDisabled || mode === "attach"}
              />
              {/* )} */}
            </Grid>
            <Grid item xs={12} sm={2}>
              <MDTypography variant="body3" fontWeight="bold">
                {purchaseorderReference.label}
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormTextFieldFast
                multiline
                form={mainForm}
                field={purchaseorderReference}
                variant="standard"
                disabled={modeDisabled}
              />
            </Grid>
            <Grid item xs={12} sm={4}></Grid>
            <Grid item xs={12} sm={2}>
              <MDTypography variant="body3" fontWeight="bold">
                {quotationRefForInvoice.label}
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormTextFieldFast
                multiline
                form={mainForm}
                field={quotationRefForInvoice}
                variant="standard"
                disabled={modeDisabled}
              />
            </Grid>
            <Grid item xs={12} sm={4}></Grid>
            <Grid item xs={12} sm={2}>
              <MDTypography variant="body3" fontWeight="bold">
                {commencementAttachments.label}
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormAttachments
                showTitle={false}
                form={mainForm}
                field={commencementAttachments}
                max={20}
                disabled={modeDisabled}
                dispatchError={dispatchMainError}
                itemFormId={commencementAttachmentsFormId}
                itemInitialValues={commencementAttachmentsInitialValues}
                itemValidation={commencementAttachmentsValidation}
                itemFileField={commencementAttachmentFile}
                itemKeyField={attachmentURL}
                itemDisplayFields={[attachmentName]}
              />
            </Grid>
            <Grid item xs={12} sm={4}></Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Card>
  );
}

Commencement.propTypes = {
  mainForm: PropTypes.object.isRequired,
  mainFormField: PropTypes.object.isRequired,
  modeDisabled: PropTypes.bool.isRequired,
  mode: PropTypes.string.isRequired,
};

export default Commencement;
