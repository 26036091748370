import format from "date-fns/format";
import { itemServiceOptions, itemServiceTypeOptions } from "schema/setups";

const defaultpaymentTerms = "30";

const defaultReminderRepeatIntervalDays = "30";

const emailSubjectOptions = [
  "<Follow up> Payment: 30 days:",
  "<Follow up> Payment: 60 days:",
  "<Follow up> Payment: 90 days:",
];

const defaultSubject = emailSubjectOptions[0];

const emailContentOptions = [
  `Dear Sir/Madam

We are following up with you on the outstanding invoice(s) under your account. 
We have noted you may have missed out our earlier email and will appreciate that you send us the payment soon.

Kindly refer to invoice(s) for payment remarks.

Invoice No:
Payment due date:
Date of visit:
Description of Work:

If payment has been recently made prior to our follow up, kindly send us the payment confirmation so we can highlight to our Finance team to check.

Please do not hesitate to contact us if you have any queries.
Thank you for your continued support and we look forward to hearing from you soon.`,
  `Dear Sir/Madam

We are following up with you on the outstanding invoice(s) under your account. 
We have noted you may have missed out our earlier email and will appreciate that you send us the payment soon.

Kindly refer to invoice(s) for payment remarks.

S/N	    Invoice No	     Location of Work	     Description of Work       Payment due date     
1			
2			
3			
4			
5			

If payment has been recently made prior to our follow up, kindly send us the payment confirmation so we can highlight to our Finance team to check.

Please do not hesitate to contact us if you have any queries.
Thank you for your continued support and we look forward to hearing from you soon.`,
  `Dear Sir/Madam

We are following up with you on the outstanding invoice(s) under your account. 
Original invoice(s) has been mailed to Address_________ on Date_________.

Do send us the screenshot of payment confirmation once completed. 

S/N	    Invoice No	     Location of Work	     Description of Work       Payment due date     			
2			
3			
4			
5			

If payment has been recently made prior to our follow up, kindly send us the payment confirmation so we can highlight to our Finance team to check.

Please do not hesitate to contact us if you have any queries.
Thank you for your continued support and we look forward to hearing from you soon.`,
];

const defaultContent = emailContentOptions[0];

const invoicingTypeOptions = [
  "For Adhoc Sales",
  "From Purchase Order",
  "From Completed Sales Order",
];

const newInvoicingTypeOptions = ["For Adhoc Sales", "From Purchase Order"];

const convertInvoicingTypeOptions = ["From Completed Sales Order"];

const defaultInvoicingType = invoicingTypeOptions[0];

const paymentModeNameOptions = ["Cheque Payment", "Bank Transfer", "PayNow"];

const defaultPaymentModes = paymentModeNameOptions.map((option) => {
  return { paymentModeName: option };
});

const defaultInvoiceDate = format(new Date(), "dd/MM/yyyy");

const defaultInvoiceNumber = "000000-00";

const invoiceItemServiceOptions = itemServiceOptions;
const invoiceItemServiceTypeOptions = itemServiceTypeOptions;

const discountTypeOptions = ["Itemized", "Non-Itemized"];
const defaultDiscountType = discountTypeOptions[0];

const paymentTypeOptions = ["Non-progressive", "Progressive"];
const defaultPaymentType = paymentTypeOptions[0];

const gstPercentage = 7;

const defaultInvoiceColumns = [
  { key: "invoiceNumberText", name: "Invoice" },
  { key: "invoiceDateText", name: "Invoice Date" },
  { key: "invoiceTitle", name: "Title" },
  { key: "invoiceTerms", name: "Payment Terms" },
  { key: "reminderIntervalDays", name: "Reminder Interval Days" },
  { key: "customerName", name: "Customer Name" },
  { key: "billingContactName", name: "Billing Contact Name" },
  { key: "createdByText", name: "Created By" },
  { key: "modifiedByText", name: "Modified By" },
  { key: "invoiceAfterGstTotalText", name: "Final Amount Inclusive of GST" },
  { key: "invoiceDepositText", name: "Deposit" },
  { key: "invoiceBalanceDueText", name: "Balance Due" },
  { key: "salesorderNumberText", name: "Sales Order" },
  { key: "sentByText", name: "Sent By" },
  { key: "receiptedByText", name: "Received By" },
  { key: "closedByText", name: "Closed By" },
  { key: "status", name: "Status" },
  { key: "actions", name: "Actions" },
];

const columnOptions = [
  { key: "canSend", name: "canSend" },
  { key: "canReceipt", name: "canReceipt" },
  { key: "canClose", name: "canClose" },
  { key: "canPrint", name: "canPrint" },
  { key: "canEdit", name: "canEdit" },
  { key: "canDelete", name: "canDelete" },
  { key: "id", name: "ID" },
  { key: "invoiceNumberText", name: "Invoice" },
  { key: "invoiceDateText", name: "Invoice Date" },
  { key: "invoiceTitle", name: "Title" },
  { key: "invoiceTerms", name: "Payment Terms" },
  { key: "invoiceRemarks", name: "Remarks" },
  { key: "reminderIntervalDays", name: "Reminder Interval Days" },
  { key: "customerName", name: "Customer Name" },
  { key: "customerAddress", name: "Customer Address" },
  { key: "billingContactName", name: "Billing Contact Name" },
  {
    key: "billingContactLandlineNumber",
    name: "Billing Contact Landline Number",
  },
  { key: "billingContactMobileNumber", name: "Billing Contact Mobile Number" },
  { key: "billingContactEmail", name: "Billing Contact Email" },
  // { key: "deliverySiteName", name: "Delivery Site Name" },
  // { key: "deliverySiteAddress", name: "Delivery Site Address" },
  { key: "installationPeriod", name: "Installation Period" },
  { key: "installationStartDateText", name: "Installation Start Date" },
  { key: "installationEndDateText", name: "Installation End Date" },
  { key: "paymentType", name: "Payment Type" },
  { key: "createdAtText", name: "Created At" },
  { key: "createdByText", name: "Created By" },
  { key: "modifiedAtText", name: "Modified At" },
  { key: "modifiedByText", name: "Modified By" },
  { key: "invoiceDiscountType", name: "Discount Type" },
  { key: "invoiceGrossTotal", name: "Total Amount Value" },
  { key: "invoiceGrossTotalText", name: "Total Amount" },
  { key: "invoiceDiscountTotalText", name: "Discount" },
  { key: "invoiceBeforeGstTotalText", name: "Total Amount Before GST" },
  { key: "invoiceAdditionalDiscountText", name: "Additional Discount" },
  { key: "invoiceNetBeforeGstTotalText", name: "Net Amount Before GST" },
  { key: "invoiceGstAmountText", name: "GST Amount" },
  { key: "invoiceAfterGstTotalText", name: "Final Amount Inclusive of GST" },
  { key: "invoiceDepositText", name: "Deposit" },
  { key: "invoiceBalanceDueText", name: "Balance Due" },
  { key: "invoicingType", name: "Invoicing Type" },
  { key: "invoicingReference", name: "Invoicing Reference" },
  { key: "salesorderIdText", name: "Sales Order Id" },
  { key: "salesorderNumberText", name: "Sales Order" },
  { key: "sentAtText", name: "Sent At" },
  { key: "sentByText", name: "Sent By" },
  { key: "receiptedAtText", name: "Received At" },
  { key: "receiptedByText", name: "Received By" },
  { key: "receiptingReference", name: "Receipting Reference" },
  { key: "invoiceReceiptingDateText", name: "Receipting Date" },
  { key: "closedAtText", name: "Closed At" },
  { key: "closedByText", name: "Closed By" },
  { key: "closingReference", name: "Closing Reference" },
  { key: "invoiceClosingDateText", name: "Closing Date" },
  { key: "status", name: "Status" },
  { key: "deletedAtText", name: "Deleted At" },
  { key: "deletedByText", name: "Deleted By" },
  { key: "actions", name: "Actions" },
];

export { serviceTeamNameOptions, serviceTypeNameOptions } from "schema/setups";

export {
  defaultpaymentTerms,
  defaultReminderRepeatIntervalDays,
  emailSubjectOptions,
  defaultSubject,
  emailContentOptions,
  defaultContent,
  invoicingTypeOptions,
  newInvoicingTypeOptions,
  convertInvoicingTypeOptions,
  defaultInvoicingType,
  paymentModeNameOptions,
  defaultPaymentModes,
  defaultInvoiceDate,
  defaultInvoiceNumber,
  invoiceItemServiceOptions,
  invoiceItemServiceTypeOptions,
  discountTypeOptions,
  defaultDiscountType,
  paymentTypeOptions,
  defaultPaymentType,
  gstPercentage,
  columnOptions,
  defaultInvoiceColumns,
};
