import format from "date-fns/format";

const defaultPurchaseOrderDate = format(new Date(), "dd/MM/yyyy");
const defaultPurchaseOrderNumber = "000000-00";
const defaultPurchaseOrderRevision = "0";

const currencyOptions = ["SGD", "USD"];
const defaultCurrency = currencyOptions[0];

const purchaseorderTermOptions = ["30 Days", "60 Days", "90 Days"];
const defaultPurchaseOrderTerm = purchaseorderTermOptions[0];

const typeofPurchaseOptions = ["Admin", "Business"];
const defaultTypeofPurchase = typeofPurchaseOptions[0];

const typeofBusinessPurchaseOptions = [
  "Equipment",
  "Materials",
  "Services",
  "Others",
];
const defaultTypeofBusinessPurchase = typeofBusinessPurchaseOptions[0];

const typeofAdminPurchaseOptions = [
  "Other Supplier",
  "Bank charges",
  "Entertainment",
  "Freight/ courier fees",
  "Foreign exchange (Realized P&L)",
  "Furniture & Fitting",
  "Gifts & Condolences",
  "Hardware",
  "Insurance Covid-19",
  "Insurance Motor",
  "Insurance Public Liabilty",
  "Insurance WICA",
  "Insurance Foreign Worker Medical",
  "Insurance Security Bond",
  "Insurance Renovation (for customer)",
  "Marketing & Advertisement",
  "Medical",
  "Pantry & Refreshment",
  "Phone",
  "PPE",
  "Printing",
  "Professional fees",
  "Recruitment",
  "Rental",
  "Salary",
  "Software",
  "Staff meal",
  "Stationery",
  "Training",
  "Uniform",
  "Utility",
  "Vehicle",
];
const defaultTypeofAdminPurchase = typeofAdminPurchaseOptions[0];

const deliveryDateDescOptions = ["Delivery Date", "TBC"];
const defaultDeliveryDateDesc = deliveryDateDescOptions[1];

const currentDate = new Date();
currentDate.setDate(currentDate.getDate() + 3);
const defaultDeliveryDate = format(currentDate, "dd/MM/yyyy");
//currentDate.setMonth(currentDate.getMonth()+1);
//const defaultDeliveryDate = format(new Date(), "dd/MM/yyyy");

const deliverySites = ["GA Office", "Onsite", "Self-collect"];
const defaultDeliverySiteName = deliverySites[0];

const billingDetailsOptions = [
  "GATE Pte Ltd\n(Accounts Department)\nAddress: Blk 53 Ubi Ave 1 #01-51 Paya Ubi Industrial Park S408934\nEmail: accounts@gifted-school.com\nTel: 6296 2002",
];
const defaultBillingDetails = billingDetailsOptions[0];

const discountTypeOptions = ["Itemized", "Non-Itemized"];
const defaultDiscountType = discountTypeOptions[0];

const paymentTypeOptions = ["Non-progressive", "Progressive"];
const defaultPaymentType = paymentTypeOptions[0];

const gstPercentage = 7;

const gstOptions = ["With GST", "No GST"];
const defaultGstOption = gstOptions[0];

const defaultPurchaseorderColumns = [
  { key: "purchaseorderNumberRevision", name: "Purchase Order" },
  { key: "purchaseorderDateText", name: "Purchase Order Date" },
  { key: "purchaseDescription", name: "Purchase Order Desc" },
  { key: "supplierName", name: "Supplier Name" },
  { key: "supplierReference", name: "Supplier Reference" },
  { key: "deliveryDateDesc", name: "Delivery Date Desc" },
  { key: "deliveryDate", name: "Delivery Date" },
  { key: "deliverySiteName", name: "Delivery Site Name" },
  { key: "createdByText", name: "Created By" },
  { key: "modifiedByText", name: "Modified By" },
  { key: "SOReference", name: "Sales Order Reference" },
  { key: "purchaseorderCurrency", name: "Currency" },
  { key: "purchaseorderTerm", name: "Term" },
  { key: "typeofPurchase", name: "Type of Purchase" },
  {
    key: "purchaseorderFinalTotalText",
    name: "Final Amount Exclusive of GST",
  },
  { key: "approvedByText", name: "Approved By" },
  { key: "sentByText", name: "Sent By" },
  { key: "status", name: "Status" },
  { key: "poPaymentEmailSentAtText", name: "Payment Email Sent At" },
  { key: "poPaymentEmailSentByText", name: "Payment Email Sent By" },
  { key: "completionActions", name: "Completion Actions" },
  { key: "actions", name: "Actions" },
];

const columnOptions = [
  { key: "id", name: "ID" },
  { key: "purchaseorderNumber", name: "Purchase Order Number" },
  { key: "purchaseorderRevision", name: "Purchase Order Revision" },
  { key: "purchaseorderNumberRevision", name: "Purchase Order" },
  { key: "purchaseorderDateText", name: "Purchase Order Date" },
  { key: "purchaseDescription", name: "Purchase Order Desc" },
  { key: "supplierName", name: "Supplier Name" },
  { key: "supplierReference", name: "Supplier Reference" },
  { key: "contactPersonName", name: "Contact Person Name" },
  {
    key: "contactPersonLandlineNumber",
    name: "Contact Person Landline Number",
  },
  { key: "contactPersonMobileNumber", name: "Contact Person Mobile Number" },
  { key: "contactPersonEmail", name: "Contact Person Email" },
  { key: "deliveryDateDesc", name: "Delivery Date Desc" },
  { key: "deliveryDate", name: "Delivery Date" },
  { key: "deliverySiteName", name: "Delivery Site Name" },
  { key: "deliverySiteAddress", name: "Delivery Site Address" },
  { key: "createdAtText", name: "Created At" },
  { key: "createdByText", name: "Created By" },
  { key: "modifiedAtText", name: "Modified At" },
  { key: "modifiedByText", name: "Modified By" },
  { key: "SOReference", name: "Sales Order Reference" },
  { key: "purchaseorderCurrency", name: "Currency" },
  { key: "purchaseorderTerm", name: "Term" },
  { key: "typeofPurchase", name: "Type of Purchase" },
  { key: "typeofBusinessPurchase", name: "Type of Business Purchase" },
  { key: "typeofAdminPurchase", name: "Type of Admin Purchase" },

  { key: "purchaseorderDiscountType", name: "Discount Type" },
  { key: "gstOption", name: "GST Option" },
  { key: "purchaseorderGrossTotal", name: "Total Amount Value" },
  { key: "purchaseorderGrossTotalText", name: "Total Amount" },
  { key: "purchaseorderDiscountTotalText", name: "Discount" },
  { key: "purchaseorderBeforeGstTotalText", name: "Total Amount Before GST" },
  { key: "purchaseorderGstAmountText", name: "GST Amount" },
  {
    key: "purchaseorderAfterGstTotalText",
    name: "Final Amount Inclusive of GST",
  },
  {
    key: "purchaseorderFinalTotalText",
    name: "Final Amount Exclusive of GST",
  },
  { key: "approvedAtText", name: "Approved At" },
  { key: "approvedByText", name: "Approved By" },
  { key: "sentAtText", name: "Sent At" },
  { key: "sentByText", name: "Sent By" },
  { key: "status", name: "Status" },
  { key: "poPaymentEmailSentAtText", name: "Payment Email Sent At" },
  { key: "poPaymentEmailSentByText", name: "Payment Email Sent By" },

  { key: "voidAtText", name: "Void At" },
  { key: "voidByText", name: "Void By" },
  { key: "poCancelDate", name: "Cancel Date" },
  { key: "poCancelEmailSentByText", name: "Cancel Email Sent By" },
  { key: "poCancelRemarks", name: "Cancel Remarks" },
  { key: "goodsReceivedAt", name: "Goods Received At" },
  { key: "goodsReceivedBy", name: "Goods Received By" },
  { key: "copyAtText", name: "Copy At" },
  { key: "copyByText", name: "Copy By" },
  { key: "deletedAtText", name: "Deleted At" },
  { key: "deletedByText", name: "Deleted By" },
  { key: "completionActions", name: "Completion Actions" },
  { key: "actions", name: "Actions" },
];

export {
  defaultPurchaseOrderDate,
  defaultPurchaseOrderNumber,
  defaultPurchaseOrderRevision,
  currencyOptions,
  defaultCurrency,
  purchaseorderTermOptions,
  defaultPurchaseOrderTerm,
  typeofPurchaseOptions,
  defaultTypeofPurchase,
  typeofBusinessPurchaseOptions,
  defaultTypeofBusinessPurchase,
  typeofAdminPurchaseOptions,
  defaultTypeofAdminPurchase,
  defaultDeliveryDate,
  defaultDeliverySiteName,
  billingDetailsOptions,
  defaultBillingDetails,
  discountTypeOptions,
  defaultDiscountType,
  paymentTypeOptions,
  defaultPaymentType,
  gstPercentage,
  deliveryDateDescOptions,
  defaultDeliveryDateDesc,
  gstOptions,
  defaultGstOption,
  columnOptions,
  defaultPurchaseorderColumns,
};
