const emailSubjectOptions = ["Quote:"];

const defaultSubject = emailSubjectOptions[0];

const emailContentOptions = [
  `Dear Sir/Madam

Attached is the quotation for your perusal and acceptance.

Kindly scan the duly signed quotation and reply to us as soon as possible.

Please do not hesitate to contact us if you have any queries.
We look forward to hearing from you soon. Thank you.`,
  `Dear Sir/Madam

Attached is the quotation for your perusal and acceptance. 

Based on our findings on site, we have prepared the necessary quote for you. 
However if a new error occurs, please note that it will not be inclusive in this quotation. We will follow up with a seperate quotation for your consideration. 

Kindly scan the duly signed quotation to us as soon as possible.

Please do not hesitate to contact us if you have any queries
We look forward to hearing from you soon. Thank you.`,
];

const defaultContent = emailContentOptions[0];

export {
  emailSubjectOptions,
  defaultSubject,
  emailContentOptions,
  defaultContent,
};
