import PropTypes from "prop-types";

import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";

import MDTypography from "components/atoms/MDTypography";

function RadioGroupWrapper({
  name,
  value,
  onChange,
  label,
  options,
  defaultValue,
  disabled,
  error,
  showTitle,
  ...rest
}) {
  return (
    <FormControl component="fieldset" error={error}>
      {showTitle && <FormLabel component="legend">{label}</FormLabel>}
      <RadioGroup
        name={name}
        value={value}
        defaultValue={defaultValue}
        onChange={onChange}
        {...rest}
      >
        {options.map((option) => {
          return (
            <MDTypography
              key={option}
              variant="body3"
              fontWeight="regular"
              color="text"
            >
              <FormControlLabel
                key={option}
                value={option}
                disabled={disabled}
                control={<Radio />}
                label={option}
              />
            </MDTypography>
          );
        })}
      </RadioGroup>
    </FormControl>
  );
}

// Setting default values for the props of RadioGroupWrapper
RadioGroupWrapper.defaultProps = {
  showTitle: true,
};

// typechecking props for RadioGroupWrapper
RadioGroupWrapper.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.array,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  showTitle: PropTypes.bool,
};

export default RadioGroupWrapper;
