import { useParams } from "react-router-dom";
import SalesOrder from "pages/salesorders/manage/SalesOrder";

// using Proxy Page to remount when route to same route location but different param
function SalesOrderProxy() {
  const { mode, salesorderId } = useParams();
  return <SalesOrder key={`${mode}/${salesorderId}`} />;
}

export default SalesOrderProxy;
