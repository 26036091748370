import { Font, StyleSheet } from "@react-pdf/renderer";
import RobotoMonoRegular from "assets/fonts/RobotoMono-Regular.ttf";
import RobotoMonoBold from "assets/fonts/RobotoMono-Bold.ttf";
import AsulRegular from "assets/fonts/Asul-Regular.ttf";
import AsulBold from "assets/fonts/Asul-Bold.ttf";

Font.register({
  family: "Asul",
  fonts: [{ src: AsulRegular }, { src: AsulBold, fontWeight: 700 }],
});

Font.register({
  family: "Roboto Mono",
  fonts: [{ src: RobotoMonoRegular }, { src: RobotoMonoBold, fontWeight: 700 }],
});

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    fontFamily: "Asul",
    padding: 30,
    fontSize: 9,
  },
  body: {
    fontSize: 9,
    flexGrow: 10,
    lineHeight: 1.5,
  },
  section: {
    margin: "6 0",
  },
  emailContent: {
    margin: "3 0",
  },
  image: {
    width: 80,
    height: 80,
  },
  container: {
    margin: 5,
  },
  containerRow: {
    flexDirection: "row",
  },
  containerCol: {
    flexDirection: "col",
  },
  spaceBetween: {
    justifyContent: "space-between",
  },
  bottomLine: {
    borderBottomWidth: 1,
  },
  logo: {
    height: 60,
  },
  header: {
    fontSize: 20,
  },
  property: {
    flexBasis: "20%",
    fontWeight: "bold",
  },
  colon: {
    flexBasis: "3%",
  },
  tableSection: {
    margin: "6 0 0",
  },
  tableheader: {
    lineHeight: 0,
    padding: "5 3",
    textAlign: "center",
    fontWeight: "bold",
    borderStyle: "solid",
    borderRightWidth: 1,
    borderBottomWidth: 1,
  },
  tablebody: {
    lineHeight: 0,
    padding: "5 3",
    borderRightWidth: 1,
  },
  tablebodytight: {
    lineHeight: 0,
    padding: "0 3",
    borderRightWidth: 1,
  },
  tablebodytext: {
    lineHeight: 1.5,
  },
  tablebodytexttight: {
    lineHeight: 1.1,
  },
  tablefooter: {
    lineHeight: 0,
    padding: "5 3",
    textAlign: "right",
    borderStyle: "solid",
    borderRightWidth: 1,
    borderBottomWidth: 1,
  },
  topmost: {
    borderTopWidth: 1,
  },
  leftmost: {
    borderLeftWidth: 1,
  },
  bottommost: {
    borderBottomWidth: 1,
  },
  price: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  tncheader: {
    textDecoration: "underline",
    fontWeight: "bold",
  },
  pmheader: {
    textDecoration: "underline",
    fontWeight: "bold",
  },
  signatory: {
    fontWeight: "bold",
  },
  signaturespace: {
    flexGrow: 10,
    flexBasis: "100",
  },
  title: {
    fontSize: 10,
  },
  samewidth: {
    fontFamily: "Roboto Mono",
    fontSize: 10,
  },
});

export default styles;
