import PropTypes from "prop-types";

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";

import MDBox from "components/atoms/MDBox";
import MDTypography from "components/atoms/MDTypography";

import { TextField } from "@mui/material";

import FormDatePicker from "components/molecules/Formik/FormDatePicker";
import FormTextField from "components/molecules/Formik/FormTextField";

import parse from "date-fns/parse";

function WorkDetails({
  mainForm,
  mainFormField,
  modeDisabled,
  mode,
  dispatchMainError,
}) {
  const { values } = mainForm;
  const { workorderNumber, workStartDate, workingDays, workRemarks } =
    mainFormField;

  return (
    <Card id="work-details" sx={{ overflow: "visible" }}>
      <MDBox m={3}>
        <MDBox mb={3}>
          <MDTypography variant="h5">Work Details</MDTypography>
        </MDBox>
        <MDBox>
          <Grid container mb={1} columnSpacing={{ xs: 1, sm: 3 }}>
            <Grid item xs={12} sm={3}>
              <FormDatePicker
                form={mainForm}
                field={workStartDate}
                inputFormat={"dd/MM/yyyy"}
                variant="standard"
                disabled={modeDisabled}
                minDate={
                  values.salesorderDate && values.salesorderDate.length > 0
                    ? parse(values.salesorderDate, "dd/MM/yyyy", new Date())
                    : null
                }
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <MDBox display="flex">
                <FormTextField
                  form={mainForm}
                  field={workingDays}
                  variant="standard"
                  disabled={modeDisabled}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} sm={3}></Grid>
            <Grid item xs={12} sm={3}>
              <MDBox display="flex">
                <FormTextField
                  form={mainForm}
                  field={workorderNumber}
                  variant="standard"
                  disabled={modeDisabled}
                  sx={
                    mode === "new" || mode === "convert"
                      ? { display: "none" }
                      : {}
                  }
                  InputProps={{
                    readOnly: true,
                    startAdornment: (
                      <InputAdornment position="start">GA-WO-</InputAdornment>
                    ),
                  }}
                />
                <TextField
                  label={workorderNumber.label}
                  defaultValue="Auto Generate"
                  variant="standard"
                  sx={
                    mode !== "new" && mode !== "convert"
                      ? { display: "none" }
                      : {}
                  }
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} sm={2}>
              <MDTypography variant="body3" fontWeight="bold">
                {workRemarks.label}
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormTextField
                multiline
                form={mainForm}
                field={workRemarks}
                variant="standard"
                disabled={modeDisabled}
              />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Card>
  );
}

WorkDetails.propTypes = {
  mainForm: PropTypes.object.isRequired,
  mainFormField: PropTypes.object.isRequired,
  modeDisabled: PropTypes.bool.isRequired,
  mode: PropTypes.string.isRequired,
};

export default WorkDetails;
