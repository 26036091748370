import React from "react";
import { Text, View } from "@react-pdf/renderer";
import styles from "pages/purchaseorders/pdf/styles";
import currency from "currency.js";
import { gstPercentage } from "pages/purchaseorders/manage/schemas/setups";

const nonitemizedRow = (title, amount, titleStyles = [], amountStyles = []) => (
  <View style={styles.containerRow}>
    <View style={[styles.tablefooter, ...titleStyles, { flexBasis: "85%" }]}>
      <Text>{title}</Text>
    </View>
    <View style={[styles.tablefooter, ...amountStyles, { flexBasis: "15%" }]}>
      <View style={styles.price}>
        <Text>$</Text>
        <Text>
          {currency(amount, {
            symbol: "",
          }).format()}
        </Text>
      </View>
    </View>
  </View>
);

const itemizedRow = (title, amount, titleStyles = [], amountStyles = []) => (
  <View style={styles.containerRow}>
    <View style={[styles.tablefooter, ...titleStyles, { flexBasis: "88%" }]}>
      <Text>{title}</Text>
    </View>
    <View style={[styles.tablefooter, ...amountStyles, { flexBasis: "12%" }]}>
      <View style={styles.price}>
        <Text>$</Text>
        <Text>
          {currency(amount, {
            symbol: "",
          }).format()}
        </Text>
      </View>
    </View>
  </View>
);

const itemizedFirstRow = (values) => (
  <View style={styles.containerRow}>
    <View
      style={[
        styles.tablefooter,
        styles.leftmost,
        styles.topmost,
        { flexBasis: "64%", fontWeight: "bold" },
      ]}
    >
      <Text>TOTAL AMOUNT</Text>
    </View>
    <View style={[styles.tablefooter, styles.topmost, { flexBasis: "12%" }]}>
      <View style={styles.price}>
        <Text>$</Text>
        <Text>
          {currency(values.purchaseorderGrossTotal, {
            symbol: "",
          }).format()}
        </Text>
      </View>
    </View>
    <View style={[styles.tablefooter, styles.topmost, { flexBasis: "12%" }]}>
      <View style={styles.price}>
        <Text>$</Text>
        <Text>
          {currency(values.purchaseorderDiscountTotal, {
            symbol: "",
          }).format()}
        </Text>
      </View>
    </View>
    <View style={[styles.tablefooter, styles.topmost, { flexBasis: "12%" }]}>
      <View style={styles.price}>
        <Text>$</Text>
        <Text>
          {currency(values.purchaseorderBeforeGstTotal, {
            symbol: "",
          }).format()}
        </Text>
      </View>
    </View>
  </View>
);

function PriceQuoteTableFooter({ values }) {
  return values.purchaseorderDiscountType === "Itemized" &&
    values.gstOption === "With GST" ? (
    <View wrap={false}>
      {itemizedFirstRow(values)}
      {itemizedRow(gstPercentage + "% GST", values.purchaseorderGstAmount, [
        styles.leftmost,
        { fontWeight: "bold" },
      ])}
      {itemizedRow(
        "FINAL AMOUNT INCLUSIVE OF GST",
        values.purchaseorderAfterGstTotal,
        [styles.leftmost, { fontWeight: "bold" }]
      )}
    </View>
  ) : values.purchaseorderDiscountType === "Itemized" &&
    values.gstOption !== "With GST" ? (
    <View wrap={false}>
      {itemizedFirstRow(values)}
      {itemizedRow("FINAL AMOUNT", values.purchaseorderFinalTotal, [
        styles.leftmost,
        { fontWeight: "bold" },
      ])}
    </View>
  ) : values.purchaseorderDiscountType !== "Itemized" &&
    values.gstOption === "With GST" ? (
    <View wrap={false}>
      {nonitemizedRow(
        "TOTAL AMOUNT",
        values.purchaseorderGrossTotal,
        [styles.topmost, styles.leftmost, { fontWeight: "bold" }],
        [styles.topmost]
      )}
      {nonitemizedRow("DISCOUNT", values.purchaseorderDiscountTotal, [
        styles.leftmost,
        { fontWeight: "bold" },
      ])}
      {nonitemizedRow(
        "TOTAL AMOUNT BEFORE GST",
        values.purchaseorderBeforeGstTotal,
        [styles.leftmost, { fontWeight: "bold" }]
      )}
      {nonitemizedRow(gstPercentage + "% GST", values.purchaseorderGstAmount, [
        styles.leftmost,
        { fontWeight: "bold" },
      ])}
      {nonitemizedRow(
        "FINAL AMOUNT INCLUSIVE OF GST",
        values.purchaseorderAfterGstTotal,
        [styles.leftmost, { fontWeight: "bold" }]
      )}
    </View>
  ) : (
    <View wrap={false}>
      {nonitemizedRow(
        "TOTAL AMOUNT",
        values.purchaseorderGrossTotal,
        [styles.topmost, styles.leftmost, { fontWeight: "bold" }],
        [styles.topmost]
      )}
      {nonitemizedRow("DISCOUNT", values.purchaseorderDiscountTotal, [
        styles.leftmost,
        { fontWeight: "bold" },
      ])}
      {nonitemizedRow("FINAL AMOUNT", values.purchaseorderFinalTotal, [
        styles.leftmost,
        { fontWeight: "bold" },
      ])}
    </View>
  );
}

export default PriceQuoteTableFooter;
