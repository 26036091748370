import { Text, View } from "@react-pdf/renderer";
import styles from "pages/quotations/pdf/styles";

function Subject({ values }) {
  return (
    <View style={[styles.containerCol, styles.section]}>
      <Text style={[styles.emailContent, { fontWeight: "bold" }]}>
        {values.quotationTitle}
      </Text>
      <Text style={styles.emailContent}>{values.quotationMessage} </Text>
    </View>
  );
}

export default Subject;
