import * as Yup from "yup";
import parse from "date-fns/parse";
import form, {
  purchaseorderAttachmentsForm,
  purchaseorderItemsForm,
  audittrailAttachmentsForm,
} from "pages/purchaseorders/manage/schemas/form";

const {
  formField: {
    purchaseorderDate,
    purchaseorderNumber,
    purchaseorderRevision,
    supplierReference,
    SOReference,
    // deliveryDate,
    typeofBusinessPurchase,
    typeofAdminPurchase,
    purchaseDescription,
    supplierId,
    supplierName,
    supplierAddress,
    contactPersonName,
    contactPersonLandlineNumber,
    contactPersonMobileNumber,
    contactPersonEmail,
    deliverySiteName,
    deliverySiteAddress,
    purchaseorderAttachments,
    audittrailAttachments,
  },
} = form;

const noValidation = Yup.object().shape({});

const validations = Yup.object().shape({
  [purchaseorderDate.name]: Yup.date()
    .transform((value, originalValue) => {
      return parse(originalValue, "dd/MM/yyyy", new Date());
    })
    .required(purchaseorderDate.errorMsg)
    .typeError(purchaseorderDate.invalidMsg),
  [purchaseorderNumber.name]: Yup.string()
    .required(purchaseorderNumber.errorMsg)
    .matches("(^\\d{6}-\\d{2}$|^---------$)", purchaseorderNumber.invalidMsg),
  [purchaseorderRevision.name]: Yup.string()
    .required(purchaseorderRevision.errorMsg)
    .matches("(^\\d{1,2}$|^-$)", purchaseorderRevision.invalidMsg),
  [supplierReference.name]: Yup.string().required(supplierReference.errorMsg),
  [SOReference.name]: Yup.string().required(SOReference.errorMsg),
  /*[deliveryDate.name]: Yup.date()
    .transform((value, originalValue) => {
      return parse(originalValue, "dd/MM/yyyy", new Date());
    })
    .required(purchaseorderDate.errorMsg)
    .typeError(purchaseorderDate.invalidMsg),*/
  [typeofBusinessPurchase.name]: Yup.string().required(
    typeofBusinessPurchase.errorMsg
  ),
  [typeofAdminPurchase.name]: Yup.string().required(
    typeofAdminPurchase.errorMsg
  ),
  [purchaseDescription.name]: Yup.string().required(
    purchaseDescription.errorMsg
  ),
  [supplierId.name]: Yup.string()
    .required(supplierId.errorMsg)
    .max(200, supplierId.invalidMsg),
  [supplierName.name]: Yup.string()
    .required(supplierName.errorMsg)
    .max(200, supplierName.invalidMsg),
  [supplierAddress.name]: Yup.string()
    .required(supplierAddress.errorMsg)
    .max(200, supplierAddress.invalidMsg),
  [contactPersonName.name]: Yup.string()
    .required(contactPersonName.errorMsg)
    .max(200, contactPersonName.invalidMsg),
  [contactPersonLandlineNumber.name]: Yup.string()
    // .when(contactPersonMobileNumber.name, {
    //   is: (contactPersonMobileNumberValue) =>
    //     !contactPersonMobileNumberValue?.length,
    //   then: Yup.string().required(contactPersonLandlineNumber.errorMsg),
    // })
    .matches(
      "^[+]?([0-9]*[\\-\\s.])*([0-9]*[(]+[0-9]{1,3}[)])*([0-9]*[\\-\\s.])*[0-9]{4,15}$",
      contactPersonLandlineNumber.invalidMsg
    ),
  [contactPersonMobileNumber.name]: Yup.string().matches(
    "^[+]?([0-9]*[\\-\\s.])*([0-9]*[(]+[0-9]{1,3}[)])*([0-9]*[\\-\\s.])*[0-9]{4,15}$",
    contactPersonMobileNumber.invalidMsg
  ),
  [contactPersonEmail.name]: Yup.string()
    //.required(contactPersonEmail.errorMsg)
    .max(200, contactPersonEmail.invalidMsg)
    .email(contactPersonEmail.invalidMsg),
  [deliverySiteName.name]: Yup.string()
    .required(deliverySiteName.errorMsg)
    .max(200, deliverySiteName.invalidMsg),
  [deliverySiteAddress.name]: Yup.string()
    .required(deliverySiteAddress.errorMsg)
    .max(200, deliverySiteAddress.invalidMsg),
  [purchaseorderAttachments.name]: Yup.array().max(
    20,
    purchaseorderAttachments.max20Msg
  ),
  [audittrailAttachments.name]: Yup.array().max(
    20,
    audittrailAttachments.max20Msg
  ),
});

const {
  formField: {
    itemNo,
    itemSubNo,
    itemDescription,
    itemQuantity,
    itemUnitCost,
    itemGrossAmount,
    itemDiscount,
    itemNetAmount,
  },
} = purchaseorderItemsForm;

const purchaseorderItemsValidation = Yup.object().shape({
  [itemNo.name]: Yup.string().matches("^\\d{1,2}$", itemNo.invalidMsg),
  [itemSubNo.name]: Yup.string().matches(
    "^\\d{1,2}\\.\\d{1,2}$",
    itemSubNo.invalidMsg
  ),
  [itemDescription.name]: Yup.string()
    .required(itemDescription.errorMsg)
    .max(2000, itemDescription.invalidMsg),
  [itemQuantity.name]: Yup.string()
    .required(itemQuantity.errorMsg)
    .matches("^\\d{1,4}$", itemQuantity.invalidMsg),
  [itemUnitCost.name]: Yup.string()
    .required(itemUnitCost.errorMsg)
    .matches("^\\d*(\\.\\d{1,5})?$", itemUnitCost.invalidMsg),
  [itemGrossAmount.name]: Yup.string()
    .required(itemGrossAmount.errorMsg)
    .matches("^\\d*(\\.\\d{1,2})?$", itemGrossAmount.invalidMsg),
  [itemDiscount.name]: Yup.string()
    .required(itemDiscount.errorMsg)
    .matches("^\\d*(\\.\\d{1,3})?$", itemDiscount.invalidMsg),
  [itemNetAmount.name]: Yup.string()
    .required(itemNetAmount.errorMsg)
    .matches("^\\d*(\\.\\d{1,2})?$", itemNetAmount.invalidMsg),
});

const {
  formField: { purchaseorderAttachmentFile },
} = purchaseorderAttachmentsForm;

const purchaseorderAttachmentsValidation = Yup.object().shape({
  [purchaseorderAttachmentFile.name]: Yup.mixed()
    .nullable()
    .required(purchaseorderAttachmentFile.errorMsg)
    .test(
      "fileSize",
      purchaseorderAttachmentFile.invalidMsg,
      (value) => !value || (value && value.size <= 5 * 1024 * 1024)
    ),
});

const {
  formField: { audittrailAttachmentFile },
} = audittrailAttachmentsForm;

const audittrailAttachmentsValidation = Yup.object().shape({
  [audittrailAttachmentFile.name]: Yup.mixed()
    .nullable()
    .required(audittrailAttachmentFile.errorMsg)
    .test(
      "fileSize",
      audittrailAttachmentFile.invalidMsg,
      (value) => !value || (value && value.size <= 5 * 1024 * 1024)
    ),
});

export {
  validations as default,
  noValidation,
  purchaseorderAttachmentsValidation,
  purchaseorderItemsValidation,
  audittrailAttachmentsValidation,
};
