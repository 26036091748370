const serviceTeamNameOptions = [
  "Team 1",
  "Team 2",
  "Team 3",
  "Team 4",
  "Team 5",
  "Subcontractor 1",
  "Subcontractor 2",
  "Subcontractor 3",
  "Subcontractor 4",
  "Subcontractor 5",
];

const serviceTypeNameOptions = [
  "Air-conditioning Works",
  "Mechanical Ventilation/ Duct Works",
  "CHW Equipment Works",
  "Chiller Works",
  "Electrical Works",
  "Hoisting",
  "A&A Works",
  "Maintenance servicing",
  "Chemical Wash",
  "Repair Works",
  "Attendance & Troubleshooting",
  "Refrigerant Charging",
  "Others",
  "Waste Water Management",
];

const itemServiceOptions = [
  {
    serviceType: "Air-conditioning Works",
    statements: [
      `ACMV Work- VRV/ DX (Re-using existing refrigerant pipes)

To Provide labours, tools and material to install the above equipment
-	Protection works prior to job commencement
-	Dismantle existing ceiling grid to facilitate replacement work
-	Pump down existing AC system, dismantle and dispose
-	Perform Pressure Test on existing piping to ensure re-usability
-	Installation of new equipment re-use existing control wiring, refrigerant piping, drainage piping and electrical wiring
-	Testing & Commissioning of system
-	Reinstatement of ceiling grid upon job completion

Note: Follow up quotation will be submitted upon detection of leakage in existing refrigerant pipes`,
      `ACMV Work- VRV/ DX (New refrigerant pipes)

To Provide labours, tools and material to install the above equipment
-	Protection works prior to job commencement
-	Dismantle existing ceiling grid to facilitate replacement work
-	Pump down existing AC system, dismantle and dispose
-	Installation of new equipment complete with new refrigerant piping, drainage piping, control wiring and electrical wiring
-	Testing & Commissioning of system
-	Reinstatement of ceiling grid upon job completion
-	Piping approx.`,
      `Complete Knockdown (CKD)

-	To provide labour, tools and material to perform CKD of equipment
-	Manual transportation of equipment to designated site
-	Assembly of equipment on at site`,
    ],
  },
  {
    serviceType: "Mechanical Ventilation/ Duct Works",
    statements: [
      `Duct – Hot Air Duct for Condensing Unit

-	Fabrication and installation of hot air duct at Condensing Unit
-	Application of primer coat and anti-corrosion paint
-	Application of outdoor waterproof sealant at Duct joints`,
      `DUCT – Fan Coil Unit

-	Fabrication and Installation of new Supply Air header come with new flexible joint, new rockwool insulation, application of waterproof sealant and necessary supports
-	Fabrication and Installation new Return Air Plenum box come with new rockwool insulation, application of waterproof sealant, removable filter frame & washable filter
-	Fabrication and Installation of new secondary insulated s/s water tray`,
      `DUCT – Modification of existing supply air duct (SAD)

-	Modification of existing supply header and retrofit to newly installed equipment
-	 Application of waterproof sealant and re-insulation work
-	Fabrication and Installation of new secondary insulated s/s water tray`,
      `DUCT – Modification of existing supply air duct (RAD)

-	Modification of existing return air plenum box and retrofit to newly installed equipment
-	To provide, fabrication and installation of new filter slot, frame and washable filter
-	 Application of waterproof sealant and re-insulation work
-	Fabrication and Installation of new secondary insulated s/s water tray`,
      `DUCT – Exhaust Air Fan(EAF)/ Fresh Air Fan (FAF) – Replacement
-	Dismantle and Disposal of faulty equipment
-	Dismantling of supply/return air duct
-	Upon Installation of new equipment, to reinstate supply/return air duct and application of sealant and insulation where necessary`,
      `DUCT – EAF/FAF Duct
-	Fabrication and installation of supply/exhaust duct complete with connecting of duct joints, application of sealant and all necessary supports (Approx.          mm)`,
    ],
  },
  {
    serviceType: "CHW Equipment Works",
    statements: [
      `CHW FCU – Replacement of Equipment & Pipe work from Gate Valves
-	Protection works before commencement of work
-	Close of existing gate valve / Release of water
-	Dismantle and dispose
-	Installation of equipment complete with new s/s flexible joint connection, strainer, thermometer, pressure gauge, drain off point, new motorised actuator, and all other necessary supports
-	Fabricate and install new GI jacketing complete with PU Insulation, application of primer coat and painting
-	Opening of S/R gate valves and topping up of water
-	Electrical wire connection of motorised actuator and control wires
-	Testing of equipment`,
    ],
  },
  {
    serviceType: "Chiller Works",
    statements: [
      `Replacement of actuator valve only
-	To dismantle & dispose of faulty part
-	Replacement of actuator only
-	Reinstate all wiring connection
-	Testing`,
      `Replacement actuator valve body
-	To dismantle & dispose of faulty part
-	Installation of new part include new gasket and water leak test
-	Reinstate all wiring connection to motorised actuator
-	Testing

Note: PU, Welding, Pipe Freezing works to be quoted separately`,
      `Hot Tapping
-	To provide Hot Tap Specialist, Welder, Equipment, Tools & Material to carry out Hot Tap Service for water pipe only

Note: PU Insulation/ GI Jacketing work to be quoted separately`,
      `Pipe Freezing
-	To provide Pipe Freezing Specialist, Equipment, Tools & Material to carry out Pipe Freezing Service for CHW/ CWP only
Note: PU Insulation/ GI Jacketing work to be quoted separately`,
      `Replacement of AHU – Bearing
-	To provide Specialist, Tools and Material to dismantle and replacement bearing
-	To provide necessary greasing
-	Testing`,
    ],
  },
  {
    serviceType: "Electrical Works",
    statements: [
      `Installation of New Power Isolator
-	Installation of new power isolator include lay of new wires from existing DB to Isolator location (Approx.       m)
-	Testing`,
      `Replacement of Power Isolator (DX Equipment)
-	To dismantle & dispose existing faulty parts
-	Installation of new power isolator include all wires connection 
-	Testing`,
      `Replacement of MCB
-	To dismantle & dispose existing faulty parts
-	Installation of new power isolator include all wires connection
-	Testing`,
      `Replacement of faulty Wires (Power wire/ Screen cable)
-	To dismantle & remove faulty wires
-	Laying of new wires include necessary connection (Approx.       m)
-	Testing of equipment

Note: Programming of equipment will be quoted separately`,
    ],
  },
  {
    serviceType: "Hoisting",
    statements: [
      `Hoisting

-	To provide professional crane operator, lifting crews to hoist equipment
-	Submission of Risk Assessment, Certificates and Lifting Plan`,
    ],
  },
  {
    serviceType: "A&A Works",
    statements: [
      `Concrete Plinth 

-	To provide labour to cast concrete plinth reinforce with rebar
Size approx. 2000mm x 4000mm`,
      `Control Panel (LCP)

-	Fabrication of control panel complete with necessary adapters, relays to communicate with FCUs
-	Functions to include, Auto-changeover, Timer, High Temperature Sensor Activation, Buzzer Alarm, Manual Selector switch, Indicator LED Lights, Test Light function and all necessary electrical wiring and feedback
-	BMS Communication/ Signal/ Feedback terminal port
-	To provide single line diagram
-	Testing and Commissioning of Panel`,
    ],
  },
  {
    serviceType: "Maintenance servicing",
    statements: [
      `General Maintenance
-	To carry out necessary protection work before commencement of work
-	To provide AC specialist to carry out general cleaning of equipment
-	To vacuum existing drainage pipe
-	To check existing refrigerant level at operational mode
-	To check existing ampere at operation mode`,
    ],
  },
  {
    serviceType: "Chemical Wash",
    statements: [
      `Chemical Wash –
Type of Fan Coil Unit: Wall Mounted Type (Dismantle)

-	To carry out protection work before commencement of work
-	To provide AC specialist to carry out chemical washing of equipment
-	To vacuum equipment
-	To standard charge refrigerant
-	Testing of Equipment`,
      `Chemical Wash –
Type of Fan Coil Unit: Ceiling Cassette Type (Dismantle)

-	To carry out protection work before commencement of work
-	To provide AC specialist to carry out chemical washing of equipment using chemical net
-	Testing of Equipment`,
      `Chemical Wash – Ducted Type Equipment (Dismantle)
-	To carry out protection work before commencement of work
-	To provide AC specialist to carry out chemical washing of equipment
-	To remove Supply/Return air duct and reinstate with new insulation upon completion of work
-	To vacuum equipment
-	To standard charge refrigerant
-	Testing of Equipment`,
      `Chemical Wash – Ducted Type Equipment (Non-Dismantle)
-	To carry out protection work before commencement of work
-	To provide AC specialist to carry out chemical washing of equipment
-	To remove Supply/Return air duct and reinstate with new insulation upon completion of work
-	Testing of Equipment`,
      `Chemical Wash – Condensing Unit (Dismantle)
-	To carry out protection work before commencement of work
-	To provide AC specialist to carry out chemical washing of equipment
-	Testing of Equipment`,
    ],
  },
  {
    serviceType: "Repair Works",
    statements: [
      `Replacement of PCB
-	Dismantle & Disposal of faulty parts
-	Installation of new part
-	Testing of Equipment`,
      `Replacement of Thermistor
-	Dismantle & Disposal of faulty parts
-	Installation of new part
-	Testing of Equipment`,
      `Replacement of Fan Motor
-	Dismantle & Disposal of faulty parts
-	Installation of new part
-	Testing of Equipment`,
      `Replacement of Fan Blower/Fan Blade
-	Dismantle & Disposal of faulty parts
-	Installation of new part
-	Testing of Equipment`,
      `Replacement of Expansion Valve Coil 
-	Dismantle & Disposal of faulty parts
-	Installation of new part
-	Testing of Equipment`,
      `Replacement of Expansion Valve Coil & Valve Body
-	Dismantle & Disposal of faulty parts
-	Installation of new part include brazing/ vacuuming of equipment
-	Charging of new refrigerant
-	Testing of Equipment`,
      `Replacement of Capacitor
-	Dismantle & Disposal of faulty parts
-	Installation of new part
-	Testing of Equipment`,
      `Replacement of fan coil unit flexible hose
-	Dismantle & Disposal of faulty parts
-	Installation of new part
-	Testing of Equipment`,
      `Replacement of Drain Pump, Float Switch & Drain Hose
-	Dismantle & Disposal of faulty parts
-	Installation of new part
-	Testing of Equipment`,
      `Replacement of Drain Pan
-	Dismantle & Disposal of faulty parts
-	Installation of new part
-	Testing of Equipment`,
      `Replacement of Decoration Panel
-	Dismantle & Disposal of faulty parts
-	Installation of new part
-	Testing of Equipment`,
      `Replacement of Magnetic Contactor
-	Dismantle & Disposal of faulty parts
-	Installation of new part
-	Testing of Equipment`,
      `Replacement of VRV Type Equipment - Low Pressure Switch (Brazing)
-	Dismantle & Disposal of faulty parts
-	Installation of new part include brazing and vacuuming of equipment
-	Testing of Equipment

Note: Charging of refrigerant to be quoted separately`,
      `Replacement of DX Type Equipment - Low Pressure Switch (Brazing)
-	Dismantle & Disposal of faulty parts
-	Installation of new part include brazing, vacuuming of equipment and charging of refrigerant
-	Testing of Equipment`,
      `Replacement of VRV Type Equipment - High Pressure Switch (Brazing) 
-	Dismantle & Disposal of faulty parts
-	Installation of new part include brazing and vacuuming of equipment
-	Testing of Equipment

Note: Charging of refrigerant to be quoted separately`,
      `Replacement of DX Type Equipment - High Pressure Switch (Brazing)
-	Dismantle & Disposal of faulty parts
-	Installation of new part include brazing, vacuuming of equipment and charging of refrigerant
-	Testing of Equipment`,
      `Replacement of DX Cap tube (Brazing)
-	Dismantle & Disposal of faulty parts
-	Installation of new part include brazing, vacuuming of equipment and charging of refrigerant
-	Testing of Equipment`,
      `Replacement of VRV Cap tube (Brazing)
-	Dismantle & Disposal of faulty parts
-	Installation of new part include brazing and vacuuming of equipment
-	Testing of Equipment

Note: Charging of refrigerant to be quoted separately`,
      `Replacement of DX compressor (Brazing)
-	To dismantle & dispose existing faulty parts
-	Installation of new compressor include brazing, vacuuming of equipment and charging of refrigerant
-	Testing of Equipment`,
      `Replacement of VRV compressor (Brazing)
-	To dismantle & dispose existing faulty parts
-	Installation of new compressor include brazing and vacuuming of equipment
-	Testing of Equipment

Note: Charging of refrigerant to be quoted separately`,
      `Replacement of refrigerant pipe for DX Equipment
-	To dismantle & dispose existing faulty parts
-	Installation of new refrigerant pipe include new insulation, brazing and all other necessary accessories
-	Vacuuming of Equipment
-	Testing of Equipment

Note: Charging of refrigerant to be quoted separately`,
      `Replacement of Fan Belt
-	To dismantle & dispose existing faulty part
-	Replacement of part
-	Testing of equipment`,
      `Pressure Test – DX Equipment
-	To dismantle existing equipment of installation of pressure gauges
-	To carry out pressure test using nitrogen gas for suction pipe, discharge pipe, fan coil units and condensing units at 400PSI
-	To hold pressure for 24 hours
-	To identify area of leak and mend leak visible and accessible to specialist
-	To generate report for customer

Note: Proposal for rectification work to be quoted separately`,
      `Drainage Pipe modification (16mm – 25mm)
-	To install new PVC T-joint include PVC ball valves, end cap for maintenance`,
      `Pressure Test – VRV Equipment
-	To dismantle existing equipment of installation of pressure gauges
-	Refer to Drawing for refrigerant pipe breakdown and pressure gauges installation location
-	To carry out pressure test using nitrogen gas for suction pipe, discharge pipe, fan coil units and condensing units at 400PSI
-	To hold pressure for 24 hours
-	To identify area of leak and mend leak visible and accessible to specialist
-	To reinstate all equipment upon completion of pressure test and Testing of Equipment (Optional)
-	To generate report for customer

Note: Proposal for rectification work to be quoted separately`,
      `Drainage Pipe modification (40mm – 50mm) 
-	To install new PVC T-joint include PVC ball valves, end cap for maintenance`,
      `Replacement of Supply/ Return – 4 Way Grilles
-	To dismantle & dispose of damage grilles
-	Installation of new grilles include insulation work, support and necessary connection of flexible duct connection to collar

Note: Plaster ceiling work to be quoted separately`,
      `Replacement of Condensate Pump (External)
-	To dismantle & dispose existing faulty equipment
-	Replacement of equipment
-	Testing of equipment`,
      `Replacement of Secondary Water Tray
-	To dismantle & dispose
-	Replacement of Secondary water tray include insulation of water tray and securing of water tray to existing AC Equipment`,
    ],
  },
  {
    serviceType: "Attendance & Troubleshooting",
    statements: [
      `Attendance & Troubleshooting (Non-Contract)`,
      `Attendance & Troubleshooting (Contract)`,
    ],
  },
  {
    serviceType: "Refrigerant Charging",
    statements: [
      `Refrigerant (R22) Per Kg
-	Standard charging of refrigerant
-	Testing of Equipment`,
      `Refrigerant (R410) Per Kg
-	Standard charging of refrigerant
-	Testing of Equipment`,
      `Refrigerant (R32) Per Kg
-	Standard charging of refrigerant
-	Testing of Equipment`,
    ],
  },
  {
    serviceType: "Others",
    statements: [
      `Brazing
-	To carry out necessary safety precaution and protection work
-	To provide certified Brazing specialist, Tools & Material to carry out brazing of copper pipes only`,
      `Vacuuming of Equipment
-	To provide AC specialist, Tools & Material to carry out vacuuming of equipment`,
      `Plaster Ceiling
-	To carry out necessary protection work before commencement of work
-	To cut ceiling to facilitate replacement/installation of equipment or removal/laying of refrigerant pipes (Approx 5-10 openings)
-	To patch affected ceiling and make good with white emulsion paint only

Note: Unless stated, all painting to be odourless white emulsion paint`,
      `Access Panel 
-	To carry out necessary protection work before commencement of work
-	To install access panel (450mm x 450mm) c/w necessary plastering and painting (White)`,
    ],
  },
  {
    serviceType: "Waste Water Management",
    statements: [
      `-	Collection of Waste Water solution
-	Neutralization of solution
-	Discharging of neutralized solution`,
    ],
  },
];

// const itemServiceTypeOptions = itemServiceOptions.map(
//   (element) => element.serviceType
// );

const itemServiceTypeOptions = serviceTypeNameOptions;

const itemServiceTeamOptions = serviceTeamNameOptions;

const exclusionClauseOptions = [
  "Heat Load/ Cooling Load calculation",
  "General maintenance/ Servicing of existing/new equipment",
  "Structural Endorsement",
  "LEW Endorsement",
  "Submission to relevant authority",
  "Scissor Lifts/ Boom lifts/ Scaffolding/ Staging",
  "Hacking/ Coring Works",
  "Partition wall/ Painting Works",
  "A&A Works",
  "Electrical - Incoming power supply",
  "Hoisting Works",
];

const approvalAmountL1 = 5000; // Quotation L1,  and SO(From PO type)

const approvalAmountL2 = 50000;

const approvalAmountAdhoc = 500;

export {
  serviceTeamNameOptions,
  serviceTypeNameOptions,
  itemServiceOptions,
  itemServiceTypeOptions,
  itemServiceTeamOptions,
  exclusionClauseOptions,
  approvalAmountL1,
  approvalAmountL2,
  approvalAmountAdhoc,
};
