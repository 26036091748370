import form, {
  paymentModesForm,
  serviceTeamsForm,
  serviceTypesForm,
  invoicingAttachmentsForm,
  supportingAttachmentsForm,
  receiptingAttachmentsForm,
  closingAttachmentsForm,
  exclusionsForm,
  invoiceItemsForm,
} from "pages/invoices/manage/schemas/form";

import {
  defaultpaymentTerms,
  defaultReminderRepeatIntervalDays,
  // defaultSubject,
  // defaultContent,
  defaultPaymentModes,
  defaultInvoicingType,
  defaultInvoiceDate,
  defaultInvoiceNumber,
  defaultDiscountType,
  defaultPaymentType,
} from "pages/invoices/manage/schemas/setups";

const {
  formField: {
    invoiceTerms,
    reminderIntervalDays,
    reminderFromName,
    reminderFromEmail,
    reminderToEmails,
    reminderCcEmails,
    reminderBccEmails,
    reminderEmailSubject,
    reminderEmailContent,
    invoicingType,
    salesorderReference,
    invoicingReference,
    quotationRefForInvoice,
    purchaseorderRefForInvoice,
    servicereportRefForInvoice,
    deliverySiteAddress,
    invoiceReceiptingDate,
    invoiceClosingDate,
    receiptingReference,
    closingReference,
    paymentModes,
    invoiceRemarks,
    serviceTeams,
    serviceTypes,
    paymentType,
    invoiceDate,
    invoiceNumber,
    customerId,
    customerName,
    customerAddress,
    invoiceItems,
    invoiceDiscountType,
    invoiceGrossTotal,
    invoiceDiscountTotal,
    invoiceBeforeGstTotal,
    invoiceNetBeforeGstTotal,
    invoiceGstAmount,
    invoiceAfterGstTotal,
    invoiceDeposit,
    invoiceAdditionalDiscount,
    invoiceBalanceDue,
    invoicingAttachments,
    supportingAttachments,
    receiptingAttachments,
    closingAttachments,
  },
} = form;

const initialValues = {
  [invoiceTerms.name]: defaultpaymentTerms,
  [reminderIntervalDays.name]: defaultReminderRepeatIntervalDays,
  [reminderFromName.name]: "",
  [reminderFromEmail.name]: "",
  [reminderToEmails.name]: [],
  [reminderCcEmails.name]: [],
  [reminderBccEmails.name]: [],
  [reminderEmailSubject.name]: "", //defaultSubject,
  [reminderEmailContent.name]: "", //defaultContent,
  [invoicingType.name]: defaultInvoicingType,
  [salesorderReference.name]: "",
  [invoicingReference.name]: "",
  [quotationRefForInvoice.name]: "",
  [servicereportRefForInvoice.name]: "",
  [purchaseorderRefForInvoice.name]: "",
  [deliverySiteAddress.name]: "",
  [invoiceReceiptingDate.name]: null,
  [invoiceClosingDate.name]: null,
  [receiptingReference.name]: "",
  [closingReference.name]: "",
  [paymentModes.name]: defaultPaymentModes,
  [invoiceRemarks.name]: "",
  [serviceTeams.name]: [],
  [serviceTypes.name]: [],
  [paymentType.name]: defaultPaymentType,
  [invoiceDate.name]: defaultInvoiceDate,
  [invoiceNumber.name]: defaultInvoiceNumber,
  [customerId.name]: "",
  [customerName.name]: "",
  [customerAddress.name]: "",
  [invoiceItems.name]: [],
  [invoiceDiscountType.name]: defaultDiscountType,
  [invoiceGrossTotal.name]: "0.00",
  [invoiceDiscountTotal.name]: "0.00",
  [invoiceBeforeGstTotal.name]: "0.00",
  [invoiceNetBeforeGstTotal.name]: "0.00",
  [invoiceGstAmount.name]: "0.00",
  [invoiceAfterGstTotal.name]: "0.00",
  [invoiceDeposit.name]: "0.00",
  [invoiceAdditionalDiscount.name]: "0.00",
  [invoiceBalanceDue.name]: "0.00",
  [invoicingAttachments.name]: [],
  [supportingAttachments.name]: [],
  [receiptingAttachments.name]: [],
  [closingAttachments.name]: [],
};

const {
  formField: { paymentModeName },
} = paymentModesForm;

const paymentModesInitialValues = {
  [paymentModeName.name]: "",
};

const {
  formField: { serviceTeamName },
} = serviceTeamsForm;

const serviceTeamsInitialValues = {
  [serviceTeamName.name]: "",
};

const {
  formField: { serviceTypeName },
} = serviceTypesForm;

const serviceTypesInitialValues = {
  [serviceTypeName.name]: "",
};

const {
  formField: { invoicingAttachmentFile },
} = invoicingAttachmentsForm;

const invoicingAttachmentsInitialValues = {
  [invoicingAttachmentFile.name]: "",
};

const {
  formField: { supportingAttachmentFile },
} = supportingAttachmentsForm;

const supportingAttachmentsInitialValues = {
  [supportingAttachmentFile.name]: "",
};

const {
  formField: { receiptingAttachmentFile },
} = receiptingAttachmentsForm;

const receiptingAttachmentsInitialValues = {
  [receiptingAttachmentFile.name]: "",
};

const {
  formField: { closingAttachmentFile },
} = closingAttachmentsForm;

const closingAttachmentsInitialValues = {
  [closingAttachmentFile.name]: "",
};

const {
  formField: { exclusionClause },
} = exclusionsForm;

const exclusionsInitialValues = {
  [exclusionClause.name]: "",
};

const {
  formField: {
    itemOrdinal,
    itemNo,
    itemSubNo,
    itemServiceType,
    itemDescription,
    itemQuantity,
    itemUnitCost,
    itemGrossAmount,
    itemDiscount,
    itemNetAmount,
  },
} = invoiceItemsForm;

const invoiceItemsInitialValues = {
  [itemOrdinal.name]: "",
  [itemNo.name]: "",
  [itemSubNo.name]: "",
  [itemServiceType.name]: "",
  [itemDescription.name]: "",
  [itemQuantity.name]: "1",
  [itemUnitCost.name]: "",
  [itemGrossAmount.name]: "0.00",
  [itemDiscount.name]: "0",
  [itemNetAmount.name]: "0.00",
};

export {
  initialValues as default,
  paymentModesInitialValues,
  serviceTeamsInitialValues,
  serviceTypesInitialValues,
  invoicingAttachmentsInitialValues,
  supportingAttachmentsInitialValues,
  receiptingAttachmentsInitialValues,
  closingAttachmentsInitialValues,
  exclusionsInitialValues,
  invoiceItemsInitialValues,
};
