const form = {
  formId: "company-form",
  formField: {
    displayName: {
      name: "displayName",
      label: "Display Name",
      type: "text",
      errorMsg: "Display Name is required.",
      invalidMsg: "Display Name is invalid.",
    },

    stampAttachments: {
      name: "stampAttachments",
      label: "Company Stamp Attachment",
      type: "box",
      min1Msg: "Must have at least 1 Attachment.",
      max20Msg: "Must have at most 20 Attachments.",
    },
  },
};

const stampAttachmentsForm = {
  formId: "stamp-attachments-form",
  formField: {
    stampAttachmentFile: {
      name: "stampAttachmentsFile",
      label: "Attachment File",
      type: "file",
      errorMsg: "Attachment File is required.",
      invalidMsg: "File size must be less than 5 MB.",
    },
    attachmentPath: {
      name: "attachmentPath",
      label: "Attachment Path",
      type: "text",
    },
    attachmentName: {
      name: "attachmentName",
      label: "Attachment Path",
      type: "text",
    },
    attachmentURL: {
      name: "attachmentURL",
      label: "Attachment Path",
      type: "text",
    },
  },
};

export { form as default, stampAttachmentsForm };
