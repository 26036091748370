// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

import MDBox from "components/atoms/MDBox";

import TableCell from "@mui/material/TableCell";
function DataTableBodyCell({ noBorder, align, sticky, children }) {
  return (
    <TableCell
      align={align}
      py={1.5}
      px={3}
      sx={({
        palette: { light },
        typography: { size },
        borders: { borderWidth },
      }) => {
        const stickyProps = sticky
          ? {
              position: "sticky",
              left: 0,
              background: "white",
              "z-index": 100,
            }
          : {};
        return {
          fontSize: size.sm,
          borderBottom: noBorder
            ? "none"
            : `${borderWidth[1]} solid ${light.main}`,
          ...stickyProps,
        };
      }}
    >
      <MDBox
        display="inline-block"
        width="max-content"
        color="text"
        sx={{ verticalAlign: "middle" }}
      >
        {children}
      </MDBox>
    </TableCell>
  );
}

// Setting default values for the props of DataTableBodyCell
DataTableBodyCell.defaultProps = {
  noBorder: false,
  align: "left",
};

// Typechecking props for the DataTableBodyCell
DataTableBodyCell.propTypes = {
  children: PropTypes.node.isRequired,
  noBorder: PropTypes.bool,
  align: PropTypes.oneOf(["left", "right", "center"]),
};

export default DataTableBodyCell;
