import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "App";

import { MuiContextProvider } from "context/MuiContext";
import { AuthContextProvider } from "context/AuthContext";
import { CacheContextProvider } from "context/CacheContext";

ReactDOM.render(
  <BrowserRouter>
    <CacheContextProvider>
      <AuthContextProvider>
        <MuiContextProvider>
          <App />
        </MuiContextProvider>
      </AuthContextProvider>
    </CacheContextProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
