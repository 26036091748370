import { useState, useEffect, useCallback } from "react";

import PropTypes from "prop-types";

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import MDBox from "components/atoms/MDBox";
import MDTypography from "components/atoms/MDTypography";

import FormTextFieldFast from "components/molecules/Formik/FormTextFieldFast";

import { useCollection } from "hooks/useCollection";

import { defaultDeliverySiteName } from "pages/purchaseorders/manage/schemas/setups";

const suppliersQueries = {
  whereQueries: [
    {
      field: "deletedAt",
      condition: "==",
      value: null,
    },
    {
      field: "approvedAt",
      condition: "!=",
      value: null,
    },
  ],
};

function SupplierInformation({
  mainForm,
  mainFormField,
  modeDisabled,
  dispatchMainError,
}) {
  const { values, setFieldValue } = mainForm;

  const {
    supplierId,
    supplierName,
    supplierAddress,
    contactPersonName,
    contactPersonLandlineNumber,
    contactPersonMobileNumber,
    contactPersonEmail,
    deliverySiteName,
    deliverySiteAddress,
  } = mainFormField;

  const [supplierOptions, setSupplierOptions] = useState([]);
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [contactPersonOptions, setContactPersonOptions] = useState([]);
  const [selectedContactPerson, setSelectedContactPerson] = useState(null);
  const [deliverySiteOptions, setDeliverySiteOptions] = useState([]);
  const [selectedDeliverySite, setSelectedDeliverySite] = useState(null);

  const { collectionData: suppliersData } = useCollection(
    "suppliers",
    suppliersQueries
  );

  const resetSupplier = useCallback(() => {
    setSelectedSupplier(null);
    setFieldValue(supplierId.name, "");
    setFieldValue(supplierName.name, "");
    setFieldValue(supplierAddress.name, "");
  }, [supplierAddress.name, supplierId.name, supplierName.name, setFieldValue]);

  const resetContactPerson = useCallback(() => {
    setSelectedContactPerson(null);
    setContactPersonOptions([]);
    setFieldValue(contactPersonName.name, "");
    setFieldValue(contactPersonLandlineNumber.name, "");
    setFieldValue(contactPersonMobileNumber.name, "");
    setFieldValue(contactPersonEmail.name, "");
  }, [
    contactPersonEmail.name,
    contactPersonLandlineNumber.name,
    contactPersonMobileNumber.name,
    contactPersonName.name,
    setFieldValue,
  ]);

  const resetDeliverySite = useCallback(() => {
    setSelectedDeliverySite(null);
    setDeliverySiteOptions([]);
    setFieldValue(deliverySiteName.name, "");
    setFieldValue(deliverySiteAddress.name, "");
  }, [deliverySiteAddress.name, deliverySiteName.name, setFieldValue]);

  useEffect(() => {
    try {
      const suppliers = suppliersData;

      setSupplierOptions(suppliers);
      if (!suppliers.includes(selectedSupplier)) {
        resetSupplier();
        resetContactPerson();
        resetDeliverySite();
      }
    } catch (err) {
      dispatchMainError(err);
    }
  }, [
    suppliersData,
    dispatchMainError,
    selectedSupplier,
    resetSupplier,
    resetContactPerson,
    resetDeliverySite,
  ]);

  useEffect(() => {
    try {
      if (!values.supplierName) {
        setSelectedSupplier(null);
        setSelectedContactPerson(null);
        setSelectedDeliverySite(null);
      }
    } catch (err) {
      dispatchMainError(err);
    }
  }, [dispatchMainError, values.supplierName]);

  return (
    <Card id="supplier-information" sx={{ overflow: "visible" }}>
      <MDBox m={3}>
        <MDBox mb={3}>
          <MDTypography variant="h5">Vendor's Details</MDTypography>
        </MDBox>
        <MDBox>
          <Grid
            container
            mb={2}
            rowSpacing={{ xs: 1, sm: 2 }}
            columnSpacing={{ xs: 1, sm: 3 }}
          >
            <Grid item xs={12} sm={4}>
              <Autocomplete
                size="small"
                value={selectedSupplier}
                getOptionLabel={(option) => option.data.supplierName}
                onChange={(e, supplier) => {
                  setSelectedSupplier(supplier);
                  resetContactPerson();
                  resetDeliverySite();
                  // update options
                  setContactPersonOptions(supplier.data.contactPersons);
                  setDeliverySiteOptions(supplier.data.deliverySites);
                  // update supplier info value
                  setFieldValue(supplierId.name, supplier.id);
                  setFieldValue(supplierName.name, supplier.data.supplierName);
                  setFieldValue(
                    supplierAddress.name,
                    `${supplier.data.supplierAddress}, Singapore ${supplier.data.supplierPostalCode}`
                  );
                }}
                options={supplierOptions}
                disabled={modeDisabled}
                renderInput={(params) => (
                  <TextField {...params} label="Step 1: Select Supplier" />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Autocomplete
                size="small"
                value={selectedContactPerson}
                getOptionLabel={(option) => option.contactPersonName}
                onChange={(e, contactPerson) => {
                  if (contactPerson) {
                    setSelectedContactPerson(contactPerson);
                    setFieldValue(
                      contactPersonName.name,
                      contactPerson.contactPersonName
                    );
                    setFieldValue(
                      contactPersonLandlineNumber.name,
                      contactPerson.contactPersonLandlineNumber
                    );
                    setFieldValue(
                      contactPersonMobileNumber.name,
                      contactPerson.contactPersonMobileNumber
                    );
                    setFieldValue(
                      contactPersonEmail.name,
                      contactPerson.contactPersonEmail
                    );
                  }
                }}
                options={contactPersonOptions}
                disabled={modeDisabled}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Step 2: Select Contact Person"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Autocomplete
                size="small"
                value={selectedDeliverySite}
                getOptionLabel={(option) => option.deliverySiteName}
                onChange={(e, deliverySite) => {
                  if (deliverySite) {
                    setSelectedDeliverySite(deliverySite);
                    setFieldValue(
                      deliverySiteName.name,
                      deliverySite.deliverySiteName
                    );

                    if (
                      deliverySite.deliverySiteName === defaultDeliverySiteName
                    ) {
                      setFieldValue(
                        deliverySiteAddress.name,
                        `${deliverySite.deliverySiteAddress}, Singapore ${deliverySite.deliverySitePostalCode}`
                      );
                    } else {
                      setFieldValue(deliverySiteAddress.name, ` `);
                    }
                  }
                }}
                options={deliverySiteOptions}
                disabled={modeDisabled}
                renderInput={(params) => (
                  <TextField {...params} label="Step 3: Select Delivery Site" />
                )}
              />
            </Grid>
          </Grid>

          <Grid container mb={1} columnSpacing={{ xs: 1, sm: 3 }}>
            <Grid item xs={12} sm={6}>
              <FormTextFieldFast
                form={mainForm}
                field={contactPersonName}
                variant="standard"
                disabled={modeDisabled}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}></Grid>
            <Grid item xs={12} sm={6}>
              <FormTextFieldFast
                form={mainForm}
                field={supplierName}
                variant="standard"
                disabled={modeDisabled}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}></Grid>
            <Grid item xs={12} sm={6}>
              <FormTextFieldFast
                form={mainForm}
                field={contactPersonLandlineNumber}
                variant="standard"
                disabled={modeDisabled}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}></Grid>
            <Grid item xs={12} sm={6}>
              <FormTextFieldFast
                form={mainForm}
                field={contactPersonMobileNumber}
                variant="standard"
                disabled={modeDisabled}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}></Grid>
            <Grid item xs={12} sm={6}>
              <FormTextFieldFast
                form={mainForm}
                field={contactPersonEmail}
                variant="standard"
                disabled={modeDisabled}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}></Grid>
            <Grid item xs={12} sm={12}>
              <FormTextFieldFast
                form={mainForm}
                field={supplierAddress}
                variant="standard"
                disabled={modeDisabled}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}></Grid>
          </Grid>
        </MDBox>

        <MDBox mb={3}>
          <MDTypography variant="h5">Shipping Address</MDTypography>
        </MDBox>
        <MDBox>
          <Grid container mb={1} columnSpacing={{ xs: 1, sm: 3 }}>
            <Grid item xs={12} sm={6}>
              <FormTextFieldFast
                form={mainForm}
                field={deliverySiteName}
                variant="standard"
                disabled={modeDisabled}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}></Grid>
            <Grid item xs={12} sm={12}>
              <FormTextFieldFast
                form={mainForm}
                field={deliverySiteAddress}
                variant="standard"
                disabled={modeDisabled}
              />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Card>
  );
}

SupplierInformation.propTypes = {
  mainForm: PropTypes.object.isRequired,
  mainFormField: PropTypes.object.isRequired,
  modeDisabled: PropTypes.bool.isRequired,
};

export default SupplierInformation;
