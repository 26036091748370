// Don't handle exception in this React Hook to allow exception bubbling
// Handle exception at upwards level:
//   send error log back to server
//   show generic error message on snackbar to inform user to report to admin

import { useCallback } from "react";

export const useSalesOrderActionManager = () => {
  const getActionState = useCallback((data) => {
    const isSituation = data.verbalAgreement || data.pendingPO;
    const hasFollowedUp =
      !!data.purchaseorderReference || data.commencementAttachments.length > 0;

    const actions = [
      {
        name: "created",
        time: data.createdAt ? data.createdAt.toMillis() : null,
      },
      {
        name: "modified",
        time: data.modifiedAt ? data.modifiedAt.toMillis() : null,
      },
      {
        name: "approved",
        time: data.approvedAt ? data.approvedAt.toMillis() : null,
      },
      {
        name: "completed",
        time: data.completedAt ? data.completedAt.toMillis() : null,
      },
      {
        name: "cancelled",
        time: data.cancelledAt ? data.cancelledAt.toMillis() : null,
      },
      {
        name: "converted",
        time: data.convertedAt ? data.convertedAt.toMillis() : null,
      },
      {
        name: "deleted",
        time: data.deletedAt ? data.deletedAt.toMillis() : null,
      },
    ];

    const times = actions
      .map((action) => action.time)
      .filter((time) => time !== null);

    const latestAction = actions.find(
      (action) => action.time === Math.max(...times)
    );

    const canApprove = ["created", "modified"].includes(latestAction.name);
    const canReject = ["created", "modified"].includes(latestAction.name);

    const canDeposit = ["approved"].includes(latestAction.name);
    const canDiscount = ["approved"].includes(latestAction.name);
    const canAttach =
      isSituation && !hasFollowedUp && ["approved"].includes(latestAction.name);
    const canCancel = ["approved"].includes(latestAction.name);
    const canComplete =
      (!isSituation || (isSituation && hasFollowedUp)) &&
      ["approved"].includes(latestAction.name);
    const canConvert = ["completed"].includes(latestAction.name);
    const canPrint = !["created", "modified"].includes(latestAction.name);
    const canEdit = ["created", "modified"].includes(latestAction.name);
    const canDelete = true;

    return {
      latestAction,
      isSituation,
      hasFollowedUp,
      canApprove,
      canReject,
      canDeposit,
      canDiscount,
      canAttach,
      canComplete,
      canConvert,
      canCancel,
      canPrint,
      canEdit,
      canDelete,
    };
  }, []);

  return {
    getActionState,
  };
};
