import PropTypes from "prop-types";

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import FormAutocompleteFast from "components/molecules/Formik/FormAutocompleteFast";

import MDBox from "components/atoms/MDBox";
import MDTypography from "components/atoms/MDTypography";
import FormTextFieldFast from "components/molecules/Formik/FormTextFieldFast";

import FormDatePicker from "components/molecules/Formik/FormDatePicker";

import { poPaymentModeOptions } from "pages/purchaseorders/pdf/schemas/setups";

function PaymentDetails({ mainForm, mainFormField, modeDisabled }) {
  const { poPaymentDate, poPaymentMode, poPaymentRemarks } = mainFormField;

  return (
    <Card id="email" sx={{ overflow: "visible" }}>
      <MDBox m={3}>
        <MDBox mb={3}>
          <MDTypography variant="h5">Payment Details</MDTypography>
        </MDBox>
        <MDBox>
          <Grid
            container
            rowSpacing={{ xs: 1, sm: 2 }}
            columnSpacing={{ xs: 1, sm: 3 }}
          >
            <Grid item xs={12} sm={6}>
              <FormDatePicker
                form={mainForm}
                field={poPaymentDate}
                inputFormat={"dd/MM/yyyy"}
                variant="standard"
                disabled={modeDisabled}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormAutocompleteFast
                freeSolo
                form={mainForm}
                field={poPaymentMode}
                options={poPaymentModeOptions}
                disabled={modeDisabled}
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <MDTypography variant="body3" fontWeight="bold">
                {poPaymentRemarks.label}
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={10}>
              <FormTextFieldFast
                form={mainForm}
                field={poPaymentRemarks}
                variant="standard"
                disabled={modeDisabled}
              />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Card>
  );
}

PaymentDetails.propTypes = {
  mainForm: PropTypes.object.isRequired,
  mainFormField: PropTypes.object.isRequired,
  modeDisabled: PropTypes.bool.isRequired,
};

export default PaymentDetails;
