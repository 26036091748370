import { useCallback } from "react";

import { pdf, Document, Page, View } from "@react-pdf/renderer";
import DocumentHeader from "pages/purchaseorders/pdf/components/DocumentHeader";
import BillingInformation from "pages/purchaseorders/pdf/components/BillingInformation";
import PriceQuote from "pages/purchaseorders/pdf/components/PriceQuote";
//import Signatory from "pages/purchaseorders/pdf/components/Signatory";
import DocumentFooter from "pages/purchaseorders/pdf/components/DocumentFooter";
import styles from "pages/purchaseorders/pdf/styles";
import { useDocument } from "hooks/useDocument";
import { useFile } from "hooks/useFile";

export const usePurchaseOrderPdfManager = () => {
  const { getFileRef, uploadFile } = useFile();
  const { retrieveDoc } = useDocument();

  const pdfDoc = (
    data,
    ownerDisplayName,
    ownerSignature,
    ownerDesignation,
    companyStamp
  ) => (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={{ marginBottom: 15 }} fixed>
          <DocumentHeader />
        </View>
        <View style={styles.body}>
          <BillingInformation values={data} />
          <PriceQuote values={data} />
        </View>
        <View style={{ marginTop: 0 }} fixed>
          <DocumentFooter companyStamp={companyStamp} />
        </View>
      </Page>
    </Document>
  );

  const generatePurchaseOrderPdf = useCallback(
    async (data) => {
      try {
        const purchaseorderNumberRevision = `${data.purchaseorderNumber}R${data.purchaseorderRevision}`;
        const filePath = `PurchaseOrder-GA-PO-${purchaseorderNumberRevision}.pdf`;

        let purchaseorderFileRef = null;

        try {
          purchaseorderFileRef = await getFileRef(filePath);
        } catch (err) {
          console.log(err);
        }

        if (!purchaseorderFileRef) {
          //Purchase Order owner name
          const createdByUser =
            data.createdBy && (await retrieveDoc("users", data.createdBy));
          const modifiedByUser =
            data.modifiedBy && (await retrieveDoc("users", data.modifiedBy));
          let ownerDisplayName = "";
          let ownerSignature = "";
          let ownerDesignation = "";
          if (modifiedByUser) {
            ownerDisplayName = modifiedByUser?.data.displayName;
            ownerSignature =
              modifiedByUser?.data.signatureAttachments[0]?.attachmentURL;
            ownerDesignation = modifiedByUser?.data.designation;
          } else {
            ownerDisplayName = createdByUser?.data.displayName;
            ownerSignature =
              createdByUser?.data.signatureAttachments[0]?.attachmentURL;
            ownerDesignation = createdByUser?.data.designation;
          }

          // Company stamp
          const company =
            data.createdBy && (await retrieveDoc("companies", "gate"));
          const companyStamp = company?.data.stampAttachments[0]?.attachmentURL;

          const blob = await pdf(
            pdfDoc(
              data,
              ownerDisplayName,
              ownerSignature,
              ownerDesignation,
              companyStamp
            )
          ).toBlob();
          const uploadedFileRef = await uploadFile(filePath, blob);

          purchaseorderFileRef = uploadedFileRef;
        }

        return purchaseorderFileRef;
      } catch (err) {
        throw err;
      }
    },
    [getFileRef, retrieveDoc, uploadFile]
  );

  return {
    generatePurchaseOrderPdf,
  };
};
