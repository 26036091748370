import { Text, View, Image } from "@react-pdf/renderer";
import styles from "pages/purchaseorders/pdf/styles";

function Footer({ companyStamp }) {
  return (
    <>
      <View style={[styles.containerRow, styles.spaceBetween]} fixed>
        <View
          style={[styles.containerCol, { justifyContent: "flex-end" }]}
          fixed
        >
          <Text>
            This Purchase Order (PO) has been electronically approved. No
            signature is required.
          </Text>
        </View>
        <View
          style={[
            styles.containerCol,
            { flexBasis: "10%", justifyContent: "flex-end" },
          ]}
          fixed
        >
          <View style={[{ paddingBottom: 0 }]}>
            {!!companyStamp && (
              <Image
                style={[
                  styles.stamp,
                  {
                    marginTop: 0,
                    marginLeft: 0,
                    transform: "rotate(10deg)",
                  },
                ]}
                src={companyStamp}
              />
            )}
          </View>
        </View>
      </View>
      <View style={[styles.containerRow, styles.spaceBetween]} fixed>
        <Text
          style={styles.footer}
          render={({ pageNumber, totalPages }) =>
            `PAGE: ${pageNumber} OF ${totalPages}`
          }
          fixed
        />
        <View style={[styles.containerCol, { alignItems: "end" }]} fixed>
          <Text src="www.gifted-school.com" style={{ color: "#92D14E" }} fixed>
            www.gifted-school.com
          </Text>
        </View>
      </View>
    </>
  );
}

export default Footer;
