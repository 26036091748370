import { useEffect, useCallback, useState, useReducer } from "react";

import { useNavigate } from "react-router-dom";

import { useAuthContext } from "context/AuthContext";
import { useDocument } from "hooks/useDocument";
import { useCollection } from "hooks/useCollection";

import MDButton from "components/atoms/MDButton";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import { AllowedTo, useAbac } from "react-abac";
import { Permission } from "models/abac";

const suppliersQueries = {
  whereQueries: [
    {
      field: "deletedAt",
      condition: "==",
      value: null,
    },
  ],
};

const usersQueries = {
  whereQueries: [
    {
      field: "deletedAt",
      condition: "==",
      value: null,
    },
  ],
};

const hiddenColumns = [
  "id",
  "createdAtText",
  "modifiedAtText",
  "approvedAtText",
  "rejectedAtText",
];

let initialState = {
  data: null,
  isPending: false,
  error: null,
  success: null,
};

const schemaReducer = (state, action) => {
  switch (action.type) {
    case "DISMISS":
      return initialState;
    case "IS_PENDING":
      return {
        isPending: true,
        data: null,
        success: null,
        error: null,
      };
    case "REFRESHED_SUPPLIERS":
      return {
        isPending: false,
        data: action.payload,
        success: "Successfully refreshed the records.",
        error: null,
      };
    case "APPROVED_SUPPLIER":
      return {
        isPending: false,
        data: action.payload,
        success: `Successfully approved the supplier, ${action.payload.supplierName}.`,
        error: null,
      };
    case "REJECTED_SUPPLIER":
      return {
        isPending: false,
        data: action.payload,
        success: `Successfully rejected the supplier, ${action.payload.supplierName}.`,
        error: null,
      };
    case "ERROR":
      return {
        isPending: false,
        data: null,
        success: null,
        error: action.error,
      };
    default:
      return state;
  }
};

export const useRecordsManager = () => {
  const [response, dispatch] = useReducer(schemaReducer, initialState);
  const [isUnmounted, setIsUnmounted] = useState(false);

  const { user } = useAuthContext();
  const { userHasPermissions } = useAbac();

  const { updateDoc, deleteDoc, serverTimestamp } = useDocument();
  const { collectionData: suppliersData } = useCollection(
    "suppliers",
    suppliersQueries
  );
  const { collectionData: usersData } = useCollection("users", usersQueries);

  const dispatchIfNotUnmounted = useCallback(
    (action) => {
      if (!isUnmounted) {
        dispatch(action);
      }
    },
    [isUnmounted]
  );

  const dispatchDismiss = useCallback(
    () => dispatchIfNotUnmounted({ type: "DISMISS" }),
    [dispatchIfNotUnmounted]
  );

  const dispatchError = useCallback(
    (err) => {
      console.error(err);
      if (
        ![
          "PermissionDeniedError",
          "OperationInvalidError",
          "SupplierInvalidError",
        ].includes(err.name)
      ) {
        err.message = "The operation couldn't be completed";
        err.name = "OperationIncompleteError";
        // TODO: send error stack to server
      }
      dispatchIfNotUnmounted({
        type: "ERROR",
        error: err,
      });
    },
    [dispatchIfNotUnmounted]
  );

  const navigate = useNavigate();

  const toPresentationValue = (data) => {
    try {
      const toDateString = (value) => {
        return value ? value.toDate().toLocaleDateString("en-Sg") : "-";
      };

      const toDisplayName = (userId) => {
        const user = usersData.find((document) => document.id === userId);
        return userId ? (user ? user.data.displayName : "Unknown") : "-";
      };

      data.approvedAtText = toDateString(data.approvedAt);
      data.rejectedAtText = toDateString(data.rejectedAt);
      data.createdAtText = toDateString(data.createdAt);
      data.modifiedAtText = toDateString(data.modifiedAt);
      data.deletedAtText = toDateString(data.deletedAt);

      data.approvedByText = toDisplayName(data.approvedBy);
      data.rejectedByText = toDisplayName(data.rejectedBy);
      data.createdByText = toDisplayName(data.createdBy);
      data.modifiedByText = toDisplayName(data.modifiedBy);
      data.deletedByText = toDisplayName(data.deletedBy);

      return data;
    } catch (err) {
      dispatchError(err);
    }
  };

  useEffect(() => {
    return () => {
      setIsUnmounted(true);
    };
  }, []);

  // TODO: move to Service Layer custom hook
  const handleApprove = async (collectionPath, documentId) => {
    try {
      dispatchIfNotUnmounted({ type: "IS_PENDING" });
      if (userHasPermissions(Permission.APPROVE_SUPPLIER)) {
        const updatedDoc = await updateDoc(collectionPath, documentId, {
          approvedAt: serverTimestamp(),
          approvedBy: user.uid,
        });
        dispatchIfNotUnmounted({
          type: "APPROVED_SUPPLIER",
          payload: updatedDoc.data,
        });
      } else {
        let error = new Error(
          "Permission Denied. You are not allowed to approve supplier."
        );
        error.name = "PermissionDeniedError";
        throw error;
      }
    } catch (err) {
      dispatchError(err);
    }
  };

  // TODO: move to Service Layer custom hook
  const handleReject = async (collectionPath, documentId) => {
    try {
      dispatchIfNotUnmounted({ type: "IS_PENDING" });
      if (userHasPermissions(Permission.REJECT_SUPPLIER)) {
        const deletedDoc = await deleteDoc(collectionPath, documentId, {
          approvedAt: null,
          approvedBy: null,
          rejectedAt: serverTimestamp(),
          rejectedBy: user.uid,
        });
        dispatchIfNotUnmounted({
          type: "REJECTED_SUPPLIER",
          payload: deletedDoc.data,
        });
      } else {
        let error = new Error(
          "Permission Denied. You are not allowed to reject supplier."
        );
        error.name = "PermissionDeniedError";
        throw error;
      }
    } catch (err) {
      dispatchError(err);
    }
  };

  const rows =
    suppliersData &&
    suppliersData.map((supplier) => {
      return toPresentationValue({ id: supplier.id, ...supplier.data });
    });

  const columns = [
    {
      Header: "Id",
      Footer: "Id",
      accessor: "id",
    },
    {
      Header: "Name",
      Footer: "Name",
      accessor: "supplierName",
    },
    {
      Header: "Type",
      Footer: "Type",
      accessor: "supplierType",
    },
    {
      Header: "Landline No.",
      Footer: "Landline No.",
      accessor: "supplierLandlineNumber",
    },
    {
      Header: "Mobile No.",
      Footer: "Mobile No.",
      accessor: "supplierMobileNumber",
    },
    {
      Header: "Status",
      Footer: "Status",
      accessor: "supplierStatus",
    },
    {
      Header: "Created At",
      Footer: "Created At",
      accessor: "createdAtText",
    },
    {
      Header: "Created By",
      Footer: "Created By",
      accessor: "createdByText",
    },
    {
      Header: "Modified At",
      Footer: "Modified At",
      accessor: "modifiedAtText",
    },
    {
      Header: "Modified By",
      Footer: "Modified By",
      accessor: "modifiedByText",
    },
    {
      Header: "Approved At",
      Footer: "Approved At",
      accessor: "approvedAtText",
    },
    {
      Header: "Approved By",
      Footer: "Approved By",
      accessor: "approvedByText",
      Cell: ({ cell }) => {
        const approvedByText = cell.row.values.approvedByText;
        const isApproved = approvedByText !== "-";
        const rejectedByText = cell.row.values.rejectedByText;
        const isRejected = rejectedByText !== "-";
        return !isApproved && !isRejected ? (
          <>
            <AllowedTo perform={Permission.APPROVE_SUPPLIER}>
              <MDButton
                variant="gradient"
                color="success"
                iconOnly
                onClick={() => handleApprove("suppliers", cell.row.values.id)}
              >
                <ThumbUpIcon />
              </MDButton>
            </AllowedTo>
          </>
        ) : (
          <>{approvedByText}</>
        );
      },
    },
    {
      Header: "Rejected At",
      Footer: "Rejected At",
      accessor: "rejectedAtText",
    },
    {
      Header: "Rejected By",
      Footer: "Rejected By",
      accessor: "rejectedByText",
      Cell: ({ cell }) => {
        const rejectedByText = cell.row.values.rejectedByText;
        const isRejected = rejectedByText !== "-";
        return !isRejected ? (
          <>
            <AllowedTo perform={Permission.REJECT_SUPPLIER}>
              <MDButton
                variant="gradient"
                color="error"
                iconOnly
                onClick={() => handleReject("suppliers", cell.row.values.id)}
              >
                <ThumbDownIcon />
              </MDButton>
            </AllowedTo>
          </>
        ) : (
          <>{rejectedByText}</>
        );
      },
    },
    {
      Header: "Actions",
      Footer: "Actions",
      accessor: "actions",
      disableFilters: true,
      disableGlobalFilter: true,
      disableSortBy: true,
      Cell: ({ cell }) => {
        const rejectedByText = cell.row.values.rejectedByText;
        const isRejected = rejectedByText !== "-";
        return (
          <>
            <AllowedTo perform={Permission.READ_SUPPLIER}>
              <MDButton
                variant="gradient"
                color="info"
                iconOnly
                onClick={() =>
                  navigate(`/suppliers/manage/view/${cell.row.values.id}`)
                }
              >
                <VisibilityIcon />
              </MDButton>
              &nbsp;&nbsp;
            </AllowedTo>
            {!isRejected && (
              <AllowedTo perform={Permission.UPDATE_SUPPLIER}>
                <MDButton
                  variant="gradient"
                  color="info"
                  iconOnly
                  onClick={() =>
                    navigate(`/suppliers/manage/edit/${cell.row.values.id}`)
                  }
                >
                  <EditIcon />
                </MDButton>
                &nbsp;&nbsp;
              </AllowedTo>
            )}
            <AllowedTo perform={Permission.DELETE_SUPPLIER}>
              <MDButton
                variant="gradient"
                color="warning"
                iconOnly
                onClick={() =>
                  navigate(`/suppliers/manage/delete/${cell.row.values.id}`)
                }
              >
                <DeleteIcon />
              </MDButton>
            </AllowedTo>
          </>
        );
      },
    },
  ];

  return {
    columns,
    rows,
    hiddenColumns,
    response,
    dispatchDismiss,
    dispatchError,
  };
};
