import { gstPercentage } from "pages/purchaseorders/manage/schemas/setups";

const form = {
  formId: "purchaseorder-form",
  formField: {
    purchaseorderDate: {
      name: "purchaseorderDate",
      label: "Date Issued",
      type: "text",
      errorMsg: "Date Issued is required.",
      invalidMsg: "Date Issued is invalid.",
    },
    purchaseorderNumber: {
      name: "purchaseorderNumber",
      label: "Purchase Order Number",
      type: "text",
      errorMsg: "Purchase Order Number is required.",
      invalidMsg: "Purchase Order Number is invalid.",
    },
    purchaseorderRevision: {
      name: "purchaseorderRevision",
      label: "Purchase Order Revision",
      type: "text",
      errorMsg: "Purchase Order Revision is required.",
      invalidMsg: "Purchase Order Revision is invalid.",
    },
    purchaseorderCurrency: {
      name: "purchaseorderCurrency",
      label: "Currency",
      type: "text",
      errorMsg: "Currency is required.",
      invalidMsg: "Currency is invalid.",
    },
    purchaseorderTerm: {
      name: "purchaseorderTerm",
      label: "Term",
      type: "text",
      errorMsg: "Term is required.",
      invalidMsg: "Term is invalid.",
    },
    supplierReference: {
      name: "supplierReference",
      label: "Supplier Reference",
      type: "text",
      errorMsg: "Supplier Reference is required.",
      invalidMsg: "Supplier Reference is invalid.",
    },
    SOReference: {
      name: "SOReference",
      label: "SO Reference",
      type: "text",
      errorMsg: "SO Reference is required.",
      invalidMsg: "SO Reference is invalid.",
    },
    deliveryDateDesc: {
      name: "deliveryDateDesc",
      label: "Delivery Date Description",
      type: "text",
      errorMsg: "Delivery Date Description is required.",
      invalidMsg: "Delivery Date Description is invalid.",
    },
    deliveryDate: {
      name: "deliveryDate",
      label: "Delivery Date",
      type: "text",
      errorMsg: "Delivery Date is required.",
      invalidMsg: "Delivery Date is invalid.",
    },
    typeofPurchase: {
      name: "typeofPurchase",
      label: "Type of Purchase",
      type: "text",
      errorMsg: "Type of Purchase is required.",
      invalidMsg: "Type of Purchase is invalid.",
    },
    typeofBusinessPurchase: {
      name: "typeofBusinessPurchase",
      label: "Type of Business Purchase",
      type: "text",
      errorMsg: "Type of Business Purchase is required.",
      invalidMsg: "Type of Business Purchase is invalid.",
    },
    typeofAdminPurchase: {
      name: "typeofAdminPurchase",
      label: "Type of Admin Purchase",
      type: "text",
      errorMsg: "Type of Admin Purchase is required.",
      invalidMsg: "Type of Admin Purchase is invalid.",
    },
    purchaseDescription: {
      name: "purchaseDescription",
      label: "Description",
      type: "text",
      errorMsg: "Purchase Description is required.",
      invalidMsg: "Purchase Description is invalid.",
    },
    supplierId: {
      name: "supplierId",
      label: "Supplier Id",
      type: "text",
      errorMsg: "Supplier Id is required.",
      invalidMsg: "Supplier Id is invalid.",
    },
    supplierName: {
      name: "supplierName",
      label: "Supplier Name",
      type: "text",
      errorMsg: "Organization / Individual Name is required.",
      invalidMsg: "Organization / Individual Name is invalid.",
    },
    supplierAddress: {
      name: "supplierAddress",
      label: "Address",
      type: "text",
      errorMsg: "Address is required.",
      invalidMsg: "Address is invalid.",
    },
    contactPersonName: {
      name: "contactPersonName",
      label: "Attention Person Name",
      type: "text",
      errorMsg: "Attention Person Name is required.",
      invalidMsg: "Attention Person Name is invalid.",
    },
    contactPersonLandlineNumber: {
      name: "contactPersonLandlineNumber",
      label: "Office / Home Contact Number",
      type: "text",
      errorMsg: "At least one contact number is required.",
      invalidMsg: "Office / Home Contact Number is invalid.",
    },
    contactPersonMobileNumber: {
      name: "contactPersonMobileNumber",
      label: "Mobile Contact Number",
      type: "text",
      errorMsg: "At least one contact number is required.",
      invalidMsg: "Mobile Contact Number is invalid.",
    },
    contactPersonEmail: {
      name: "contactPersonEmail",
      label: "Email",
      type: "text",
      errorMsg: "Email is required.",
      invalidMsg: "Email is invalid.",
    },
    deliverySiteName: {
      name: "deliverySiteName",
      label: "Delivery Site Name",
      type: "text",
      errorMsg: "Delivery Site Name is required.",
      invalidMsg: "Delivery Site Name is invalid.",
    },
    deliverySiteAddress: {
      name: "deliverySiteAddress",
      label: "Delivery Site Address",
      type: "text",
      errorMsg: "Delivery Site Address is required.",
      invalidMsg: "Delivery Site Address is invalid.",
    },
    billingDetails: {
      name: "billingDetails",
      label: "Billing Details",
      type: "text",
      errorMsg: "Billing Details is required.",
      invalidMsg: "Billing Details is invalid.",
    },
    purchaseorderItems: {
      name: "purchaseorderItems",
      label: "Purchase Order Items",
      type: "box",
      min1Msg: "Must have at least 1 Item.",
      max100Msg: "Must have at most 100 Items.",
    },
    purchaseorderDiscountType: {
      name: "purchaseorderDiscountType",
      label: "Discount Type",
      type: "text",
      errorMsg: "Discount Type is required.",
      invalidMsg: "Discount Type is invalid.",
    },
    purchaseorderGrossTotal: {
      name: "purchaseorderGrossTotal",
      label: "Gross Total",
      type: "text",
      errorMsg: "Gross Total is required.",
      invalidMsg: "Gross Total is invalid.",
    },
    purchaseorderDiscountTotal: {
      name: "purchaseorderDiscountTotal",
      label: "Discount",
      type: "text",
      errorMsg: "Discount Total is required.",
      invalidMsg: "Discount Total is invalid.",
    },
    purchaseorderBeforeGstTotal: {
      name: "purchaseorderBeforeGstTotal",
      label: "Total Amount Before GST",
      type: "text",
      errorMsg: "Total Amount Before GST is required.",
      invalidMsg: "Total Amount  Before GST is invalid.",
    },
    purchaseorderGstAmount: {
      name: "purchaseorderGstAmount",
      label: `${gstPercentage}% GST`,
      type: "text",
      errorMsg: "GST Amount is required.",
      invalidMsg: "GST Amount is invalid.",
    },
    purchaseorderFinalTotal: {
      name: "purchaseorderFinalTotal",
      label: "Final Amount",
      type: "text",
      errorMsg: "Final Amount is required.",
      invalidMsg: "Final Amount is invalid.",
    },
    purchaseorderAfterGstTotal: {
      name: "purchaseorderAfterGstTotal",
      label: "Final Amount inclusive of GST",
      type: "text",
      errorMsg: "Final Amount is required.",
      invalidMsg: "Final Amount is invalid.",
    },
    purchaseorderAttachments: {
      name: "purchaseorderAttachments",
      label: "Attachments",
      type: "box",
      min1Msg: "Must have at least 1 Attachment.",
      max20Msg: "Must have at most 20 Attachments.",
    },
    gstOption: {
      name: "gstOption",
      label: "GST Option",
      type: "text",
      errorMsg: "GST option is required.",
      invalidMsg: "GST option is invalid.",
    },
    audittrailAttachments: {
      name: "audittrailAttachments",
      label: "Supplier Quotation / Invoice",
      type: "box",
      min1Msg: "Must have at least 1 Attachment.",
      max20Msg: "Must have at most 20 Attachments.",
    },
  },
};

const purchaseorderAttachmentsForm = {
  formId: "purchaseorder-attachments-form",
  formField: {
    purchaseorderAttachmentFile: {
      name: "purchaseorderAttachmentFile",
      label: "Attachment File",
      type: "box",
      errorMsg: "Attachment File is required.",
      invalidMsg: "File size must be less than 5 MB.",
    },
  },
};

const purchaseorderItemsForm = {
  formId: "purchaseorder-items-form",
  formField: {
    itemOrdinal: {
      name: "itemOrdinal",
      label: "Ordinal",
      type: "text",
      errorMsg: "Ordinal is required.",
      invalidMsg: "Ordinal is invalid.",
    },
    itemNo: {
      name: "itemNo",
      label: "No.",
      type: "text",
      errorMsg: "Required.",
      invalidMsg: "Invalid.",
    },
    itemSubNo: {
      name: "itemSubNo",
      label: "Sub No.",
      type: "text",
      errorMsg: "Required.",
      invalidMsg: "Invalid.",
    },
    itemServiceType: {
      name: "itemServiceType",
      label: "Service Type",
      type: "text",
      errorMsg: "Required.",
      invalidMsg: "Invalid.",
    },
    itemDescription: {
      name: "itemDescription",
      label: "Description",
      type: "text",
      errorMsg: "Required.",
      invalidMsg: "Invalid.",
    },
    itemQuantity: {
      name: "itemQuantity",
      label: "Quantity",
      type: "text",
      errorMsg: "Required.",
      invalidMsg: "Invalid.",
    },
    itemUnitCost: {
      name: "itemUnitCost",
      label: "Unit Cost",
      type: "text",
      errorMsg: "Required.",
      invalidMsg: "Invalid.",
    },
    itemGrossAmount: {
      name: "itemGrossAmount",
      label: "Gross Amount",
      type: "text",
      errorMsg: "Required.",
      invalidMsg: "Invalid.",
    },
    itemDiscount: {
      name: "itemDiscount",
      label: "Discount",
      type: "text",
      errorMsg: "Required.",
      invalidMsg: "Invalid.",
    },
    itemNetAmount: {
      name: "itemNetAmount",
      label: "Net Amount",
      type: "text",
      errorMsg: "Required.",
      invalidMsg: "Invalid.",
    },
  },
};

const audittrailAttachmentsForm = {
  formId: "audittrail-attachments-form",
  formField: {
    audittrailAttachmentFile: {
      name: "audittrailAttachmentFile",
      label: "Attachment File",
      type: "file",
      errorMsg: "Attachment File is required.",
      invalidMsg: "File size must be less than 5 MB.",
    },
    attachmentPath: {
      name: "attachmentPath",
      label: "Attachment Path",
      type: "text",
    },
    attachmentName: {
      name: "attachmentName",
      label: "Attachment Path",
      type: "text",
    },
    attachmentURL: {
      name: "attachmentURL",
      label: "Attachment Path",
      type: "text",
    },
  },
};

export {
  form as default,
  purchaseorderAttachmentsForm,
  purchaseorderItemsForm,
  audittrailAttachmentsForm,
};
