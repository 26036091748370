import { Text, View } from "@react-pdf/renderer";
import styles from "pages/salesorders/pdf/styles";
import currency from "currency.js";

const itemizedRow = (item) => {
  const isItemHeader = item.itemQuantity === "0";
  const fontWeight = isItemHeader
    ? { fontWeight: "bold" }
    : { fontWeight: "normal" };
  const lineHeight = isItemHeader ? { lineHeight: 0 } : { lineHeight: 1.5 };
  return (
    <View key={item.itemOrdinal} wrap={false}>
      <View style={styles.containerRow}>
        <View
          style={[
            styles.tablebody,
            styles.leftmost,
            { flexBasis: "5%", textAlign: "left" },
            { ...fontWeight },
          ]}
        >
          <Text style={{ ...lineHeight }}>{item.itemNo}</Text>
        </View>
        <View
          style={[
            styles.tablebody,
            { flexBasis: "5%", textAlign: "left" },
            { ...fontWeight },
          ]}
        >
          <Text style={{ ...lineHeight }}>{item.itemSubNo}</Text>
        </View>
        <View
          style={[
            styles.tablebody,
            { flexBasis: "37%", textAlign: "left" },
            { ...fontWeight },
          ]}
        >
          <Text style={{ lineHeight: 1.5, fontWeight: "bold" }}>
            {item.itemServiceType}
          </Text>
          <Text style={{ ...lineHeight }}>{item.itemDescription}</Text>
        </View>
        <View
          style={[styles.tablebody, { flexBasis: "5%", textAlign: "center" }]}
        >
          {isItemHeader ? <Text> </Text> : <Text>{item.itemQuantity}</Text>}
        </View>
        <View style={[styles.tablebody, { flexBasis: "12%" }]}>
          {isItemHeader ? <Text> </Text> : price(item.itemUnitCost)}
        </View>
        <View style={[styles.tablebody, { flexBasis: "12%" }]}>
          {isItemHeader ? <Text> </Text> : price(item.itemGrossAmount)}
        </View>
        <View
          style={[styles.tablebody, { flexBasis: "12%", textAlign: "right" }]}
        >
          {isItemHeader ? (
            <Text> </Text>
          ) : (
            <Text>{`${item.itemDiscount} %`}</Text>
          )}
        </View>
        <View style={[styles.tablebody, { flexBasis: "12%" }]}>
          {isItemHeader ? <Text> </Text> : price(item.itemNetAmount)}
        </View>
      </View>

      {/* adding empty row to create spaces between items */}
      <View style={styles.containerRow}>
        <View
          style={[
            styles.tablebody,
            styles.leftmost,
            { flexBasis: "5%", textAlign: "left" },
            { ...fontWeight },
          ]}
        >
          <Text style={{ ...lineHeight }}></Text>
        </View>
        <View
          style={[
            styles.tablebody,
            { flexBasis: "5%", textAlign: "left" },
            { ...fontWeight },
          ]}
        >
          <Text style={{ ...lineHeight }}></Text>
        </View>
        <View
          style={[
            styles.tablebody,
            { flexBasis: "37%", textAlign: "left" },
            { ...fontWeight },
          ]}
        >
          <Text style={{ ...lineHeight }}></Text>
        </View>
        <View
          style={[styles.tablebody, { flexBasis: "5%", textAlign: "center" }]}
        >
          <Text> </Text>
        </View>
        <View style={[styles.tablebody, { flexBasis: "12%" }]}>
          <Text> </Text>
        </View>
        <View style={[styles.tablebody, { flexBasis: "12%" }]}>
          <Text> </Text>
        </View>
        <View
          style={[styles.tablebody, { flexBasis: "12%", textAlign: "right" }]}
        >
          <Text> </Text>
        </View>
        <View style={[styles.tablebody, { flexBasis: "12%" }]}>
          <Text> </Text>
        </View>
      </View>
    </View>
  );
};

const nonItemizedRow = (item) => {
  const isItemHeader = item.itemQuantity === "0";
  const fontWeight = isItemHeader
    ? { fontWeight: "bold" }
    : { fontWeight: "normal" };
  const lineHeight = isItemHeader ? { lineHeight: 0 } : { lineHeight: 1.5 };
  return (
    <View key={item.itemOrdinal} wrap={false}>
      <View style={styles.containerRow}>
        <View
          style={[
            styles.tablebody,
            styles.leftmost,
            { flexBasis: "5%", textAlign: "left" },
            { ...fontWeight },
          ]}
        >
          <Text style={{ ...lineHeight }}>{item.itemNo}</Text>
        </View>
        <View
          style={[
            styles.tablebody,
            { flexBasis: "5%", textAlign: "left" },
            { ...fontWeight },
          ]}
        >
          <Text style={{ ...lineHeight }}>{item.itemSubNo}</Text>
        </View>
        <View
          style={[
            styles.tablebody,
            { flexBasis: "55%", textAlign: "left" },
            { ...fontWeight },
          ]}
        >
          <Text style={{ ...lineHeight }}>{item.itemDescription}</Text>
        </View>
        <View
          style={[styles.tablebody, { flexBasis: "5%", textAlign: "center" }]}
        >
          {isItemHeader ? <Text> </Text> : <Text>{item.itemQuantity}</Text>}
        </View>
        <View style={[styles.tablebody, { flexBasis: "15%" }]}>
          {isItemHeader ? <Text> </Text> : price(item.itemUnitCost)}
        </View>
        <View style={[styles.tablebody, { flexBasis: "15%" }]}>
          {isItemHeader ? <Text> </Text> : price(item.itemGrossAmount)}
        </View>
      </View>

      {/* adding empty row to create spaces between items */}
      <View style={styles.containerRow}>
        <View
          style={[
            styles.tablebody,
            styles.leftmost,
            { flexBasis: "5%", textAlign: "left" },
            { ...fontWeight },
          ]}
        >
          <Text style={{ ...lineHeight }}></Text>
        </View>
        <View
          style={[
            styles.tablebody,
            { flexBasis: "5%", textAlign: "left" },
            { ...fontWeight },
          ]}
        >
          <Text style={{ ...lineHeight }}></Text>
        </View>
        <View
          style={[
            styles.tablebody,
            { flexBasis: "55%", textAlign: "left" },
            { ...fontWeight },
          ]}
        >
          <Text style={{ ...lineHeight }}></Text>
        </View>
        <View
          style={[styles.tablebody, { flexBasis: "5%", textAlign: "center" }]}
        >
          <Text></Text>
        </View>
        <View style={[styles.tablebody, { flexBasis: "15%" }]}>
          <Text> </Text>
        </View>
        <View style={[styles.tablebody, { flexBasis: "15%" }]}>
          <Text> </Text>
        </View>
      </View>
    </View>
  );
};

const price = (amount) => (
  <View style={styles.price}>
    <Text>$</Text>
    <Text>
      {currency(amount, {
        symbol: "",
      }).format()}
    </Text>
  </View>
);

function PriceQuoteTableItem({ values }) {
  return values.salesDiscountType === "Itemized"
    ? values.salesItems.map((item) => itemizedRow(item))
    : values.salesItems.map((item) => nonItemizedRow(item));
}

export default PriceQuoteTableItem;
