//import { de } from "date-fns/locale";
/*import form, {
  serviceTeamsForm,
  serviceTypesForm,
  quotationAttachmentsForm,
  exclusionsForm,
  quoteItemsForm,
} from "pages/purchaseorders/manage/schemas/form";*/

import form, {
  purchaseorderAttachmentsForm,
  purchaseorderItemsForm,
  audittrailAttachmentsForm,
} from "pages/purchaseorders/manage/schemas/form";

import {
  defaultPurchaseOrderDate,
  defaultPurchaseOrderNumber,
  defaultPurchaseOrderRevision,
  defaultCurrency,
  defaultPurchaseOrderTerm,
  defaultTypeofPurchase,
  defaultTypeofBusinessPurchase,
  defaultTypeofAdminPurchase,
  defaultDeliveryDate,
  defaultBillingDetails,
  defaultDiscountType,
  defaultDeliveryDateDesc,
  defaultGstOption,
} from "pages/purchaseorders/manage/schemas/setups";

const {
  formField: {
    purchaseorderDate,
    purchaseorderNumber,
    purchaseorderRevision,
    purchaseorderCurrency,
    purchaseorderTerm,
    supplierReference,
    SOReference,
    deliveryDateDesc,
    deliveryDate,
    typeofPurchase,
    typeofBusinessPurchase,
    typeofAdminPurchase,
    purchaseDescription,
    billingDetails,
    purchaseorderItems,
    purchaseorderDiscountType,
    purchaseorderGrossTotal,
    purchaseorderDiscountTotal,
    purchaseorderBeforeGstTotal,
    purchaseorderGstAmount,
    purchaseorderAfterGstTotal,
    purchaseorderFinalTotal,
    purchaseorderAttachments,
    gstOption,
    audittrailAttachments,
  },
} = form;

const initialValues = {
  [purchaseorderDate.name]: defaultPurchaseOrderDate,
  [purchaseorderNumber.name]: defaultPurchaseOrderNumber,
  [purchaseorderRevision.name]: defaultPurchaseOrderRevision,
  [purchaseorderCurrency.name]: defaultCurrency,
  [purchaseorderTerm.name]: defaultPurchaseOrderTerm,
  [supplierReference.name]: "",
  [SOReference.name]: "",
  [deliveryDateDesc.name]: defaultDeliveryDateDesc,
  [deliveryDate.name]: defaultDeliveryDate,
  [typeofPurchase.name]: defaultTypeofPurchase,
  [typeofBusinessPurchase.name]: defaultTypeofBusinessPurchase,
  [typeofAdminPurchase.name]: defaultTypeofAdminPurchase,
  [purchaseDescription.name]: "",
  [billingDetails.name]: defaultBillingDetails,
  [purchaseorderItems.name]: [],
  [purchaseorderDiscountType.name]: defaultDiscountType,
  [purchaseorderGrossTotal.name]: "0.00",
  [purchaseorderDiscountTotal.name]: "0.00",
  [purchaseorderBeforeGstTotal.name]: "0.00",
  [purchaseorderGstAmount.name]: "0.00",
  [purchaseorderAfterGstTotal.name]: "0.00",
  [purchaseorderFinalTotal.name]: "0.00",
  [purchaseorderAttachments.name]: [],
  [gstOption.name]: defaultGstOption,
  [audittrailAttachments.name]: [],
};

const {
  formField: { purchaseorderAttachmentFile },
} = purchaseorderAttachmentsForm;

const purchaseorderAttachmentsInitialValues = {
  [purchaseorderAttachmentFile.name]: "",
};

const {
  formField: {
    itemOrdinal,
    itemNo,
    itemSubNo,
    itemServiceType,
    itemDescription,
    itemQuantity,
    itemUnitCost,
    itemGrossAmount,
    itemDiscount,
    itemNetAmount,
  },
} = purchaseorderItemsForm;

const purchaseorderItemsInitialValues = {
  [itemOrdinal.name]: "",
  [itemNo.name]: "",
  [itemSubNo.name]: "",
  [itemServiceType.name]: "",
  [itemDescription.name]: "",
  [itemQuantity.name]: "1",
  [itemUnitCost.name]: "",
  [itemGrossAmount.name]: "0.00",
  [itemDiscount.name]: "0",
  [itemNetAmount.name]: "0.00",
};

const {
  formField: { audittrailAttachmentFile },
} = audittrailAttachmentsForm;

const audittrailAttachmentsInitialValues = {
  [audittrailAttachmentFile.name]: "",
};

export {
  initialValues as default,
  purchaseorderItemsInitialValues,
  purchaseorderAttachmentsInitialValues,
  audittrailAttachmentsInitialValues,
};
