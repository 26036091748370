import { Link, useLocation, useNavigate } from "react-router-dom";

import NavCoverLayout from "components/templates/generic/layouts/NavCoverLayout";

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

import MDBox from "components/atoms/MDBox";
import MDTypography from "components/atoms/MDTypography";
import MDButton from "components/atoms/MDButton";

import ErrorAlert from "components/molecules/Notifications/ErrorAlert";
import SuccessAlert from "components/molecules/Notifications/SuccessAlert";

import FormField from "components/molecules/FormField";

import logoWideImage from "assets/images/logo-wide.jpeg";
import bgImage from "assets/images/bg-main.png";

import { Formik, Form } from "formik";
import * as Yup from "yup";

import RedirectAuth from "components/molecules/RedirectAuth";

import { useSignin } from "pages/authentication/hooks/useSignin";

import { defaultSignedInPath } from "models/navigate";

import mainRoutes from "models/routes";

function SignIn() {
  const { signin, error, success } = useSignin();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || defaultSignedInPath;

  return (
    <RedirectAuth>
      {error && <ErrorAlert message={error} />}
      {success && <SuccessAlert message={success} />}
      <NavCoverLayout
        image={bgImage}
        logo={
          <MDBox
            bgColor="white"
            px="0.4rem"
            py="0.4rem"
            borderRadius="xl"
            component="img"
            src={logoWideImage}
            alt="Brand"
            width="8rem"
          />
        }
        action={{
          type: "internal",
          route: "/authentication/signin",
          label: "Sign in",
        }}
        routes={mainRoutes}
      >
        <MDBox
          mt={{ xs: -20, lg: -18 }}
          px={1}
          width="calc(100% - 2rem)"
          mx="auto"
        >
          <Grid container spacing={1} justifyContent="center">
            <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
              <Card>
                <MDBox
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                  mx={2}
                  mt={-2}
                  p={2}
                  mb={1}
                  textAlign="center"
                >
                  <MDTypography
                    variant="h4"
                    fontWeight="medium"
                    color="white"
                    mt={1}
                  >
                    Sign in
                  </MDTypography>
                  <MDTypography
                    display="block"
                    variant="body3"
                    color="white"
                    my={1}
                  >
                    Enter your email and password to sign in.
                  </MDTypography>
                </MDBox>
                <MDBox pt={4} pb={3} px={3}>
                  <Formik
                    initialValues={{
                      email: "",
                      password: "",
                      remember: false,
                    }}
                    validationSchema={Yup.object({
                      email: Yup.string()
                        .required("Required")
                        .email("Invalid email address"),
                      password: Yup.string().required("Required"),
                    })}
                    onSubmit={async (values, { setSubmitting }) => {
                      await signin(
                        values.email,
                        values.password,
                        values.remember
                      );
                      error && setSubmitting(false);
                      success && navigate(from, { replace: true });
                    }}
                  >
                    {({ values, errors, touched, isSubmitting }) => (
                      <Form>
                        <FormField
                          type="email"
                          label="Email"
                          name="email"
                          error={errors.email && touched.email}
                          success={values.email.length > 0 && !errors.email}
                        />
                        <FormField
                          type="password"
                          label="Password"
                          name="password"
                          error={errors.password && touched.password}
                          success={
                            values.password.length > 0 && !errors.password
                          }
                        />
                        <MDBox mt={-1} ml={-1}>
                          <FormField type="checkbox" label="" name="remember">
                            <MDTypography
                              variant="body3"
                              fontWeight="regular"
                              color="text"
                              sx={{
                                cursor: "pointer",
                                userSelect: "none",
                                ml: -1,
                              }}
                            >
                              &nbsp;&nbsp;Remember me
                            </MDTypography>
                          </FormField>
                        </MDBox>
                        <MDBox mt={2} mb={1}>
                          <MDButton
                            disabled={isSubmitting}
                            type="submit"
                            variant="gradient"
                            color="success"
                            fullWidth
                          >
                            sign in
                          </MDButton>
                        </MDBox>
                        <MDBox mt={-1} textAlign="right">
                          <MDTypography
                            component={Link}
                            to="/authentication/resetpassword"
                            variant="caption"
                            color="info"
                            fontWeight="medium"
                            textGradient
                          >
                            Forgot password?
                          </MDTypography>
                        </MDBox>
                      </Form>
                    )}
                  </Formik>
                  <MDBox mt={2} mb={1} textAlign="center">
                    <MDBox>
                      <MDTypography variant="caption" color="text" textGradient>
                        Don&apos;t have an account?{" "}
                      </MDTypography>
                    </MDBox>
                    <MDBox mt={-1}>
                      <MDTypography
                        variant="caption"
                        color="info"
                        fontWeight="medium"
                        textGradient
                      >
                        Please Consult with Your Administrator
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      </NavCoverLayout>
    </RedirectAuth>
  );
}

export default SignIn;
