import PropTypes from "prop-types";

import Grid from "@mui/material/Grid";
import AddIcon from "@mui/icons-material/Add";

import MDBox from "components/atoms/MDBox";
import MDButton from "components/atoms/MDButton";
import MDTypography from "components/atoms/MDTypography";

import FormTextFieldFast from "components/molecules/Formik/FormTextFieldFast";
import FormLeftFieldArray from "components/molecules/Formik/FormLeftFieldArray";

import { contactPersonForm } from "pages/suppliers/manage/schemas/form";
import { contactPersonsInitialValues } from "pages/suppliers/manage/schemas/initialValues";
import { contactPersonsValidation } from "pages/suppliers/manage/schemas/validations";

import ControlPointDuplicateIcon from "@mui/icons-material/ControlPointDuplicate";

function SupplierContactPersons({
  mainForm,
  mainFormField,
  modeDisabled,
  dispatchMainError,
}) {
  const {values} = mainForm;
  const { contactPersons } = mainFormField;
  
  // destructure from Contact Person Form for current step
  const { formId: innerFormId, formField: innerFormField } = contactPersonForm;
  const {
    contactPersonName,
    contactPersonEmail,
    contactPersonLandlineNumber,
    contactPersonMobileNumber,
  } = innerFormField;

  const handleCopy = (setSectionFormFieldValue) => {
    try {
      setSectionFormFieldValue(contactPersonName.name, values.supplierName);

      setSectionFormFieldValue(contactPersonEmail.name, values.supplierEmail);
      setSectionFormFieldValue(
        contactPersonLandlineNumber.name,
        values.supplierLandlineNumber
      );
      setSectionFormFieldValue(
        contactPersonMobileNumber.name,
        values.supplierMobileNumber
      );
    } catch (err) {
      dispatchMainError(err);
    }
  };

  return (
    <MDBox>
      <MDBox lineHeight={0}>
        <MDTypography variant="h5">Supplier Sales Contacts</MDTypography>
        <MDTypography variant="body3" color="text">
          Please add and fill in Supplier Contact Persons details
        </MDTypography>
      </MDBox>
      <MDBox mt={2}>
        <FormLeftFieldArray
          form={mainForm}
          field={contactPersons}
          max={100}
          disabled={modeDisabled}
          dispatchError={dispatchMainError}
          itemInitialValues={contactPersonsInitialValues}
          itemValidation={contactPersonsValidation}
          itemKeyField={contactPersonName} // TODO: change to uuid
          itemDisplayFields={[contactPersonName]}
          itemForm={(formik, disabledField, disabledAdd) => {
            return (
              /* not using <Form> because cannot have form within another form (Customer.js),
                      in order not to trigger validation of sub formik when submitting main formik */
              <MDBox id={innerFormId} autoComplete="off">
                <Grid container columnSpacing={{ xs: 1, sm: 3 }}>
                  <Grid item xs={12} sm={12}>
                    <FormTextFieldFast
                      form={formik}
                      field={contactPersonName}
                      variant="standard"
                      disabled={disabledField}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <FormTextFieldFast
                      form={formik}
                      field={contactPersonEmail}
                      variant="standard"
                      disabled={disabledField}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <FormTextFieldFast
                      form={formik}
                      field={contactPersonLandlineNumber}
                      variant="standard"
                      disabled={disabledField}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <FormTextFieldFast
                      form={formik}
                      field={contactPersonMobileNumber}
                      variant="standard"
                      disabled={disabledField}
                    />
                  </Grid>
                  {!disabledAdd && (
                    <>
                    <Grid item xs={12} sm={2}>
                      <MDButton
                        onClick={() => handleCopy(formik.setFieldValue)}
                        color="warning"
                        size="small"
                        fullWidth
                      >
                        <ControlPointDuplicateIcon />
                        &nbsp; Copy
                      </MDButton>
                    </Grid>
                    <Grid item xs={12} sm={10}>
                      <MDButton
                        onClick={formik.handleSubmit}
                        color="success"
                        size="small"
                        fullWidth
                      >
                        <AddIcon /> &nbsp; Add Contact Person
                      </MDButton>
                    </Grid>
                    </>
                  )}
                </Grid>
              </MDBox>
            );
          }}
        />
      </MDBox>
    </MDBox>
  );
}

SupplierContactPersons.propTypes = {
  mainForm: PropTypes.object.isRequired,
  mainFormField: PropTypes.object.isRequired,
  modeDisabled: PropTypes.bool,
  dispatchMainError: PropTypes.func,
};

export default SupplierContactPersons;
