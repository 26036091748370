import { useState, useEffect, useCallback } from "react";

import PropTypes from "prop-types";

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import MDBox from "components/atoms/MDBox";
import MDTypography from "components/atoms/MDTypography";

import FormTextFieldFast from "components/molecules/Formik/FormTextFieldFast";

import { useCollection } from "hooks/useCollection";

const customersQueries = {
  whereQueries: [
    {
      field: "deletedAt",
      condition: "==",
      value: null,
    },
    {
      field: "approvedAt",
      condition: "!=",
      value: null,
    },
  ],
};

function CustomerInformation({
  mainForm,
  mainFormField,
  modeDisabled,
  dispatchMainError,
}) {
  const { values, setFieldValue } = mainForm;
  const {
    contactPersonName,
    customerId,
    customerName,
    contactPersonLandlineNumber,
    contactPersonMobileNumber,
    contactPersonEmail,
    customerAddress,
    deliverySiteName,
    deliverySiteAddress,
  } = mainFormField;

  const [customerOptions, setCustomerOptions] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [contactPersonOptions, setContactPersonOptions] = useState([]);
  const [selectedContactPerson, setSelectedContactPerson] = useState(null);
  const [deliverySiteOptions, setDeliverySiteOptions] = useState([]);
  const [selectedDeliverySite, setSelectedDeliverySite] = useState(null);

  const { collectionData: customersData } = useCollection(
    "customers",
    customersQueries
  );

  const resetCustomer = useCallback(() => {
    setSelectedCustomer(null);
    setFieldValue(customerId.name, "");
    setFieldValue(customerName.name, "");
    setFieldValue(customerAddress.name, "");
  }, [customerAddress.name, customerId.name, customerName.name, setFieldValue]);

  const resetContactPerson = useCallback(() => {
    setSelectedContactPerson(null);
    setContactPersonOptions([]);
    setFieldValue(contactPersonName.name, "");
    setFieldValue(contactPersonLandlineNumber.name, "");
    setFieldValue(contactPersonMobileNumber.name, "");
    setFieldValue(contactPersonEmail.name, "");
  }, [
    contactPersonEmail.name,
    contactPersonLandlineNumber.name,
    contactPersonMobileNumber.name,
    contactPersonName.name,
    setFieldValue,
  ]);

  const resetDeliverySite = useCallback(() => {
    setSelectedDeliverySite(null);
    setDeliverySiteOptions([]);
    setFieldValue(deliverySiteName.name, "");
    setFieldValue(deliverySiteAddress.name, "");
  }, [deliverySiteAddress.name, deliverySiteName.name, setFieldValue]);

  useEffect(() => {
    try {
      const customers = customersData;
      if (customers.length > 0) {
        setCustomerOptions(customers);
        const customerNames = customers.map((c) => c.data.customerName);
        if (!customerNames.includes(values.customerName)) {
          resetCustomer();
          resetContactPerson();
          resetDeliverySite();
        }
      }
    } catch (err) {
      dispatchMainError(err);
    }
  }, [
    customersData,
    dispatchMainError,
    resetContactPerson,
    resetCustomer,
    resetDeliverySite,
    selectedCustomer,
    values.customerName,
  ]);

  return (
    <Card id="customer-information" sx={{ overflow: "visible" }}>
      <MDBox m={3}>
        <MDBox mb={3}>
          <MDTypography variant="h5">Customer Information</MDTypography>
        </MDBox>
        <MDBox>
          <Grid container mb={2} columnSpacing={{ xs: 1, sm: 3 }}>
            <Grid item xs={12} sm={6}>
              <Grid container mb={6} rowSpacing={{ xs: 1, sm: 3 }}>
                <Grid item xs={12} sm={12}>
                  <Autocomplete
                    size="small"
                    value={selectedCustomer}
                    getOptionLabel={(option) => option.data.customerName}
                    onChange={(e, customer) => {
                      setSelectedCustomer(customer);
                      resetContactPerson();
                      resetDeliverySite();
                      // update options
                      setContactPersonOptions(customer.data.contactPersons);
                      setDeliverySiteOptions(customer.data.deliverySites);
                      // update customer info value
                      setFieldValue(customerId.name, customer.id);
                      setFieldValue(
                        customerName.name,
                        customer.data.customerName
                      );
                      setFieldValue(
                        customerAddress.name,
                        `${customer.data.customerAddress}, Singapore ${customer.data.customerPostalCode}`
                      );
                    }}
                    options={customerOptions}
                    disabled={modeDisabled}
                    renderInput={(params) => (
                      <TextField {...params} label="Step 1: Select Customer" />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Autocomplete
                    size="small"
                    value={selectedContactPerson}
                    getOptionLabel={(option) => option.contactPersonName}
                    onChange={(e, contactPerson) => {
                      setSelectedContactPerson(contactPerson);
                      if (contactPerson) {
                        setFieldValue(
                          contactPersonName.name,
                          contactPerson.contactPersonName
                        );
                        setFieldValue(
                          contactPersonLandlineNumber.name,
                          contactPerson.contactPersonLandlineNumber
                        );
                        setFieldValue(
                          contactPersonMobileNumber.name,
                          contactPerson.contactPersonMobileNumber
                        );
                        setFieldValue(
                          contactPersonEmail.name,
                          contactPerson.contactPersonEmail
                        );
                      }
                    }}
                    options={contactPersonOptions}
                    disabled={modeDisabled}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Step 2: Select Contact Person"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Autocomplete
                    size="small"
                    value={selectedDeliverySite}
                    getOptionLabel={(option) => option.deliverySiteName}
                    onChange={(e, deliverySite) => {
                      if (deliverySite) {
                        setSelectedDeliverySite(deliverySite);
                        setFieldValue(
                          deliverySiteName.name,
                          deliverySite.deliverySiteName
                        );
                        setFieldValue(
                          deliverySiteAddress.name,
                          `${deliverySite.deliverySiteAddress}, Singapore ${deliverySite.deliverySitePostalCode}`
                        );
                      }
                    }}
                    options={deliverySiteOptions}
                    disabled={modeDisabled}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Step 3: Select Delivery Site"
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid container mb={2} rowSpacing={{ xs: 1, sm: 1 }}>
                <Grid item xs={12} sm={12}>
                  <FormTextFieldFast
                    form={mainForm}
                    field={contactPersonName}
                    variant="standard"
                    disabled={modeDisabled}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={12}>
                  <FormTextFieldFast
                    form={mainForm}
                    field={customerName}
                    variant="standard"
                    disabled={modeDisabled}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={12}>
                  <FormTextFieldFast
                    form={mainForm}
                    field={contactPersonLandlineNumber}
                    variant="standard"
                    disabled={modeDisabled}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={12}>
                  <FormTextFieldFast
                    form={mainForm}
                    field={contactPersonMobileNumber}
                    variant="standard"
                    disabled={modeDisabled}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={12}>
                  <FormTextFieldFast
                    form={mainForm}
                    field={contactPersonEmail}
                    variant="standard"
                    disabled={modeDisabled}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={12}>
                  <FormTextFieldFast
                    form={mainForm}
                    field={customerAddress}
                    variant="standard"
                    disabled={modeDisabled}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={12}>
                  <FormTextFieldFast
                    form={mainForm}
                    field={deliverySiteName}
                    variant="standard"
                    disabled={modeDisabled}
                  />
                </Grid>

                <Grid item xs={12} sm={12}>
                  <FormTextFieldFast
                    form={mainForm}
                    field={deliverySiteAddress}
                    variant="standard"
                    disabled={modeDisabled}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}></Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Card>
  );
}

CustomerInformation.propTypes = {
  mainForm: PropTypes.object.isRequired,
  mainFormField: PropTypes.object.isRequired,
  modeDisabled: PropTypes.bool.isRequired,
};

export default CustomerInformation;
