import { useParams } from "react-router-dom";
import Invoice from "pages/invoices/manage/Invoice";

// using Proxy Page to remount when route to same route location but different param
function InvoiceProxy() {
  const { mode, invoiceId } = useParams();
  return <Invoice key={`${mode}/${invoiceId}`} />;
}

export default InvoiceProxy;
