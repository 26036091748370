import PropTypes from "prop-types";

import { useEffect, useState } from "react";

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";

import MDBox from "components/atoms/MDBox";
import MDTypography from "components/atoms/MDTypography";
import FormField from "components/molecules/FormField";
import FormAttachments from "components/molecules/Formik/FormAttachments";
import FormTextFieldFast from "components/molecules/Formik/FormTextFieldFast";
import FormAutocompleteFast from "components/molecules/Formik/FormAutocompleteFast";
import FormRadioGroup from "components/molecules/Formik/FormRadioGroup";

import { TextField } from "@mui/material";

import FormDatePicker from "components/molecules/Formik/FormDatePicker";

import { audittrailAttachmentsForm } from "pages/purchaseorders/manage/schemas/form";
import { audittrailAttachmentsInitialValues } from "pages/purchaseorders/manage/schemas/initialValues";
import { audittrailAttachmentsValidation } from "pages/purchaseorders/manage/schemas/validations";

import {
  currencyOptions,
  purchaseorderTermOptions,
  typeofPurchaseOptions,
  typeofBusinessPurchaseOptions,
  typeofAdminPurchaseOptions,
  deliveryDateDescOptions,
  defaultDeliveryDateDesc,
  defaultTypeofBusinessPurchase,
} from "pages/purchaseorders/manage/schemas/setups";

function AuditTrail({
  mainForm,
  mainFormField,
  modeDisabled,
  mode,
  respondedPurchaseOrderNumber,
  dispatchMainError,
}) {
  const { setFieldValue: setMainFormFieldValue } = mainForm;
  const [isDraft, setIsDraft] = useState(false);

  useEffect(() => {
    try {
      setIsDraft(respondedPurchaseOrderNumber === "---------");
    } catch (err) {
      dispatchMainError(err);
    }
  }, [dispatchMainError, respondedPurchaseOrderNumber]);

  const [showAdminPurchaseForm, setShowAdminPurchaseForm] = useState(true);

  const { values, errors, touched } = mainForm;

  const {
    purchaseorderNumber,
    purchaseorderRevision,
    purchaseorderDate,
    purchaseorderCurrency,
    purchaseorderTerm,
    supplierReference,
    SOReference,
    deliveryDateDesc,
    deliveryDate,
    typeofPurchase,
    typeofBusinessPurchase,
    typeofAdminPurchase,
    purchaseDescription,
    audittrailAttachments,
  } = mainFormField;

  // destructure from Attachments Form for current section
  const {
    formId: audittrailAttachmentsFormId,
    formField: audittrailAttachmentsFormField,
  } = audittrailAttachmentsForm;
  const { audittrailAttachmentFile, attachmentName, attachmentURL } =
    audittrailAttachmentsFormField;

  return (
    <Card id="audit-trail" sx={{ overflow: "visible" }}>
      <MDBox m={3}>
        <MDBox mb={3}>
          <MDTypography variant="h5">Audit Trail</MDTypography>
        </MDBox>
        <MDBox>
          <Grid container mb={1} columnSpacing={{ xs: 1, sm: 3 }}>
            <Grid item xs={12} sm={4}>
              <FormDatePicker
                form={mainForm}
                field={purchaseorderDate}
                inputFormat={"dd/MM/yyyy"}
                variant="standard"
                disabled={modeDisabled}
              />
            </Grid>
            <Grid item xs={12} sm={4}></Grid>
            <Grid item xs={12} sm={4}>
              <MDBox display="flex">
                <FormField
                  type={purchaseorderNumber.type}
                  label={purchaseorderNumber.label}
                  name={purchaseorderNumber.name}
                  variant="standard"
                  placeholder={purchaseorderNumber.placeholder}
                  disabled={modeDisabled}
                  error={
                    errors.purchaseorderNumber && touched.purchaseorderNumber
                  }
                  success={
                    !!values.purchaseorderNumber &&
                    values.purchaseorderNumber.length > 0 &&
                    !errors.purchaseorderNumber
                  }
                  sx={
                    mode === "new" || mode === "draft"
                      ? { display: "none" }
                      : {}
                  }
                  InputProps={{
                    readOnly: true,
                  }}
                />
                <TextField
                  label={purchaseorderNumber.label}
                  defaultValue="Auto Generate"
                  variant="standard"
                  sx={
                    mode !== "new" && mode !== "draft"
                      ? { display: "none" }
                      : {}
                  }
                  InputProps={{
                    readOnly: true,
                  }}
                />
                <FormField
                  type={purchaseorderRevision.type}
                  label={purchaseorderRevision.label}
                  name={purchaseorderRevision.name}
                  variant="standard"
                  placeholder={purchaseorderRevision.placeholder}
                  disabled={modeDisabled}
                  error={
                    errors.purchaseorderRevision &&
                    touched.purchaseorderRevision
                  }
                  success={
                    !!values.purchaseorderRevision &&
                    values.purchaseorderRevision.length > 0 &&
                    !errors.purchaseorderRevision
                  }
                  sx={mode === "edit" && isDraft ? { display: "none" } : {}}
                  InputProps={{
                    readOnly: true,
                    startAdornment: (
                      <InputAdornment position="start">R</InputAdornment>
                    ),
                  }}
                />
                <TextField
                  label={purchaseorderRevision.label}
                  defaultValue="Auto Increment"
                  variant="standard"
                  sx={mode !== "edit" || !isDraft ? { display: "none" } : {}}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </MDBox>
            </Grid>
          </Grid>

          <Grid container mb={1} columnSpacing={{ xs: 1, sm: 3 }}>
            <Grid item xs={12} sm={3}>
              <FormAutocompleteFast
                freeSolo
                form={mainForm}
                field={purchaseorderCurrency}
                options={currencyOptions}
                disabled={modeDisabled}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormAutocompleteFast
                freeSolo
                form={mainForm}
                field={purchaseorderTerm}
                options={purchaseorderTermOptions}
                disabled={modeDisabled}
              />
            </Grid>
          </Grid>

          <Grid container mb={1} columnSpacing={{ xs: 1, sm: 3 }}>
            <Grid item xs={12} sm={3}>
              <FormTextFieldFast
                form={mainForm}
                field={supplierReference}
                variant="standard"
                disabled={modeDisabled}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormTextFieldFast
                form={mainForm}
                field={SOReference}
                variant="standard"
                disabled={modeDisabled}
              />
            </Grid>
            <Grid item xs={12} sm={1}></Grid>
            <Grid item xs={12} sm={2}>
              <FormRadioGroup
                column
                showTitle={false}
                form={mainForm}
                field={deliveryDateDesc}
                options={deliveryDateDescOptions}
                defaultValue={defaultDeliveryDateDesc}
                onChange={(event, value) => {
                  if (value === "TBC") {
                    setMainFormFieldValue(deliveryDate.name, null);
                    setMainFormFieldValue(deliveryDateDesc.name, value);
                  } else {
                    setMainFormFieldValue(deliveryDateDesc.name, value);
                  }
                }}
                disabled={modeDisabled}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormDatePicker
                form={mainForm}
                field={deliveryDate}
                inputFormat={"dd/MM/yyyy"}
                variant="standard"
                disabled={modeDisabled}
              />
            </Grid>
          </Grid>

          <Grid container mb={1} columnSpacing={{ xs: 1, sm: 3 }}>
            <Grid item xs={12} sm={2}>
              <FormRadioGroup
                column
                showTitle={false}
                form={mainForm}
                field={typeofPurchase}
                options={typeofPurchaseOptions}
                defaultValue={defaultTypeofBusinessPurchase}
                onChange={(event, value) => {
                  if (value === "Admin") {
                    setShowAdminPurchaseForm(true);
                    setMainFormFieldValue(typeofPurchase.name, value);
                  } else {
                    setShowAdminPurchaseForm(false);
                    setMainFormFieldValue(typeofPurchase.name, value);
                  }
                }}
                disabled={modeDisabled}
              />
            </Grid>
            {showAdminPurchaseForm && (
              <Grid item xs={12} sm={4}>
                <FormAutocompleteFast
                  freeSolo
                  form={mainForm}
                  field={typeofAdminPurchase}
                  options={typeofAdminPurchaseOptions}
                  disabled={modeDisabled}
                />
              </Grid>
            )}
            {!showAdminPurchaseForm && (
              <Grid item xs={12} sm={4}>
                <FormAutocompleteFast
                  freeSolo
                  form={mainForm}
                  field={typeofBusinessPurchase}
                  options={typeofBusinessPurchaseOptions}
                  disabled={modeDisabled}
                />
              </Grid>
            )}
          </Grid>

          <Grid container mb={1} columnSpacing={{ xs: 1, sm: 3 }}>
            <Grid item xs={12} sm={12}>
              <FormTextFieldFast
                form={mainForm}
                field={purchaseDescription}
                variant="standard"
                disabled={modeDisabled}
              />
            </Grid>
          </Grid>

          <Grid container mb={1} columnSpacing={{ xs: 1, sm: 3 }}>
            <Grid item xs={12} sm={2}>
              <MDTypography variant="body3" fontWeight="bold">
                {audittrailAttachments.label}
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormAttachments
                showTitle={false}
                form={mainForm}
                field={audittrailAttachments}
                max={20}
                disabled={modeDisabled}
                dispatchError={dispatchMainError}
                itemFormId={audittrailAttachmentsFormId}
                itemInitialValues={audittrailAttachmentsInitialValues}
                itemValidation={audittrailAttachmentsValidation}
                itemFileField={audittrailAttachmentFile}
                itemKeyField={attachmentURL}
                itemDisplayFields={[attachmentName]}
              />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Card>
  );
}

AuditTrail.propTypes = {
  mainForm: PropTypes.object.isRequired,
  mainFormField: PropTypes.object.isRequired,
  modeDisabled: PropTypes.bool.isRequired,
  mode: PropTypes.string.isRequired,
};

export default AuditTrail;
