import { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";

import PropTypes from "prop-types";

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import FormAutocompleteFast from "components/molecules/Formik/FormAutocompleteFast";

import MDBox from "components/atoms/MDBox";
import MDTypography from "components/atoms/MDTypography";
import FormField from "components/molecules/FormField";

import { useAuthContext } from "context/AuthContext";
import { useDocument } from "hooks/useDocument";
import { useCollection } from "hooks/useCollection";

import {
  emailSubjectOptions,
  emailContentOptions,
  emailContentOptionsForPaymentMade,
  emailContentOptionsForCancellation,
} from "pages/purchaseorders/pdf/schemas/setups";

const usersQueries = {
  whereQueries: [
    {
      field: "deletedAt",
      condition: "==",
      value: null,
    },
  ],
};

function Email({
  mainForm,
  mainFormField,
  modeDisabled,
  response,
  dispatchMainError,
}) {
  const {
    values: mainFormValues,
    errors,
    touched,
    setFieldValue: setMainFormFieldValue,
  } = mainForm;

  const {
    poPaymentFromName,
    poPaymentFromEmail,
    poPaymentToEmails,
    poPaymentCcEmails,
    poPaymentBccEmails,
    poPaymentEmailSubject,
    poPaymentEmailContent,
  } = mainFormField;

  const [recipientOptions, setRecipientOptions] = useState([]);
  const [contentOptions, setContentOptions] = useState([]);
  const { user } = useAuthContext();
  const { retrieveDoc } = useDocument();
  const { mode } = useParams();

  const { collectionData: usersData } = useCollection("users", usersQueries);

  const initializeFieldValue = useCallback(async () => {
    try {
      const senderUser = user.uid && (await retrieveDoc("users", user.uid));
      const senderName = senderUser ? senderUser.data.displayName : "";
      const senderEmail = senderUser ? senderUser.data.email : "";
      setMainFormFieldValue(poPaymentFromName.name, senderName);
      setMainFormFieldValue(poPaymentFromEmail.name, senderEmail);
      setMainFormFieldValue(poPaymentBccEmails.name, [senderEmail]);

      const senderEmailSignOff = senderUser ? senderUser.data.emailSignOff : "";
      mode === "send"
        ? setContentOptions(
            emailContentOptions.map(
              (element) => `${element}\n\n${senderEmailSignOff}`
            )
          )
        : mode === "complete"
        ? setContentOptions(
            emailContentOptionsForPaymentMade.map(
              (element) => `${element}\n\n${senderEmailSignOff}`
            )
          )
        : setContentOptions(
            emailContentOptionsForCancellation.map(
              (element) => `${element}\n\n${senderEmailSignOff}`
            )
          );

      //const toBillingContactEmail = response?.data?.billingContactEmail;
      //toBillingContactEmail && setMainFormFieldValue(poPaymentToEmails.name, [toBillingContactEmail]);

      const toContactPersonsEmail = response?.data?.contactPersonEmail;
      toContactPersonsEmail &&
        setMainFormFieldValue(poPaymentToEmails.name, [toContactPersonsEmail]);
    } catch (err) {
      dispatchMainError(err);
    }
  }, [
    mode,
    poPaymentBccEmails.name,
    dispatchMainError,
    poPaymentFromEmail.name,
    poPaymentFromName.name,
    response?.data?.contactPersonEmail,
    retrieveDoc,
    setMainFormFieldValue,
    poPaymentToEmails.name,
    user.uid,
  ]);

  const updateRecipientOptions = useCallback(async () => {
    try {
      const supplierId = response.data.supplierId;
      const supplier =
        supplierId && (await retrieveDoc("suppliers", supplierId));

      const contactPersonsEmails = supplier
        ? supplier.data.contactPersons
            .map((contactPerson) => contactPerson.contactPersonEmail)
            .filter((email) => email !== null)
        : [];

      const usersEmails =
        usersData &&
        usersData
          .map((user) => user.data.email)
          .filter((email) => email !== null);

      setRecipientOptions([...contactPersonsEmails, ...usersEmails]);
    } catch (err) {
      dispatchMainError(err);
    }
  }, [dispatchMainError, response.data.supplierId, retrieveDoc, usersData]);

  useEffect(() => {
    try {
      initializeFieldValue();
      updateRecipientOptions();
    } catch (err) {
      dispatchMainError(err);
    }
  }, [dispatchMainError, initializeFieldValue, updateRecipientOptions]);

  return (
    <Card id="email" sx={{ overflow: "visible" }}>
      <MDBox m={3}>
        <MDBox mb={3}>
          <MDTypography variant="h5">Email</MDTypography>
        </MDBox>
        <MDBox>
          <Grid
            container
            rowSpacing={{ xs: 1, sm: 2 }}
            columnSpacing={{ xs: 1, sm: 3 }}
          >
            <Grid item xs={12} sm={2}>
              <MDTypography variant="body3" fontWeight="bold">
                From
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={5}>
              <FormField
                type={poPaymentFromName.type}
                label={poPaymentFromName.label}
                name={poPaymentFromName.name}
                value={
                  mainFormValues.poPaymentFromName &&
                  mainFormValues.poPaymentFromName.length > 0
                    ? mainFormValues.poPaymentFromName
                    : ""
                }
                placeholder={poPaymentFromName.placeholder}
                disabled={modeDisabled}
                error={errors.poPaymentFromName && touched.poPaymentFromName}
                success={
                  !!mainFormValues.poPaymentFromName &&
                  mainFormValues.poPaymentFromName.length > 0 &&
                  !errors.poPaymentFromName
                }
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={5}>
              <FormField
                type={poPaymentFromEmail.type}
                label={poPaymentFromEmail.label}
                name={poPaymentFromEmail.name}
                value={
                  mainFormValues.poPaymentFromEmail &&
                  mainFormValues.poPaymentFromEmail.length > 0
                    ? mainFormValues.poPaymentFromEmail
                    : ""
                }
                placeholder={poPaymentFromEmail.placeholder}
                disabled={modeDisabled}
                error={errors.poPaymentFromEmail && touched.poPaymentFromEmail}
                success={
                  !!mainFormValues.poPaymentFromEmail &&
                  mainFormValues.poPaymentFromEmail.length > 0 &&
                  !errors.poPaymentFromEmail
                }
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <MDTypography variant="body3" fontWeight="bold">
                To
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={10}>
              <FormAutocompleteFast
                multiple
                freeSolo
                form={mainForm}
                field={poPaymentToEmails}
                variant="standard"
                options={recipientOptions}
                disabled={modeDisabled}
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <MDTypography variant="body3" fontWeight="bold">
                Cc
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={10}>
              <FormAutocompleteFast
                multiple
                freeSolo
                form={mainForm}
                field={poPaymentCcEmails}
                variant="standard"
                options={recipientOptions}
                disabled={modeDisabled}
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <MDTypography variant="body3" fontWeight="bold">
                Bcc
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={10}>
              <FormAutocompleteFast
                multiple
                freeSolo
                form={mainForm}
                field={poPaymentBccEmails}
                variant="standard"
                options={recipientOptions}
                disabled={modeDisabled}
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <MDTypography variant="body3" fontWeight="bold">
                {poPaymentEmailSubject.label}
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={10}>
              <FormAutocompleteFast
                freeSolo
                form={mainForm}
                field={poPaymentEmailSubject}
                variant="standard"
                options={emailSubjectOptions}
                disabled={modeDisabled}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <MDTypography variant="body3" fontWeight="bold">
                {poPaymentEmailContent.label}
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={10}>
              <FormAutocompleteFast
                freeSolo
                form={mainForm}
                field={poPaymentEmailContent}
                variant="standard"
                options={contentOptions}
                disabled={modeDisabled}
              />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Card>
  );
}

Email.propTypes = {
  mainForm: PropTypes.object.isRequired,
  mainFormField: PropTypes.object.isRequired,
  modeDisabled: PropTypes.bool.isRequired,
};

export default Email;
