import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

import MDBox from "components/atoms/MDBox";
import MDButton from "components/atoms/MDButton";

import ErrorSnackbar from "components/molecules/Notifications/ErrorSnackbar";
import SuccessSnackbar from "components/molecules/Notifications/SuccessSnackbar";

import DashboardLayout from "components/templates/DashboardLayout";
import Topnav from "components/organisms/Topnav";
import DashboardFooter from "components/organisms/Footer/DashboardFooter";
import PermissionDenied from "components/organisms/PermissionDenied";
import InvalidOperation from "components/organisms/InvalidOperation";
import IncompleteOperation from "components/organisms/IncompleteOperation";
import SubmissionProgress from "components/organisms/SubmissionProgress";

// Customer page components
import CustomerParticulars from "pages/customers/manage/components/CustomerParticulars";
import CustomerContactPersons from "pages/customers/manage/components/CustomerContactPersons";
import CustomerDeliverySites from "pages/customers/manage/components/CustomerDeliverySites";
import CustomerBillingContacts from "pages/customers/manage/components/CustomerBillingContacts";

import { Formik, Form } from "formik";
import form from "pages/customers/manage/schemas/form";

import { AllowedTo } from "react-abac";
import { Permission } from "models/abac";

import { useCustomerManager } from "pages/customers/hooks/useCustomerManager";

function getSteps() {
  return ["Customer", "Contact Persons", "Delivery Sites", "Billing Contacts"];
}

function getStepContent(
  stepIndex,
  formik,
  formField,
  modeFieldDisabled,
  dispatchError
) {
  switch (stepIndex) {
    case 0:
      return (
        <CustomerParticulars
          mainForm={formik}
          mainFormField={formField}
          modeDisabled={modeFieldDisabled}
        />
      );
    case 1:
      return (
        <CustomerContactPersons
          mainForm={formik}
          mainFormField={formField}
          modeDisabled={modeFieldDisabled}
          dispatchMainError={dispatchError}
        />
      );
    case 2:
      return (
        <CustomerDeliverySites
          mainForm={formik}
          mainFormField={formField}
          modeDisabled={modeFieldDisabled}
          dispatchMainError={dispatchError}
        />
      );
    case 3:
      return (
        <CustomerBillingContacts
          mainForm={formik}
          mainFormField={formField}
          modeDisabled={modeFieldDisabled}
          dispatchMainError={dispatchError}
        />
      );
    default:
      return null;
  }
}

function Customer() {
  const [isProgressing, setIsProgressing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const { mode, customerId } = useParams();
  const {
    modeTitle,
    modeSubmit,
    modeFieldDisabled,
    modePermission,
    modeValidation,
    submitReject,
    submitNew,
    submitEdit,
    submitDelete,
    response,
    dispatchError,
  } = useCustomerManager(mode, customerId);

  const navigate = useNavigate();
  const navigateAfter = (path, millisecond) => {
    return new Promise(() => {
      setTimeout(() => {
        navigate(path);
      }, millisecond);
    });
  };

  const steps = getSteps();
  const { formId, formField } = form;
  const currentValidation = modeValidation[activeStep];
  const isLastStep = activeStep === steps.length - 1;

  const handleBack = () => {
    try {
      setActiveStep(activeStep - 1);
    } catch (err) {
      dispatchError(err);
    }
  };

  const handleReject = async (values) => {
    try {
      setIsSaving(true);
      setIsProgressing(true);
      await submitReject(values);
      await navigateAfter("/customers/records", 3000);
    } catch (err) {
      dispatchError(err);
    } finally {
      setIsSaving(false);
      setIsProgressing(false);
    }
  };

  const handleSubmit = async (values, actions) => {
    if (isLastStep) {
      try {
        setIsProgressing(true);
        switch (mode) {
          case "new":
            await submitNew(values);
            await navigateAfter("/customers/records", 3000);
            break;
          case "edit":
            await submitEdit(values);
            await navigateAfter("/customers/records", 3000);
            break;
          case "view":
            navigate("/customers/records");
            break;
          case "delete":
            await submitDelete();
            await navigateAfter("/customers/records", 3000);
            break;
          default:
            actions.setSubmitting(false);
            break;
        }
      } catch (err) {
        dispatchError(err);
        actions.setSubmitting(false);
      } finally {
        actions.setSubmitting(false);
        setIsProgressing(false);
      }
    } else {
      try {
        setActiveStep(activeStep + 1);
        actions.setTouched({});
        actions.setSubmitting(false);
      } catch (err) {
        dispatchError(err);
      } finally {
        actions.setSubmitting(false);
        setIsProgressing(false);
      }
    }
  };

  return (
    <DashboardLayout>
      <Topnav title={modeTitle} />
      <AllowedTo perform={modePermission} no={() => <PermissionDenied />} />
      <MDBox pt={6} pb={3}>
        {response.isPending && <SubmissionProgress />}
        {isProgressing && <SubmissionProgress />}
        {response.error && (
          <>
            {response.error?.name === "OperationInvalidError" && (
              <InvalidOperation />
            )}
            {response.error?.name === "OperationIncompleteError" && (
              <IncompleteOperation />
            )}
            <ErrorSnackbar
              title={modeTitle}
              dateTime=""
              message={response.error?.message}
              autoDismiss
            />
          </>
        )}
        {response.success && (
          <SuccessSnackbar
            title={modeTitle}
            dateTime={""}
            message={response.success}
            autoDismiss
          />
        )}
        <MDBox>
          {response.error?.name !== "OperationInvalidError" && (
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              sx={{ height: "100%" }}
            >
              <Grid item xs={12} lg={10}>
                <Formik
                  enableReinitialize
                  initialValues={response.data}
                  validationSchema={currentValidation}
                  onSubmit={handleSubmit}
                >
                  {(formik) => (
                    <Form id={formId} autoComplete="off">
                      <Card sx={{ height: "100%" }}>
                        <MDBox mx={2} mt={-3}>
                          <Stepper activeStep={activeStep} alternativeLabel>
                            {steps.map((label) => (
                              <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                              </Step>
                            ))}
                          </Stepper>
                        </MDBox>
                        {!formik.values.rejectedBy ? (
                          <MDBox
                            pr={3}
                            pt={3}
                            mb={-6}
                            display="flex"
                            justifyContent="flex-end"
                          >
                            <AllowedTo perform={Permission.REJECT_CUSTOMER}>
                              <MDButton
                                disabled={formik.isSubmitting || isSaving}
                                variant="gradient"
                                color="error"
                                onClick={handleReject}
                              >
                                reject
                              </MDButton>
                            </AllowedTo>
                          </MDBox>
                        ) : (
                          <MDBox
                            pr={3}
                            pt={3}
                            mb={-6}
                            display="flex"
                            justifyContent="flex-end"
                          >
                            <MDButton variant="outlined" color="error">
                              rejected
                            </MDButton>
                          </MDBox>
                        )}
                        <MDBox p={3}>
                          <MDBox>
                            {getStepContent(
                              activeStep,
                              formik,
                              formField,
                              modeFieldDisabled,
                              dispatchError
                            )}
                            <MDBox
                              mt={4}
                              width="100%"
                              display="flex"
                              justifyContent="space-between"
                            >
                              {activeStep === 0 ? (
                                <MDBox />
                              ) : (
                                <MDButton
                                  variant="gradient"
                                  color="light"
                                  onClick={handleBack}
                                >
                                  back
                                </MDButton>
                              )}
                              <MDButton
                                disabled={formik.isSubmitting || isSaving}
                                type="submit"
                                variant="gradient"
                                color="dark"
                              >
                                {isLastStep ? modeSubmit : "next"}
                              </MDButton>
                            </MDBox>
                          </MDBox>
                        </MDBox>
                      </Card>
                    </Form>
                  )}
                </Formik>
              </Grid>
            </Grid>
          )}
        </MDBox>
      </MDBox>
      <DashboardFooter />
    </DashboardLayout>
  );
}

export default Customer;
