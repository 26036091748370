import form, {
  serviceTeamsForm,
  serviceTypesForm,
  quotationAttachmentsForm,
  exclusionsForm,
  quoteItemsForm,
} from "pages/quotations/manage/schemas/form";

import {
  defaultQuotationDate,
  defaultQuotationNumber,
  defaultQuotationRevision,
  defaultQuotationMessage,
  defaultBeginingStatement,
  defaultPaymentTerm,
  defaultValidity,
  defaultExclusions,
  defaultNote,
  defaultEndingStatement,
  defaultInstallationPeriod,
  defaultDiscountType,
  defaultPaymentType,
} from "pages/quotations/manage/schemas/setups";

const {
  formField: {
    serviceTeams,
    serviceTypes,
    paymentType,
    quotationDate,
    quotationNumber,
    quotationRevision,
    contactPersonName,
    contactPersonLandlineNumber,
    contactPersonMobileNumber,
    contactPersonEmail,
    customerId,
    customerName,
    customerAddress,
    deliverySiteName,
    deliverySiteAddress,
    quotationTitle,
    quotationMessage,
    quoteItems,
    quoteDiscountType,
    quoteGrossTotal,
    quoteDiscountTotal,
    quoteBeforeGstTotal,
    quoteGstAmount,
    quoteAfterGstTotal,
    beginingStatement,
    installationPeriod,
    installationStartDate,
    installationEndDate,
    paymentTerm,
    validity,
    exclusions,
    note,
    endingStatement,
    quotationAttachments,
  },
} = form;

const initialValues = {
  [serviceTeams.name]: [],
  [serviceTypes.name]: [],
  [paymentType.name]: defaultPaymentType,
  [quotationDate.name]: defaultQuotationDate,
  [quotationNumber.name]: defaultQuotationNumber,
  [quotationRevision.name]: defaultQuotationRevision,
  [contactPersonName.name]: "",
  [contactPersonLandlineNumber.name]: "",
  [contactPersonMobileNumber.name]: "",
  [contactPersonEmail.name]: "",
  [customerId.name]: "",
  [customerName.name]: "",
  [contactPersonEmail.name]: "",
  [customerAddress.name]: "",
  [deliverySiteName.name]: "",
  [deliverySiteAddress.name]: "",
  [quotationTitle.name]: "",
  [quotationMessage.name]: defaultQuotationMessage,
  [quoteItems.name]: [],
  [quoteDiscountType.name]: defaultDiscountType,
  [quoteGrossTotal.name]: "0.00",
  [quoteDiscountTotal.name]: "0.00",
  [quoteBeforeGstTotal.name]: "0.00",
  [quoteGstAmount.name]: "0.00",
  [quoteAfterGstTotal.name]: "0.00",
  [beginingStatement.name]: defaultBeginingStatement,
  [installationPeriod.name]: defaultInstallationPeriod,
  [installationStartDate.name]: null,
  [installationEndDate.name]: null,
  [paymentTerm.name]: defaultPaymentTerm,
  [validity.name]: defaultValidity,
  [exclusions.name]: defaultExclusions,
  [note.name]: defaultNote,
  [endingStatement.name]: defaultEndingStatement,
  [quotationAttachments.name]: [],
};

const {
  formField: { serviceTeamName },
} = serviceTeamsForm;

const serviceTeamsInitialValues = {
  [serviceTeamName.name]: "",
};

const {
  formField: { serviceTypeName },
} = serviceTypesForm;

const serviceTypesInitialValues = {
  [serviceTypeName.name]: "",
};

const {
  formField: { quotationAttachmentFile },
} = quotationAttachmentsForm;

const quotationAttachmentsInitialValues = {
  [quotationAttachmentFile.name]: "",
};

const {
  formField: { exclusionClause },
} = exclusionsForm;

const exclusionsInitialValues = {
  [exclusionClause.name]: "",
};

const {
  formField: {
    itemOrdinal,
    itemNo,
    itemSubNo,
    itemServiceType,
    itemDescription,
    itemServiceTeam,
    itemQuantity,
    itemUnitCost,
    itemGrossAmount,
    itemDiscount,
    itemNetAmount,
  },
} = quoteItemsForm;

const quoteItemsInitialValues = {
  [itemOrdinal.name]: "",
  [itemNo.name]: "",
  [itemSubNo.name]: "",
  [itemServiceType.name]: "",
  [itemDescription.name]: "",
  [itemServiceTeam.name]: "",
  [itemQuantity.name]: "1",
  [itemUnitCost.name]: "",
  [itemGrossAmount.name]: "0.00",
  [itemDiscount.name]: "0",
  [itemNetAmount.name]: "0.00",
};

export {
  initialValues as default,
  serviceTeamsInitialValues,
  serviceTypesInitialValues,
  quotationAttachmentsInitialValues,
  exclusionsInitialValues,
  quoteItemsInitialValues,
};
