import form, {
  stampAttachmentsForm,
} from "pages/settings/company/manage/schemas/form";

const {
  formField: { displayName, stampAttachments },
} = form;

const initialValues = {
  [displayName.name]: "",

  [stampAttachments.name]: [],
};

const {
  formField: { stampAttachmentFile },
} = stampAttachmentsForm;

const stampAttachmentsInitialValues = {
  [stampAttachmentFile.name]: "",
};

export { initialValues as default, stampAttachmentsInitialValues };
