import PropTypes from "prop-types";

import MDBox from "components/atoms/MDBox";
import MDTypography from "components/atoms/MDTypography";

import { ErrorMessage, Field } from "formik";
import RadioGroupWrapper from "components/molecules/Formik/Wrapper/RadioGroupWrapper";

function FormRadioGroup({
  form,
  field,
  options,
  defaultValue,
  onChange,
  disabled,
  ...rest
}) {
  const { values, errors, touched, setFieldValue } = form;
  const value = values[field.name] ? values[field.name] : null;
  const error = disabled ? false : errors[field.name] && touched[field.name];
  const handleChange =
    onChange ??
    ((e, v) => {
      setFieldValue(field.name, v);
    });

  return (
    <MDBox mb={2}>
      <Field
        name={field.name}
        label={field.label}
        value={value}
        options={options}
        defaultValue={defaultValue}
        onChange={handleChange}
        as={RadioGroupWrapper}
        disabled={disabled}
        error={error}
        {...rest}
      />
      <MDBox mt={0.75}>
        <MDTypography
          component="div"
          variant="caption"
          color="error"
          fontWeight="regular"
          sx={{ whiteSpace: "pre-wrap" }}
        >
          <ErrorMessage name={field.name}></ErrorMessage>
        </MDTypography>
      </MDBox>
    </MDBox>
  );
}

// typechecking props for FormRadioGroup
FormRadioGroup.propTypes = {
  form: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  options: PropTypes.array,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  showTitle: PropTypes.bool,
  children: PropTypes.node,
};

export default FormRadioGroup;
