import { useParams } from "react-router-dom";
import Supplier from "pages/suppliers/manage/Supplier";

// using Proxy Page to remount when route to same route location but different param
function SupplierProxy() {
  const { mode, supplierId } = useParams();
  return <Supplier key={`${mode}/${supplierId}`} />;
}

export default SupplierProxy;
