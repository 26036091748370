import * as Yup from "yup";
import form, {
  serviceTeamsForm,
  serviceTypesForm,
  quotationAttachmentsForm,
  exclusionsForm,
  quoteItemsForm,
} from "pages/quotations/manage/schemas/form";

import {
  discountTypeOptions,
  installationPeriodOptions,
  paymentTypeOptions,
} from "pages/quotations/manage/schemas/setups";

import parse from "date-fns/parse";

const {
  formField: {
    serviceTeams,
    serviceTypes,
    paymentType,
    quotationDate,
    quotationNumber,
    quotationRevision,
    contactPersonName,
    contactPersonLandlineNumber,
    contactPersonMobileNumber,
    contactPersonEmail,
    customerId,
    customerName,
    customerAddress,
    deliverySiteName,
    deliverySiteAddress,
    quotationTitle,
    quotationMessage,
    quoteItems,
    quoteDiscountType,
    quoteGrossTotal,
    quoteDiscountTotal,
    quoteBeforeGstTotal,
    quoteGstAmount,
    quoteAfterGstTotal,
    beginingStatement,
    installationPeriod,
    installationStartDate,
    installationEndDate,
    paymentTerm,
    validity,
    exclusions,
    note,
    endingStatement,
    quotationAttachments,
  },
} = form;

const noValidation = Yup.object().shape({});

const validations = Yup.object().shape({
  [serviceTeams.name]: Yup.array()
    // .min(1, serviceTeams.min1Msg)
    .max(20, serviceTeams.max20Msg),
  [serviceTypes.name]: Yup.array()
    // .min(1, serviceTypes.min1Msg)
    .max(20, serviceTypes.max20Msg),
  [paymentType.name]: Yup.string().matches(
    `(${paymentTypeOptions.join("|")})`,
    {
      excludeEmptyString: true,
    }
  ),
  [quotationDate.name]: Yup.date()
    .transform((value, originalValue) => {
      return originalValue === null
        ? null
        : parse(originalValue, "dd/MM/yyyy", new Date());
    })
    .required(quotationDate.errorMsg)
    .typeError(quotationDate.invalidMsg),
  [quotationNumber.name]: Yup.string()
    .required(quotationNumber.errorMsg)
    .matches("(^\\d{6}-\\d{2}$|^---------$)", quotationNumber.invalidMsg),
  [quotationRevision.name]: Yup.string()
    .required(quotationRevision.errorMsg)
    .matches("(^\\d{1,2}$|^-$)", quotationRevision.invalidMsg),
  [contactPersonName.name]: Yup.string()
    .required(contactPersonName.errorMsg)
    .max(200, contactPersonName.invalidMsg),
  [contactPersonLandlineNumber.name]: Yup.string()
    // .when(contactPersonMobileNumber.name, {
    //   is: (contactPersonMobileNumberValue) =>
    //     !contactPersonMobileNumberValue?.length,
    //   then: Yup.string().required(contactPersonLandlineNumber.errorMsg),
    // })
    .matches(
      "^[+]?([0-9]*[\\-\\s.])*([0-9]*[(]+[0-9]{1,3}[)])*([0-9]*[\\-\\s.])*[0-9]{4,15}$",
      contactPersonLandlineNumber.invalidMsg
    ),
  [contactPersonMobileNumber.name]: Yup.string().matches(
    "^[+]?([0-9]*[\\-\\s.])*([0-9]*[(]+[0-9]{1,3}[)])*([0-9]*[\\-\\s.])*[0-9]{4,15}$",
    contactPersonMobileNumber.invalidMsg
  ),
  [contactPersonEmail.name]: Yup.string()
    //.required(contactPersonEmail.errorMsg)
    .max(200, contactPersonEmail.invalidMsg)
    .email(contactPersonEmail.invalidMsg),
  [customerId.name]: Yup.string()
    .required(customerId.errorMsg)
    .max(200, customerId.invalidMsg),
  [customerName.name]: Yup.string()
    .required(customerName.errorMsg)
    .max(200, customerName.invalidMsg),
  [customerAddress.name]: Yup.string()
    .required(customerAddress.errorMsg)
    .max(200, customerAddress.invalidMsg),
  [deliverySiteName.name]: Yup.string()
    .required(deliverySiteName.errorMsg)
    .max(200, deliverySiteName.invalidMsg),
  [deliverySiteAddress.name]: Yup.string()
    .required(deliverySiteAddress.errorMsg)
    .max(200, deliverySiteAddress.invalidMsg),
  [quotationTitle.name]: Yup.string()
    .required(quotationTitle.errorMsg)
    .max(500, quotationTitle.invalidMsg),
  [quotationMessage.name]: Yup.string()
    .required(quotationMessage.errorMsg)
    .max(2000, quotationMessage.invalidMsg)
    .nullable(true),
  [quoteItems.name]: Yup.array()
    .min(1, quoteItems.min1Msg)
    .max(100, quoteItems.max100Msg),
  [quoteDiscountType.name]: Yup.string().matches(
    `(${discountTypeOptions.join("|")})`,
    {
      excludeEmptyString: true,
    }
  ),
  [quoteGrossTotal.name]: Yup.string()
    .required(quoteGrossTotal.errorMsg)
    .matches("^\\d*(\\.\\d{1,2})?$", quoteGrossTotal.invalidMsg),
  [quoteDiscountTotal.name]: Yup.string()
    .required(quoteDiscountTotal.errorMsg)
    .matches("^\\d*(\\.\\d{1,2})?$", quoteDiscountTotal.invalidMsg),
  [quoteBeforeGstTotal.name]: Yup.string()
    .required(quoteBeforeGstTotal.errorMsg)
    .matches("^\\d*(\\.\\d{1,2})?$", quoteBeforeGstTotal.invalidMsg),
  [quoteGstAmount.name]: Yup.string()
    .required(quoteGstAmount.errorMsg)
    .matches("^\\d*(\\.\\d{1,2})?$", quoteGstAmount.invalidMsg),
  [quoteAfterGstTotal.name]: Yup.string()
    .required(quoteAfterGstTotal.errorMsg)
    .matches("^\\d*(\\.\\d{1,2})?$", quoteAfterGstTotal.invalidMsg),
  [beginingStatement.name]: Yup.string()
    .required(beginingStatement.errorMsg)
    .max(2000, beginingStatement.invalidMsg)
    .nullable(true),
  [installationPeriod.name]: Yup.string().matches(
    `(${installationPeriodOptions.join("|")})`,
    {
      excludeEmptyString: true,
    }
  ),
  [installationStartDate.name]: Yup.date()
    .nullable()
    .transform((value, originalValue) => {
      return originalValue === null
        ? null
        : parse(originalValue, "dd/MM/yyyy", new Date());
    })
    .when(installationPeriod.name, {
      is: (installationPeriodValue) => installationPeriodValue === "Duration",
      then: Yup.date()
        .nullable()
        .transform((value, originalValue) => {
          return originalValue === null
            ? null
            : parse(originalValue, "dd/MM/yyyy", new Date());
        })
        .required(installationStartDate.errorMsg)

        .typeError(installationStartDate.invalidMsg),
    })

    .typeError(installationStartDate.invalidMsg),
  [installationEndDate.name]: Yup.date()
    .nullable()
    .transform((value, originalValue) => {
      return originalValue === null
        ? null
        : parse(originalValue, "dd/MM/yyyy", new Date());
    })
    .when(installationPeriod.name, {
      is: (installationPeriodValue) => installationPeriodValue === "Duration",
      then: Yup.date()
        .nullable()
        .transform((value, originalValue) => {
          return originalValue === null
            ? null
            : parse(originalValue, "dd/MM/yyyy", new Date());
        })
        .required(installationEndDate.errorMsg)
        .typeError(installationEndDate.invalidMsg),
    })
    .typeError(installationEndDate.invalidMsg),
  [paymentTerm.name]: Yup.string()
    .required(paymentTerm.errorMsg)
    .max(2000, paymentTerm.invalidMsg)
    .nullable(true),
  [validity.name]: Yup.string()
    .required(validity.errorMsg)
    .max(2000, validity.invalidMsg)
    .nullable(true),
  [exclusions.name]: Yup.array()
    .min(1, exclusions.min1Msg)
    .max(50, exclusions.max50Msg),
  [note.name]: Yup.string()
    .required(note.errorMsg)
    .max(2000, note.invalidMsg)
    .nullable(true),
  [endingStatement.name]: Yup.string()
    .required(endingStatement.errorMsg)
    .max(2000, endingStatement.invalidMsg)
    .nullable(true),
  [quotationAttachments.name]: Yup.array().max(
    20,
    quotationAttachments.max20Msg
  ),
});

const {
  formField: { serviceTeamName },
} = serviceTeamsForm;

const serviceTeamsValidation = Yup.object().shape({
  [serviceTeamName.name]: Yup.string()
    .required(serviceTeamName.errorMsg)
    .max(200, serviceTeamName.invalidMsg)
    .nullable(true),
});

const {
  formField: { serviceTypeName },
} = serviceTypesForm;

const serviceTypesValidation = Yup.object().shape({
  [serviceTypeName.name]: Yup.string()
    .required(serviceTypeName.errorMsg)
    .max(200, serviceTypeName.invalidMsg)
    .nullable(true),
});

const {
  formField: { quotationAttachmentFile },
} = quotationAttachmentsForm;

const quotationAttachmentsValidation = Yup.object().shape({
  [quotationAttachmentFile.name]: Yup.mixed()
    .nullable()
    .required(quotationAttachmentFile.errorMsg)
    .test(
      "fileSize",
      quotationAttachmentFile.invalidMsg,
      (value) => !value || (value && value.size <= 5 * 1024 * 1024)
    ),
});

const {
  formField: { exclusionClause },
} = exclusionsForm;

const exclusionsValidation = Yup.object().shape({
  [exclusionClause.name]: Yup.string()
    .required(exclusionClause.errorMsg)
    .max(200, exclusionClause.invalidMsg)
    .nullable(true),
});

const {
  formField: {
    itemNo,
    itemSubNo,
    itemServiceType,
    itemDescription,
    itemServiceTeam,
    itemQuantity,
    itemUnitCost,
    itemGrossAmount,
    itemDiscount,
    itemNetAmount,
  },
} = quoteItemsForm;

const quoteItemsValidation = Yup.object().shape({
  [itemNo.name]: Yup.string().matches("^\\d{1,2}$", itemNo.invalidMsg),
  [itemSubNo.name]: Yup.string().matches(
    "^\\d{1,2}\\.\\d{1,2}$",
    itemSubNo.invalidMsg
  ),
  [itemServiceType.name]: Yup.string()
    .required(itemServiceType.errorMsg)
    .max(2000, itemServiceType.invalidMsg),
  [itemDescription.name]: Yup.string()
    .required(itemDescription.errorMsg)
    .max(2000, itemDescription.invalidMsg),
  [itemServiceTeam.name]: Yup.string()
    //.required(itemServiceTeam.errorMsg)
    .max(2000, itemServiceTeam.invalidMsg),
  [itemQuantity.name]: Yup.string()
    .required(itemQuantity.errorMsg)
    .matches("^\\d{1,4}$", itemQuantity.invalidMsg),
  [itemUnitCost.name]: Yup.string()
    .required(itemUnitCost.errorMsg)
    .matches("^\\d*(\\.\\d{1,2})?$", itemUnitCost.invalidMsg),
  [itemGrossAmount.name]: Yup.string()
    .required(itemGrossAmount.errorMsg)
    .matches("^\\d*(\\.\\d{1,2})?$", itemGrossAmount.invalidMsg),
  [itemDiscount.name]: Yup.string()
    .required(itemDiscount.errorMsg)
    .matches("^\\d*(\\.\\d{1,3})?$", itemDiscount.invalidMsg),
  [itemNetAmount.name]: Yup.string()
    .required(itemNetAmount.errorMsg)
    .matches("^\\d*(\\.\\d{1,2})?$", itemNetAmount.invalidMsg),
});

export {
  validations as default,
  noValidation,
  serviceTeamsValidation,
  serviceTypesValidation,
  quotationAttachmentsValidation,
  exclusionsValidation,
  quoteItemsValidation,
};
