import { useEffect, useCallback, useState, useReducer } from "react";
import { useLocation } from "react-router-dom";

import { useAuthContext } from "context/AuthContext";
import { useDocument } from "hooks/useDocument";

import { useAbac } from "react-abac";
import { Permission } from "models/abac";

import initialValues from "pages/purchaseorders/manage/schemas/initialValues";
import validations, {
  noValidation,
} from "pages/purchaseorders/manage/schemas/validations";
import sendValidations from "pages/purchaseorders/pdf/schemas/validations"; //completeValidations, //goodsReceivedValidations,

import { sendMail } from "services/callable";

import parse from "date-fns/parse";

import currency from "currency.js";

const collectionPath = "purchaseorders";

const initialState = {
  data: initialValues,
  isPending: false,
  error: null,
  success: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "DISMISS":
      return {
        isPending: false,
        data: initialValues,
        success: null,
        error: null,
      };
    case "IS_PENDING":
      return {
        isPending: true,
        data: initialValues,
        success: null,
        error: null,
      };
    case "INITIAL_PURCHASEORDER":
      return {
        isPending: false,
        data: action.payload,
        success: null,
        error: null,
      };
    case "RETRIEVED_PURCHASEORDER":
      return {
        isPending: false,
        data: action.payload,
        success: null,
        error: null,
      };
    case "CREATED_DRAFT":
      return {
        isPending: false,
        data: action.payload,
        success: `Successfully created the draft.`, //, ${action.payload.quotationNumber}R${action.payload.quotationRevision}.`,
        error: null,
      };
    case "UPDATED_DRAFT":
      return {
        isPending: false,
        data: action.payload,
        success: `Successfully updated the draft.`, //, ${action.payload.quotationNumber}R${action.payload.quotationRevision}.`,
        error: null,
      };
    case "CREATED_PURCHASEORDER":
      return {
        isPending: false,
        data: action.payload,
        success: `Successfully created the purchase order.`, //, ${action.payload.quotationNumber}R${action.payload.quotationRevision}.`,
        error: null,
      };
    case "UPDATED_PURCHASEORDER":
      return {
        isPending: false,
        data: action.payload,
        success: `Successfully updated the purchase order.`, //, ${action.payload.quotationNumber}R${action.payload.quotationRevision}.`,
        error: null,
      };
    case "DELETED_PURCHASEORDER":
      return {
        isPending: false,
        data: action.payload,
        success: `Successfully deleted the purchase order.`, //, ${action.payload.quotationNumber}R${action.payload.quotationRevision}.`,
        error: null,
      };
    case "SENT_PURCHASEORDER":
      return {
        isPending: false,
        data: action.payload,
        success: `Successfully sent the purchase order.`, //, ${action.payload.quotationNumber}R${action.payload.quotationRevision}.`,
        error: null,
      };
    case "SENT_PAYMENTMADE_PURCHASEORDER":
      return {
        isPending: false,
        data: action.payload,
        success: `Successfully sent the payment made notice.`, //, ${action.payload.quotationNumber}R${action.payload.quotationRevision}.`,
        error: null,
      };
    case "SENT_CANCELLATION_PURCHASEORDER":
      return {
        isPending: false,
        data: action.payload,
        success: `Successfully sent the cancellation notice.`, //, ${action.payload.quotationNumber}R${action.payload.quotationRevision}.`,
        error: null,
      };
    case "SENT_GOODSRECEIVED_PURCHASEORDER":
      return {
        isPending: false,
        data: action.payload,
        success: `Successfully update goods received details.`, //, ${action.payload.quotationNumber}R${action.payload.quotationRevision}.`,
        error: null,
      };
    case "ERROR":
      return {
        isPending: false,
        data: initialValues,
        success: null,
        error: action.error,
      };
    default:
      return state;
  }
};

export const usePurchaseOrderManager = (mode, purchaseorderId) => {
  const [response, dispatch] = useReducer(reducer, initialState);
  const [isUnmounted, setIsUnmounted] = useState(false);

  const { user } = useAuthContext();
  const { userHasPermissions } = useAbac();

  const {
    createDoc,
    retrieveDoc,
    retrieveDocs,
    updateDoc,
    deleteDoc,
    serverTimestamp,
    convertToTimestamp,
  } = useDocument();

  const { pathname } = useLocation();

  const dispatchIfNotUnmounted = useCallback(
    (action) => {
      if (!isUnmounted) {
        dispatch(action);
      }
    },
    [isUnmounted]
  );

  const dispatchDismiss = useCallback(
    () => dispatchIfNotUnmounted({ type: "DISMISS" }),
    [dispatchIfNotUnmounted]
  );

  const dispatchError = useCallback(
    (err) => {
      console.error(err);
      if (
        !["PermissionDeniedError", "OperationInvalidError"].includes(err.name)
      ) {
        err.message = "The operation couldn't be completed";
        err.name = "OperationIncompleteError";
        // TODO: send error stack to server
      }
      dispatchIfNotUnmounted({
        type: "ERROR",
        error: err,
      });
    },
    [dispatchIfNotUnmounted]
  );

  // TODO: Refactor to DAO Layer
  const toPersistenceValue = useCallback(
    (document) => {
      try {
        if (document.purchaseorderDate) {
          document.purchaseorderDate = convertToTimestamp(
            parse(document.purchaseorderDate, "dd/MM/yyyy", new Date())
          );
        }

        if (document.installationStartDate) {
          document.installationStartDate = convertToTimestamp(
            parse(document.installationStartDate, "dd/MM/yyyy", new Date())
          );
        }

        if (document.installationStartDate) {
          document.installationEndDate = convertToTimestamp(
            parse(document.installationEndDate, "dd/MM/yyyy", new Date())
          );
        }

        if (document.emailAttachments) {
          document.emailAttachments = document.emailAttachments.map(
            (element) => {
              const { attachmentName, attachmentPath, attachmentURL } = element;
              return { attachmentName, attachmentPath, attachmentURL };
            }
          );
        }

        if (document.purchaseorderAttachments) {
          document.purchaseorderAttachments =
            document.purchaseorderAttachments.map((element) => {
              const { attachmentName, attachmentPath, attachmentURL } = element;
              return { attachmentName, attachmentPath, attachmentURL };
            });
        }

        return document;
      } catch (err) {
        dispatchError(err);
      }
    },
    [convertToTimestamp, dispatchError]
  );

  // TODO: Refactor to DAO Layer
  const toPresentationValue = useCallback(
    (data) => {
      try {
        if (data.purchaseorderDate)
          data.purchaseorderDate = data.purchaseorderDate
            .toDate()
            .toLocaleDateString("en-SG");

        if (data.purchaseorderNumber === "---------")
          data.purchaseorderNumber = "000000-00";

        if (data.purchaseorderRevision === "-")
          data.purchaseorderRevision = "0";

        if (data.installationStartDate)
          data.installationStartDate = data.installationStartDate
            .toDate()
            .toLocaleDateString("en-SG");

        if (data.installationEndDate)
          data.installationEndDate = data.installationEndDate
            .toDate()
            .toLocaleDateString("en-SG");

        return data;
      } catch (err) {
        dispatchError(err);
      }
    },
    [dispatchError]
  );

  const validateOperation = useCallback(async () => {
    try {
      dispatchIfNotUnmounted({ type: "IS_PENDING" });
      let operationInvalidError = new Error(
        "Invalid Operation. You are not allowed to carry out this activity."
      );
      operationInvalidError.name = "OperationInvalidError";

      const retrievedPurchaseOrder =
        purchaseorderId && (await retrieveDoc(collectionPath, purchaseorderId));

      // if one of the purchase order revision had sent to customer,
      // disallow activity on all other revisions of the purchase order
      let isOutdated = false;
      if (mode !== "new") {
        const purchaseordersQueries = {
          whereQueries: [
            {
              field: "purchaseorderNumber",
              condition: "==",
              value: retrievedPurchaseOrder.data.purchaseorderNumber,
            },
          ],
        };
        const purchaseorderSeries =
          retrievedPurchaseOrder.data.purchaseorderNumber &&
          (await retrieveDocs(collectionPath, purchaseordersQueries));
        const sentPurchaseOrder = purchaseorderSeries.find(
          (element) => element.data.sentAt !== null
        );

        if (!!sentPurchaseOrder && sentPurchaseOrder.id !== purchaseorderId) {
          isOutdated = true;
        }
      }

      // Reset the purchase order number as "000000-00"
      // The system will assign the next PO number after submission
      if (mode === "copy") {
        retrievedPurchaseOrder.data.purchaseorderNumber =
          initialValues.purchaseorderNumber;
        retrievedPurchaseOrder.data.purchaseorderRevision =
          initialValues.purchaseorderRevision;
      }

      // validate operation
      switch (mode) {
        case "new":
          if (!pathname.includes("/purchaseorders/manage")) {
            throw operationInvalidError;
          }

          dispatchIfNotUnmounted({
            type: "INITIAL_PURCHASEORDER",
            payload: initialValues,
          });
          break;
        case "draft":
          if (
            !(
              pathname.includes("/purchaseorders/manage") &&
              retrievedPurchaseOrder.data.purchaseorderNumber === "---------"
            )
          ) {
            throw operationInvalidError;
          }

          dispatchIfNotUnmounted({
            type: "RETRIEVED_PURCHASEORDER",
            payload: toPresentationValue(retrievedPurchaseOrder.data),
          });
          break;
        case "edit":
          if (
            !(
              pathname.includes("/purchaseorders/manage") &&
              retrievedPurchaseOrder.data.purchaseorderNumber.match(
                /^\d{6}-\d{2}$/
              ) &&
              retrievedPurchaseOrder.data.purchaseorderRevision.match(
                /^\d{1,2}$/
              ) &&
              retrievedPurchaseOrder.data.sentAt === null &&
              retrievedPurchaseOrder.data.sentBy === null &&
              !isOutdated
            )
          ) {
            throw operationInvalidError;
          }

          dispatchIfNotUnmounted({
            type: "RETRIEVED_PURCHASEORDER",
            payload: toPresentationValue(retrievedPurchaseOrder.data),
          });
          break;
        case "copy":
          if (
            !(
              pathname.includes("/purchaseorders/manage") &&
              retrievedPurchaseOrder.data.purchaseorderNumber.match(
                /^\d{6}-\d{2}$/
              ) &&
              retrievedPurchaseOrder.data.purchaseorderRevision.match(
                /^\d{1,2}$/
              ) &&
              retrievedPurchaseOrder.data.sentAt !== null &&
              retrievedPurchaseOrder.data.sentBy !== null &&
              !isOutdated
            )
          ) {
            throw operationInvalidError;
          }

          dispatchIfNotUnmounted({
            type: "RETRIEVED_PURCHASEORDER",
            payload: toPresentationValue(retrievedPurchaseOrder.data),
          });
          break;
        case "view":
        case "delete":
          if (!pathname.includes("/purchaseorders/manage")) {
            throw operationInvalidError;
          }

          dispatchIfNotUnmounted({
            type: "RETRIEVED_PURCHASEORDER",
            payload: toPresentationValue(retrievedPurchaseOrder.data),
          });
          break;
        case "print":
          if (
            !(
              pathname.includes("/purchaseorders/pdf") &&
              retrievedPurchaseOrder.data.purchaseorderNumber.match(
                /^\d{6}-\d{2}$/
              ) &&
              retrievedPurchaseOrder.data.purchaseorderRevision.match(
                /^\d{1,2}$/
              ) &&
              retrievedPurchaseOrder.data.approvedAt !== null &&
              retrievedPurchaseOrder.data.approvedBy !== null &&
              !isOutdated
            )
          ) {
            throw operationInvalidError;
          }

          dispatchIfNotUnmounted({
            type: "RETRIEVED_PURCHASEORDER",
            payload: toPresentationValue(retrievedPurchaseOrder.data),
          });
          break;
        case "send":
          if (
            !(
              pathname.includes("/purchaseorders/pdf") &&
              retrievedPurchaseOrder.data.purchaseorderNumber.match(
                /^\d{6}-\d{2}$/
              ) &&
              retrievedPurchaseOrder.data.purchaseorderRevision.match(
                /^\d{1,2}$/
              ) &&
              retrievedPurchaseOrder.data.approvedAt !== null &&
              retrievedPurchaseOrder.data.approvedBy !== null &&
              retrievedPurchaseOrder.data.sentAt === null &&
              retrievedPurchaseOrder.data.sentBy === null &&
              !isOutdated
            )
          ) {
            throw operationInvalidError;
          }

          dispatchIfNotUnmounted({
            type: "RETRIEVED_PURCHASEORDER",
            payload: toPresentationValue(retrievedPurchaseOrder.data),
          });
          break;
        case "complete":
          if (
            !(
              pathname.includes("/purchaseorders/pdf") &&
              retrievedPurchaseOrder.data.purchaseorderNumber.match(
                /^\d{6}-\d{2}$/
              ) &&
              retrievedPurchaseOrder.data.approvedAt !== null &&
              retrievedPurchaseOrder.data.approvedBy !== null &&
              retrievedPurchaseOrder.data.sentAt !== null &&
              retrievedPurchaseOrder.data.sentBy !== null &&
              !isOutdated
            )
          ) {
            throw operationInvalidError;
          }

          dispatchIfNotUnmounted({
            type: "RETRIEVED_PURCHASEORDER",
            payload: toPresentationValue(retrievedPurchaseOrder.data),
          });
          break;
        case "cancel":
          if (
            !(
              pathname.includes("/purchaseorders/pdf") &&
              retrievedPurchaseOrder.data.purchaseorderNumber.match(
                /^\d{6}-\d{2}$/
              ) &&
              retrievedPurchaseOrder.data.approvedAt !== null &&
              retrievedPurchaseOrder.data.approvedBy !== null &&
              retrievedPurchaseOrder.data.sentAt !== null &&
              retrievedPurchaseOrder.data.sentBy !== null &&
              !isOutdated
            )
          ) {
            throw operationInvalidError;
          }

          dispatchIfNotUnmounted({
            type: "RETRIEVED_PURCHASEORDER",
            payload: toPresentationValue(retrievedPurchaseOrder.data),
          });
          break;
        case "receive":
          if (
            !(
              pathname.includes("/purchaseorders/pdf") &&
              retrievedPurchaseOrder.data.purchaseorderNumber.match(
                /^\d{6}-\d{2}$/
              ) &&
              retrievedPurchaseOrder.data.approvedAt !== null &&
              retrievedPurchaseOrder.data.approvedBy !== null &&
              retrievedPurchaseOrder.data.sentAt !== null &&
              retrievedPurchaseOrder.data.sentBy !== null &&
              !isOutdated
            )
          ) {
            throw operationInvalidError;
          }

          dispatchIfNotUnmounted({
            type: "RETRIEVED_PURCHASEORDER",
            payload: toPresentationValue(retrievedPurchaseOrder.data),
          });
          break;
        default:
          throw operationInvalidError;
      }
    } catch (err) {
      dispatchError(err);
    }
  }, [
    dispatchIfNotUnmounted,
    purchaseorderId,
    retrieveDoc,
    mode,
    retrieveDocs,
    pathname,
    toPresentationValue,
    dispatchError,
  ]);

  useEffect(() => {
    try {
      validateOperation();
    } catch (err) {
      dispatchError(err);
    }
    return () => {
      setIsUnmounted(true);
    };
  }, [dispatchError, validateOperation]);

  let modeTitle = "";
  let modeSubmit = "";
  let modeFieldDisabled = true;
  let modePermission = "";
  let modeValidation = noValidation;

  switch (mode) {
    case "new":
      modeTitle = "Create Purchase Order";
      modeSubmit = "Create";
      modeFieldDisabled = false;
      modePermission = Permission.CREATE_PURCHASEORDER;
      modeValidation = validations;
      break;
    case "draft":
      modeTitle = "Edit Draft / Create Purchase Order";
      modeSubmit = "Create";
      modeFieldDisabled = false;
      modePermission = Permission.CREATE_PURCHASEORDER;
      modeValidation = validations;
      break;
    case "edit":
      modeTitle = "Edit Purchase Order";
      modeSubmit = "Update";
      modeFieldDisabled = false;
      modePermission = Permission.UPDATE_PURCHASEORDER;
      modeValidation = validations;
      break;
    case "copy":
      modeTitle = "Copy Purchase Order";
      modeSubmit = "Copy";
      modeFieldDisabled = false;
      modePermission = Permission.UPDATE_PURCHASEORDER;
      modeValidation = validations;
      break;
    case "view":
      modeTitle = "View Purchase Order";
      modeSubmit = "Close";
      modeFieldDisabled = true;
      modePermission = Permission.READ_PURCHASEORDER;
      modeValidation = noValidation;
      break;
    case "delete":
      modeTitle = "Delete Purchase Order";
      modeSubmit = "Delete";
      modeFieldDisabled = true;
      modePermission = Permission.DELETE_PURCHASEORDER;
      modeValidation = noValidation;
      break;
    case "print":
      modeTitle = "Print Purchase Order";
      modeSubmit = "Close";
      modeFieldDisabled = true;
      modePermission = Permission.PRINT_PURCHASEORDER;
      modeValidation = noValidation;
      break;
    case "send":
      modeTitle = "Send Purchase Order";
      modeSubmit = "Send";
      modeFieldDisabled = false;
      modePermission = Permission.SEND_PURCHASEORDER;
      modeValidation = sendValidations;
      break;
    case "complete":
      modeTitle = "Complete Purchase Order";
      modeSubmit = "Send";
      modeFieldDisabled = false;
      modePermission = Permission.COMPLETE_PURCHASEORDER;
      modeValidation = noValidation;
      break;
    case "cancel":
      modeTitle = "Cancel Purchase Order";
      modeSubmit = "Send";
      modeFieldDisabled = false;
      modePermission = Permission.CANCEL_PURCHASEORDER;
      modeValidation = noValidation;
      break;
    case "receive":
      modeTitle = "Receive Goods";
      modeSubmit = "Send";
      modeFieldDisabled = false;
      modePermission = Permission.RECEIVE_PURCHASEORDER;
      modeValidation = noValidation;
      break;
    default:
      modeTitle = "Illegal Action";
  }

  const submitNewDraft = async (values) => {
    values.purchaseorderNumber = "---------";
    values.purchaseorderRevision = "-";
    return await submitNew(values);
  };

  const submitEditDraft = async (values) => {
    values.purchaseorderNumber = "---------";
    values.purchaseorderRevision = "-";
    return await submitEdit(values);
  };

  const submitNew = async (values) => {
    try {
      dispatchIfNotUnmounted({ type: "IS_PENDING" });
      if (userHasPermissions(Permission.CREATE_PURCHASEORDER)) {
        values = toPersistenceValue(values);
        const isAutoApproval = values.purchaseorderGrossTotal
          ? currency(values.purchaseorderGrossTotal).value < 1000
          : false;
        const createdDoc = await createDoc(
          collectionPath,
          {
            ...values,
            approvedAt: isAutoApproval ? serverTimestamp() : null,
            approvedBy: isAutoApproval ? "system" : null,
            sentAt: null,
            sentBy: null,
            fromName: "",
            fromEmail: "",
            toEmails: [],
            ccEmails: [],
            bccEmails: [],
            emailSubject: "",
            emailContent: "",
            emailAttachments: [],
            poPaymentEmailSentAt: null,
            poPaymentEmailSentBy: null,
            poCancelEmailSentAt: null,
            poCancelEmailSentBy: null,
            goodsReceivedAt: null,
            goodsReceivedBy: null,
            voidAt: null,
            voidBy: null,
            copyAt: null,
            copyBy: null,
          },
          user.uid
        );
        const dispatchType =
          values.purchaseorderNumber !== "---------"
            ? "CREATED_PURCHASEORDER"
            : "CREATED_DRAFT";
        dispatchIfNotUnmounted({
          type: dispatchType,
          payload: toPresentationValue(createdDoc.data),
        });
      } else {
        let error = new Error(
          "Permission Denied. You are not allowed to create supplier."
        );
        error.name = "PermissionDeniedError";
        throw error;
      }
    } catch (err) {
      dispatchError(err);
    }
  };

  const submitEdit = async (values) => {
    try {
      dispatchIfNotUnmounted({ type: "IS_PENDING" });
      if (userHasPermissions(Permission.UPDATE_PURCHASEORDER)) {
        values = toPersistenceValue(values);
        const updatedDoc = await updateDoc(collectionPath, purchaseorderId, {
          ...values,
          modifiedAt: serverTimestamp(),
          modifiedBy: user.uid,
          approvedAt: null,
          approvedBy: null,
        });
        const dispatchType =
          values.purchaseorderNumber !== "---------"
            ? "UPDATED_PURCHASEORDER"
            : "UPDATED_DRAFT";
        dispatchIfNotUnmounted({
          type: dispatchType,
          payload: toPresentationValue(updatedDoc.data),
        });
      } else {
        let error = new Error(
          "Permission Denied. You are not allowed to create supplier."
        );
        error.name = "PermissionDeniedError";
        throw error;
      }
    } catch (err) {
      dispatchError(err);
    }
  };

  const submitDelete = async () => {
    try {
      dispatchIfNotUnmounted({ type: "IS_PENDING" });
      if (userHasPermissions(Permission.DELETE_PURCHASEORDER)) {
        const deletedDoc = await deleteDoc(collectionPath, purchaseorderId, {
          deletedAt: serverTimestamp(),
          deletedBy: user.uid,
        });
        dispatchIfNotUnmounted({
          type: "DELETED_PURCHASEORDER",
          payload: toPresentationValue(deletedDoc.data),
        });
      } else {
        let error = new Error(
          "Permission Denied. You are not allowed to create customer."
        );
        error.name = "PermissionDeniedError";
        throw error;
      }
    } catch (err) {
      dispatchError(err);
    }
  };

  const submitSend = async (values) => {
    try {
      dispatchIfNotUnmounted({ type: "IS_PENDING" });
      if (userHasPermissions(Permission.SEND_PURCHASEORDER)) {
        await sendMail({
          fromName: values.fromName,
          fromEmail: values.fromEmail,
          toEmails: values.toEmails,
          ccEmails: values.ccEmails,
          bccEmails: values.bccEmails,
          emailSubject: values.emailSubject,
          emailContent: values.emailContent,
          emailAttachments: values.emailAttachments.map((attachment) => {
            return {
              filename: attachment.attachmentName,
              path: attachment.attachmentURL,
            };
          }),
        });

        values = toPersistenceValue(values);

        const updatedDoc = await updateDoc(collectionPath, purchaseorderId, {
          ...values,
          sentAt: serverTimestamp(),
          sentBy: user.uid,
        });

        dispatchIfNotUnmounted({
          type: "SENT_PURCHASEORDER",
          payload: toPresentationValue(updatedDoc.data),
        });
      } else {
        let error = new Error(
          "Permission Denied. You are not allowed to create supplier."
        );
        error.name = "PermissionDeniedError";
        throw error;
      }
    } catch (err) {
      dispatchError(err);
    }
  };

  const submitSendPaymentMade = async (values) => {
    try {
      dispatchIfNotUnmounted({ type: "IS_PENDING" });
      if (userHasPermissions(Permission.COMPLETE_PURCHASEORDER)) {
        await sendMail({
          fromName: values.fromName,
          fromEmail: values.fromEmail,
          toEmails: values.toEmails,
          ccEmails: values.ccEmails,
          bccEmails: values.bccEmails,
          emailSubject: values.emailSubject,
          emailContent: values.emailContent,
          emailAttachments: values.emailAttachments.map((attachment) => {
            return {
              filename: attachment.attachmentName,
              path: attachment.attachmentURL,
            };
          }),
        });

        values = toPersistenceValue(values);

        const updatedDoc = await updateDoc(collectionPath, purchaseorderId, {
          ...values,
          poPaymentEmailSentAt: serverTimestamp(),
          poPaymentEmailSentBy: user.uid,
        });

        dispatchIfNotUnmounted({
          type: "SENT_PAYMENTMADE_PURCHASEORDER",
          payload: toPresentationValue(updatedDoc.data),
        });
      } else {
        let error = new Error(
          "Permission Denied. You are not allowed to create supplier."
        );
        error.name = "PermissionDeniedError";
        throw error;
      }
    } catch (err) {
      dispatchError(err);
    }
  };

  // Submit send cancellation
  const submitSendCancellation = async (values) => {
    try {
      dispatchIfNotUnmounted({ type: "IS_PENDING" });
      if (userHasPermissions(Permission.CANCEL_PURCHASEORDER)) {
        await sendMail({
          fromName: values.fromName,
          fromEmail: values.fromEmail,
          toEmails: values.toEmails,
          ccEmails: values.ccEmails,
          bccEmails: values.bccEmails,
          emailSubject: values.emailSubject,
          emailContent: values.emailContent,
          emailAttachments: values.emailAttachments.map((attachment) => {
            return {
              filename: attachment.attachmentName,
              path: attachment.attachmentURL,
            };
          }),
        });

        values = toPersistenceValue(values);

        const updatedDoc = await updateDoc(collectionPath, purchaseorderId, {
          ...values,
          poCancelEmailSentAt: serverTimestamp(),
          poCancelEmailSentBy: user.uid,
        });

        dispatchIfNotUnmounted({
          type: "SENT_CANCELLATION_PURCHASEORDER",
          payload: toPresentationValue(updatedDoc.data),
        });
      } else {
        let error = new Error(
          "Permission Denied. You are not allowed to create supplier."
        );
        error.name = "PermissionDeniedError";
        throw error;
      }
    } catch (err) {
      dispatchError(err);
    }
  };

  // Submit send goods received
  const submitSendGoodsReceived = async (values) => {
    try {
      dispatchIfNotUnmounted({ type: "IS_PENDING" });

      if (userHasPermissions(Permission.RECEIVE_PURCHASEORDER)) {
        values = toPersistenceValue(values);
        const updatedDoc = await updateDoc(collectionPath, purchaseorderId, {
          ...values,
        });

        dispatchIfNotUnmounted({
          type: "SENT_GOODSRECEIVED_PURCHASEORDER",
          payload: toPresentationValue(updatedDoc.data),
        });
      } else {
        let error = new Error(
          "Permission Denied. You are not allowed to create supplier."
        );
        error.name = "PermissionDeniedError";
        throw error;
      }
    } catch (err) {
      dispatchError(err);
    }
  };

  return {
    modeTitle,
    modeSubmit,
    modeFieldDisabled,
    modePermission,
    modeValidation,
    submitNewDraft,
    submitEditDraft,
    submitNew,
    submitEdit,
    submitDelete,
    submitSend,
    submitSendPaymentMade,
    submitSendCancellation,
    submitSendGoodsReceived,
    response,
    dispatchDismiss,
    dispatchError,
  };
};
