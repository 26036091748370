const form = {
  formId: "quotation-email-form",
  formField: {
    fromName: {
      name: "fromName",
      label: "Sender Name",
      type: "text",
      errorMsg: "Sender Name is required.",
      invalidMsg: "Sender Name is invalid.",
    },
    fromEmail: {
      name: "fromEmail",
      label: "Sender Email",
      type: "text",
      errorMsg: "Sender Email is required.",
      invalidMsg: "Sender Email is invalid.",
    },
    toEmails: {
      name: "toEmails",
      label: "Recipient Emails",
      type: "box",
      min1Msg: "Must have at least 1 Recipient Email.",
      max20Msg: "Must have at most 20 Recipient Emails.",
    },
    ccEmails: {
      name: "ccEmails",
      label: "Cc Emails",
      type: "box",
      max20Msg: "Must have at most 20 CC Emails.",
    },
    bccEmails: {
      name: "bccEmails",
      label: "Bcc Emails",
      type: "box",
      max20Msg: "Must have at most 20 BCC Emails.",
    },
    emailSubject: {
      name: "emailSubject",
      label: "Subject",
      type: "text",
      errorMsg: "Subject is required.",
      invalidMsg: "Subject is invalid.",
    },
    emailContent: {
      name: "emailContent",
      label: "Content",
      type: "text",
      errorMsg: "Content is required.",
      invalidMsg: "Content is invalid.",
    },
    emailAttachments: {
      name: "emailAttachments",
      label: "Attachments",
      type: "box",
      max20Msg: "Must have at most 20 Attachments.",
    },
  },
};

const toEmailsForm = {
  formId: "to-emails-form",
  formField: {
    toEmail: {
      name: "toEmail",
      label: "To",
      type: "text",
      errorMsg: "Email is required.",
      invalidMsg: "Email is invalid",
    },
  },
};

const ccEmailsForm = {
  formId: "cc-emails-form",
  formField: {
    ccEmail: {
      name: "ccEmail",
      label: "Cc",
      type: "text",
      errorMsg: "Email is required.",
      invalidMsg: "Email is invalid",
    },
  },
};

const bccEmailsForm = {
  formId: "bcc-emails-form",
  formField: {
    bccEmail: {
      name: "bccEmail",
      label: "Bcc",
      type: "text",
      errorMsg: "Email is required.",
      invalidMsg: "Email is invalid",
    },
  },
};

const emailAttachmentsForm = {
  formId: "email-attachments-form",
  formField: {
    emailAttachmentFile: {
      name: "emailAttachmentFile",
      label: "Attachment File",
      type: "box",
      errorMsg: "Attachment File is required.",
      invalidMsg: "File size must be less than 5 MB.",
    },
  },
};

export {
  form as default,
  toEmailsForm,
  ccEmailsForm,
  bccEmailsForm,
  emailAttachmentsForm,
};
