import { View } from "@react-pdf/renderer";
import PriceQuoteTableHeader from "pages/purchaseorders/pdf/components/PriceQuoteTableHeader";
import PriceQuoteTableItem from "pages/purchaseorders/pdf/components/PriceQuoteTableItem";
import PriceQuoteTableFooter from "pages/purchaseorders/pdf/components/PriceQuoteTableFooter";
import styles from "pages/purchaseorders/pdf/styles";

function PriceQuote({ values }) {
  return (
    <View style={[styles.containerCol, styles.tableSection]}>
      <PriceQuoteTableHeader values={values} />
      <PriceQuoteTableItem values={values} />
      <PriceQuoteTableFooter values={values} />
    </View>
  );
}

export default PriceQuote;
