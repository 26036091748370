import form, {
  contactPersonForm,
  deliverySiteForm,
  billingContactForm,
} from "pages/suppliers/manage/schemas/form";

const {
  formField: {
    supplierName,
    supplierUEN,
    supplierType,
    supplierStatus,
    supplierAddress,
    supplierBuildingName,
    supplierPostalCode,
    supplierLandlineNumber,
    supplierMobileNumber,
    supplierEmail,
    supplierWebsite,
    supplierPreferredContact,
    contactPersons,
    deliverySites,
    billingContacts,
  },
} = form;

const {
  formField: {
    deliverySiteName,
    siteSupervisorName,
    deliverySiteAddress,
    deliverySiteBlkBuildingName,
    deliverySitePostalCode,
    deliverySiteEmail,
    deliverySiteLandlineNumber,
    deliverySiteMobileNumber,
  },
} = deliverySiteForm;

const initialValues = {
  [supplierName.name]: "",
  [supplierUEN.name]: "",
  [supplierType.name]: null,
  [supplierStatus.name]: null,
  [supplierAddress.name]: "",
  [supplierBuildingName.name]: "",
  [supplierPostalCode.name]: "",
  [supplierLandlineNumber.name]: "",
  [supplierMobileNumber.name]: "",
  [supplierEmail.name]: "",
  [supplierWebsite.name]: "",
  [supplierPreferredContact.name]: null,
  [contactPersons.name]: [],
  [deliverySites.name]: [
    {
      [deliverySiteName.name]: "GA Office",
      [siteSupervisorName.name]: "",
      [deliverySiteAddress.name]: "Ubi Ave 1, #01-51, Paya Ubi Industrial Park",
      [deliverySiteBlkBuildingName.name]: "Blk 53",
      [deliverySitePostalCode.name]: "408934",
      [deliverySiteEmail.name]: "",
      [deliverySiteLandlineNumber.name]: "",
      [deliverySiteMobileNumber.name]: ""
    },
    {
      [deliverySiteName.name]: "Onsite",
      [siteSupervisorName.name]: "",
      [deliverySiteAddress.name]: "",
      [deliverySiteBlkBuildingName.name]: "",
      [deliverySitePostalCode.name]: "",
      [deliverySiteEmail.name]: "",
      [deliverySiteLandlineNumber.name]: "",
      [deliverySiteMobileNumber.name]: ""
    },
    {
      [deliverySiteName.name]: "Self-collect",
      [siteSupervisorName.name]: "",
      [deliverySiteAddress.name]: "",
      [deliverySiteBlkBuildingName.name]: "",
      [deliverySitePostalCode.name]: "",
      [deliverySiteEmail.name]: "",
      [deliverySiteLandlineNumber.name]: "",
      [deliverySiteMobileNumber.name]: ""
    },
  ],
  [billingContacts.name]: [],
};

const {
  formField: {
    contactPersonName,
    contactPersonEmail,
    contactPersonLandlineNumber,
    contactPersonMobileNumber,
  },
} = contactPersonForm;

const contactPersonsInitialValues = {
  [contactPersonName.name]: "",
  [contactPersonEmail.name]: "",
  [contactPersonLandlineNumber.name]: "",
  [contactPersonMobileNumber.name]: "",
};


const deliverySitesInitialValues = {
  [deliverySiteName.name]: "",
  [siteSupervisorName.name]: "",
  [deliverySiteAddress.name]: "",
  [deliverySiteBlkBuildingName.name]: "",
  [deliverySitePostalCode.name]: "",
  [deliverySiteEmail.name]: "",
  [deliverySiteLandlineNumber.name]: "",
  [deliverySiteMobileNumber.name]: "",
};

const {
  formField: {
    billingContactName,
    billingContactEmail,
    billingContactLandlineNumber,
    billingContactMobileNumber,
  },
} = billingContactForm;

const billingContactsInitialValues = {
  [billingContactName.name]: "",
  [billingContactEmail.name]: "",
  [billingContactLandlineNumber.name]: "",
  [billingContactMobileNumber.name]: "",
};

export {
  initialValues as default,
  contactPersonsInitialValues,
  deliverySitesInitialValues,
  billingContactsInitialValues,
};
