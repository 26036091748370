import * as Yup from "yup";
import form, {
  stampAttachmentsForm,
} from "pages/settings/company/manage/schemas/form";

const {
  formField: { displayName, stampAttachments },
} = form;

const noValidation = Yup.object().shape({});

const validations = Yup.object().shape({
  [displayName.name]: Yup.string()
    .required(displayName.errorMsg)
    .max(200, displayName.invalidMsg),
  [stampAttachments.name]: Yup.array().max(20, stampAttachments.max20Msg),
});

const {
  formField: { stampAttachmentFile },
} = stampAttachmentsForm;

const stampAttachmentsValidation = Yup.object().shape({
  [stampAttachmentFile.name]: Yup.mixed()
    .nullable()
    .required(stampAttachmentFile.errorMsg)
    .test(
      "fileSize",
      stampAttachmentFile.invalidMsg,
      (value) => !value || (value && value.size <= 5 * 1024 * 1024)
    ),
});

export { validations as default, noValidation, stampAttachmentsValidation };
