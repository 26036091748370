import format from "date-fns/format";
import {
  itemServiceOptions,
  itemServiceTypeOptions,
  itemServiceTeamOptions,
  exclusionClauseOptions,
} from "schema/setups";

const defaultWorkorderNumber = "000000-00";

const defaultWorkStartDate = format(new Date(), "dd/MM/yyyy");

const defaultWorkingDays = "1";

const commencementTypeOptions = [
  "For Adhoc Service",
  "From Purchase Order",
  "From Quotation",
];
const newCommencementTypeOptions = ["For Adhoc Service", "From Purchase Order"];
const convertCommencementTypeOptions = ["From Quotation"];

const defaultCommencementType = commencementTypeOptions[0];

const defaultSalesorderDate = format(new Date(), "dd/MM/yyyy");

const defaultSalesorderNumber = "000000-00";

const salesorderMessageOptions = [
  "Thank you for inviting us to provide a quotation for the job as described above. We are pleased to submit herewith our quotation for your evaluation and confirmation as follows:-",
  "Thank you for asking us to provide a quotation for the job as described above. Xxx xxx xxx",
];
const defaultSalesorderMessage = salesorderMessageOptions[0];

const salesItemServiceOptions = itemServiceOptions;

const salesItemServiceTypeOptions = itemServiceTypeOptions;

const salesItemServiceTeamOptions = itemServiceTeamOptions;

const beginingStatementOptions = [
  `The above is quoted in Singapore Dollars.
The amount quoted is based on normal working hours: Mon to Fri 9:00am to 6:00pm, Sat 9:00am to 1:00pm unless stated.
Please notify us immediately in the event of non-compliance of terms and conditions.`,
];
const defaultBeginingStatement = beginingStatementOptions[0];

const paymentTermOptions = [
  `Thirty (30) days upon submission of service report.
An interest of 3% per month will be levied on the outstanding amount after due date.`,
];
const defaultPaymentTerm = paymentTermOptions[0];

const validityOptions = [`Thirty (30) days from date hereof.`];
const defaultValidity = validityOptions[0];

const noteOptions = [`Items not stated in quotation will be excluded.`];
const defaultNote = noteOptions[0];

const endingStatementOptions = [
  `We trust that the above will meet your requirement and should you require further information, please do not hesitate to contact the undersigned.`,
];
const defaultEndingStatement = endingStatementOptions[0];

const installationPeriodOptions = ["TBC", "Duration"];
const defaultInstallationPeriod = installationPeriodOptions[0];

const defaultExclusions = exclusionClauseOptions.map((option) => {
  return { exclusionClause: option };
});

const discountTypeOptions = ["Itemized", "Non-Itemized"];
const defaultDiscountType = discountTypeOptions[0];

const paymentTypeOptions = ["Non-progressive", "Progressive"];
const defaultPaymentType = paymentTypeOptions[0];

const gstPercentage = 7;

const defaultSalesorderColumns = [
  { key: "salesorderNumberText", name: "Sales Order" },
  { key: "salesorderDateText", name: "Sales Order Date" },
  { key: "workorderNumberText", name: "Work Order" },
  { key: "workStartDateText", name: "Work Start Date" },
  { key: "salesorderTitle", name: "Title" },
  { key: "workingDays", name: "Working Days" },
  { key: "customerName", name: "Customer Name" },
  { key: "deliverySiteName", name: "Delivery Site Name" },
  { key: "createdByText", name: "Created By" },
  { key: "modifiedByText", name: "Modified By" },
  { key: "salesAfterGstTotalText", name: "Final Amount Inclusive of GST" },
  { key: "approvedByText", name: "Approved By" },
  //{ key: "rejectedByText", name: "Rejected By",},
  { key: "verbalAgreementText", name: "Verbal Agreement" },
  { key: "pendingPOText", name: "Pending PO" },
  { key: "completedByText", name: "Completed By" },
  { key: "cancelledByText", name: "Cancelled By" },
  { key: "convertedByText", name: "Converted By" },
  { key: "status", name: "Status" },
  { key: "invoiceNumberText", name: "Invoice Number" },
  { key: "actions", name: "Actions" },
];

const columnOptions = [
  { key: "canApprove", name: "canApprove" },
  { key: "canReject", name: "canReject" },
  { key: "canDeposit", name: "canDeposit" },
  { key: "canDiscount", name: "canDiscount" },
  { key: "canAttach", name: "canAttach" },
  { key: "canComplete", name: "canComplete" },
  { key: "canConvert", name: "canConvert" },
  { key: "canCancel", name: "canCancel" },
  { key: "canPrint", name: "canPrint" },
  { key: "canEdit", name: "canEdit" },
  { key: "canDelete", name: "canDelete" },
  { key: "id", name: "ID" },
  { key: "salesorderNumberText", name: "Sales Order" },
  { key: "salesorderDateText", name: "Sales Order Date" },
  { key: "workorderNumberText", name: "Work Order" },
  { key: "workStartDateText", name: "Work Start Date" },
  { key: "workCompletionDateText", name: "Work Completion Date" },
  { key: "salesorderTitle", name: "Title" },
  { key: "workingDays", name: "Working Days" },
  { key: "customerName", name: "Customer Name" },
  { key: "customerAddress", name: "Customer Address" },
  { key: "contactPersonName", name: "Contact Person Name" },
  {
    key: "contactPersonLandlineNumber",
    name: "Contact Person Landline Number",
  },
  { key: "contactPersonMobileNumber", name: "Contact Person Mobile Number" },
  { key: "contactPersonEmail", name: "Contact Person Email" },
  { key: "deliverySiteName", name: "Delivery Site Name" },
  { key: "deliverySiteAddress", name: "Delivery Site Address" },
  { key: "installationPeriod", name: "Installation Period" },
  { key: "installationStartDateText", name: "Installation Start Date" },
  { key: "installationEndDateText", name: "Installation End Date" },
  { key: "paymentType", name: "Payment Type" },
  { key: "createdAtText", name: "Created At" },
  { key: "createdByText", name: "Created By" },
  { key: "modifiedAtText", name: "Modified At" },
  { key: "modifiedByText", name: "Modified By" },
  { key: "salesDiscountType", name: "Discount Type" },
  { key: "salesGrossTotal", name: "Total Amount Value" },
  { key: "salesGrossTotalText", name: "Total Amount" },
  { key: "salesDiscountTotalText", name: "Discount" },
  { key: "salesBeforeGstTotalText", name: "Total Amount Before GST" },
  { key: "salesAdditionalDiscountText", name: "Additional Discount" },
  { key: "discountReference", name: "Discount Reference" },
  { key: "salesNetBeforeGstTotalText", name: "Net Amount Before GST" },
  { key: "salesGstAmountText", name: "GST Amount" },
  { key: "salesAfterGstTotalText", name: "Final Amount Inclusive of GST" },
  { key: "salesDepositText", name: "Deposit" },
  { key: "depositReference", name: "Deposit Reference" },
  { key: "purchaseorderReference", name: "Purchase Order Reference" },
  { key: "quotationRefForInvoice", name: "Quotation Reference" },
  { key: "approvedAtText", name: "Approved At" },
  { key: "approvedByText", name: "Approved By" },
  { key: "commencementType", name: "Commencement Type" },
  { key: "commencementReference", name: "Commencement Reference" },
  { key: "quotationIdText", name: "Quotation Id" },
  { key: "quotationNumberText", name: "Quotation" },
  // { key: "rejectedAtText", name: "Rejected At" },
  // { key: "rejectedByText", name: "Rejected By" },
  { key: "followedUp", name: "Followed Up" },
  { key: "verbalAgreement", name: "Verbal Agreement State" },
  { key: "verbalAgreementText", name: "Verbal Agreement" },
  { key: "pendingPO", name: "Pending PO State" },
  { key: "pendingPOText", name: "Pending PO" },
  { key: "completedAtText", name: "Completed At" },
  { key: "completedByText", name: "Completed By" },
  { key: "completionReference", name: "Completion Reference" },
  { key: "cancelledAtText", name: "Cancelled At" },
  { key: "cancelledByText", name: "Cancelled By" },
  { key: "cancellationReference", name: "Cancellation Reference" },
  { key: "convertedAtText", name: "Converted At" },
  { key: "convertedByText", name: "Converted By" },
  { key: "status", name: "Status" },
  { key: "invoiceIdText", name: "Invoice Id" },
  { key: "invoiceNumberText", name: "Invoice Number" },
  { key: "deletedAtText", name: "Deleted At" },
  { key: "deletedByText", name: "Deleted By" },
  { key: "actions", name: "Actions" },
];

export {
  serviceTeamNameOptions,
  serviceTypeNameOptions,
  exclusionClauseOptions,
} from "schema/setups";
export {
  defaultWorkorderNumber,
  defaultWorkStartDate,
  defaultWorkingDays,
  commencementTypeOptions,
  newCommencementTypeOptions,
  convertCommencementTypeOptions,
  defaultCommencementType,
  salesorderMessageOptions,
  defaultSalesorderDate,
  defaultSalesorderNumber,
  defaultSalesorderMessage,
  salesItemServiceOptions,
  salesItemServiceTypeOptions,
  salesItemServiceTeamOptions,
  beginingStatementOptions,
  defaultBeginingStatement,
  paymentTermOptions,
  defaultPaymentTerm,
  validityOptions,
  defaultValidity,
  noteOptions,
  defaultNote,
  endingStatementOptions,
  defaultEndingStatement,
  installationPeriodOptions,
  defaultInstallationPeriod,
  defaultExclusions,
  discountTypeOptions,
  defaultDiscountType,
  paymentTypeOptions,
  defaultPaymentType,
  gstPercentage,
  columnOptions,
  defaultSalesorderColumns,
};
