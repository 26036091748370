import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Grid from "@mui/material/Grid";

import MDBox from "components/atoms/MDBox";
import MDButton from "components/atoms/MDButton";

import ErrorSnackbar from "components/molecules/Notifications/ErrorSnackbar";
import SuccessSnackbar from "components/molecules/Notifications/SuccessSnackbar";

import DashboardLayout from "components/templates/DashboardLayout";
import Topnav from "components/organisms/Topnav";
import DashboardFooter from "components/organisms/Footer/DashboardFooter";
import PermissionDenied from "components/organisms/PermissionDenied";
import InvalidOperation from "components/organisms/InvalidOperation";
import IncompleteOperation from "components/organisms/IncompleteOperation";
import SubmissionProgress from "components/organisms/SubmissionProgress";

// SalesOrder page components
import Commencement from "pages/salesorders/manage/components/Commencement";
import WorkDetails from "pages/salesorders/manage/components/WorkDetails";
import Deposit from "pages/salesorders/manage/components/Deposit";
import Discount from "pages/salesorders/manage/components/Discount";
import Completion from "pages/salesorders/manage/components/Completion";
import Cancellation from "pages/salesorders/manage/components/Cancellation";
import InternalReference from "pages/salesorders/manage/components/InternalReference";
import AuditTrail from "pages/salesorders/manage/components/AuditTrail";
import CustomerInformation from "pages/salesorders/manage/components/CustomerInformation";
import Subject from "pages/salesorders/manage/components/Subject";
import PriceQuote from "pages/salesorders/manage/components/PriceQuote";
import TermsAndConditions from "pages/salesorders/manage/components/TermsAndConditions";
import Sidenav from "pages/salesorders/manage/components/Sidenav";

import { Formik, Form } from "formik";
import form from "pages/salesorders/manage/schemas/form";

import { AllowedTo } from "react-abac";

import { useSalesOrderManager } from "pages/salesorders/hooks/useSalesOrderManager";

function SalesOrder() {
  const [isProgressing, setIsProgressing] = useState(false);
  // In "convert" mode, the salesorderId param is actually quotationId to be converted
  const { mode, salesorderId } = useParams();

  const {
    modeTitle,
    modeSubmit,
    modeFieldDisabled,
    defaultFieldReadOnly,
    modePermission,
    modeValidation,
    submitNew,
    submitEdit,
    submitAttach,
    submitDeposit,
    submitDiscount,
    submitComplete,
    submitCancel,
    submitDelete,
    response,
    dispatchError,
  } = useSalesOrderManager(mode, salesorderId);

  const [defaultFinalReadOnly, setDefaultFinalReadOnly] =
    useState(defaultFieldReadOnly);
  const [overrideFieldDisabled, setOverrideFieldDisabled] =
    useState(modeFieldDisabled);
  const [showCommencement, setShowCommencement] = useState(false);
  const [showDeposit, setShowDeposit] = useState(false);
  const [showDiscount, setShowDiscount] = useState(false);
  const [showCompletion, setShowCompletion] = useState(false);
  const [showCancellation, setShowCancellation] = useState(false);

  const navigate = useNavigate();
  const navigateAfter = (path, millisecond) => {
    return new Promise(() => {
      setTimeout(() => {
        navigate(path);
      }, millisecond);
    });
  };

  const { formId, formField } = form;

  const handleSubmit = async (values, actions) => {
    try {
      setIsProgressing(true);
      switch (mode) {
        case "new":
        case "convert":
          await submitNew(values);
          await navigateAfter("/salesorders/records", 3000);
          break;
        case "edit":
          await submitEdit(values);
          await navigateAfter("/salesorders/records", 3000);
          break;
        case "attach":
          await submitAttach(values);
          await navigateAfter("/salesorders/records", 3000);
          break;
        case "deposit":
          await submitDeposit(values);
          await navigateAfter("/salesorders/records", 3000);
          break;
        case "discount":
          await submitDiscount(values);
          await navigateAfter("/salesorders/records", 3000);
          break;
        case "complete":
          await submitComplete(values);
          await navigateAfter("/salesorders/records", 3000);
          break;
        case "cancel":
          await submitCancel(values);
          await navigateAfter("/salesorders/records", 3000);
          break;
        case "view":
          navigate("/salesorders/records");
          break;
        case "delete":
          await submitDelete();
          await navigateAfter("/salesorders/records", 3000);
          break;
        default:
          actions.setSubmitting(false);
          break;
      }
    } catch (err) {
      dispatchError(err);
      actions.setSubmitting(false);
    } finally {
      actions.setSubmitting(false);
      setIsProgressing(false);
    }
  };

  useEffect(() => {
    try {
      const readOnly =
        mode === "edit" && response.data.commencementType !== "From Quotation"
          ? false
          : defaultFieldReadOnly;
      setDefaultFinalReadOnly(readOnly);
    } catch (err) {
      dispatchError(err);
    }
  }, [
    defaultFieldReadOnly,
    dispatchError,
    mode,
    response.data.commencementType,
  ]);

  useEffect(() => {
    try {
      switch (mode) {
        case "new":
        case "convert":
        case "edit":
          setShowCommencement(true);
          setShowDeposit(false);
          setShowDiscount(false);
          setShowCompletion(false);
          setShowCancellation(false);
          setOverrideFieldDisabled(modeFieldDisabled);
          break;
        case "attach":
          setShowCommencement(true);
          setShowDeposit(false);
          setShowDiscount(false);
          setShowCompletion(false);
          setShowCancellation(false);
          setOverrideFieldDisabled(true);
          break;
        case "deposit":
          setShowCommencement(false);
          setShowDeposit(true);
          setShowDiscount(false);
          setShowCompletion(false);
          setShowCancellation(false);
          setOverrideFieldDisabled(true);
          break;
        case "discount":
          setShowCommencement(false);
          setShowDeposit(false);
          setShowDiscount(true);
          setShowCompletion(false);
          setShowCancellation(false);
          setOverrideFieldDisabled(true);
          break;
        case "complete":
          setShowCommencement(false);
          setShowDeposit(false);
          setShowDiscount(false);
          setShowCompletion(true);
          setShowCancellation(false);
          setOverrideFieldDisabled(true);
          break;
        case "cancel":
          setShowCommencement(false);
          setShowDeposit(false);
          setShowDiscount(false);
          setShowCompletion(false);
          setShowCancellation(true);
          setOverrideFieldDisabled(true);
          break;
        default:
          setShowCommencement(true);
          setShowDeposit(false);
          setShowDiscount(false);
          setShowCompletion(true);
          setShowCancellation(true);
          setOverrideFieldDisabled(modeFieldDisabled);
      }
    } catch (err) {
      dispatchError(err);
    }
  }, [dispatchError, mode, modeFieldDisabled]);

  return (
    <DashboardLayout>
      <Topnav title={modeTitle} />
      <AllowedTo perform={modePermission} no={() => <PermissionDenied />} />
      <MDBox pt={3}>
        {response.isPending && <SubmissionProgress />}
        {isProgressing && <SubmissionProgress />}
        {response.error && (
          <>
            {response.error?.name === "OperationInvalidError" && (
              <InvalidOperation />
            )}
            {response.error?.name === "OperationIncompleteError" && (
              <IncompleteOperation />
            )}
            <ErrorSnackbar
              title={modeTitle}
              dateTime=""
              message={response.error?.message}
              autoDismiss
            />
          </>
        )}
        {response.success && (
          <SuccessSnackbar
            title={modeTitle}
            dateTime=""
            message={response.success}
            autoDismiss
          />
        )}
        <MDBox>
          {response.error?.name !== "OperationInvalidError" && (
            <Grid container spacing={2}>
              <Grid item xs={12} lg={2}>
                <Sidenav mode={mode} />
              </Grid>

              <Grid item xs={12} lg={10}>
                <MDBox mb={3}>
                  <Formik
                    enableReinitialize
                    initialValues={response.data}
                    validationSchema={modeValidation}
                    onSubmit={handleSubmit}
                  >
                    {(formik) => (
                      <Form id={formId} autoComplete="off">
                        <Grid container spacing={3}>
                          <>
                            {showCommencement && (
                              <Grid item xs={12}>
                                <Commencement
                                  mainForm={formik}
                                  mainFormField={formField}
                                  modeDisabled={modeFieldDisabled}
                                  defaultReadOnly={defaultFinalReadOnly}
                                  mode={mode}
                                  dispatchMainError={dispatchError}
                                />
                              </Grid>
                            )}
                          </>
                          <>
                            {showDeposit && (
                              <Grid item xs={12}>
                                <Deposit
                                  mainForm={formik}
                                  mainFormField={formField}
                                  modeDisabled={modeFieldDisabled}
                                  defaultReadOnly={defaultFinalReadOnly}
                                  mode={mode}
                                  dispatchMainError={dispatchError}
                                />
                              </Grid>
                            )}
                          </>
                          <>
                            {showDiscount && (
                              <Grid item xs={12}>
                                <Discount
                                  mainForm={formik}
                                  mainFormField={formField}
                                  modeDisabled={modeFieldDisabled}
                                  defaultReadOnly={defaultFinalReadOnly}
                                  mode={mode}
                                  dispatchMainError={dispatchError}
                                />
                              </Grid>
                            )}
                          </>
                          <>
                            {showCompletion && (
                              <Grid item xs={12}>
                                <Completion
                                  mainForm={formik}
                                  mainFormField={formField}
                                  modeDisabled={modeFieldDisabled}
                                  mode={mode}
                                  dispatchMainError={dispatchError}
                                />
                              </Grid>
                            )}
                          </>
                          <>
                            {showCancellation && (
                              <Grid item xs={12}>
                                <Cancellation
                                  mainForm={formik}
                                  mainFormField={formField}
                                  modeDisabled={modeFieldDisabled}
                                  mode={mode}
                                  dispatchMainError={dispatchError}
                                />
                              </Grid>
                            )}
                          </>
                          <Grid item xs={12}>
                            <WorkDetails
                              mainForm={formik}
                              mainFormField={formField}
                              modeDisabled={overrideFieldDisabled}
                              mode={mode}
                              dispatchMainError={dispatchError}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <AuditTrail
                              mainForm={formik}
                              mainFormField={formField}
                              modeDisabled={overrideFieldDisabled}
                              mode={mode}
                              dispatchMainError={dispatchError}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <InternalReference
                              mainForm={formik}
                              mainFormField={formField}
                              modeDisabled={modeFieldDisabled}
                              defaultReadOnly={defaultFinalReadOnly}
                              dispatchMainError={dispatchError}
                            />
                          </Grid>

                          <Grid item xs={12}>
                            <CustomerInformation
                              mainForm={formik}
                              mainFormField={formField}
                              modeDisabled={modeFieldDisabled}
                              defaultReadOnly={defaultFinalReadOnly}
                              dispatchMainError={dispatchError}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Subject
                              mainForm={formik}
                              mainFormField={formField}
                              modeDisabled={modeFieldDisabled}
                              defaultReadOnly={defaultFinalReadOnly}
                              dispatchMainError={dispatchError}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <PriceQuote
                              mainForm={formik}
                              mainFormField={formField}
                              modeDisabled={modeFieldDisabled}
                              defaultReadOnly={defaultFinalReadOnly}
                              dispatchMainError={dispatchError}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TermsAndConditions
                              mainForm={formik}
                              mainFormField={formField}
                              modeDisabled={modeFieldDisabled}
                              defaultReadOnly={defaultFinalReadOnly}
                              dispatchMainError={dispatchError}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <MDBox
                              width="100%"
                              display="flex"
                              justifyContent="space-between"
                            >
                              <MDBox></MDBox>
                              <MDButton
                                disabled={formik.isSubmitting}
                                type="submit"
                                variant="gradient"
                                color="dark"
                              >
                                {modeSubmit}
                              </MDButton>
                            </MDBox>
                          </Grid>
                        </Grid>
                      </Form>
                    )}
                  </Formik>
                </MDBox>
              </Grid>
            </Grid>
          )}
        </MDBox>
      </MDBox>
      <DashboardFooter />
    </DashboardLayout>
  );
}

export default SalesOrder;
