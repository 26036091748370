import PropTypes from "prop-types";

import Grid from "@mui/material/Grid";

import MDBox from "components/atoms/MDBox";
import MDTypography from "components/atoms/MDTypography";

import FormTextFieldFast from "components/molecules/Formik/FormTextFieldFast";
import FormAutocompleteFast from "components/molecules/Formik/FormAutocompleteFast";

import {
  supplierTypeOptions,
  supplierStatusOptions,
  supplierPreferredContactOptions,
} from "pages/suppliers/manage/schemas/setups";
function SupplierParticulars({ mainForm, mainFormField, modeDisabled }) {
  const {
    supplierName,
    supplierUEN,
    supplierAddress,
    supplierType,
    supplierStatus,
    supplierBuildingName,
    supplierPostalCode,
    supplierLandlineNumber,
    supplierMobileNumber,
    supplierEmail,
    supplierWebsite,
    supplierPreferredContact,
  } = mainFormField;

  return (
    <MDBox>
      <MDBox lineHeight={0}>
        <MDTypography variant="h5">Supplier Company Particulars</MDTypography>
        <MDTypography variant="body3" color="text">
          Please fill in Supplier details
        </MDTypography>
      </MDBox>
      <MDBox mt={2}>
        <Grid container mb={1} columnSpacing={{ xs: 1, sm: 3 }}>
          <Grid item xs={12} sm={6}>
            <FormTextFieldFast
              form={mainForm}
              field={supplierName}
              variant="standard"
              disabled={modeDisabled}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormAutocompleteFast
              form={mainForm}
              field={supplierType}
              variant="standard"
              options={supplierTypeOptions}
              disabled={modeDisabled}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTextFieldFast
              form={mainForm}
              field={supplierUEN}
              variant="standard"
              disabled={modeDisabled}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormAutocompleteFast
              form={mainForm}
              field={supplierStatus}
              variant="standard"
              options={supplierStatusOptions}
              disabled={modeDisabled}
            />
          </Grid>
        </Grid>
        <Grid container mb={1} columnSpacing={{ xs: 1, sm: 3 }}>
          <Grid item xs={12} sm={12}>
            <FormTextFieldFast
              form={mainForm}
              field={supplierAddress}
              variant="standard"
              disabled={modeDisabled}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTextFieldFast
              form={mainForm}
              field={supplierBuildingName}
              variant="standard"
              disabled={modeDisabled}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTextFieldFast
              form={mainForm}
              field={supplierPostalCode}
              variant="standard"
              disabled={modeDisabled}
            />
          </Grid>
        </Grid>
        <Grid container mb={1} columnSpacing={{ xs: 1, sm: 3 }}>
          <Grid item xs={12} sm={6}>
            <FormTextFieldFast
              form={mainForm}
              field={supplierLandlineNumber}
              variant="standard"
              disabled={modeDisabled}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTextFieldFast
              form={mainForm}
              field={supplierMobileNumber}
              variant="standard"
              disabled={modeDisabled}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTextFieldFast
              form={mainForm}
              field={supplierEmail}
              variant="standard"
              disabled={modeDisabled}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTextFieldFast
              form={mainForm}
              field={supplierWebsite}
              variant="standard"
              disabled={modeDisabled}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormAutocompleteFast
              form={mainForm}
              field={supplierPreferredContact}
              variant="standard"
              options={supplierPreferredContactOptions}
              disabled={modeDisabled}
            />
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

SupplierParticulars.propTypes = {
  mainForm: PropTypes.object.isRequired,
  mainFormField: PropTypes.object.isRequired,
  modeDisabled: PropTypes.bool,
  dispatchMainError: PropTypes.func,
};

export default SupplierParticulars;
