import * as Yup from "yup";
import form, {
  paymentModesForm,
  serviceTeamsForm,
  serviceTypesForm,
  invoicingAttachmentsForm,
  receiptingAttachmentsForm,
  closingAttachmentsForm,
  exclusionsForm,
  invoiceItemsForm,
} from "pages/invoices/manage/schemas/form";

import {
  invoicingTypeOptions,
  discountTypeOptions,
  paymentTypeOptions,
} from "pages/invoices/manage/schemas/setups";

import parse from "date-fns/parse";

const {
  formField: {
    invoiceTerms,
    reminderIntervalDays,
    reminderFromName,
    reminderFromEmail,
    reminderToEmails,
    reminderCcEmails,
    reminderBccEmails,
    reminderEmailSubject,
    reminderEmailContent,
    invoicingType,
    invoiceReceiptingDate,
    //invoiceClosingDate,
    receiptingReference,
    //closingReference,
    paymentModes,
    invoiceRemarks,
    serviceTeams,
    serviceTypes,
    paymentType,
    invoiceDate,
    invoiceNumber,
    billingContactName,
    billingContactLandlineNumber,
    billingContactMobileNumber,
    billingContactEmail,
    customerId,
    customerName,
    customerAddress,
    invoiceItems,
    invoiceDiscountType,
    invoiceGrossTotal,
    invoiceDiscountTotal,
    invoiceBeforeGstTotal,
    invoiceNetBeforeGstTotal,
    invoiceGstAmount,
    invoiceAfterGstTotal,
    invoiceDeposit,
    invoiceAdditionalDiscount,
    invoiceBalanceDue,
    // invoicingAttachments,
    receiptingAttachments,
    closingAttachments,
  },
} = form;

const noValidation = Yup.object().shape({});

const validations = Yup.object().shape({
  [invoiceTerms.name]: Yup.string()
    .required(invoiceTerms.errorMsg)
    .matches("^\\d{1,4}$", invoiceTerms.invalidMsg),
  [reminderIntervalDays.name]: Yup.string()
    .required(invoiceTerms.errorMsg)
    .matches("^\\d{1,4}$", invoiceTerms.invalidMsg),
  [reminderFromName.name]: Yup.string()
    .required(reminderFromName.errorMsg)
    .max(200, reminderFromName.invalidMsg),
  [reminderFromEmail.name]: Yup.string()
    .required(reminderFromEmail.errorMsg)
    .max(200, reminderFromEmail.invalidMsg)
    .email(reminderFromEmail.invalidMsg),
  [reminderToEmails.name]: Yup.array()
    // .min(1, reminderToEmails.min1Msg)
    .max(20, reminderToEmails.max20Msg),
  [reminderCcEmails.name]: Yup.array()
    // .min(1, reminderCcEmails.min1Msg)
    .max(20, reminderCcEmails.max20Msg),
  [reminderBccEmails.name]: Yup.array()
    // .min(1, reminderBccEmails.min1Msg)
    .max(20, reminderBccEmails.max20Msg),
  [reminderEmailSubject.name]: Yup.string()
    .required(reminderEmailSubject.errorMsg)
    .max(200, reminderEmailSubject.invalidMsg),
  [reminderEmailContent.name]: Yup.string()
    .required(reminderEmailContent.errorMsg)
    .max(20000, reminderEmailContent.invalidMsg),

  [invoicingType.name]: Yup.string().matches(
    `(${invoicingTypeOptions.join("|")})`,
    {
      excludeEmptyString: true,
    }
  ),
  [paymentModes.name]: Yup.array()
    // .min(1, paymentModes.min1Msg)
    .max(20, paymentModes.max20Msg),
  [invoiceRemarks.name]: Yup.string().max(200, invoiceRemarks.invalidMsg),
  [serviceTeams.name]: Yup.array()
    // .min(1, serviceTeams.min1Msg)
    .max(20, serviceTeams.max20Msg),
  [serviceTypes.name]: Yup.array()
    // .min(1, serviceTypes.min1Msg)
    .max(20, serviceTypes.max20Msg),
  [paymentType.name]: Yup.string().matches(
    `(${paymentTypeOptions.join("|")})`,
    {
      excludeEmptyString: true,
    }
  ),
  [invoiceDate.name]: Yup.date()
    .transform((value, originalValue) => {
      return originalValue === null
        ? null
        : parse(originalValue, "dd/MM/yyyy", new Date());
    })
    .required(invoiceDate.errorMsg)
    .typeError(invoiceDate.invalidMsg),
  [invoiceNumber.name]: Yup.string()
    .required(invoiceNumber.errorMsg)
    .matches("(^\\d{6}-\\d{2}$|^---------$)", invoiceNumber.invalidMsg),
  [billingContactName.name]: Yup.string()
    .required(billingContactName.errorMsg)
    .max(200, billingContactName.invalidMsg),
  [billingContactLandlineNumber.name]: Yup.string()
    // .when(billingContactMobileNumber.name, {
    //   is: (billingContactMobileNumberValue) =>
    //     !billingContactMobileNumberValue?.length,
    //   then: Yup.string().required(billingContactLandlineNumber.errorMsg),
    // })
    .matches(
      "^[+]?([0-9]*[\\-\\s.])*([0-9]*[(]+[0-9]{1,3}[)])*([0-9]*[\\-\\s.])*[0-9]{4,15}$",
      billingContactLandlineNumber.invalidMsg
    ),
  [billingContactMobileNumber.name]: Yup.string().matches(
    "^[+]?([0-9]*[\\-\\s.])*([0-9]*[(]+[0-9]{1,3}[)])*([0-9]*[\\-\\s.])*[0-9]{4,15}$",
    billingContactMobileNumber.invalidMsg
  ),
  [billingContactEmail.name]: Yup.string()
    // .required(billingContactEmail.errorMsg)
    .max(200, billingContactEmail.invalidMsg)
    .email(billingContactEmail.invalidMsg),
  [customerId.name]: Yup.string()
    .required(customerId.errorMsg)
    .max(200, customerId.invalidMsg),
  [customerName.name]: Yup.string()
    .required(customerName.errorMsg)
    .max(200, customerName.invalidMsg),
  [customerAddress.name]: Yup.string()
    .required(customerAddress.errorMsg)
    .max(200, customerAddress.invalidMsg),

  [invoiceItems.name]: Yup.array()
    .min(1, invoiceItems.min1Msg)
    .max(100, invoiceItems.max100Msg),
  [invoiceDiscountType.name]: Yup.string().matches(
    `(${discountTypeOptions.join("|")})`,
    {
      excludeEmptyString: true,
    }
  ),
  [invoiceGrossTotal.name]: Yup.string()
    .required(invoiceGrossTotal.errorMsg)
    .matches("^\\d*(\\.\\d{1,2})?$", invoiceGrossTotal.invalidMsg),
  [invoiceDiscountTotal.name]: Yup.string()
    .required(invoiceDiscountTotal.errorMsg)
    .matches("^\\d*(\\.\\d{1,2})?$", invoiceDiscountTotal.invalidMsg),
  [invoiceBeforeGstTotal.name]: Yup.string()
    .required(invoiceBeforeGstTotal.errorMsg)
    .matches("^\\d*(\\.\\d{1,2})?$", invoiceBeforeGstTotal.invalidMsg),
  [invoiceNetBeforeGstTotal.name]: Yup.string()
    .required(invoiceNetBeforeGstTotal.errorMsg)
    .matches("^\\d*(\\.\\d{1,2})?$", invoiceNetBeforeGstTotal.invalidMsg),
  [invoiceGstAmount.name]: Yup.string()
    .required(invoiceGstAmount.errorMsg)
    .matches("^\\d*(\\.\\d{1,2})?$", invoiceGstAmount.invalidMsg),
  [invoiceAfterGstTotal.name]: Yup.string()
    .required(invoiceAfterGstTotal.errorMsg)
    .matches("^\\d*(\\.\\d{1,2})?$", invoiceAfterGstTotal.invalidMsg),
  [invoiceDeposit.name]: Yup.string()
    .optional()
    .matches("^\\d*(\\.\\d{1,2})?$", invoiceDeposit.invalidMsg),
  [invoiceAdditionalDiscount.name]: Yup.string()
    .optional()
    .matches("^\\d*(\\.\\d{1,3})?$", invoiceAdditionalDiscount.invalidMsg),
  [invoiceBalanceDue.name]: Yup.string()
    .optional()
    .matches("^\\d*(\\.\\d{1,2})?$", invoiceBalanceDue.invalidMsg),
  // [invoicingAttachments.name]: Yup.array()
  //   .max(20, invoicingAttachments.max20Msg)
  //   .when(invoicingType.name, {
  //     is: (invoicingTypeValue) =>
  //       invoicingTypeValue === "From Purchase Order" ||
  //       invoicingTypeValue === "From Completed Sales Order",
  //     then: Yup.array().min(1, invoicingAttachments.min1Msg),
  //   }),
  [receiptingAttachments.name]: Yup.array()
    .when([invoiceReceiptingDate.name, receiptingReference.name], {
      is: (invoiceReceiptingDateValue, receiptingReferenceValue) => {
        return invoiceReceiptingDateValue && !receiptingReferenceValue?.length;
      },
      then: Yup.array().min(1, receiptingAttachments.errorMsg),
    })
    .max(20, receiptingAttachments.max20Msg),
  [closingAttachments.name]: Yup.array()
    // .when([invoiceClosingDate.name, closingReference.name], {
    //   is: (invoiceClosingDateValue, closingReferenceValue) => {
    //     return invoiceClosingDateValue && !closingReferenceValue?.length;
    //   },
    //   then: Yup.array().min(1, closingAttachments.errorMsg),
    // })
    .max(20, closingAttachments.max20Msg),
});

const {
  formField: { paymentModeName },
} = paymentModesForm;

const paymentModesValidation = Yup.object().shape({
  [paymentModeName.name]: Yup.string()
    .required(paymentModeName.errorMsg)
    .max(200, paymentModeName.invalidMsg)
    .nullable(true),
});

const {
  formField: { serviceTeamName },
} = serviceTeamsForm;

const serviceTeamsValidation = Yup.object().shape({
  [serviceTeamName.name]: Yup.string()
    .required(serviceTeamName.errorMsg)
    .max(200, serviceTeamName.invalidMsg)
    .nullable(true),
});

const {
  formField: { serviceTypeName },
} = serviceTypesForm;

const serviceTypesValidation = Yup.object().shape({
  [serviceTypeName.name]: Yup.string()
    .required(serviceTypeName.errorMsg)
    .max(200, serviceTypeName.invalidMsg)
    .nullable(true),
});

const {
  formField: { invoicingAttachmentFile },
} = invoicingAttachmentsForm;

const invoicingAttachmentsValidation = Yup.object().shape({
  [invoicingAttachmentFile.name]: Yup.mixed()
    .nullable()
    .required(invoicingAttachmentFile.errorMsg)
    .test(
      "fileSize",
      invoicingAttachmentFile.invalidMsg,
      (value) => !value || (value && value.size <= 5 * 1024 * 1024)
    ),
});

const {
  formField: { receiptingAttachmentFile },
} = receiptingAttachmentsForm;

const receiptingAttachmentsValidation = Yup.object().shape({
  [receiptingAttachmentFile.name]: Yup.mixed()
    .nullable()
    .required(receiptingAttachmentFile.errorMsg)
    .test(
      "fileSize",
      receiptingAttachmentFile.invalidMsg,
      (value) => !value || (value && value.size <= 5 * 1024 * 1024)
    ),
});

const {
  formField: { closingAttachmentFile },
} = closingAttachmentsForm;

const closingAttachmentsValidation = Yup.object().shape({
  [closingAttachmentFile.name]: Yup.mixed()
    .nullable()
    .required(closingAttachmentFile.errorMsg)
    .test(
      "fileSize",
      closingAttachmentFile.invalidMsg,
      (value) => !value || (value && value.size <= 5 * 1024 * 1024)
    ),
});

const {
  formField: { exclusionClause },
} = exclusionsForm;

const exclusionsValidation = Yup.object().shape({
  [exclusionClause.name]: Yup.string()
    .required(exclusionClause.errorMsg)
    .max(200, exclusionClause.invalidMsg)
    .nullable(true),
});

const {
  formField: {
    itemNo,
    itemSubNo,
    itemServiceType,
    itemDescription,
    itemQuantity,
    itemUnitCost,
    itemGrossAmount,
    itemDiscount,
    itemNetAmount,
  },
} = invoiceItemsForm;

const invoiceItemsValidation = Yup.object().shape({
  [itemNo.name]: Yup.string().matches("^\\d{1,2}$", itemNo.invalidMsg),
  [itemSubNo.name]: Yup.string().matches(
    "^\\d{1,2}\\.\\d{1,2}$",
    itemSubNo.invalidMsg
  ),
  [itemServiceType.name]: Yup.string()
    .required(itemServiceType.errorMsg)
    .max(2000, itemServiceType.invalidMsg),
  [itemDescription.name]: Yup.string()
    .required(itemDescription.errorMsg)
    .max(2000, itemDescription.invalidMsg),
  [itemQuantity.name]: Yup.string()
    .required(itemQuantity.errorMsg)
    .matches("^\\d{1,4}$", itemQuantity.invalidMsg),
  [itemUnitCost.name]: Yup.string()
    .required(itemUnitCost.errorMsg)
    .matches("^\\d*(\\.\\d{1,2})?$", itemUnitCost.invalidMsg),
  [itemGrossAmount.name]: Yup.string()
    .required(itemGrossAmount.errorMsg)
    .matches("^\\d*(\\.\\d{1,2})?$", itemGrossAmount.invalidMsg),
  [itemDiscount.name]: Yup.string()
    .required(itemDiscount.errorMsg)
    .matches("^\\d*(\\.\\d{1,3})?$", itemDiscount.invalidMsg),
  [itemNetAmount.name]: Yup.string()
    .required(itemNetAmount.errorMsg)
    .matches("^\\d*(\\.\\d{1,2})?$", itemNetAmount.invalidMsg),
});

export {
  validations as default,
  noValidation,
  paymentModesValidation,
  serviceTeamsValidation,
  serviceTypesValidation,
  invoicingAttachmentsValidation,
  receiptingAttachmentsValidation,
  closingAttachmentsValidation,
  exclusionsValidation,
  invoiceItemsValidation,
};
