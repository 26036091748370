import PropTypes from "prop-types";

import { useEffect, useCallback } from "react";

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";

import MDBox from "components/atoms/MDBox";
import MDTypography from "components/atoms/MDTypography";

import FormAttachments from "components/molecules/Formik/FormAttachments";
import FormTextFieldFast from "components/molecules/Formik/FormTextFieldFast";
import { discountAttachmentsForm } from "pages/salesorders/manage/schemas/form";
import { discountAttachmentsInitialValues } from "pages/salesorders/manage/schemas/initialValues";
import { discountAttachmentsValidation } from "pages/salesorders/manage/schemas/validations";

import { gstPercentage } from "pages/salesorders/manage/schemas/setups";

import currency from "currency.js";

function Discount({
  mainForm,
  mainFormField,
  modeDisabled,
  mode,
  dispatchMainError,
}) {
  const { values: mainFormValues, setFieldValue: setMainFormFieldValue } =
    mainForm;
  const {
    salesAdditionalDiscount,
    discountReference,
    discountAttachments,
    salesNetBeforeGstTotal,
    salesGstAmount,
    salesAfterGstTotal,
  } = mainFormField;

  const handleCalculateTotalPrice = useCallback(
    (salesAdditionalDiscount = "0.00") => {
      try {
        const postSalesDiscount = currency(salesAdditionalDiscount, {
          symbol: "",
          separator: "",
        });

        const beforeGstTotal = currency(mainFormValues.salesBeforeGstTotal, {
          symbol: "",
          separator: "",
        });

        const netBeforeGstTotal = beforeGstTotal.subtract(postSalesDiscount);

        const gstAmount = beforeGstTotal
          .subtract(postSalesDiscount)
          .multiply(gstPercentage / 100);

        const afterGstTotal = beforeGstTotal
          .subtract(postSalesDiscount)
          .add(gstAmount);

        const formattedNetBeforeGstTotal = netBeforeGstTotal.format();
        const formattedGstAmount = gstAmount.format();
        const formattedAfterGstTotal = afterGstTotal.format();
        setMainFormFieldValue(
          salesNetBeforeGstTotal.name,
          formattedNetBeforeGstTotal
        );
        setMainFormFieldValue(salesGstAmount.name, formattedGstAmount);
        setMainFormFieldValue(salesAfterGstTotal.name, formattedAfterGstTotal);
      } catch (err) {
        dispatchMainError(err);
      }
    },
    [
      dispatchMainError,
      mainFormValues.salesBeforeGstTotal,
      salesAfterGstTotal.name,
      salesGstAmount.name,
      salesNetBeforeGstTotal.name,
      setMainFormFieldValue,
    ]
  );

  useEffect(() => {
    handleCalculateTotalPrice(mainFormValues.salesAdditionalDiscount);
  }, [handleCalculateTotalPrice, mainFormValues.salesAdditionalDiscount]);

  // destructure from Attachments Form for current section
  const {
    formId: discountAttachmentsFormId,
    formField: discountAttachmentsFormField,
  } = discountAttachmentsForm;
  const { discountAttachmentFile, attachmentName, attachmentURL } =
    discountAttachmentsFormField;

  return (
    <Card id="discount" sx={{ overflow: "visible" }}>
      <MDBox m={3}>
        <MDBox mb={3}>
          <MDTypography variant="h5">Discount</MDTypography>
        </MDBox>
        <MDBox>
          <Grid
            container
            rowSpacing={{ xs: 1, sm: 2 }}
            columnSpacing={{ xs: 1, sm: 3 }}
          >
            <Grid item xs={12} sm={2}>
              <MDTypography variant="body3" fontWeight="bold">
                {salesAdditionalDiscount.label}
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormTextFieldFast
                form={mainForm}
                field={salesAdditionalDiscount}
                variant="standard"
                disabled={modeDisabled}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                onChange={(e) => {
                  const newValue = e.target.value;
                  handleCalculateTotalPrice(newValue);
                  setMainFormFieldValue(salesAdditionalDiscount.name, newValue);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}></Grid>
            <Grid item xs={12} sm={2}>
              <MDTypography variant="body3" fontWeight="bold">
                {discountReference.label}
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormTextFieldFast
                multiline
                form={mainForm}
                field={discountReference}
                variant="standard"
                disabled={modeDisabled}
              />
            </Grid>
            <Grid item xs={12} sm={4}></Grid>

            <Grid item xs={12} sm={2}>
              <MDTypography variant="body3" fontWeight="bold">
                {discountAttachments.label}
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormAttachments
                showTitle={false}
                form={mainForm}
                field={discountAttachments}
                max={20}
                disabled={modeDisabled}
                dispatchError={dispatchMainError}
                itemFormId={discountAttachmentsFormId}
                itemInitialValues={discountAttachmentsInitialValues}
                itemValidation={discountAttachmentsValidation}
                itemFileField={discountAttachmentFile}
                itemKeyField={attachmentURL}
                itemDisplayFields={[attachmentName]}
              />
            </Grid>
            <Grid item xs={12} sm={4}></Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Card>
  );
}

Discount.propTypes = {
  mainForm: PropTypes.object.isRequired,
  mainFormField: PropTypes.object.isRequired,
  modeDisabled: PropTypes.bool.isRequired,
  mode: PropTypes.string.isRequired,
};

export default Discount;
