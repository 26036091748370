import PropTypes from "prop-types";

import { useState, useEffect } from "react";

import Card from "@mui/material/Card";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";

import MDBox from "components/atoms/MDBox";
import MDButton from "components/atoms/MDButton";
import MDTypography from "components/atoms/MDTypography";
import FormField from "components/molecules/FormField";

import DebounceAutocomplete from "components/molecules/Debounce/DebounceAutocomplete";
import DebounceTextField from "components/molecules/Debounce/DebounceTextField";

import { Formik } from "formik";

import FormRadioGroup from "components/molecules/Formik/FormRadioGroup";
import FormAutocompleteFast from "components/molecules/Formik/FormAutocompleteFast";
import FormTextFieldFast from "components/molecules/Formik/FormTextFieldFast";
import FormTextField from "components/molecules/Formik/FormTextField";

import { purchaseorderItemsForm } from "pages/purchaseorders/manage/schemas/form";
import { purchaseorderItemsInitialValues } from "pages/purchaseorders/manage/schemas/initialValues";
import { purchaseorderItemsValidation } from "pages/purchaseorders/manage/schemas/validations";

import currency from "currency.js";

import {
  discountTypeOptions,
  defaultDiscountType,
  gstPercentage,
  gstOptions,
  defaultGstOption,
} from "pages/purchaseorders/manage/schemas/setups";

function PriceQuote({
  mainForm,
  mainFormField,
  modeDisabled,
  dispatchMainError,
}) {
  const { values: mainFormValues, setFieldValue: setMainFormFieldValue } =
    mainForm;
  const {
    purchaseorderItems,
    purchaseorderDiscountType,
    purchaseorderGrossTotal,
    purchaseorderDiscountTotal,
    purchaseorderBeforeGstTotal,
    purchaseorderGstAmount,
    purchaseorderAfterGstTotal,
    purchaseorderFinalTotal,
    gstOption,
  } = mainFormField;

  // destructure from Quote Items Form for current section
  const {
    formId: purchaseorderItemsFormId,
    formField: purchaseorderItemsFormField,
  } = purchaseorderItemsForm;
  const {
    itemOrdinal,
    itemNo,
    itemSubNo,
    itemServiceType,
    itemDescription,
    itemQuantity,
    itemUnitCost,
    itemGrossAmount,
    itemDiscount,
    itemNetAmount,
  } = purchaseorderItemsFormField;

  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [showAddForm, setShowAddForm] = useState(false);
  const [itemizedDiscount, setItemizedDiscount] = useState(true);
  const [discountTotalType, setDiscountTotalType] = useState("$");
  const [discountTotalValue, setDiscountTotalValue] = useState("");
  const [purchaseorderItemStatementOptions, setQuoteItemStatementOptions] =
    useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [withGst, setGstOption] = useState(true);

  useEffect(() => {
    try {
      mainFormValues.purchaseorderDiscountType === "Itemized" &&
        setItemizedDiscount(true);
      mainFormValues.purchaseorderDiscountType === "Non-Itemized" &&
        setItemizedDiscount(false);

      const withGST = mainFormValues.gstOption === defaultGstOption;
      if (withGST) {
        setMainFormFieldValue(
          purchaseorderFinalTotal.name,
          mainFormValues.purchaseorderAfterGstTotal
        );
      } else {
        setMainFormFieldValue(
          purchaseorderFinalTotal.name,
          mainFormValues.purchaseorderBeforeGstTotal
        );
      }

      setQuoteItemStatementOptions([]);
    } catch (err) {
      dispatchMainError(err);
    }
  }, [
    dispatchMainError,
    mainFormValues.purchaseorderDiscountType,
    mainFormValues.purchaseorderAfterGstTotal,
    mainFormValues.purchaseorderBeforeGstTotal,
    mainFormValues.purchaseorderFinalTotal,
    mainFormValues.gstOption,
    purchaseorderFinalTotal.name,
    setMainFormFieldValue,
  ]);

  const handleTableRowClick = (index, setSectionFormFieldValue) => {
    try {
      !modeDisabled && !isEditing && setSelectedIndex(index);
    } catch (err) {
      dispatchMainError(err);
    }
  };

  const handleAddQuoteItem = () => {
    try {
      setSelectedIndex(-1);
      setShowAddForm(true);
      setIsEditing(true);
    } catch (err) {
      dispatchMainError(err);
    }
  };

  const publishQuoteItem = (index, setSectionFormFieldValue) => {
    try {
      const purchaseorderItem = mainFormValues.purchaseorderItems[index];
      setSectionFormFieldValue(itemOrdinal.name, purchaseorderItem.itemOrdinal);
      setSectionFormFieldValue(itemNo.name, purchaseorderItem.itemNo);
      setSectionFormFieldValue(itemSubNo.name, purchaseorderItem.itemSubNo);
      setSectionFormFieldValue(
        itemServiceType.name,
        purchaseorderItem.itemServiceType
      );
      setSectionFormFieldValue(
        itemDescription.name,
        purchaseorderItem.itemDescription
      );
      setSectionFormFieldValue(
        itemQuantity.name,
        purchaseorderItem.itemQuantity
      );
      setSectionFormFieldValue(
        itemUnitCost.name,
        purchaseorderItem.itemUnitCost
      );
      setSectionFormFieldValue(
        itemGrossAmount.name,
        purchaseorderItem.itemGrossAmount
      );
      setSectionFormFieldValue(
        itemDiscount.name,
        purchaseorderItem.itemDiscount
      );
      setSectionFormFieldValue(
        itemNetAmount.name,
        purchaseorderItem.itemNetAmount
      );
    } catch (err) {
      dispatchMainError(err);
    }
  };

  const handleRemoveQuoteItem = (index) => {
    try {
      mainFormValues.purchaseorderItems.splice(selectedIndex, 1);
      setMainFormFieldValue(
        purchaseorderItems.name,
        mainFormValues.purchaseorderItems
      );

      handleCalculateTotalPrice(
        mainFormValues.purchaseorderItems,
        mainFormValues.purchaseorderDiscountTotal
      );
    } catch (err) {
      dispatchMainError(err);
    }
  };

  const handleEditQuoteItem = (index, setSectionFormFieldValue) => {
    try {
      publishQuoteItem(index, setSectionFormFieldValue);
      setIsEditing(true);
    } catch (err) {
      dispatchMainError(err);
    }
  };

  const handleCancelEditingQuoteItem = (resetForm) => {
    try {
      resetForm({ values: purchaseorderItemsInitialValues });
      setSelectedIndex(-1);
      setShowAddForm(false);
      setIsEditing(false);
    } catch (err) {
      dispatchMainError(err);
    }
  };

  const handleMoveUpQuoteItem = (event, index) => {
    try {
      event.stopPropagation();
      !isEditing && setSelectedIndex(index - 1);
      setSelectedIndex(index - 1);
      const items = mainFormValues.purchaseorderItems;
      [items[index - 1].itemOrdinal, items[index].itemOrdinal] = [
        index,
        index - 1,
      ];
      [items[index - 1], items[index]] = [items[index], items[index - 1]];
      setMainFormFieldValue(purchaseorderItems.name, items);
    } catch (err) {
      dispatchMainError(err);
    }
  };

  const handleMoveDownQuoteItem = (event, index) => {
    try {
      event.stopPropagation();
      !isEditing && setSelectedIndex(index + 1);
      const items = mainFormValues.purchaseorderItems;
      [items[index].itemOrdinal, items[index + 1].itemOrdinal] = [
        index + 1,
        index,
      ];
      [items[index], items[index + 1]] = [items[index + 1], items[index]];
      setMainFormFieldValue(purchaseorderItems.name, items);
    } catch (err) {
      dispatchMainError(err);
    }
  };

  const handleCalculateDiscountTotal = (
    discountTotalType,
    discountTotalValue
  ) => {
    try {
      const grossTotal = currency(mainFormValues.purchaseorderGrossTotal, {
        symbol: "",
        separator: "",
      });
      let discountTotalAmount = "0.00";
      if (discountTotalValue && !isNaN(discountTotalValue)) {
        const discountTotal = currency(discountTotalValue, {
          symbol: "",
          separator: "",
        });
        if (discountTotalType === "$") {
          discountTotalAmount = discountTotal.format();
          setMainFormFieldValue(
            purchaseorderDiscountTotal.name,
            discountTotalAmount
          );
        }
        if (discountTotalType === "%") {
          discountTotalAmount = grossTotal
            .multiply(discountTotal / 100)
            .format();
          setMainFormFieldValue(
            purchaseorderDiscountTotal.name,
            discountTotalAmount
          );
        }
      }
      return discountTotalAmount;
    } catch (err) {
      dispatchMainError(err);
    }
  };

  const handleCalculateTotalPrice = (purchaseorderItems, discount = "0.00") => {
    try {
      if (itemizedDiscount) {
        const grossTotal = purchaseorderItems.reduce(
          (total, item) => {
            return currency(item.itemGrossAmount, {
              symbol: "",
              separator: "",
            }).add(total);
          },
          currency(0, {
            symbol: "",
            separator: "",
          })
        );
        const beforeGstTotal = purchaseorderItems.reduce(
          (total, item) => {
            return currency(item.itemNetAmount, {
              symbol: "",
              separator: "",
            }).add(total);
          },
          currency(0, {
            symbol: "",
            separator: "",
          })
        );
        const discountTotal = grossTotal.subtract(beforeGstTotal);
        const gstAmount = beforeGstTotal.multiply(gstPercentage / 100);
        const afterGstTotal = beforeGstTotal.add(gstAmount);

        const formattedGrossTotal = grossTotal.format();
        const formattedDiscountTotal = discountTotal.format();
        const formattedBeforeGstTotal = beforeGstTotal.format();
        const formattedGstAmount = gstAmount.format();
        const formattedAfterGstTotal = afterGstTotal.format();
        setMainFormFieldValue(
          purchaseorderGrossTotal.name,
          formattedGrossTotal
        );
        setMainFormFieldValue(
          purchaseorderDiscountTotal.name,
          formattedDiscountTotal
        );
        setMainFormFieldValue(
          purchaseorderBeforeGstTotal.name,
          formattedBeforeGstTotal
        );
        setMainFormFieldValue(purchaseorderGstAmount.name, formattedGstAmount);
        setMainFormFieldValue(
          purchaseorderAfterGstTotal.name,
          formattedAfterGstTotal
        );
      }
      if (!itemizedDiscount) {
        const grossTotal = purchaseorderItems.reduce(
          (total, item) => {
            return currency(item.itemNetAmount, {
              symbol: "",
              separator: "",
            }).add(total);
          },
          currency(0, {
            symbol: "",
            separator: "",
          })
        );

        const discountTotal = currency(discount, {
          symbol: "",
          separator: "",
        });

        const beforeGstTotal = grossTotal.subtract(discountTotal);
        const gstAmount = beforeGstTotal.multiply(gstPercentage / 100);
        const afterGstTotal = beforeGstTotal.add(gstAmount);

        const formattedGrossTotal = grossTotal.format();
        const formattedDiscountTotal = discountTotal.format();
        const formattedBeforeGstTotal = beforeGstTotal.format();
        const formattedGstAmount = gstAmount.format();
        const formattedAfterGstTotal = afterGstTotal.format();
        setMainFormFieldValue(
          purchaseorderGrossTotal.name,
          formattedGrossTotal
        );
        setMainFormFieldValue(
          purchaseorderDiscountTotal.name,
          formattedDiscountTotal
        );
        setMainFormFieldValue(
          purchaseorderBeforeGstTotal.name,
          formattedBeforeGstTotal
        );
        setMainFormFieldValue(purchaseorderGstAmount.name, formattedGstAmount);
        setMainFormFieldValue(
          purchaseorderAfterGstTotal.name,
          formattedAfterGstTotal
        );
      }
    } catch (err) {
      dispatchMainError(err);
    }
  };

  const handleCalculateItemPrice = (
    quantity,
    unitCost,
    discount,
    setSectionFormFieldValue
  ) => {
    try {
      if (quantity && !isNaN(quantity) && quantity === "0") {
        // reset unit cost, gross amount, discount, net amount to zero, use this item as title
        setSectionFormFieldValue(itemUnitCost.name, "0.00");
        setSectionFormFieldValue(itemGrossAmount.name, "0.00");
        setSectionFormFieldValue(itemDiscount.name, "0");
        setSectionFormFieldValue(itemNetAmount.name, "0.00");
      } else if (quantity && !isNaN(quantity) && unitCost && !isNaN(unitCost)) {
        const grossAmount = calculateGrossAmount(quantity, unitCost);
        setSectionFormFieldValue(itemGrossAmount.name, grossAmount);

        if (
          discount &&
          !isNaN(discount) &&
          grossAmount &&
          !isNaN(grossAmount)
        ) {
          const netAmount = calculateNetAmount(grossAmount, discount);
          setSectionFormFieldValue(itemNetAmount.name, netAmount);
        }
      }
    } catch (err) {
      dispatchMainError(err);
    }
  };

  const calculateGrossAmount = (quantity, unitCost) => {
    try {
      return currency(unitCost, {
        symbol: "",
        separator: "",
      })
        .multiply(quantity)
        .format();
    } catch (err) {
      dispatchMainError(err);
    }
  };

  const calculateNetAmount = (grossAmount, discount) => {
    try {
      return currency(grossAmount, {
        symbol: "",
        separator: "",
      })
        .multiply((100 - discount) / 100)
        .format();
    } catch (err) {
      dispatchMainError(err);
    }
  };

  const submitQuoteItem = (values, { resetForm, setSubmitting }) => {
    try {
      if (selectedIndex !== -1 && isEditing) {
        // Edit existing item
        const currentQuoteItems = mainFormValues.purchaseorderItems;
        currentQuoteItems[selectedIndex] = values;
        handleCalculateTotalPrice(
          currentQuoteItems,
          mainFormValues.purchaseorderDiscountTotal
        );
        setMainFormFieldValue(purchaseorderItems.name, currentQuoteItems);
        setIsEditing(false);
      } else if (selectedIndex === -1 && isEditing) {
        // Add new item
        const newIndex = mainFormValues.purchaseorderItems.length;
        values.itemOrdinal = newIndex;
        const currentQuoteItems = [
          ...mainFormValues.purchaseorderItems,
          values,
        ];
        handleCalculateTotalPrice(
          currentQuoteItems,
          mainFormValues.purchaseorderDiscountTotal
        );
        setShowAddForm(false);
        setIsEditing(false);
        setMainFormFieldValue(purchaseorderItems.name, currentQuoteItems);
      } else {
        // recalculate
      }
      resetForm({ values: purchaseorderItemsInitialValues });
    } catch (err) {
      dispatchMainError(err);
      setSubmitting(false);
    }
  };

  const renderItem = (selectedIndex, formik, purchaseorderItem, index = -1) => {
    const {
      values,
      handleChange,
      handleSubmit,
      setFieldValue: setSectionFormFieldValue,
      resetForm,
    } = formik;
    return (
      <TableRow
        key={`purchaseorder-item-${index}`}
        selected={selectedIndex === index}
        onClick={() => handleTableRowClick(index, setSectionFormFieldValue)}
      >
        <TableCell align="center">
          <MDBox display="flex" alignItems="center" columnGap="5px">
            {!modeDisabled && (
              <>
                <MDBox>
                  {index > -1 && (
                    <IconButton
                      edge="start"
                      size="small"
                      color="success"
                      disabled={index !== selectedIndex || isEditing}
                      onClick={() =>
                        handleEditQuoteItem(index, setSectionFormFieldValue)
                      }
                    >
                      <EditIcon />
                    </IconButton>
                  )}
                  <IconButton
                    edge="start"
                    size="small"
                    color="success"
                    disabled={index !== selectedIndex || !isEditing}
                    onClick={handleSubmit}
                  >
                    <SaveIcon />
                  </IconButton>
                  <IconButton
                    edge="start"
                    size="small"
                    color="warning"
                    disabled={index !== selectedIndex || !isEditing}
                    onClick={() => handleCancelEditingQuoteItem(resetForm)}
                  >
                    <DoNotDisturbIcon />
                  </IconButton>
                </MDBox>
                <MDBox>
                  <IconButton
                    edge="start"
                    size="small"
                    color="info"
                    disabled={
                      index !== selectedIndex || index === 0 || isEditing
                    }
                    onClick={(event) => handleMoveUpQuoteItem(event, index)}
                  >
                    <ArrowCircleUpIcon />
                  </IconButton>
                  <IconButton
                    edge="start"
                    size="small"
                    color="info"
                    disabled={
                      index !== selectedIndex ||
                      index === mainFormValues.purchaseorderItems.length - 1 ||
                      isEditing
                    }
                    onClick={(event) => handleMoveDownQuoteItem(event, index)}
                  >
                    <ArrowCircleDownIcon />
                  </IconButton>
                </MDBox>
              </>
            )}
          </MDBox>
        </TableCell>
        <TableCell align="center">
          {isEditing && index === selectedIndex ? (
            <FormTextFieldFast
              hideStatus={true}
              form={formik}
              field={itemNo}
              variant="standard"
              disabled={modeDisabled}
            />
          ) : (
            purchaseorderItem.itemNo
          )}
        </TableCell>
        <TableCell align="center">
          {isEditing && index === selectedIndex ? (
            <FormTextFieldFast
              hideStatus={true}
              form={formik}
              field={itemSubNo}
              variant="standard"
              disabled={modeDisabled}
            />
          ) : (
            purchaseorderItem.itemSubNo
          )}
        </TableCell>
        <TableCell align="left">
          {isEditing && index === selectedIndex ? (
            <>
              <FormAutocompleteFast
                hideStatus={true}
                freeSolo
                form={formik}
                field={itemDescription}
                options={purchaseorderItemStatementOptions}
                variant="standard"
                disabled={modeDisabled}
              />
            </>
          ) : (
            purchaseorderItem.itemDescription
          )}
        </TableCell>
        <TableCell align="center">
          {isEditing && index === selectedIndex ? (
            <FormTextField
              hideStatus={true}
              form={formik}
              field={itemQuantity}
              variant="standard"
              disabled={modeDisabled}
              onChange={(e) => {
                handleChange(e);
                const quantity = e.target.value;
                const unitCost = values.itemUnitCost;
                const discount = values.itemDiscount;
                handleCalculateItemPrice(
                  quantity,
                  unitCost,
                  discount,
                  setSectionFormFieldValue
                );
              }}
            />
          ) : purchaseorderItem.itemQuantity !== "0" ? (
            purchaseorderItem.itemQuantity
          ) : (
            ""
          )}
        </TableCell>
        <TableCell align="center">
          {isEditing && index === selectedIndex ? (
            <FormTextField
              hideStatus={true}
              form={formik}
              field={itemUnitCost}
              variant="standard"
              disabled={modeDisabled}
              onChange={(e) => {
                handleChange(e);
                const unitCost = e.target.value;
                const quantity = values.itemQuantity;
                const discount = values.itemDiscount;
                handleCalculateItemPrice(
                  quantity,
                  unitCost,
                  discount,
                  setSectionFormFieldValue
                );
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
            />
          ) : purchaseorderItem.itemQuantity !== "0" ? (
            currency(purchaseorderItem.itemUnitCost).format()
          ) : (
            ""
          )}
        </TableCell>
        <TableCell
          align="center"
          sx={itemizedDiscount ? {} : { display: "none" }}
        >
          {isEditing && index === selectedIndex ? (
            <FormTextField
              hideStatus={true}
              form={formik}
              field={itemGrossAmount}
              variant="standard"
              disabled={modeDisabled}
              InputProps={{
                readOnly: true,
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
            />
          ) : purchaseorderItem.itemQuantity !== "0" ? (
            currency(purchaseorderItem.itemGrossAmount).format()
          ) : (
            ""
          )}
        </TableCell>
        <TableCell
          align="center"
          sx={itemizedDiscount ? {} : { display: "none" }}
        >
          {isEditing && index === selectedIndex ? (
            <FormTextField
              hideStatus={true}
              form={formik}
              field={itemDiscount}
              variant="standard"
              disabled={modeDisabled}
              onChange={(e) => {
                handleChange(e);
                const quantity = values.itemQuantity;
                const unitCost = values.itemUnitCost;
                const discount = e.target.value;
                handleCalculateItemPrice(
                  quantity,
                  unitCost,
                  discount,
                  setSectionFormFieldValue
                );
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
            />
          ) : purchaseorderItem.itemQuantity !== "0" ? (
            purchaseorderItem.itemDiscount + " %"
          ) : (
            ""
          )}
        </TableCell>
        <TableCell align="center">
          {isEditing && index === selectedIndex ? (
            <FormTextField
              hideStatus={true}
              form={formik}
              field={itemNetAmount}
              variant="standard"
              disabled={modeDisabled}
              onChange={(e) => {
                handleChange(e);
                const quantity = values.itemQuantity;
                const unitCost = values.itemUnitCost;
                const discount = e.target.value;
                handleCalculateItemPrice(
                  quantity,
                  unitCost,
                  discount,
                  setSectionFormFieldValue
                );
              }}
              InputProps={{
                readOnly: true,
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
            />
          ) : purchaseorderItem.itemQuantity !== "0" ? (
            currency(purchaseorderItem.itemNetAmount).format()
          ) : (
            ""
          )}
        </TableCell>
        <TableCell align="center">
          {!modeDisabled && index > -1 && (
            <IconButton
              edge="start"
              size="small"
              color="error"
              disabled={index !== selectedIndex || isEditing}
              onClick={() => handleRemoveQuoteItem(index)}
            >
              <DeleteIcon />
            </IconButton>
          )}
        </TableCell>
      </TableRow>
    );
  };

  return (
    <Card id="price-purchaseorder" sx={{ overflow: "visible" }}>
      <MDBox m={3}>
        <MDBox mb={3}>
          <MDTypography variant="h5">Purchase Order Items</MDTypography>
        </MDBox>
        <MDBox display="flex" alignItems="baseline">
          <MDTypography p={2} variant="body3" fontWeight="bold">
            {purchaseorderDiscountType.label}
          </MDTypography>

          <FormRadioGroup
            row
            showTitle={false}
            form={mainForm}
            field={purchaseorderDiscountType}
            options={discountTypeOptions}
            defaultValue={defaultDiscountType}
            onChange={(event) => {
              const value = event.target.value;
              setMainFormFieldValue(purchaseorderDiscountType.name, value);
              const isItemized = value === "Itemized";
              if (mainFormValues.purchaseorderItems.length > 0) {
                if (!isItemized) {
                  const updatedQuoteItems = mainFormValues.purchaseorderItems;
                  updatedQuoteItems.forEach((purchaseorderItem) => {
                    purchaseorderItem.itemDiscount = "0";
                    purchaseorderItem.itemNetAmount = calculateNetAmount(
                      purchaseorderItem.itemGrossAmount,
                      purchaseorderItem.itemDiscount
                    );
                  });
                  // reset discount
                  handleCalculateTotalPrice(updatedQuoteItems, "0.00");
                  setMainFormFieldValue(
                    purchaseorderItems.name,
                    updatedQuoteItems
                  );
                }
                if (isItemized) {
                  // reset discount
                  handleCalculateTotalPrice(
                    mainFormValues.purchaseorderItems,
                    "0.00"
                  );
                }
              }
              setItemizedDiscount(isItemized); //set state and rerender

              setMainFormFieldValue(gstOption.name, defaultGstOption);
              setGstOption(true);
            }}
            disabled={modeDisabled}
          />

          <MDTypography p={2} variant="body3" fontWeight="bold">
            {gstOption.label}
          </MDTypography>
          <FormRadioGroup
            row
            showTitle={false}
            form={mainForm}
            field={gstOption}
            options={gstOptions}
            defaultValue={defaultGstOption}
            onChange={(event) => {
              const value = event.target.value;
              setMainFormFieldValue(gstOption.name, value);
              const withGST = value === "With GST";

              if (withGST) {
                setMainFormFieldValue(
                  purchaseorderFinalTotal.name,
                  mainFormValues.purchaseorderAfterGstTotal
                );
              }
              if (!withGST) {
                setMainFormFieldValue(
                  purchaseorderFinalTotal.name,
                  mainFormValues.purchaseorderBeforeGstTotal
                );
              }
              setGstOption(withGST);
            }}
            disabled={modeDisabled}
          />
        </MDBox>
        <Formik
          initialValues={purchaseorderItemsInitialValues}
          validationSchema={purchaseorderItemsValidation}
          onSubmit={submitQuoteItem}
        >
          {(formik) => (
            <MDBox id={purchaseorderItemsFormId} autoComplete="off">
              <TableContainer sx={{ boxShadow: "none" }}>
                <Table sx={{ minWidth: "40rem" }}>
                  <TableHead>
                    <TableRow key="head">
                      <TableCell align="center" width="4%" />
                      <TableCell align="center" width="6%">
                        {itemNo.label}
                      </TableCell>
                      <TableCell align="center" width="8%">
                        {itemSubNo.label}
                      </TableCell>
                      <TableCell align="center" width="28%">
                        {itemDescription.label}
                      </TableCell>
                      <TableCell align="center" width="6%">
                        {itemQuantity.label}
                      </TableCell>
                      <TableCell align="center" width="11%">
                        {itemUnitCost.label}
                      </TableCell>
                      <TableCell align="center" width="11%">
                        {itemizedDiscount
                          ? itemGrossAmount.label
                          : "Total Amount"}
                      </TableCell>
                      <TableCell
                        align="center"
                        width="11%"
                        sx={itemizedDiscount ? {} : { display: "none" }}
                      >
                        {itemDiscount.label}
                      </TableCell>
                      <TableCell
                        align="center"
                        width="11%"
                        sx={itemizedDiscount ? {} : { display: "none" }}
                      >
                        {itemNetAmount.label}
                      </TableCell>
                      <TableCell align="center" width="4%" />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {mainFormValues.purchaseorderItems.map(
                      (purchaseorderItem, index) =>
                        renderItem(
                          selectedIndex,
                          formik,
                          purchaseorderItem,
                          index
                        )
                    )}
                    {showAddForm && renderItem(selectedIndex, formik)}
                    <TableRow>
                      <TableCell
                        rowSpan={itemizedDiscount ? 3 : 5}
                        colSpan={itemizedDiscount ? 4 : 2}
                        sx={{ verticalAlign: "top" }}
                      >
                        {!modeDisabled && (
                          <MDButton
                            size="small"
                            onClick={handleAddQuoteItem}
                            color="success"
                            disabled={
                              mainFormValues.purchaseorderItems.length >= 100 ||
                              isEditing ||
                              formik.isSubmitting
                            }
                            iconOnly
                          >
                            <AddIcon />
                          </MDButton>
                        )}
                      </TableCell>
                      <TableCell
                        align="right"
                        colSpan={itemizedDiscount ? 2 : 4}
                        sx={{ fontWeight: "bold" }}
                      >
                        Total Amount
                      </TableCell>
                      {itemizedDiscount ? (
                        <>
                          <TableCell align="right" sx={{ fontWeight: "bold" }}>
                            <FormTextField
                              hideLabel={true}
                              form={mainForm}
                              field={purchaseorderGrossTotal}
                              defaultValue={"0.00"}
                              variant="standard"
                              disabled={modeDisabled}
                              InputProps={{
                                readOnly: true,
                                startAdornment: (
                                  <InputAdornment position="start">
                                    $
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </TableCell>
                          <TableCell align="right" sx={{ fontWeight: "bold" }}>
                            <FormTextField
                              hideLabel={true}
                              form={mainForm}
                              field={purchaseorderDiscountTotal}
                              defaultValue={"0.00"}
                              variant="standard"
                              disabled={modeDisabled}
                              InputProps={{
                                readOnly: true,
                                startAdornment: (
                                  <InputAdornment position="start">
                                    $
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </TableCell>
                          <TableCell align="right" sx={{ fontWeight: "bold" }}>
                            <FormTextField
                              hideLabel={true}
                              form={mainForm}
                              field={purchaseorderBeforeGstTotal}
                              defaultValue={"0.00"}
                              variant="standard"
                              disabled={modeDisabled}
                              InputProps={{
                                readOnly: true,
                                startAdornment: (
                                  <InputAdornment position="start">
                                    $
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </TableCell>
                        </>
                      ) : (
                        <TableCell align="right" sx={{ fontWeight: "bold" }}>
                          <FormTextField
                            hideLabel={true}
                            form={mainForm}
                            field={purchaseorderGrossTotal}
                            defaultValue={"0.00"}
                            variant="standard"
                            disabled={modeDisabled}
                            InputProps={{
                              readOnly: true,
                              startAdornment: (
                                <InputAdornment position="start">
                                  $
                                </InputAdornment>
                              ),
                            }}
                          />
                        </TableCell>
                      )}
                    </TableRow>
                    {!itemizedDiscount && (
                      <>
                        <TableRow>
                          <TableCell align="right"></TableCell>
                          <TableCell colSpan={2}>
                            <MDBox
                              display="flex"
                              justifyContent="flex-end"
                              alignItems="center"
                              flexWrap="nowrap"
                            >
                              <DebounceAutocomplete
                                disablePortal
                                size="small"
                                label="Type"
                                value={discountTotalType}
                                options={["$", "%"]}
                                sx={{ width: 80 }}
                                onChange={(event, newValue) => {
                                  const discountTotalAmount =
                                    handleCalculateDiscountTotal(newValue, 0);
                                  handleCalculateTotalPrice(
                                    mainFormValues.purchaseorderItems,
                                    discountTotalAmount
                                  );
                                  setDiscountTotalValue("");
                                  setDiscountTotalType(newValue);
                                }}
                                disabled={modeDisabled}
                              />
                              <DebounceTextField
                                size="small"
                                label="Amount"
                                value={discountTotalValue}
                                disabled={modeDisabled}
                                onChange={(e) => {
                                  const newValue = e.target.value;

                                  const discountTotalAmount =
                                    handleCalculateDiscountTotal(
                                      discountTotalType,
                                      newValue
                                    );
                                  handleCalculateTotalPrice(
                                    mainFormValues.purchaseorderItems,
                                    discountTotalAmount
                                  );
                                  setDiscountTotalValue(newValue);
                                }}
                              />
                            </MDBox>
                          </TableCell>
                          <TableCell align="right" sx={{ fontWeight: "bold" }}>
                            {purchaseorderDiscountTotal.label}
                          </TableCell>
                          <TableCell align="right" sx={{ fontWeight: "bold" }}>
                            <FormTextField
                              hideLabel={true}
                              form={mainForm}
                              field={purchaseorderDiscountTotal}
                              defaultValue={"0.00"}
                              variant="standard"
                              disabled={modeDisabled}
                              InputProps={{
                                readOnly: true,
                                startAdornment: (
                                  <InputAdornment position="start">
                                    $
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            align="right"
                            colSpan={4}
                            sx={{ fontWeight: "bold" }}
                          >
                            {purchaseorderBeforeGstTotal.label}
                          </TableCell>
                          <TableCell align="right" sx={{ fontWeight: "bold" }}>
                            <FormTextField
                              hideLabel={true}
                              hideStatus={true}
                              form={mainForm}
                              field={purchaseorderBeforeGstTotal}
                              defaultValue={"0.00"}
                              variant="standard"
                              disabled={modeDisabled}
                              InputProps={{
                                readOnly: true,
                                startAdornment: (
                                  <InputAdornment position="start">
                                    $
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </TableCell>
                        </TableRow>
                      </>
                    )}

                    {withGst && (
                      <TableRow>
                        <TableCell
                          align="right"
                          colSpan={4}
                          sx={{ fontWeight: "bold" }}
                        >
                          {purchaseorderGstAmount.label}
                        </TableCell>
                        <TableCell align="right" sx={{ fontWeight: "bold" }}>
                          <FormTextField
                            hideLabel={true}
                            hideStatus={true}
                            form={mainForm}
                            field={purchaseorderGstAmount}
                            defaultValue={"0.00"}
                            variant="standard"
                            disabled={modeDisabled}
                            InputProps={{
                              readOnly: true,
                              startAdornment: (
                                <InputAdornment position="start">
                                  $
                                </InputAdornment>
                              ),
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    )}
                    <TableRow>
                      <TableCell
                        align="right"
                        colSpan={4}
                        sx={{ fontWeight: "bold" }}
                      >
                        {purchaseorderFinalTotal.label}
                      </TableCell>
                      <TableCell align="right" sx={{ fontWeight: "bold" }}>
                        <FormTextField
                          hideLabel={true}
                          notTouchable={true}
                          form={mainForm}
                          field={purchaseorderFinalTotal}
                          defaultValue={"0.00"}
                          variant="standard"
                          disabled={modeDisabled}
                          InputProps={{
                            readOnly: true,
                            startAdornment: (
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                            ),
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </MDBox>
          )}
        </Formik>
        <FormField
          type={purchaseorderItems.type}
          label={purchaseorderItems.label}
          name={purchaseorderItems.name}
        />
      </MDBox>
    </Card>
  );
}

PriceQuote.propTypes = {
  mainForm: PropTypes.object.isRequired,
  mainFormField: PropTypes.object.isRequired,
  modeDisabled: PropTypes.bool.isRequired,
};

export default PriceQuote;
