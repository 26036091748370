import { useParams } from "react-router-dom";
import Customer from "pages/customers/manage/Customer";

// using Proxy Page to remount when route to same route location but different param
function CustomerProxy() {
  const { mode, customerId } = useParams();
  return <Customer key={`${mode}/${customerId}`} />;
}

export default CustomerProxy;
