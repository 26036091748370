import * as Yup from "yup";
import form, {
  serviceTeamsForm,
  serviceTypesForm,
  commencementAttachmentsForm,
  depositAttachmentsForm,
  discountAttachmentsForm,
  completionAttachmentsForm,
  cancellationAttachmentsForm,
  exclusionsForm,
  salesItemsForm,
} from "pages/salesorders/manage/schemas/form";

import {
  commencementTypeOptions,
  discountTypeOptions,
  installationPeriodOptions,
  paymentTypeOptions,
} from "pages/salesorders/manage/schemas/setups";

import parse from "date-fns/parse";

const {
  formField: {
    workorderNumber,
    workStartDate,
    workingDays,
    commencementType,
    verbalAgreement,
    pendingPO,
    workCompletionDate,
    workCancellationDate,
    purchaseorderReference,
    completionReference,
    cancellationReference,
    serviceTeams,
    serviceTypes,
    paymentType,
    salesorderDate,
    salesorderNumber,
    contactPersonName,
    contactPersonLandlineNumber,
    contactPersonMobileNumber,
    contactPersonEmail,
    customerId,
    customerName,
    customerAddress,
    deliverySiteName,
    deliverySiteAddress,
    salesorderTitle,
    salesorderMessage,
    salesItems,
    salesDiscountType,
    salesGrossTotal,
    salesDiscountTotal,
    salesBeforeGstTotal,
    salesNetBeforeGstTotal,
    salesGstAmount,
    salesAfterGstTotal,
    salesDeposit,
    depositReference,
    salesAdditionalDiscount,
    discountReference,
    beginingStatement,
    installationPeriod,
    installationStartDate,
    installationEndDate,
    paymentTerm,
    validity,
    exclusions,
    note,
    endingStatement,
    commencementAttachments,
    depositAttachments,
    discountAttachments,
    completionAttachments,
    cancellationAttachments,
  },
} = form;

const noValidation = Yup.object().shape({});

const validations = Yup.object().shape({
  [workorderNumber.name]: Yup.string()
    .required(workorderNumber.errorMsg)
    .max(200, workorderNumber.invalidMsg),
  [workStartDate.name]: Yup.date()
    .nullable()
    .transform((value, originalValue) => {
      return originalValue === null
        ? null
        : parse(originalValue, "dd/MM/yyyy", new Date());
    })
    .required(workStartDate.errorMsg)
    .typeError(workStartDate.invalidMsg),
  [workingDays.name]: Yup.string()
    .required(workingDays.errorMsg)
    .matches("^\\d{1,4}$", workingDays.invalidMsg),
  [commencementType.name]: Yup.string().matches(
    `(${commencementTypeOptions.join("|")})`,
    {
      excludeEmptyString: true,
    }
  ),
  [serviceTeams.name]: Yup.array()
    // .min(1, serviceTeams.min1Msg)
    .max(20, serviceTeams.max20Msg),
  [serviceTypes.name]: Yup.array()
    // .min(1, serviceTypes.min1Msg)
    .max(20, serviceTypes.max20Msg),
  [paymentType.name]: Yup.string().matches(
    `(${paymentTypeOptions.join("|")})`,
    {
      excludeEmptyString: true,
    }
  ),
  [salesorderDate.name]: Yup.date()
    .transform((value, originalValue) => {
      return originalValue === null
        ? null
        : parse(originalValue, "dd/MM/yyyy", new Date());
    })
    .required(salesorderDate.errorMsg)
    .typeError(salesorderDate.invalidMsg),
  [salesorderNumber.name]: Yup.string()
    .required(salesorderNumber.errorMsg)
    .matches("^\\d{6}-\\d{2}$", salesorderNumber.invalidMsg),
  [contactPersonName.name]: Yup.string()
    .required(contactPersonName.errorMsg)
    .max(200, contactPersonName.invalidMsg),
  [contactPersonLandlineNumber.name]: Yup.string()
    // .when(contactPersonMobileNumber.name, {
    //   is: (contactPersonMobileNumberValue) =>
    //     !contactPersonMobileNumberValue?.length,
    //   then: Yup.string().required(contactPersonLandlineNumber.errorMsg),
    // })
    .matches(
      "^[+]?([0-9]*[\\-\\s.])*([0-9]*[(]+[0-9]{1,3}[)])*([0-9]*[\\-\\s.])*[0-9]{4,15}$",
      contactPersonLandlineNumber.invalidMsg
    ),
  [contactPersonMobileNumber.name]: Yup.string().matches(
    "^[+]?([0-9]*[\\-\\s.])*([0-9]*[(]+[0-9]{1,3}[)])*([0-9]*[\\-\\s.])*[0-9]{4,15}$",
    contactPersonMobileNumber.invalidMsg
  ),
  [contactPersonEmail.name]: Yup.string()
    //.required(contactPersonEmail.errorMsg)
    .max(200, contactPersonEmail.invalidMsg)
    .email(contactPersonEmail.invalidMsg),
  [customerId.name]: Yup.string()
    .required(customerId.errorMsg)
    .max(200, customerId.invalidMsg),
  [customerName.name]: Yup.string()
    .required(customerName.errorMsg)
    .max(200, customerName.invalidMsg),
  [customerAddress.name]: Yup.string()
    .required(customerAddress.errorMsg)
    .max(200, customerAddress.invalidMsg),
  [deliverySiteName.name]: Yup.string()
    .required(deliverySiteName.errorMsg)
    .max(200, deliverySiteName.invalidMsg),
  [deliverySiteAddress.name]: Yup.string()
    .required(deliverySiteAddress.errorMsg)
    .max(200, deliverySiteAddress.invalidMsg),
  [salesorderTitle.name]: Yup.string()
    .required(salesorderTitle.errorMsg)
    .max(500, salesorderTitle.invalidMsg),
  [salesorderMessage.name]: Yup.string()
    .required(salesorderMessage.errorMsg)
    .max(2000, salesorderMessage.invalidMsg)
    .nullable(true),
  [salesItems.name]: Yup.array()
    .min(1, salesItems.min1Msg)
    .max(100, salesItems.max100Msg),
  [salesDiscountType.name]: Yup.string().matches(
    `(${discountTypeOptions.join("|")})`,
    {
      excludeEmptyString: true,
    }
  ),
  [salesGrossTotal.name]: Yup.string()
    .required(salesGrossTotal.errorMsg)
    .matches("^\\d*(\\.\\d{1,2})?$", salesGrossTotal.invalidMsg),
  [salesDiscountTotal.name]: Yup.string()
    .required(salesDiscountTotal.errorMsg)
    .matches("^\\d*(\\.\\d{1,2})?$", salesDiscountTotal.invalidMsg),
  [salesBeforeGstTotal.name]: Yup.string()
    .required(salesBeforeGstTotal.errorMsg)
    .matches("^\\d*(\\.\\d{1,2})?$", salesBeforeGstTotal.invalidMsg),
  [salesNetBeforeGstTotal.name]: Yup.string()
    .required(salesNetBeforeGstTotal.errorMsg)
    .matches("^\\d*(\\.\\d{1,2})?$", salesNetBeforeGstTotal.invalidMsg),
  [salesGstAmount.name]: Yup.string()
    .required(salesGstAmount.errorMsg)
    .matches("^\\d*(\\.\\d{1,2})?$", salesGstAmount.invalidMsg),
  [salesAfterGstTotal.name]: Yup.string()
    .required(salesAfterGstTotal.errorMsg)
    .matches("^\\d*(\\.\\d{1,2})?$", salesAfterGstTotal.invalidMsg),
  [salesDeposit.name]: Yup.string()
    .optional()
    .matches("^\\d*(\\.\\d{1,2})?$", salesDeposit.invalidMsg),
  [salesAdditionalDiscount.name]: Yup.string()
    .optional()
    .matches("^\\d*(\\.\\d{1,3})?$", salesAdditionalDiscount.invalidMsg),
  [beginingStatement.name]: Yup.string()
    .required(beginingStatement.errorMsg)
    .max(2000, beginingStatement.invalidMsg)
    .nullable(true),
  [installationPeriod.name]: Yup.string().matches(
    `(${installationPeriodOptions.join("|")})`,
    {
      excludeEmptyString: true,
    }
  ),
  [installationStartDate.name]: Yup.date()
    .nullable()
    .transform((value, originalValue) => {
      return originalValue === null
        ? null
        : parse(originalValue, "dd/MM/yyyy", new Date());
    })
    .when(installationPeriod.name, {
      is: (installationPeriodValue) => installationPeriodValue === "Duration",
      then: Yup.date()
        .nullable()
        .transform((value, originalValue) => {
          return originalValue === null
            ? null
            : parse(originalValue, "dd/MM/yyyy", new Date());
        })
        .required(installationStartDate.errorMsg)

        .typeError(installationStartDate.invalidMsg),
    })
    .typeError(installationStartDate.invalidMsg),
  [installationEndDate.name]: Yup.date()
    .nullable()
    .transform((value, originalValue) => {
      return originalValue === null
        ? null
        : parse(originalValue, "dd/MM/yyyy", new Date());
    })
    .when(installationPeriod.name, {
      is: (installationPeriodValue) => installationPeriodValue === "Duration",
      then: Yup.date()
        .nullable()
        .transform((value, originalValue) => {
          return originalValue === null
            ? null
            : parse(originalValue, "dd/MM/yyyy", new Date());
        })
        .required(installationEndDate.errorMsg)
        .typeError(installationEndDate.invalidMsg),
    })
    .typeError(installationEndDate.invalidMsg),
  [paymentTerm.name]: Yup.string()
    .required(paymentTerm.errorMsg)
    .max(2000, paymentTerm.invalidMsg)
    .nullable(true),
  [validity.name]: Yup.string()
    .required(validity.errorMsg)
    .max(2000, validity.invalidMsg)
    .nullable(true),
  [exclusions.name]: Yup.array()
    .min(1, exclusions.min1Msg)
    .max(50, exclusions.max50Msg),
  [note.name]: Yup.string()
    .required(note.errorMsg)
    .max(2000, note.invalidMsg)
    .nullable(true),
  [endingStatement.name]: Yup.string()
    .required(endingStatement.errorMsg)
    .max(2000, endingStatement.invalidMsg)
    .nullable(true),
  [purchaseorderReference.name]: Yup.string()
    // .when(commencementType.name, {
    //   is: (commencementTypeValue) =>
    //     commencementTypeValue === "From Purchase Order",
    //   then: Yup.string().required(purchaseorderReference.errorMsg),
    // })
    .max(200, purchaseorderReference.invalidMsg),
  [commencementAttachments.name]: Yup.array()
    .max(20, commencementAttachments.max20Msg)
    .when([commencementType.name, verbalAgreement.name, pendingPO.name], {
      is: (commencementTypeValue, verbalAgreementValue, pendingPOValue) =>
        commencementTypeValue === "From Quotation" &&
        !verbalAgreementValue &&
        !pendingPOValue,
      then: Yup.array().min(1, commencementAttachments.min1Msg),
    }),
  [depositAttachments.name]: Yup.array()
    .when([salesDeposit.name, depositReference.name], {
      is: (salesDepositValue, depositReferenceValue) => {
        return salesDepositValue !== "0.00" && !depositReferenceValue?.length;
      },
      then: Yup.array().min(1, depositAttachments.errorMsg),
    })
    .max(20, depositAttachments.max20Msg),
  [discountAttachments.name]: Yup.array()
    .when([salesAdditionalDiscount.name, discountReference.name], {
      is: (salesAdditionalDiscountValue, discountReferenceValue) => {
        return (
          salesAdditionalDiscountValue !== "0.00" &&
          !discountReferenceValue?.length
        );
      },
      then: Yup.array().min(1, discountAttachments.errorMsg),
    })
    .max(20, discountAttachments.max20Msg),
  [completionReference.name]: Yup.string()
    .when(workCompletionDate.name, {
      is: (workCompletionDateValue) => {
        return workCompletionDateValue;
      },
      then: Yup.string().required(completionReference.errorMsg),
    })
    .max(200, completionReference.invalidMsg),
  [completionAttachments.name]: Yup.array()
    .when(workCompletionDate.name, {
      is: (workCompletionDateValue) => {
        return workCompletionDateValue;
      },
      then: Yup.array().min(1, completionAttachments.errorMsg),
    })
    .max(20, completionAttachments.max20Msg),
  [cancellationAttachments.name]: Yup.array()
    .when([workCancellationDate.name, cancellationReference.name], {
      is: (workCancellationDateValue, cancellationReferenceValue) => {
        return workCancellationDateValue && !cancellationReferenceValue?.length;
      },
      then: Yup.array().min(1, cancellationAttachments.errorMsg),
    })
    .max(20, cancellationAttachments.max20Msg),
});

const {
  formField: { serviceTeamName },
} = serviceTeamsForm;

const serviceTeamsValidation = Yup.object().shape({
  [serviceTeamName.name]: Yup.string()
    .required(serviceTeamName.errorMsg)
    .max(200, serviceTeamName.invalidMsg)
    .nullable(true),
});

const {
  formField: { serviceTypeName },
} = serviceTypesForm;

const serviceTypesValidation = Yup.object().shape({
  [serviceTypeName.name]: Yup.string()
    .required(serviceTypeName.errorMsg)
    .max(200, serviceTypeName.invalidMsg)
    .nullable(true),
});

const {
  formField: { commencementAttachmentFile },
} = commencementAttachmentsForm;

const commencementAttachmentsValidation = Yup.object().shape({
  [commencementAttachmentFile.name]: Yup.mixed()
    .nullable()
    .required(commencementAttachmentFile.errorMsg)
    .test(
      "fileSize",
      commencementAttachmentFile.invalidMsg,
      (value) => !value || (value && value.size <= 5 * 1024 * 1024)
    ),
});

const {
  formField: { depositAttachmentFile },
} = depositAttachmentsForm;

const depositAttachmentsValidation = Yup.object().shape({
  [depositAttachmentFile.name]: Yup.mixed()
    .nullable()
    .required(depositAttachmentFile.errorMsg)
    .test(
      "fileSize",
      depositAttachmentFile.invalidMsg,
      (value) => !value || (value && value.size <= 5 * 1024 * 1024)
    ),
});

const {
  formField: { discountAttachmentFile },
} = discountAttachmentsForm;

const discountAttachmentsValidation = Yup.object().shape({
  [discountAttachmentFile.name]: Yup.mixed()
    .nullable()
    .required(discountAttachmentFile.errorMsg)
    .test(
      "fileSize",
      discountAttachmentFile.invalidMsg,
      (value) => !value || (value && value.size <= 5 * 1024 * 1024)
    ),
});

const {
  formField: { completionAttachmentFile },
} = completionAttachmentsForm;

const completionAttachmentsValidation = Yup.object().shape({
  [completionAttachmentFile.name]: Yup.mixed()
    .nullable()
    .required(completionAttachmentFile.errorMsg)
    .test(
      "fileSize",
      completionAttachmentFile.invalidMsg,
      (value) => !value || (value && value.size <= 5 * 1024 * 1024)
    ),
});

const {
  formField: { cancellationAttachmentFile },
} = cancellationAttachmentsForm;

const cancellationAttachmentsValidation = Yup.object().shape({
  [cancellationAttachmentFile.name]: Yup.mixed()
    .nullable()
    .required(cancellationAttachmentFile.errorMsg)
    .test(
      "fileSize",
      cancellationAttachmentFile.invalidMsg,
      (value) => !value || (value && value.size <= 5 * 1024 * 1024)
    ),
});

const {
  formField: { exclusionClause },
} = exclusionsForm;

const exclusionsValidation = Yup.object().shape({
  [exclusionClause.name]: Yup.string()
    .required(exclusionClause.errorMsg)
    .max(200, exclusionClause.invalidMsg)
    .nullable(true),
});

const {
  formField: {
    itemNo,
    itemSubNo,
    itemServiceType,
    itemDescription,
    itemServiceTeam,
    itemQuantity,
    itemUnitCost,
    itemGrossAmount,
    itemDiscount,
    itemNetAmount,
  },
} = salesItemsForm;

const salesItemsValidation = Yup.object().shape({
  [itemNo.name]: Yup.string().matches("^\\d{1,2}$", itemNo.invalidMsg),
  [itemSubNo.name]: Yup.string().matches(
    "^\\d{1,2}\\.\\d{1,2}$",
    itemSubNo.invalidMsg
  ),
  [itemServiceType.name]: Yup.string()
    .required(itemServiceType.errorMsg)
    .max(2000, itemServiceType.invalidMsg),
  [itemDescription.name]: Yup.string()
    .required(itemDescription.errorMsg)
    .max(2000, itemDescription.invalidMsg),
  [itemServiceTeam.name]: Yup.string()
    //.required(itemServiceTeam.errorMsg)
    .max(2000, itemServiceTeam.invalidMsg),
  [itemQuantity.name]: Yup.string()
    .required(itemQuantity.errorMsg)
    .matches("^\\d{1,4}$", itemQuantity.invalidMsg),
  [itemUnitCost.name]: Yup.string()
    .required(itemUnitCost.errorMsg)
    .matches("^\\d*(\\.\\d{1,2})?$", itemUnitCost.invalidMsg),
  [itemGrossAmount.name]: Yup.string()
    .required(itemGrossAmount.errorMsg)
    .matches("^\\d*(\\.\\d{1,2})?$", itemGrossAmount.invalidMsg),
  [itemDiscount.name]: Yup.string()
    .required(itemDiscount.errorMsg)
    .matches("^\\d*(\\.\\d{1,3})?$", itemDiscount.invalidMsg),
  [itemNetAmount.name]: Yup.string()
    .required(itemNetAmount.errorMsg)
    .matches("^\\d*(\\.\\d{1,2})?$", itemNetAmount.invalidMsg),
});

export {
  validations as default,
  noValidation,
  serviceTeamsValidation,
  serviceTypesValidation,
  commencementAttachmentsValidation,
  depositAttachmentsValidation,
  discountAttachmentsValidation,
  completionAttachmentsValidation,
  cancellationAttachmentsValidation,
  exclusionsValidation,
  salesItemsValidation,
};
