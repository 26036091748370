import PropTypes from "prop-types";

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

import MDBox from "components/atoms/MDBox";
import MDTypography from "components/atoms/MDTypography";

import FormAttachments from "components/molecules/Formik/FormAttachments";
import FormTextFieldFast from "components/molecules/Formik/FormTextFieldFast";
import FormDatePicker from "components/molecules/Formik/FormDatePicker";

import { completionAttachmentsForm } from "pages/salesorders/manage/schemas/form";
import { completionAttachmentsInitialValues } from "pages/salesorders/manage/schemas/initialValues";
import { completionAttachmentsValidation } from "pages/salesorders/manage/schemas/validations";

import format from "date-fns/format";
import parse from "date-fns/parse";
function Completion({
  mainForm,
  mainFormField,
  modeDisabled,
  mode,
  dispatchMainError,
}) {
  const { workCompletionDate, completionReference, completionAttachments } =
    mainFormField;

  // destructure from Attachments Form for current section
  const {
    formId: completionAttachmentsFormId,
    formField: completionAttachmentsFormField,
  } = completionAttachmentsForm;
  const { completionAttachmentFile, attachmentName, attachmentURL } =
    completionAttachmentsFormField;

  return (
    <Card id="completion" sx={{ overflow: "visible" }}>
      <MDBox m={3}>
        <MDBox mb={3}>
          <MDTypography variant="h5">Completion</MDTypography>
        </MDBox>
        <MDBox>
          <Grid
            container
            rowSpacing={{ xs: 1, sm: 2 }}
            columnSpacing={{ xs: 1, sm: 3 }}
          >
            <Grid item xs={12} sm={12}>
              <FormDatePicker
                form={mainForm}
                field={workCompletionDate}
                inputFormat={"dd/MM/yyyy"}
                variant="standard"
                disabled={modeDisabled}
                maxDate={parse(
                  format(new Date(), "dd/MM/yyyy"),
                  "dd/MM/yyyy",
                  new Date()
                )}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <MDTypography variant="body3" fontWeight="bold">
                {completionReference.label}
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormTextFieldFast
                multiline
                form={mainForm}
                field={completionReference}
                variant="standard"
                disabled={modeDisabled}
              />
            </Grid>
            <Grid item xs={12} sm={4}></Grid>

            <Grid item xs={12} sm={2}>
              <MDTypography variant="body3" fontWeight="bold">
                {completionAttachments.label}
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormAttachments
                showTitle={false}
                form={mainForm}
                field={completionAttachments}
                max={20}
                disabled={modeDisabled}
                dispatchError={dispatchMainError}
                itemFormId={completionAttachmentsFormId}
                itemInitialValues={completionAttachmentsInitialValues}
                itemValidation={completionAttachmentsValidation}
                itemFileField={completionAttachmentFile}
                itemKeyField={attachmentURL}
                itemDisplayFields={[attachmentName]}
              />
            </Grid>
            <Grid item xs={12} sm={4}></Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Card>
  );
}

Completion.propTypes = {
  mainForm: PropTypes.object.isRequired,
  mainFormField: PropTypes.object.isRequired,
  modeDisabled: PropTypes.bool.isRequired,
  mode: PropTypes.string.isRequired,
};

export default Completion;
