import PropTypes from "prop-types";

import Grid from "@mui/material/Grid";
import AddIcon from "@mui/icons-material/Add";

import MDBox from "components/atoms/MDBox";
import MDButton from "components/atoms/MDButton";
import MDTypography from "components/atoms/MDTypography";

import FormTextFieldFast from "components/molecules/Formik/FormTextFieldFast";
import FormLeftFieldArray from "components/molecules/Formik/FormLeftFieldArray";

import { deliverySiteForm } from "pages/customers/manage/schemas/form";
import { deliverySitesInitialValues } from "pages/customers/manage/schemas/initialValues";
import { deliverySitesValidation } from "pages/customers/manage/schemas/validations";

import ControlPointDuplicateIcon from "@mui/icons-material/ControlPointDuplicate";

function CustomerDeliverySites({
  mainForm,
  mainFormField,
  modeDisabled,
  dispatchMainError,
}) {
  const { values } = mainForm;
  const { deliverySites } = mainFormField;

  // destructure from Contact Persons Form for current step
  const { formId: innerFormId, formField: innerFormField } = deliverySiteForm;
  const {
    deliverySiteName,
    siteSupervisorName,
    deliverySiteAddress,
    deliverySiteBlkBuildingName,
    deliverySitePostalCode,
    deliverySiteEmail,
    deliverySiteLandlineNumber,
    deliverySiteMobileNumber,
  } = innerFormField;

  const handleCopy = (setSectionFormFieldValue) => {
    try {
      setSectionFormFieldValue(deliverySiteName.name, values.customerName);
      setSectionFormFieldValue(
        deliverySiteAddress.name,
        values.customerAddress
      );
      setSectionFormFieldValue(
        deliverySiteBlkBuildingName.name,
        values.customerBuildingName
      );
      setSectionFormFieldValue(
        deliverySitePostalCode.name,
        values.customerPostalCode
      );
      setSectionFormFieldValue(deliverySiteEmail.name, values.customerEmail);
      setSectionFormFieldValue(
        deliverySiteLandlineNumber.name,
        values.customerLandlineNumber
      );
      setSectionFormFieldValue(
        deliverySiteMobileNumber.name,
        values.customerMobileNumber
      );
    } catch (err) {
      dispatchMainError(err);
    }
  };

  return (
    <MDBox>
      <MDBox lineHeight={0}>
        <MDTypography variant="h5">Customer Delivery Sites</MDTypography>
        <MDTypography variant="body3" color="text">
          Please add and fill in Customer Delivery Sites details
        </MDTypography>
      </MDBox>
      <MDBox mt={2}>
        <FormLeftFieldArray
          form={mainForm}
          field={deliverySites}
          max={100}
          disabled={modeDisabled}
          dispatchError={dispatchMainError}
          itemInitialValues={deliverySitesInitialValues}
          itemValidation={deliverySitesValidation}
          itemKeyField={deliverySiteName} // TODO: change to uuid
          itemDisplayFields={[deliverySiteName]}
          itemForm={(formik, disabledField, disabledAdd) => {
            return (
              /* not using <Form> because cannot have form within another form (Customer.js),
                      in order not to trigger validation of sub formik when submitting main formik */
              <MDBox id={innerFormId} autoComplete="off">
                <Grid container columnSpacing={{ xs: 1, sm: 3 }}>
                  <Grid item xs={12} sm={12}>
                    <FormTextFieldFast
                      form={formik}
                      field={deliverySiteName}
                      variant="standard"
                      disabled={disabledField}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <FormTextFieldFast
                      form={formik}
                      field={deliverySiteAddress}
                      variant="standard"
                      disabled={disabledField}
                      multiline
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormTextFieldFast
                      form={formik}
                      field={deliverySiteBlkBuildingName}
                      variant="standard"
                      disabled={disabledField}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormTextFieldFast
                      form={formik}
                      field={deliverySitePostalCode}
                      variant="standard"
                      disabled={disabledField}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormTextFieldFast
                      form={formik}
                      field={siteSupervisorName}
                      variant="standard"
                      disabled={disabledField}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormTextFieldFast
                      form={formik}
                      field={deliverySiteEmail}
                      variant="standard"
                      disabled={disabledField}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormTextFieldFast
                      form={formik}
                      field={deliverySiteLandlineNumber}
                      variant="standard"
                      disabled={disabledField}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormTextFieldFast
                      form={formik}
                      field={deliverySiteMobileNumber}
                      variant="standard"
                      disabled={disabledField}
                    />
                  </Grid>
                  {!disabledAdd && (
                    <>
                      <Grid item xs={12} sm={2}>
                        <MDButton
                          onClick={() => handleCopy(formik.setFieldValue)}
                          color="warning"
                          size="small"
                          fullWidth
                        >
                          <ControlPointDuplicateIcon />
                          &nbsp; Copy
                        </MDButton>
                      </Grid>
                      <Grid item xs={12} sm={10}>
                        <MDButton
                          onClick={formik.handleSubmit}
                          color="success"
                          size="small"
                          fullWidth
                        >
                          <AddIcon />
                          &nbsp; Add Delivery Site
                        </MDButton>
                      </Grid>
                    </>
                  )}
                </Grid>
              </MDBox>
            );
          }}
        />
      </MDBox>
    </MDBox>
  );
}

CustomerDeliverySites.propTypes = {
  mainForm: PropTypes.object.isRequired,
  mainFormField: PropTypes.object.isRequired,
  modeDisabled: PropTypes.bool,
  dispatchMainError: PropTypes.func,
};

export default CustomerDeliverySites;
