import PropTypes from "prop-types";

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";

import MDBox from "components/atoms/MDBox";
import MDTypography from "components/atoms/MDTypography";

import { TextField } from "@mui/material";

import FormDatePicker from "components/molecules/Formik/FormDatePicker";
import FormTextFieldFast from "components/molecules/Formik/FormTextFieldFast";

function AuditTrail({ mainForm, mainFormField, modeDisabled, mode }) {
  const { invoiceNumber, invoiceDate } = mainFormField;

  return (
    <Card id="audit-trail" sx={{ overflow: "visible" }}>
      <MDBox m={3}>
        <MDBox mb={3}>
          <MDTypography variant="h5">Audit Trail</MDTypography>
        </MDBox>
        <MDBox>
          <Grid container mb={1} columnSpacing={{ xs: 1, sm: 3 }}>
            <Grid item xs={12} sm={3}>
              <FormDatePicker
                form={mainForm}
                field={invoiceDate}
                inputFormat={"dd/MM/yyyy"}
                variant="standard"
                disabled={modeDisabled}
              />
            </Grid>
            <Grid item xs={12} sm={6}></Grid>
            <Grid item xs={12} sm={3}>
              <MDBox display="flex">
                <FormTextFieldFast
                  form={mainForm}
                  field={invoiceNumber}
                  variant="standard"
                  disabled={modeDisabled}
                  sx={
                    mode === "new" || mode === "convert"
                      ? { display: "none" }
                      : {}
                  }
                  InputProps={{
                    readOnly: true,
                    startAdornment: (
                      <InputAdornment position="start">GA-INV-</InputAdornment>
                    ),
                  }}
                />
                <TextField
                  label={invoiceNumber.label}
                  defaultValue="Auto Generate"
                  variant="standard"
                  sx={
                    mode !== "new" && mode !== "convert"
                      ? { display: "none" }
                      : {}
                  }
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Card>
  );
}

AuditTrail.propTypes = {
  mainForm: PropTypes.object.isRequired,
  mainFormField: PropTypes.object.isRequired,
  modeDisabled: PropTypes.bool.isRequired,
  mode: PropTypes.string.isRequired,
};

export default AuditTrail;
