const customerTypeOptions = ["Non-residential", "Residential"];
const customerStatusOptions = ["Standard", "VIP", "V VIP"];
const customerPreferredContactOptions = [
  "Office / Home",
  "Mobile",
  "WhatsApp",
  "Email",
];

export {
  customerTypeOptions,
  customerStatusOptions,
  customerPreferredContactOptions,
};
