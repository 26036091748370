import PropTypes from "prop-types";

import { useCallback, useEffect, useState } from "react";

import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

import MDTypography from "components/atoms/MDTypography";

import { useDebouncedCallback } from "use-debounce";

const INPUT_DELAY = 500;

function DebounceAutocomplete({
  value,
  onInputChange,
  type,
  label,
  placeholder,
  variant,
  disabled,
  error,
  success,
  freeSolo,
  ...rest
}) {
  const [innerValue, setInnerValue] = useState(null);

  useEffect(() => {
    if (value) {
      setInnerValue(value);
    } else {
      setInnerValue(null);
    }
  }, [value]);

  const debouncedHandleOnInputChange = useDebouncedCallback((event, value) => {
    if (onInputChange) {
      onInputChange(event, value);
    }
  }, INPUT_DELAY);

  const handleOnInputChange = useCallback(
    (event, value) => {
      event && event.persist();

      debouncedHandleOnInputChange(event, value);
    },
    [debouncedHandleOnInputChange]
  );

  // textarea
  const renderOptionProp = freeSolo
    ? {
        renderOption: (props, option) => {
          return (
            <MDTypography
              {...props}
              className="MuiAutocomplete-option"
              sx={{ whiteSpace: "pre-wrap" }}
            >
              {option}
            </MDTypography>
          );
        },
      }
    : {};

  const multilineProp = freeSolo
    ? {
        multiline: true,
      }
    : {};

  return (
    <>
      <Autocomplete
        value={innerValue}
        onInputChange={handleOnInputChange}
        disabled={disabled}
        freeSolo={freeSolo}
        {...renderOptionProp}
        {...rest}
        renderInput={(params) => (
          <TextField
            {...params}
            {...multilineProp}
            type={type}
            label={label}
            placeholder={placeholder}
            variant={variant}
            disabled={disabled}
            error={error}
            success={success}
          />
        )}
      />
    </>
  );
}

// Setting default values for the props of DebounceAutocomplete
DebounceAutocomplete.defaultProps = {
  label: "",
};

// typechecking props for DebounceAutocomplete
DebounceAutocomplete.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  variant: PropTypes.string,
  options: PropTypes.array,
  onChange: PropTypes.func,
  onInputChange: PropTypes.func,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  success: PropTypes.bool,
  children: PropTypes.node,
};

export default DebounceAutocomplete;
