import PropTypes from "prop-types";

import Grid from "@mui/material/Grid";
import AddIcon from "@mui/icons-material/Add";

import MDBox from "components/atoms/MDBox";
import MDButton from "components/atoms/MDButton";
import MDTypography from "components/atoms/MDTypography";

import FormTextFieldFast from "components/molecules/Formik/FormTextFieldFast";
import FormLeftFieldArray from "components/molecules/Formik/FormLeftFieldArray";

import { billingContactForm } from "pages/customers/manage/schemas/form";
import { billingContactsInitialValues } from "pages/customers/manage/schemas/initialValues";
import { billingContactsValidation } from "pages/customers/manage/schemas/validations";

import ControlPointDuplicateIcon from "@mui/icons-material/ControlPointDuplicate";

function CustomerBillingContacts({
  mainForm,
  mainFormField,
  modeDisabled,
  dispatchMainError,
}) {
  const { values } = mainForm;
  const { billingContacts } = mainFormField;

  // destructure from Billing Contacts Form for current step
  const { formId: innerFormId, formField: innerFormField } = billingContactForm;
  const {
    billingContactName,
    billingContactAddress,
    billingContactBlkBuildingName,
    billingContactPostalCode,
    billingContactEmail,
    billingContactLandlineNumber,
    billingContactMobileNumber,
  } = innerFormField;

  const handleCopy = (setSectionFormFieldValue) => {
    try {
      setSectionFormFieldValue(billingContactName.name, values.customerName);
      setSectionFormFieldValue(
        billingContactAddress.name,
        values.customerAddress
      );
      setSectionFormFieldValue(
        billingContactBlkBuildingName.name,
        values.customerBuildingName
      );
      setSectionFormFieldValue(
        billingContactPostalCode.name,
        values.customerPostalCode
      );
      setSectionFormFieldValue(billingContactEmail.name, values.customerEmail);
      setSectionFormFieldValue(
        billingContactLandlineNumber.name,
        values.customerLandlineNumber
      );
      setSectionFormFieldValue(
        billingContactMobileNumber.name,
        values.customerMobileNumber
      );
    } catch (err) {
      dispatchMainError(err);
    }
  };

  return (
    <MDBox>
      <MDBox lineHeight={0}>
        <MDTypography variant="h5">Customer Billing Contacts</MDTypography>
        <MDTypography variant="body3" color="text">
          Please add and fill in Customer Billing Contacts details
        </MDTypography>
      </MDBox>
      <MDBox mt={2}>
        <FormLeftFieldArray
          form={mainForm}
          field={billingContacts}
          max={100}
          disabled={modeDisabled}
          dispatchError={dispatchMainError}
          itemInitialValues={billingContactsInitialValues}
          itemValidation={billingContactsValidation}
          itemKeyField={billingContactName} // TODO: change to uuid
          itemDisplayFields={[billingContactName]}
          itemForm={(formik, disabledField, disabledAdd) => {
            return (
              /* not using <Form> because cannot have form within another form (Customer.js),
                      in order not to trigger validation of sub formik when submitting main formik */
              <MDBox id={innerFormId} autoComplete="off">
                <Grid container columnSpacing={{ xs: 1, sm: 3 }}>
                  <Grid item xs={12} sm={12}>
                    <FormTextFieldFast
                      form={formik}
                      field={billingContactName}
                      variant="standard"
                      disabled={disabledField}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <FormTextFieldFast
                      form={formik}
                      field={billingContactAddress}
                      variant="standard"
                      disabled={disabledField}
                      multiline
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormTextFieldFast
                      form={formik}
                      field={billingContactBlkBuildingName}
                      variant="standard"
                      disabled={disabledField}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormTextFieldFast
                      form={formik}
                      field={billingContactPostalCode}
                      variant="standard"
                      disabled={disabledField}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <FormTextFieldFast
                      form={formik}
                      field={billingContactEmail}
                      variant="standard"
                      disabled={disabledField}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormTextFieldFast
                      form={formik}
                      field={billingContactLandlineNumber}
                      variant="standard"
                      disabled={disabledField}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormTextFieldFast
                      form={formik}
                      field={billingContactMobileNumber}
                      variant="standard"
                      disabled={disabledField}
                    />
                  </Grid>
                  {!disabledAdd && (
                    <>
                      <Grid item xs={12} sm={2}>
                        <MDButton
                          onClick={() => handleCopy(formik.setFieldValue)}
                          color="warning"
                          size="small"
                          fullWidth
                        >
                          <ControlPointDuplicateIcon />
                          &nbsp; Copy
                        </MDButton>
                      </Grid>
                      <Grid item xs={12} sm={10}>
                        <MDButton
                          onClick={formik.handleSubmit}
                          color="success"
                          size="small"
                          fullWidth
                        >
                          <AddIcon />
                          &nbsp; Add Billing Contact
                        </MDButton>
                      </Grid>
                    </>
                  )}
                </Grid>
              </MDBox>
            );
          }}
        />
      </MDBox>
    </MDBox>
  );
}

CustomerBillingContacts.propTypes = {
  mainForm: PropTypes.object.isRequired,
  mainFormField: PropTypes.object.isRequired,
  modeDisabled: PropTypes.bool,
  dispatchMainError: PropTypes.func,
};

export default CustomerBillingContacts;
