import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Grid from "@mui/material/Grid";

import MDBox from "components/atoms/MDBox";
import MDButton from "components/atoms/MDButton";

import ErrorSnackbar from "components/molecules/Notifications/ErrorSnackbar";
import SuccessSnackbar from "components/molecules/Notifications/SuccessSnackbar";

import DashboardLayout from "components/templates/DashboardLayout";
import Topnav from "components/organisms/Topnav";
import DashboardFooter from "components/organisms/Footer/DashboardFooter";
import PermissionDenied from "components/organisms/PermissionDenied";
import InvalidOperation from "components/organisms/InvalidOperation";
import IncompleteOperation from "components/organisms/IncompleteOperation";
import SubmissionProgress from "components/organisms/SubmissionProgress";

import Sidenav from "pages/invoices/pdf/components/Sidenav";
import Attachments from "pages/invoices/pdf/components/Attachments";
import InvoicePreview from "pages/invoices/pdf/components/InvoicePreview";
import Email from "pages/invoices/pdf/components/Email";

import { Formik, Form } from "formik";
import form from "pages/invoices/pdf/schemas/form";
import initialValues from "pages/invoices/pdf/schemas/initialValues";

import { AllowedTo } from "react-abac";

import { useInvoiceManager } from "pages/invoices/hooks/useInvoiceManager";

function InvoicePDFView() {
  const [isProgressing, setIsProgressing] = useState(false);
  const { mode, invoiceId } = useParams();
  const {
    modeTitle,
    modeSubmit,
    modeFieldDisabled,
    modePermission,
    modeValidation,
    submitSend,
    response,
    dispatchError,
  } = useInvoiceManager(mode, invoiceId);

  const navigate = useNavigate();
  const navigateAfter = (path, millisecond) => {
    return new Promise(() => {
      setTimeout(() => {
        navigate(path);
      }, millisecond);
    });
  };

  const { formId, formField } = form;

  const handleSubmit = async (values, actions) => {
    try {
      setIsProgressing(true);
      switch (mode) {
        case "send":
          await submitSend(values);
          await navigateAfter("/invoices/records", 3000);
          break;
        case "print":
          navigate("/invoices/records");
          break;
        default:
          actions.setSubmitting(false);
          break;
      }
    } catch (err) {
      dispatchError(err);
    } finally {
      actions.setSubmitting(false);
      setIsProgressing(false);
    }
  };

  return (
    <DashboardLayout>
      <Topnav title={modeTitle} />
      <AllowedTo perform={modePermission} no={() => <PermissionDenied />} />
      <MDBox pt={3}>
        {response.isPending && <SubmissionProgress />}
        {isProgressing && <SubmissionProgress />}
        {response.error && (
          <>
            {response.error?.name === "OperationInvalidError" && (
              <InvalidOperation />
            )}
            {response.error?.name === "OperationIncompleteError" && (
              <IncompleteOperation />
            )}
            <ErrorSnackbar
              title={modeTitle}
              dateTime=""
              message={response.error?.message}
              autoDismiss
            />
          </>
        )}
        {response.success && (
          <SuccessSnackbar
            title={modeTitle}
            dateTime=""
            message={response.success}
            autoDismiss
          />
        )}
        <MDBox>
          {response.error?.name !== "OperationInvalidError" &&
            response.data && (
              <Grid container spacing={2}>
                <Grid item xs={12} lg={2}>
                  <Sidenav mode={mode} />
                </Grid>
                <Grid item xs={12} lg={10}>
                  <MDBox mb={3}>
                    <Formik
                      enableReinitialize
                      initialValues={initialValues}
                      validationSchema={modeValidation}
                      onSubmit={handleSubmit}
                    >
                      {(formik) => (
                        <Form id={formId} autoComplete="off">
                          <Grid container spacing={3}>
                            {mode === "send" && (
                              <>
                                <Grid item xs={12}>
                                  <Email
                                    mainForm={formik}
                                    mainFormField={formField}
                                    modeDisabled={modeFieldDisabled}
                                    response={response}
                                    dispatchMainError={dispatchError}
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <Attachments
                                    mainForm={formik}
                                    mainFormField={formField}
                                    modeDisabled={modeFieldDisabled}
                                    mode={mode}
                                    dispatchMainError={dispatchError}
                                  />
                                </Grid>
                              </>
                            )}
                            <Grid item xs={12}>
                              <InvoicePreview
                                mainForm={formik}
                                mainFormField={formField}
                                mode={mode}
                                response={response}
                                dispatchMainError={dispatchError}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <MDBox
                                width="100%"
                                display="flex"
                                justifyContent="space-between"
                              >
                                <MDBox></MDBox>
                                <MDButton
                                  disabled={formik.isSubmitting}
                                  type="submit"
                                  variant="gradient"
                                  color="dark"
                                >
                                  {modeSubmit}
                                </MDButton>
                              </MDBox>
                            </Grid>
                          </Grid>
                        </Form>
                      )}
                    </Formik>
                  </MDBox>
                </Grid>
              </Grid>
            )}
        </MDBox>
      </MDBox>
      <DashboardFooter />
    </DashboardLayout>
  );
}

export default InvoicePDFView;
