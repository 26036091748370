import form, {
  toEmailsForm,
  ccEmailsForm,
  bccEmailsForm,
  emailAttachmentsForm,
} from "pages/invoices/pdf/schemas/form";

// import {
//   defaultSubject,
//   defaultContent,
// } from "pages/invoices/pdf/schemas/setups";

const {
  formField: {
    fromName,
    fromEmail,
    toEmails,
    ccEmails,
    bccEmails,
    emailSubject,
    emailContent,
    emailAttachments,
  },
} = form;

const initialValues = {
  [fromName.name]: "",
  [fromEmail.name]: "",
  [toEmails.name]: [],
  [ccEmails.name]: [],
  [bccEmails.name]: [],
  [emailSubject.name]: "", //defaultSubject,
  [emailContent.name]: "", //defaultContent,
  [emailAttachments.name]: [],
};

const {
  formField: { toEmail },
} = toEmailsForm;

const toEmailsInitialValues = {
  [toEmail.name]: "",
};

const {
  formField: { ccEmail },
} = ccEmailsForm;

const ccEmailsInitialValues = {
  [ccEmail.name]: "",
};

const {
  formField: { bccEmail },
} = bccEmailsForm;

const bccEmailsInitialValues = {
  [bccEmail.name]: "",
};

const {
  formField: { emailAttachmentFile },
} = emailAttachmentsForm;

const emailAttachmentsInitialValues = {
  [emailAttachmentFile.name]: "",
};

export {
  initialValues as default,
  toEmailsInitialValues,
  ccEmailsInitialValues,
  bccEmailsInitialValues,
  emailAttachmentsInitialValues,
};
