import PropTypes from "prop-types";

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import FormAutocompleteFast from "components/molecules/Formik/FormAutocompleteFast";

import MDBox from "components/atoms/MDBox";
import MDTypography from "components/atoms/MDTypography";
import FormTextFieldFast from "components/molecules/Formik/FormTextFieldFast";

import FormDatePicker from "components/molecules/Formik/FormDatePicker";

import { goodsReceivedByOptions } from "pages/purchaseorders/pdf/schemas/setups";

function GoodsReceived({ mainForm, mainFormField, modeDisabled }) {
  const { goodsReceivedAt, goodsReceivedBy, goodsReceivedRemarks } =
    mainFormField;

  //mainForm.values.goodsReceivedAt = format(new Date(), "dd/MM/yyyy");
  return (
    <Card id="email" sx={{ overflow: "visible" }}>
      <MDBox m={3}>
        <MDBox mb={3}>
          <MDTypography variant="h5">Goods Received</MDTypography>
        </MDBox>
        <MDBox>
          <Grid
            container
            rowSpacing={{ xs: 1, sm: 2 }}
            columnSpacing={{ xs: 1, sm: 3 }}
          >
            <Grid item xs={12} sm={6}>
              <FormDatePicker
                form={mainForm}
                field={goodsReceivedAt}
                inputFormat={"dd/MM/yyyy"}
                variant="standard"
                disabled={modeDisabled}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormAutocompleteFast
                freeSolo
                form={mainForm}
                field={goodsReceivedBy}
                options={goodsReceivedByOptions}
                disabled={modeDisabled}
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <MDTypography variant="body3" fontWeight="bold">
                {goodsReceivedRemarks.label}
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={10}>
              <FormTextFieldFast
                form={mainForm}
                field={goodsReceivedRemarks}
                variant="standard"
                disabled={modeDisabled}
              />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Card>
  );
}

GoodsReceived.propTypes = {
  mainForm: PropTypes.object.isRequired,
  mainFormField: PropTypes.object.isRequired,
  modeDisabled: PropTypes.bool.isRequired,
};

export default GoodsReceived;
