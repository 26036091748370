import PropTypes from "prop-types";

import Grid from "@mui/material/Grid";

import MDBox from "components/atoms/MDBox";
import MDTypography from "components/atoms/MDTypography";

import FormTextFieldFast from "components/molecules/Formik/FormTextFieldFast";
import FormAutocompleteFast from "components/molecules/Formik/FormAutocompleteFast";

import {
  customerTypeOptions,
  customerStatusOptions,
  customerPreferredContactOptions,
} from "pages/customers/manage/schemas/setups";
function CustomerParticulars({ mainForm, mainFormField, modeDisabled }) {
  const {
    customerName,
    customerUEN,
    customerAddress,
    customerType,
    customerStatus,
    customerBuildingName,
    customerPostalCode,
    customerLandlineNumber,
    customerMobileNumber,
    customerEmail,
    customerWebsite,
    customerPreferredContact,
  } = mainFormField;

  return (
    <MDBox>
      <MDBox lineHeight={0}>
        <MDTypography variant="h5">Customer Particulars</MDTypography>
        <MDTypography variant="body3" color="text">
          Please fill in Customer details
        </MDTypography>
      </MDBox>
      <MDBox mt={2}>
        <Grid container mb={1} columnSpacing={{ xs: 1, sm: 3 }}>
          <Grid item xs={12} sm={6}>
            <FormTextFieldFast
              form={mainForm}
              field={customerName}
              variant="standard"
              disabled={modeDisabled}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormAutocompleteFast
              form={mainForm}
              field={customerType}
              variant="standard"
              options={customerTypeOptions}
              disabled={modeDisabled}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTextFieldFast
              form={mainForm}
              field={customerUEN}
              variant="standard"
              disabled={modeDisabled}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormAutocompleteFast
              form={mainForm}
              field={customerStatus}
              variant="standard"
              options={customerStatusOptions}
              disabled={modeDisabled}
            />
          </Grid>
        </Grid>
        <Grid container mb={1} columnSpacing={{ xs: 1, sm: 3 }}>
          <Grid item xs={12} sm={12}>
            <FormTextFieldFast
              form={mainForm}
              field={customerAddress}
              variant="standard"
              disabled={modeDisabled}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTextFieldFast
              form={mainForm}
              field={customerBuildingName}
              variant="standard"
              disabled={modeDisabled}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTextFieldFast
              form={mainForm}
              field={customerPostalCode}
              variant="standard"
              disabled={modeDisabled}
            />
          </Grid>
        </Grid>
        <Grid container mb={1} columnSpacing={{ xs: 1, sm: 3 }}>
          <Grid item xs={12} sm={6}>
            <FormTextFieldFast
              form={mainForm}
              field={customerLandlineNumber}
              variant="standard"
              disabled={modeDisabled}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTextFieldFast
              form={mainForm}
              field={customerMobileNumber}
              variant="standard"
              disabled={modeDisabled}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTextFieldFast
              form={mainForm}
              field={customerEmail}
              variant="standard"
              disabled={modeDisabled}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTextFieldFast
              form={mainForm}
              field={customerWebsite}
              variant="standard"
              disabled={modeDisabled}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormAutocompleteFast
              form={mainForm}
              field={customerPreferredContact}
              variant="standard"
              options={customerPreferredContactOptions}
              disabled={modeDisabled}
            />
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

CustomerParticulars.propTypes = {
  mainForm: PropTypes.object.isRequired,
  mainFormField: PropTypes.object.isRequired,
  modeDisabled: PropTypes.bool,
  dispatchMainError: PropTypes.func,
};

export default CustomerParticulars;
