import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

import MDBox from "components/atoms/MDBox";
import MDTypography from "components/atoms/MDTypography";

import FormAttachments from "components/molecules/Formik/FormAttachments";
import FormTextFieldFast from "components/molecules/Formik/FormTextFieldFast";

import { stampAttachmentsForm } from "pages/settings/company/manage/schemas/form";
import { stampAttachmentsInitialValues } from "pages/settings/company/manage/schemas/initialValues";
import { stampAttachmentsValidation } from "pages/settings/company/manage/schemas/validations";

function BasicInformation({
  mainForm,
  mainFormField,
  modeDisabled,
  dispatchMainError,
}) {
  const { displayName, stampAttachments } = mainFormField;

  // destructure from Attachments Form for current section
  const {
    formId: stampAttachmentsFormId,
    formField: stampAttachmentsFormField,
  } = stampAttachmentsForm;
  const { stampAttachmentFile, attachmentName, attachmentURL } =
    stampAttachmentsFormField;

  return (
    <Card id="basic-information" sx={{ overflow: "visible" }}>
      <MDBox m={3}>
        <MDBox mb={3}>
          <MDTypography variant="h5">Basic Information</MDTypography>
        </MDBox>

        <MDBox>
          <Grid
            container
            rowSpacing={{ xs: 1, sm: 2 }}
            columnSpacing={{ xs: 1, sm: 3 }}
          >
            <Grid item xs={12} sm={2}>
              <MDTypography variant="body3" fontWeight="bold">
                {displayName.label}
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormTextFieldFast
                form={mainForm}
                field={displayName}
                variant="standard"
                disabled={modeDisabled}
              />
            </Grid>
            <Grid item xs={12} sm={4}></Grid>

            <Grid item xs={12} sm={2}>
              <MDTypography variant="body3" fontWeight="bold">
                {stampAttachments.label}
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormAttachments
                showTitle={false}
                form={mainForm}
                field={stampAttachments}
                max={1}
                disabled={modeDisabled}
                dispatchError={dispatchMainError}
                itemFormId={stampAttachmentsFormId}
                itemInitialValues={stampAttachmentsInitialValues}
                itemValidation={stampAttachmentsValidation}
                itemFileField={stampAttachmentFile}
                itemKeyField={attachmentURL}
                itemDisplayFields={[attachmentName]}
              />
            </Grid>
            <Grid item xs={12} sm={4}></Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default BasicInformation;
