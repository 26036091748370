import { Text, View } from "@react-pdf/renderer";

import styles from "pages/invoices/pdf/styles";

function AuditTrail({ values }) {
  return (
    <View
      style={[styles.containerRow, styles.spaceBetween, { marginTop: 10 }]}
      fixed
    >
      <Text fixed>Date: {values.invoiceDate}</Text>
      <Text style={{ fontWeight: "bold" }} fixed>
        INVOICE REF:{" "}
        <Text style={styles.samewidth}>GA-INV-{values.invoiceNumber}</Text>
      </Text>
    </View>
  );
}

export default AuditTrail;
