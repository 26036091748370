import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Grid from "@mui/material/Grid";

import MDBox from "components/atoms/MDBox";
import MDButton from "components/atoms/MDButton";

import ErrorSnackbar from "components/molecules/Notifications/ErrorSnackbar";
import SuccessSnackbar from "components/molecules/Notifications/SuccessSnackbar";

import DashboardLayout from "components/templates/DashboardLayout";
import Topnav from "components/organisms/Topnav";
import DashboardFooter from "components/organisms/Footer/DashboardFooter";
import PermissionDenied from "components/organisms/PermissionDenied";
import InvalidOperation from "components/organisms/InvalidOperation";
import IncompleteOperation from "components/organisms/IncompleteOperation";
import SubmissionProgress from "components/organisms/SubmissionProgress";

// Invoice page components
import Invoicing from "pages/invoices/manage/components/Invoicing";
import InvoiceDetails from "pages/invoices/manage/components/InvoiceDetails";
import ReminderDetails from "pages/invoices/manage/components/ReminderDetails";
import BillingInformation from "pages/invoices/manage/components/BillingInformation";
import Receipting from "pages/invoices/manage/components/Receipting";
import Closing from "pages/invoices/manage/components/Closing";
import InternalReference from "pages/invoices/manage/components/InternalReference";
import AuditTrail from "pages/invoices/manage/components/AuditTrail";
import PriceQuote from "pages/invoices/manage/components/PriceQuote";
import Sidenav from "pages/invoices/manage/components/Sidenav";

import { Formik, Form } from "formik";
import form from "pages/invoices/manage/schemas/form";

import { AllowedTo } from "react-abac";

import { useInvoiceManager } from "pages/invoices/hooks/useInvoiceManager";

function Invoice() {
  const [isProgressing, setIsProgressing] = useState(false);
  // In "convert" mode, the invoiceId param is actually salesorderId to be converted
  const { mode, invoiceId } = useParams();
  const {
    modeTitle,
    modeSubmit,
    modeFieldDisabled,
    defaultFieldReadOnly,
    modePermission,
    modeValidation,
    submitNew,
    submitEdit,
    submitReceipt,
    submitClose,
    submitDelete,
    response,
    dispatchError,
  } = useInvoiceManager(mode, invoiceId);

  const [defaultFinalReadOnly, setDefaultFinalReadOnly] =
    useState(defaultFieldReadOnly);
  const [overrideFieldDisabled, setOverrideFieldDisabled] =
    useState(modeFieldDisabled);
  const [showInvoicing, setShowInvoicing] = useState(false);
  const [showReceipting, setShowReceipting] = useState(false);
  const [showClosing, setShowClosing] = useState(false);

  const navigate = useNavigate();
  const navigateAfter = (path, millisecond) => {
    return new Promise(() => {
      setTimeout(() => {
        navigate(path);
      }, millisecond);
    });
  };

  const { formId, formField } = form;

  const handleSubmit = async (values, actions) => {
    try {
      setIsProgressing(true);
      switch (mode) {
        case "new":
        case "convert":
          await submitNew(values);
          await navigateAfter("/invoices/records", 3000);
          break;
        case "edit":
          await submitEdit(values);
          await navigateAfter("/invoices/records", 3000);
          break;
        case "receipt":
          await submitReceipt(values);
          await navigateAfter("/invoices/records", 3000);
          break;
        case "close":
          await submitClose(values);
          await navigateAfter("/invoices/records", 3000);
          break;
        case "view":
          navigate("/invoices/records");
          break;
        case "delete":
          await submitDelete();
          await navigateAfter("/invoices/records", 3000);
          break;
        default:
          actions.setSubmitting(false);
          break;
      }
    } catch (err) {
      dispatchError(err);
      actions.setSubmitting(false);
    } finally {
      actions.setSubmitting(false);
      setIsProgressing(false);
    }
  };

  useEffect(() => {
    try {
      const readOnly =
        mode === "edit" &&
        response.data.invoicingType !== "From Completed Sales Order"
          ? false
          : defaultFieldReadOnly;
      setDefaultFinalReadOnly(readOnly);
    } catch (err) {
      dispatchError(err);
    }
  }, [defaultFieldReadOnly, dispatchError, mode, response.data.invoicingType]);

  useEffect(() => {
    try {
      switch (mode) {
        case "new":
        case "convert":
        case "edit":
          setShowInvoicing(true);
          setShowReceipting(false);
          setShowClosing(false);
          setOverrideFieldDisabled(modeFieldDisabled);
          break;
        case "receipt":
          setShowInvoicing(false);
          setShowReceipting(true);
          setShowClosing(false);
          setOverrideFieldDisabled(true);
          break;
        case "close":
          setShowInvoicing(false);
          setShowReceipting(false);
          setShowClosing(true);
          setOverrideFieldDisabled(true);
          break;
        default:
          setShowInvoicing(true);
          setShowReceipting(true);
          setShowClosing(true);
          setOverrideFieldDisabled(modeFieldDisabled);
      }
    } catch (err) {
      dispatchError(err);
    }
  }, [dispatchError, mode, modeFieldDisabled]);

  return (
    <DashboardLayout>
      <Topnav title={modeTitle} />
      <AllowedTo perform={modePermission} no={() => <PermissionDenied />} />
      <MDBox pt={3}>
        {response.isPending && <SubmissionProgress />}
        {isProgressing && <SubmissionProgress />}
        {response.error && (
          <>
            {response.error?.name === "OperationInvalidError" && (
              <InvalidOperation />
            )}
            {response.error?.name === "OperationIncompleteError" && (
              <IncompleteOperation />
            )}
            <ErrorSnackbar
              title={modeTitle}
              dateTime=""
              message={response.error?.message}
              autoDismiss
            />
          </>
        )}
        {response.success && (
          <SuccessSnackbar
            title={modeTitle}
            dateTime=""
            message={response.success}
            autoDismiss
          />
        )}
        <MDBox>
          {response.error?.name !== "OperationInvalidError" && (
            <Grid container spacing={2}>
              <Grid item xs={12} lg={2}>
                <Sidenav mode={mode} />
              </Grid>

              <Grid item xs={12} lg={10}>
                <MDBox mb={3}>
                  <Formik
                    enableReinitialize
                    initialValues={response.data}
                    validationSchema={modeValidation}
                    onSubmit={handleSubmit}
                  >
                    {(formik) => (
                      <Form id={formId} autoComplete="off">
                        <Grid container spacing={3}>
                          <>
                            {showInvoicing && (
                              <Grid item xs={12}>
                                <Invoicing
                                  mainForm={formik}
                                  mainFormField={formField}
                                  modeDisabled={modeFieldDisabled}
                                  defaultReadOnly={defaultFinalReadOnly}
                                  mode={mode}
                                  dispatchMainError={dispatchError}
                                />
                              </Grid>
                            )}
                          </>
                          <>
                            {showReceipting && (
                              <Grid item xs={12}>
                                <Receipting
                                  mainForm={formik}
                                  mainFormField={formField}
                                  modeDisabled={modeFieldDisabled}
                                  mode={mode}
                                  dispatchMainError={dispatchError}
                                />
                              </Grid>
                            )}
                          </>
                          <>
                            {showClosing && (
                              <Grid item xs={12}>
                                <Closing
                                  mainForm={formik}
                                  mainFormField={formField}
                                  modeDisabled={modeFieldDisabled}
                                  mode={mode}
                                  dispatchMainError={dispatchError}
                                />
                              </Grid>
                            )}
                          </>
                          <Grid item xs={12}>
                            <InvoiceDetails
                              mainForm={formik}
                              mainFormField={formField}
                              modeDisabled={overrideFieldDisabled}
                              mode={mode}
                              dispatchMainError={dispatchError}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <BillingInformation
                              mainForm={formik}
                              mainFormField={formField}
                              modeDisabled={modeFieldDisabled}
                              defaultReadOnly={defaultFinalReadOnly}
                              dispatchMainError={dispatchError}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <ReminderDetails
                              mainForm={formik}
                              mainFormField={formField}
                              modeDisabled={overrideFieldDisabled}
                              mode={mode}
                              dispatchMainError={dispatchError}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <AuditTrail
                              mainForm={formik}
                              mainFormField={formField}
                              modeDisabled={overrideFieldDisabled}
                              mode={mode}
                              dispatchMainError={dispatchError}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <InternalReference
                              mainForm={formik}
                              mainFormField={formField}
                              modeDisabled={modeFieldDisabled}
                              defaultReadOnly={defaultFinalReadOnly}
                              dispatchMainError={dispatchError}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <PriceQuote
                              mainForm={formik}
                              mainFormField={formField}
                              modeDisabled={modeFieldDisabled}
                              defaultReadOnly={defaultFinalReadOnly}
                              dispatchMainError={dispatchError}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <MDBox
                              width="100%"
                              display="flex"
                              justifyContent="space-between"
                            >
                              <MDBox></MDBox>
                              <MDButton
                                disabled={formik.isSubmitting}
                                type="submit"
                                variant="gradient"
                                color="dark"
                              >
                                {modeSubmit}
                              </MDButton>
                            </MDBox>
                          </Grid>
                        </Grid>
                      </Form>
                    )}
                  </Formik>
                </MDBox>
              </Grid>
            </Grid>
          )}
        </MDBox>
      </MDBox>
      <DashboardFooter />
    </DashboardLayout>
  );
}

export default Invoice;
