const emailSubjectOptions = ["Supplier name / GA-PO-8xxxxx"];

const defaultSubject = emailSubjectOptions[0];

const emailContentOptions = [
  `Hi sales department,

  Please find attached PO for your processing. Thank you.`,
];

const defaultContent = emailContentOptions[0];

const emailContentOptionsForPaymentMade = [
  `Dear Sir/ Mdm,

  Please be informed that payment of $xxxx has been made for your invoice: xxxxx on <Date: xxx>. Thank you.`,
  `Hi,

  Please find reimbursement of $xxx to your account on <Date: xxxx>. Thank you.`,
];

const defaultContentForPaymentMade = emailContentOptionsForPaymentMade[0];

const emailContentOptionsForCancellation = [
  `Dear Supplier

  Please be informed that we would like to cancel Purchase order: GA-PO-xxxxxx. Thank you.`,
];

const defaultContentForCancellation = emailContentOptionsForCancellation[0];

const poPaymentModeOptions = [
  "Bank Transfer",
  "Paynow",
  "Petty Cash",
  "Fund request",
  "Reimbursement",
  "Cheque",
  "Others",
];
const defaultPOPaymentMode = poPaymentModeOptions[0];

const goodsReceivedByOptions = ["Account Personnel", "Finance Personnel"];
const defaultGoodsReceivedBy = goodsReceivedByOptions[0];

export {
  emailSubjectOptions,
  defaultSubject,
  emailContentOptions,
  defaultContent,
  emailContentOptionsForPaymentMade,
  defaultContentForPaymentMade,
  emailContentOptionsForCancellation,
  defaultContentForCancellation,
  poPaymentModeOptions,
  defaultPOPaymentMode,
  goodsReceivedByOptions,
  defaultGoodsReceivedBy,
};
