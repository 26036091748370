const form = {
  formId: "purchaseorder-email-form",
  formField: {
    poCancelFromName: {
      name: "poCancelFromName",
      label: "Sender Name",
      type: "text",
      errorMsg: "Sender Name is required.",
      invalidMsg: "Sender Name is invalid.",
    },
    poCancelFromEmail: {
      name: "poCancelFromEmail",
      label: "Sender Email",
      type: "text",
      errorMsg: "Sender Email is required.",
      invalidMsg: "Sender Email is invalid.",
    },
    poCancelToEmails: {
      name: "poCancelToEmails",
      label: "Recipient Emails",
      type: "box",
      min1Msg: "Must have at least 1 Recipient Email.",
      max20Msg: "Must have at most 20 Recipient Emails.",
    },
    poCancelCcEmails: {
      name: "poCancelCcEmails",
      label: "Cc Emails",
      type: "box",
      max20Msg: "Must have at most 20 CC Emails.",
    },
    poCancelBccEmails: {
      name: "poCancelBccEmails",
      label: "Bcc Emails",
      type: "box",
      max20Msg: "Must have at most 20 BCC Emails.",
    },
    poCancelEmailSubject: {
      name: "poCancelEmailSubject",
      label: "Subject",
      type: "text",
      errorMsg: "Subject is required.",
      invalidMsg: "Subject is invalid.",
    },
    poCancelEmailContent: {
      name: "poCancelEmailContent",
      label: "Content",
      type: "text",
      errorMsg: "Content is required.",
      invalidMsg: "Content is invalid.",
    },
    poCancelEmailAttachments: {
      name: "poCancelEmailAttachments",
      label: "Attachments",
      type: "box",
      max20Msg: "Must have at most 20 Attachments.",
    },
    poCancelDate: {
      name: "poCancelDate",
      label: "Date",
      type: "text",
      errorMsg: "Cancel Date Issued is required.",
      invalidMsg: "Cancel Date Issued is invalid.",
    },
    poCancelRemarks: {
      name: "poCancelRemarks",
      label: "Cancel Remarks",
      type: "text",
      errorMsg: "Cancel Remarks is required.",
      invalidMsg: "Cancel Remarks is invalid.",
    },
    goodsReceivedAt: {
      name: "goodsReceivedAt",
      label: "Date",
      type: "text",
      errorMsg: "Goods Received Date Issued is required.",
      invalidMsg: "Goods Received Date Issued is invalid.",
    },
    goodsReceivedBy: {
      name: "goodsReceivedBy",
      label: "Goods Received By",
      type: "text",
      errorMsg: "Goods Received By is required.",
      invalidMsg: "Goods Received By is invalid.",
    },
    goodsReceivedRemarks: {
      name: "goodsReceivedRemarks",
      label: "Goods Received Remarks",
      type: "text",
      errorMsg: "Goods Received Remarks is required.",
      invalidMsg: "Goods Received Remarks is invalid.",
    },
    poPaymentDate: {
      name: "poPaymentDate",
      label: "Date",
      type: "text",
      errorMsg: "Date Issued is required.",
      invalidMsg: "Date Issued is invalid.",
    },
    poPaymentMode: {
      name: "poPaymentMode",
      label: "Payment Mode",
      type: "text",
      errorMsg: "Payment Mode is required.",
      invalidMsg: "Payment is invalid.",
    },
    poPaymentRemarks: {
      name: "poPaymentRemarks",
      label: "Payment Remarks",
      type: "text",
      errorMsg: "Payment Remarks is required.",
      invalidMsg: "Payment Remarks is invalid.",
    },
    poPaymentFromName: {
      name: "poPaymentFromName",
      label: "Sender Name",
      type: "text",
      errorMsg: "Sender Name is required.",
      invalidMsg: "Sender Name is invalid.",
    },
    poPaymentFromEmail: {
      name: "poPaymentFromEmail",
      label: "Sender Email",
      type: "text",
      errorMsg: "Sender Email is required.",
      invalidMsg: "Sender Email is invalid.",
    },
    poPaymentToEmails: {
      name: "poPaymentToEmails",
      label: "Recipient Emails",
      type: "box",
      min1Msg: "Must have at least 1 Recipient Email.",
      max20Msg: "Must have at most 20 Recipient Emails.",
    },
    poPaymentCcEmails: {
      name: "poPaymentCcEmails",
      label: "Cc Emails",
      type: "box",
      max20Msg: "Must have at most 20 CC Emails.",
    },
    poPaymentBccEmails: {
      name: "poPaymentBccEmails",
      label: "Bcc Emails",
      type: "box",
      max20Msg: "Must have at most 20 BCC Emails.",
    },
    poPaymentEmailSubject: {
      name: "poPaymentEmailSubject",
      label: "Subject",
      type: "text",
      errorMsg: "Subject is required.",
      invalidMsg: "Subject is invalid.",
    },
    poPaymentEmailContent: {
      name: "poPaymentEmailContent",
      label: "Content",
      type: "text",
      errorMsg: "Content is required.",
      invalidMsg: "Content is invalid.",
    },
    poPaymentEmailAttachments: {
      name: "poPaymentEmailAttachments",
      label: "Attachments",
      type: "box",
      max20Msg: "Must have at most 20 Attachments.",
    },
    fromName: {
      name: "fromName",
      label: "Sender Name",
      type: "text",
      errorMsg: "Sender Name is required.",
      invalidMsg: "Sender Name is invalid.",
    },
    fromEmail: {
      name: "fromEmail",
      label: "Sender Email",
      type: "text",
      errorMsg: "Sender Email is required.",
      invalidMsg: "Sender Email is invalid.",
    },
    toEmails: {
      name: "toEmails",
      label: "Recipient Emails",
      type: "box",
      min1Msg: "Must have at least 1 Recipient Email.",
      max20Msg: "Must have at most 20 Recipient Emails.",
    },
    ccEmails: {
      name: "ccEmails",
      label: "Cc Emails",
      type: "box",
      max20Msg: "Must have at most 20 CC Emails.",
    },
    bccEmails: {
      name: "bccEmails",
      label: "Bcc Emails",
      type: "box",
      max20Msg: "Must have at most 20 BCC Emails.",
    },
    emailSubject: {
      name: "emailSubject",
      label: "Subject",
      type: "text",
      errorMsg: "Subject is required.",
      invalidMsg: "Subject is invalid.",
    },
    emailContent: {
      name: "emailContent",
      label: "Content",
      type: "text",
      errorMsg: "Content is required.",
      invalidMsg: "Content is invalid.",
    },
    emailAttachments: {
      name: "emailAttachments",
      label: "Attachments",
      type: "box",
      max20Msg: "Must have at most 20 Attachments.",
    },
  },
};

const toEmailsForm = {
  formId: "to-emails-form",
  formField: {
    toEmail: {
      name: "toEmail",
      label: "To",
      type: "text",
      errorMsg: "Email is required.",
      invalidMsg: "Email is invalid",
    },
  },
};

const ccEmailsForm = {
  formId: "cc-emails-form",
  formField: {
    ccEmail: {
      name: "ccEmail",
      label: "Cc",
      type: "text",
      errorMsg: "Email is required.",
      invalidMsg: "Email is invalid",
    },
  },
};

const bccEmailsForm = {
  formId: "bcc-emails-form",
  formField: {
    bccEmail: {
      name: "bccEmail",
      label: "Bcc",
      type: "text",
      errorMsg: "Email is required.",
      invalidMsg: "Email is invalid",
    },
  },
};

const emailAttachmentsForm = {
  formId: "email-attachments-form",
  formField: {
    emailAttachmentFile: {
      name: "emailAttachmentFile",
      label: "Attachment File",
      type: "box",
      errorMsg: "Attachment File is required.",
      invalidMsg: "File size must be less than 5 MB.",
    },
  },
};

export {
  form as default,
  toEmailsForm,
  ccEmailsForm,
  bccEmailsForm,
  emailAttachmentsForm,
};
