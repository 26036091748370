import { Text, View, Image } from "@react-pdf/renderer";
import styles from "pages/quotations/pdf/styles";

function Signatory({
  values,
  ownerName,
  ownerSignature,
  ownerDesignation,
  companyStamp,
}) {
  return (
    <View style={[styles.containerCol, styles.section]} wrap={false}>
      <View
        style={[styles.containerRow, styles.spaceBetween, styles.signatory]}
      >
        <View style={[styles.containerCol, { flexBasis: "35%" }]}>
          <Text>Yours Faithfully</Text>
          <Text>GATE PTE LTD</Text>
          <View
            style={[
              styles.bottomLine,
              styles.emailContent,
              styles.signaturespace,
              { paddingBottom: 10 },
            ]}
          >
            {!!ownerSignature && (
              <Image style={[styles.signature]} src={ownerSignature} />
            )}
            {!!companyStamp && (
              <Image
                style={[
                  styles.stamp,
                  {
                    marginTop: -60,
                    marginLeft: 125,
                    transform: "rotate(10deg)",
                  },
                ]}
                src={companyStamp}
              />
            )}
          </View>
          <Text>{ownerName}</Text>
          <Text>{ownerDesignation}</Text>
          <Text>Date: {values.quotationDate}</Text>
        </View>
        <View style={[styles.containerCol, { flexBasis: "35%" }]}>
          <Text>Name of Authorized Signatory</Text>
          <View
            style={[
              styles.bottomLine,
              styles.emailContent,
              styles.signaturespace,
              { paddingBottom: 10 },
            ]}
          ></View>
          <Text>Signature & Designation</Text>
          <Text>Company Stamp (if applicable)</Text>
          <Text>Date:</Text>
        </View>
      </View>
    </View>
  );
}

export default Signatory;
