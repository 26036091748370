import { useParams } from "react-router-dom";
import PurchaseOrder from "pages/purchaseorders/manage/PurchaseOrder";

// using Proxy Page to remount when route to same route location but different param
function PurchaseOrderProxy() {
  const { mode, purchaseorderId } = useParams();
  return <PurchaseOrder key={`${mode}/${purchaseorderId}`} />;
}

export default PurchaseOrderProxy;
