import { useEffect, useState, useCallback } from "react";

import PropTypes from "prop-types";

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import FormAutocompleteFast from "components/molecules/Formik/FormAutocompleteFast";

import MDBox from "components/atoms/MDBox";
import MDTypography from "components/atoms/MDTypography";
import FormField from "components/molecules/FormField";

import { useAuthContext } from "context/AuthContext";
import { useDocument } from "hooks/useDocument";
import { useCollection } from "hooks/useCollection";

import {
  emailSubjectOptions,
  emailContentOptions,
} from "pages/invoices/pdf/schemas/setups";

const usersQueries = {
  whereQueries: [
    {
      field: "deletedAt",
      condition: "==",
      value: null,
    },
  ],
};

function Email({
  mainForm,
  mainFormField,
  modeDisabled,
  response,
  dispatchMainError,
}) {
  const {
    values: mainFormValues,
    errors,
    touched,
    setFieldValue: setMainFormFieldValue,
  } = mainForm;

  const {
    fromName,
    fromEmail,
    toEmails,
    ccEmails,
    bccEmails,
    emailSubject,
    emailContent,
  } = mainFormField;

  const [recipientOptions, setRecipientOptions] = useState([]);
  const [contentOptions, setContentOptions] = useState([]);
  const { user } = useAuthContext();
  const { retrieveDoc } = useDocument();

  const { collectionData: usersData } = useCollection("users", usersQueries);

  const initializeFieldValue = useCallback(async () => {
    try {
      const senderUser = user.uid && (await retrieveDoc("users", user.uid));
      const senderName = senderUser ? senderUser.data.displayName : "";
      const senderEmail = senderUser ? senderUser.data.email : "";
      setMainFormFieldValue(fromName.name, senderName);
      setMainFormFieldValue(fromEmail.name, senderEmail);
      setMainFormFieldValue(bccEmails.name, [senderEmail]);

      const senderEmailSignOff = senderUser ? senderUser.data.emailSignOff : "";
      setContentOptions(
        emailContentOptions.map(
          (element) => `${element}\n\n${senderEmailSignOff}`
        )
      );

      const toBillingContactEmail = response?.data?.billingContactEmail;
      toBillingContactEmail &&
        setMainFormFieldValue(toEmails.name, [toBillingContactEmail]);

      const oldEmailSubject = response?.data?.emailSubject;
      oldEmailSubject &&
        setMainFormFieldValue(emailSubject.name, oldEmailSubject);
      const oldEmailContent = response?.data?.emailContent;
      oldEmailContent &&
        setMainFormFieldValue(emailContent.name, oldEmailContent);
    } catch (err) {
      dispatchMainError(err);
    }
  }, [
    bccEmails.name,
    dispatchMainError,
    emailContent.name,
    emailSubject.name,
    fromEmail.name,
    fromName.name,
    response?.data?.billingContactEmail,
    response?.data?.emailContent,
    response?.data?.emailSubject,
    retrieveDoc,
    setMainFormFieldValue,
    toEmails.name,
    user.uid,
  ]);

  const updateRecipientOptions = useCallback(async () => {
    try {
      const customerId = response.data.customerId;
      const customer =
        customerId && (await retrieveDoc("customers", customerId));

      const contactPersonsEmails = customer
        ? customer.data.contactPersons
            .map((contactPerson) => contactPerson.contactPersonEmail)
            .filter((email) => email !== null)
        : [];

      const billingContactsEmails = customer
        ? customer?.data?.billingContacts
            .map((billingContact) => billingContact.billingContactEmail)
            .filter((email) => email !== null)
        : [];

      const usersEmails =
        usersData &&
        usersData
          .map((user) => user.data.email)
          .filter((email) => email !== null);

      setRecipientOptions([
        ...billingContactsEmails,
        ...contactPersonsEmails,
        ...usersEmails,
      ]);
    } catch (err) {
      dispatchMainError(err);
    }
  }, [dispatchMainError, response.data.customerId, retrieveDoc, usersData]);

  useEffect(() => {
    try {
      initializeFieldValue();
      updateRecipientOptions();
    } catch (err) {
      dispatchMainError(err);
    }
  }, [dispatchMainError, initializeFieldValue, updateRecipientOptions]);

  return (
    <Card id="email" sx={{ overflow: "visible" }}>
      <MDBox m={3}>
        <MDBox mb={3}>
          <MDTypography variant="h5">Email</MDTypography>
        </MDBox>
        <MDBox>
          <Grid
            container
            rowSpacing={{ xs: 1, sm: 2 }}
            columnSpacing={{ xs: 1, sm: 3 }}
          >
            <Grid item xs={12} sm={2}>
              <MDTypography variant="body3" fontWeight="bold">
                From
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={5}>
              <FormField
                type={fromName.type}
                label={fromName.label}
                name={fromName.name}
                value={
                  mainFormValues.fromName && mainFormValues.fromName.length > 0
                    ? mainFormValues.fromName
                    : ""
                }
                placeholder={fromName.placeholder}
                disabled={modeDisabled}
                error={errors.fromName && touched.fromName}
                success={
                  !!mainFormValues.fromName &&
                  mainFormValues.fromName.length > 0 &&
                  !errors.fromName
                }
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={5}>
              <FormField
                type={fromEmail.type}
                label={fromEmail.label}
                name={fromEmail.name}
                value={
                  mainFormValues.fromEmail &&
                  mainFormValues.fromEmail.length > 0
                    ? mainFormValues.fromEmail
                    : ""
                }
                placeholder={fromEmail.placeholder}
                disabled={modeDisabled}
                error={errors.fromEmail && touched.fromEmail}
                success={
                  !!mainFormValues.fromEmail &&
                  mainFormValues.fromEmail.length > 0 &&
                  !errors.fromEmail
                }
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <MDTypography variant="body3" fontWeight="bold">
                To
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={10}>
              <FormAutocompleteFast
                multiple
                freeSolo
                form={mainForm}
                field={toEmails}
                variant="standard"
                options={recipientOptions}
                disabled={modeDisabled}
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <MDTypography variant="body3" fontWeight="bold">
                Cc
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={10}>
              <FormAutocompleteFast
                multiple
                freeSolo
                form={mainForm}
                field={ccEmails}
                variant="standard"
                options={recipientOptions}
                disabled={modeDisabled}
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <MDTypography variant="body3" fontWeight="bold">
                Bcc
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={10}>
              <FormAutocompleteFast
                multiple
                freeSolo
                form={mainForm}
                field={bccEmails}
                variant="standard"
                options={recipientOptions}
                disabled={modeDisabled}
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <MDTypography variant="body3" fontWeight="bold">
                {emailSubject.label}
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={10}>
              <FormAutocompleteFast
                freeSolo
                form={mainForm}
                field={emailSubject}
                variant="standard"
                options={emailSubjectOptions}
                disabled={modeDisabled}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <MDTypography variant="body3" fontWeight="bold">
                {emailContent.label}
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={10}>
              <FormAutocompleteFast
                freeSolo
                form={mainForm}
                field={emailContent}
                variant="standard"
                options={contentOptions}
                disabled={modeDisabled}
              />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Card>
  );
}

Email.propTypes = {
  mainForm: PropTypes.object.isRequired,
  mainFormField: PropTypes.object.isRequired,
  modeDisabled: PropTypes.bool.isRequired,
};

export default Email;
