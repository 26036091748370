import { Text, View } from "@react-pdf/renderer";
import styles from "pages/invoices/pdf/styles";

function Footer() {
  return (
    <View style={[styles.containerRow, styles.spaceBetween]} fixed>
      <Text
        style={styles.footer}
        render={({ pageNumber, totalPages }) =>
          `PAGE: ${pageNumber} OF ${totalPages}`
        }
        fixed
      />

      <View style={[styles.containerCol, { alignItems: "center" }]} fixed>
        <Text style={styles.footer} fixed>
          GATE PTE LTD
        </Text>
        <Text src="www.gifted-school.com" style={{ color: "#92D14E" }} fixed>
          www.gifted-school.com
        </Text>
      </View>
    </View>
  );
}

export default Footer;
