import { useEffect, useCallback, useState, useReducer } from "react";
import { useLocation } from "react-router-dom";

import { useAuthContext } from "context/AuthContext";
import { useDocument } from "hooks/useDocument";

import { useAbac } from "react-abac";
import { Permission } from "models/abac";

import initialValues from "pages/suppliers/manage/schemas/initialValues";
import validations, {
  noValidation,
} from "pages/suppliers/manage/schemas/validations";

// path in firebase
const collectionPath = "suppliers";

const initialState = {
  data: initialValues,
  isPending: false,
  error: null,
  success: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "DISMISS":
      return {
        isPending: false,
        data: initialValues,
        success: null,
        error: null,
      };
    case "IS_PENDING":
      return {
        isPending: true,
        data: initialValues,
        success: null,
        error: null,
      };
    case "INITIAL_SUPPLIER":
      return {
        isPending: false,
        data: action.payload,
        success: null,
        error: null,
      };
    case "RETRIEVED_SUPPLIER":
      return {
        isPending: false,
        data: action.payload,
        success: null,
        error: null,
      };
    case "REJECTED_SUPPLIER":
      return {
        isPending: false,
        data: action.payload,
        success: `Successfully rejected the supplier, ${action.payload.supplierName}.`,
        error: null,
      };
    case "CREATED_SUPPLIER":
      return {
        isPending: false,
        data: action.payload,
        success: `Successfully created the supplier, ${action.payload.supplierName}.`,
        error: null,
      };
    case "UPDATED_SUPPLIER":
      return {
        isPending: false,
        data: action.payload,
        success: `Successfully updated the supplier, ${action.payload.supplierName}.`,
        error: null,
      };
    case "DELETED_SUPPLIER":
      return {
        isPending: false,
        data: action.payload,
        success: `Successfully deleted the supplier, ${action.payload.supplierName}.`,
        error: null,
      };
    case "ERROR":
      return {
        isPending: false,
        data: initialValues,
        success: null,
        error: action.error,
      };
    default:
      return state;
  }
};

export const useSupplierManager = (mode, supplierId) => {
  const [response, dispatch] = useReducer(reducer, initialState);
  const [isUnmounted, setIsUnmounted] = useState(false);
  const { user } = useAuthContext();
  const { createDoc, retrieveDoc, updateDoc, deleteDoc, serverTimestamp } =
    useDocument();

  const { pathname } = useLocation();

  const { userHasPermissions } = useAbac();

  const dispatchIfNotUnmounted = useCallback(
    (action) => {
      if (!isUnmounted) {
        dispatch(action);
      }
    },
    [isUnmounted]
  );

  const dispatchDismiss = useCallback(
    () => dispatchIfNotUnmounted({ type: "DISMISS" }),
    [dispatchIfNotUnmounted]
  );

  const dispatchError = useCallback(
    (err) => {
      console.error(err);
      if (
        !["PermissionDeniedError", "OperationInvalidError"].includes(err.name)
      ) {
        err.message = "The operation couldn't be completed";
        err.name = "OperationIncompleteError";
        // TODO: send error stack to server
      }
      dispatchIfNotUnmounted({
        type: "ERROR",
        error: err,
      });
    },
    [dispatchIfNotUnmounted]
  );

  const validateOperation = useCallback(async () => {
    try {
      dispatchIfNotUnmounted({ type: "IS_PENDING" });
      let operationInvalidError = new Error(
        "Invalid Operation. You are not allowed to carry out this activity."
      );
      operationInvalidError.name = "OperationInvalidError";

      const retrievedSupplier =
        supplierId && (await retrieveDoc(collectionPath, supplierId));

      // validate operation
      switch (mode) {
        case "new":
          if (!pathname.includes("/suppliers/manage")) {
            throw operationInvalidError;
          }

          dispatchIfNotUnmounted({
            type: "INITIAL_SUPPLIER",
            payload: initialValues,
          });
          break;
        case "view":
        case "edit":
        case "delete":
          if (!pathname.includes("/suppliers/manage")) {
            throw operationInvalidError;
          }

          dispatchIfNotUnmounted({
            type: "RETRIEVED_SUPPLIER",
            payload: retrievedSupplier.data,
          });
          break;
        default:
          throw operationInvalidError;
      }
    } catch (err) {
      dispatchError(err);
    }
  }, [
    dispatchIfNotUnmounted,
    supplierId,
    retrieveDoc,
    mode,
    pathname,
    dispatchError,
  ]);

  useEffect(() => {
    try {
      validateOperation();
    } catch (err) {
      dispatchError(err);
    }
    return () => {
      setIsUnmounted(true);
    };
  }, [dispatchError, validateOperation]);

  let modeTitle = "";
  let modeSubmit = "";
  let modeFieldDisabled = true;
  let modePermission = "";
  let modeValidation = noValidation;
  switch (mode) {
    case "new":
      modeTitle = "Create Supplier";
      modeSubmit = "Create";
      modeFieldDisabled = false;
      modePermission = Permission.CREATE_SUPPLIER;
      modeValidation = validations;
      break;
    case "edit":
      modeTitle = "Edit Supplier";
      modeSubmit = "Update";
      modeFieldDisabled = false;
      modePermission = Permission.UPDATE_SUPPLIER;
      modeValidation = validations;
      break;
    case "view":
      modeTitle = "View Supplier";
      modeSubmit = "Close";
      modeFieldDisabled = true;
      modePermission = Permission.READ_SUPPLIER;
      modeValidation = noValidation;
      break;
    case "delete":
      modeTitle = "Delete Supplier";
      modeSubmit = "Delete";
      modeFieldDisabled = true;
      modePermission = Permission.DELETE_SUPPLIER;
      modeValidation = noValidation;
      break;
    default:
      modeTitle = "Illegal Action";
  }

  // TODO: move to Service Layer custom hook
  const submitReject = async (values) => {
    try {
      dispatchIfNotUnmounted({ type: "IS_PENDING" });
      if (userHasPermissions(Permission.REJECT_SUPPLIER)) {
        const updatedDoc = await updateDoc(collectionPath, supplierId, {
          approvedAt: null,
          approvedBy: null,
          rejectedAt: serverTimestamp(),
          rejectedBy: user.uid,
        });

        dispatchIfNotUnmounted({
          type: "REJECTED_SUPPLIER",
          payload: updatedDoc.data,
        });
      } else {
        let error = new Error(
          "Permission Denied. You are not allowed to reject supplier."
        );
        error.name = "PermissionDeniedError";
        throw error;
      }
    } catch (err) {
      dispatchError(err);
    }
  };

  // TODO: move to Service Layer custom hook
  const submitNew = async (values) => {
    try {
      dispatchIfNotUnmounted({ type: "IS_PENDING" });
      if (userHasPermissions(Permission.CREATE_SUPPLIER)) {
        const createdDoc = await createDoc(
          collectionPath,
          {
            ...values,
            approvedAt: null,
            approvedBy: null,
            rejectedAt: null,
            rejectedBy: null,
          },
          user.uid
        );
        dispatchIfNotUnmounted({
          type: "CREATED_SUPPLIER",
          payload: createdDoc.data,
        });
      } else {
        let error = new Error(
          "Permission Denied. You are not allowed to create supplier."
        );
        error.name = "PermissionDeniedError";
        throw error;
      }
    } catch (err) {
      dispatchError(err);
    }
  };

  // TODO: move to Service Layer custom hook
  const submitEdit = async (values) => {
    try {
      dispatchIfNotUnmounted({ type: "IS_PENDING" });
      if (userHasPermissions(Permission.UPDATE_SUPPLIER)) {
        const updatedDoc = await updateDoc(collectionPath, supplierId, {
          ...values,
          modifiedAt: serverTimestamp(),
          modifiedBy: user.uid,
          approvedAt: null,
          approvedBy: null,
        });
        dispatchIfNotUnmounted({
          type: "UPDATED_SUPPLIER",
          payload: updatedDoc.data,
        });
      } else {
        let error = new Error(
          "Permission Denied. You are not allowed to update supplier."
        );
        error.name = "PermissionDeniedError";
        throw error;
      }
    } catch (err) {
      dispatchError(err);
    }
  };

  // TODO: move to Service Layer custom hook
  const submitDelete = async () => {
    try {
      dispatchIfNotUnmounted({ type: "IS_PENDING" });
      if (userHasPermissions(Permission.DELETE_SUPPLIER)) {
        const deletedDoc = await deleteDoc(collectionPath, supplierId, {
          deletedAt: serverTimestamp(),
          deletedBy: user.uid,
        });
        dispatchIfNotUnmounted({
          type: "DELETED_SUPPLIER",
          payload: deletedDoc.data,
        });
      } else {
        let error = new Error(
          "Permission Denied. You are not allowed to delete supplier."
        );
        error.name = "PermissionDeniedError";
        throw error;
      }
    } catch (err) {
      dispatchError(err);
    }
  };

  return {
    modeTitle,
    modeSubmit,
    modeFieldDisabled,
    modePermission,
    modeValidation,
    submitReject,
    submitNew,
    submitEdit,
    submitDelete,
    response,
    dispatchDismiss,
    dispatchError,
  };
};
