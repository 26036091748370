const supplierTypeOptions = ["Term", "Non-Term", "SC-Term", "SC-Non-Term"];
const supplierStatusOptions = ["Standard", "VIP", "V VIP"];
const supplierPreferredContactOptions = [
  "Office / Home",
  "Mobile",
  "WhatsApp",
  "Email",
];

export {
  supplierTypeOptions,
  supplierStatusOptions,
  supplierPreferredContactOptions,
};
