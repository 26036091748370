import { useEffect, useState, useCallback } from "react";

import PropTypes from "prop-types";

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";

import MDBox from "components/atoms/MDBox";
import MDTypography from "components/atoms/MDTypography";

import FormTextFieldFast from "components/molecules/Formik/FormTextFieldFast";
import FormAutocompleteFast from "components/molecules/Formik/FormAutocompleteFast";

import { useAuthContext } from "context/AuthContext";
import { useDocument } from "hooks/useDocument";
import { useCollection } from "hooks/useCollection";

import {
  emailSubjectOptions,
  emailContentOptions,
} from "pages/invoices/manage/schemas/setups";

const usersQueries = {
  whereQueries: [
    {
      field: "deletedAt",
      condition: "==",
      value: null,
    },
  ],
};

function ReminderDetails({
  mainForm,
  mainFormField,
  modeDisabled,
  mode,
  dispatchMainError,
}) {
  const { values: mainFormValues, setFieldValue: setMainFormFieldValue } =
    mainForm;

  const {
    reminderIntervalDays,
    reminderFromName,
    reminderFromEmail,
    reminderToEmails,
    reminderCcEmails,
    reminderBccEmails,
    reminderEmailSubject,
    reminderEmailContent,
  } = mainFormField;

  const [recipientOptions, setRecipientOptions] = useState([]);
  const [contentOptions, setContentOptions] = useState([]);
  const { user } = useAuthContext();
  const { retrieveDoc } = useDocument();

  const { collectionData: usersData } = useCollection("users", usersQueries);

  const initializeFieldValue = useCallback(async () => {
    try {
      const senderUser = user.uid && (await retrieveDoc("users", user.uid));
      const senderName = senderUser ? senderUser.data.displayName : "";
      const senderEmail = senderUser ? senderUser.data.email : "";
      setMainFormFieldValue(reminderFromName.name, senderName);
      setMainFormFieldValue(reminderFromEmail.name, senderEmail);
      setMainFormFieldValue(reminderBccEmails.name, [senderEmail]);

      const senderEmailSignOff = senderUser ? senderUser.data.emailSignOff : "";
      setContentOptions(
        emailContentOptions.map(
          (element) => `${element}\n\n${senderEmailSignOff}`
        )
      );

      const toBillingContactEmail = mainFormValues.billingContactEmail;
      toBillingContactEmail &&
        setMainFormFieldValue(reminderToEmails.name, [toBillingContactEmail]);
    } catch (err) {
      dispatchMainError(err);
    }
  }, [
    user.uid,
    retrieveDoc,
    setMainFormFieldValue,
    reminderFromName.name,
    reminderFromEmail.name,
    reminderBccEmails.name,
    mainFormValues.billingContactEmail,
    reminderToEmails.name,
    dispatchMainError,
  ]);

  const updateRecipientOptions = useCallback(async () => {
    try {
      const customerId = mainFormValues.customerId;
      const customer =
        customerId && (await retrieveDoc("customers", customerId));

      const contactPersonsEmails = customer
        ? customer?.data?.contactPersons
            .map((contactPerson) => contactPerson.contactPersonEmail)
            .filter((email) => email !== null)
        : [];

      const billingContactsEmails = customer
        ? customer?.data?.billingContacts
            .map((billingContact) => billingContact.billingContactEmail)
            .filter((email) => email !== null)
        : [];

      const usersEmails =
        usersData &&
        usersData
          .map((user) => user.data.email)
          .filter((email) => email !== null);

      setRecipientOptions([
        ...billingContactsEmails,
        ...contactPersonsEmails,
        ...usersEmails,
      ]);
    } catch (err) {
      dispatchMainError(err);
    }
  }, [dispatchMainError, mainFormValues.customerId, retrieveDoc, usersData]);

  useEffect(() => {
    try {
      if (mode === "new" || mode === "convert" || mode === "edit") {
        initializeFieldValue();
        updateRecipientOptions();
      }
    } catch (err) {
      dispatchMainError(err);
    }
  }, [dispatchMainError, initializeFieldValue, mode, updateRecipientOptions]);

  return (
    <Card id="reminder-details" sx={{ overflow: "visible" }}>
      <MDBox m={3}>
        <MDBox mb={3}>
          <MDTypography variant="h5">Reminder Details</MDTypography>
        </MDBox>
        <MDBox>
          <Grid
            container
            rowSpacing={{ xs: 1, sm: 2 }}
            columnSpacing={{ xs: 1, sm: 3 }}
          >
            <Grid item xs={12} sm={2}>
              <MDTypography variant="body3" fontWeight="bold">
                {reminderIntervalDays.label}
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormTextFieldFast
                form={mainForm}
                field={reminderIntervalDays}
                variant="standard"
                disabled={modeDisabled}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">Days</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}></Grid>
            <Grid item xs={12} sm={2}>
              <MDTypography variant="body3" fontWeight="bold">
                From
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={5}>
              <FormTextFieldFast
                form={mainForm}
                field={reminderFromName}
                variant="standard"
                disabled={modeDisabled}
              />
            </Grid>
            <Grid item xs={12} sm={5}>
              <FormTextFieldFast
                form={mainForm}
                field={reminderFromEmail}
                variant="standard"
                disabled={modeDisabled}
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <MDTypography variant="body3" fontWeight="bold">
                To
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={10}>
              <FormAutocompleteFast
                multiple
                freeSolo
                form={mainForm}
                field={reminderToEmails}
                variant="standard"
                options={recipientOptions}
                disabled={modeDisabled}
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <MDTypography variant="body3" fontWeight="bold">
                Cc
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={10}>
              <FormAutocompleteFast
                multiple
                freeSolo
                form={mainForm}
                field={reminderCcEmails}
                variant="standard"
                options={recipientOptions}
                disabled={modeDisabled}
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <MDTypography variant="body3" fontWeight="bold">
                Bcc
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={10}>
              <FormAutocompleteFast
                multiple
                freeSolo
                form={mainForm}
                field={reminderBccEmails}
                variant="standard"
                options={recipientOptions}
                disabled={modeDisabled}
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <MDTypography variant="body3" fontWeight="bold">
                {reminderEmailSubject.label}
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={10}>
              <FormAutocompleteFast
                freeSolo
                form={mainForm}
                field={reminderEmailSubject}
                variant="standard"
                options={emailSubjectOptions}
                disabled={modeDisabled}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <MDTypography variant="body3" fontWeight="bold">
                {reminderEmailContent.label}
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={10}>
              <FormAutocompleteFast
                freeSolo
                form={mainForm}
                field={reminderEmailContent}
                variant="standard"
                options={contentOptions}
                disabled={modeDisabled}
              />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Card>
  );
}

ReminderDetails.propTypes = {
  mainForm: PropTypes.object.isRequired,
  mainFormField: PropTypes.object.isRequired,
  modeDisabled: PropTypes.bool.isRequired,
};

export default ReminderDetails;
