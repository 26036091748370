import { useEffect, useState, useReducer, useCallback } from "react";

import { useNavigate } from "react-router-dom";

import { useAuthContext } from "context/AuthContext";
import { useDocument } from "hooks/useDocument";
import { useCollection } from "hooks/useCollection";

import { useInvoiceActionManager } from "pages/invoices/hooks/useInvoiceActionManager";

import Tooltip from "@mui/material/Tooltip";
import MDBox from "components/atoms/MDBox";
import MDButton from "components/atoms/MDButton";
import MDTypography from "components/atoms/MDTypography";
import PrintIcon from "@mui/icons-material/Print";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import EmailIcon from "@mui/icons-material/Email";
import DeleteIcon from "@mui/icons-material/Delete";
import BlurLinearIcon from "@mui/icons-material/BlurLinear";
import CircularProgress from "@mui/material/CircularProgress";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import CreditScoreIcon from "@mui/icons-material/CreditScore";

import { AllowedTo, useAbac } from "react-abac";
import { Permission } from "models/abac";

import {
  columnOptions,
  defaultInvoiceColumns,
} from "pages/invoices/manage/schemas/setups";

import { parse } from "json2csv";

import currency from "currency.js";

const invoicesQueries = {
  whereQueries: [
    {
      field: "deletedAt",
      condition: "==",
      value: null,
    },
  ],
  orderByQueries: [
    {
      field: "invoiceDate",
      direction: "desc",
    },
  ],
};

const usersQueries = {
  whereQueries: [
    {
      field: "deletedAt",
      condition: "==",
      value: null,
    },
  ],
};

const salesordersQueries = {
  whereQueries: [
    {
      field: "convertedAt",
      condition: "!=",
      value: null,
    },
  ],
};

let initialState = {
  isPending: false,
  data: null,
  success: null,
  error: null,
};

const schemaReducer = (state, action) => {
  switch (action.type) {
    case "DISMISS":
      return initialState;
    case "IS_PENDING":
      return {
        isPending: true,
        data: null,
        success: null,
        error: null,
      };
    case "EXPORTED_INVOICES":
      return {
        isPending: false,
        data: null,
        success: `Successfully exported the invoices.`,
        error: null,
      };
    case "ERROR":
      return {
        isPending: false,
        data: null,
        success: null,
        error: action.error,
      };
    default:
      return state;
  }
};

export const useRecordsManager = () => {
  const [response, dispatch] = useReducer(schemaReducer, initialState);
  const [hiddenColumns, setHiddenColumns] = useState([]);
  const [userData, setUserData] = useState(null);
  const [isDataReady, setIsDataReady] = useState(false);
  const [isUnmounted, setIsUnmounted] = useState(false);
  const [isChanged, setIsChanged] = useState(0);

  const { user } = useAuthContext();
  const { userHasPermissions } = useAbac();

  const { getActionState } = useInvoiceActionManager();
  const { retrieveDoc, updateDoc, serverTimestamp } = useDocument();

  const { collectionData: invoicesData } = useCollection(
    "invoices",
    invoicesQueries
  );
  const { collectionData: usersData } = useCollection("users", usersQueries);
  const { collectionData: salesordersData } = useCollection(
    "salesorders",
    salesordersQueries
  );

  const dispatchIfNotUnmounted = useCallback(
    (action) => {
      if (!isUnmounted) {
        dispatch(action);
      }
    },
    [isUnmounted]
  );

  const dispatchDismiss = useCallback(
    () => dispatchIfNotUnmounted({ type: "DISMISS" }),
    [dispatchIfNotUnmounted]
  );

  const dispatchError = useCallback(
    (err) => {
      console.error(err);
      if (
        ![
          "PermissionDeniedError",
          "OperationInvalidError",
          "CustomerInvalidError",
        ].includes(err.name)
      ) {
        err.message = "The operation couldn't be completed";
        err.name = "OperationIncompleteError";
        // TODO: send error stack to server
      }
      dispatchIfNotUnmounted({
        type: "ERROR",
        error: err,
      });
    },
    [dispatchIfNotUnmounted]
  );

  const navigate = useNavigate();

  const toPresentationValue = (data) => {
    try {
      const toDateString = (value) => {
        return value ? value.toDate().toLocaleDateString("en-Sg") : "-";
      };

      const toDisplayName = (userId) => {
        const user = usersData.find((document) => document.id === userId);
        return userId ? (user ? user.data.displayName : "Unknown") : "-";
      };

      const toAmountString = (value) => {
        return value ? currency(value).format() : "-";
      };

      const getSalesorderNumber = (salesorderId) => {
        const salesorder = salesordersData.find(
          (document) => document.id === salesorderId
        );
        return salesorder ? `GA-SO-${salesorder.data.salesorderNumber}` : "-";
      };

      data.invoiceDateText = toDateString(data.invoiceDate);
      data.installationStartDateText = toDateString(data.installationStartDate);
      data.installationEndDateText = toDateString(data.installationEndDate);

      data.createdAtText = toDateString(data.createdAt);
      data.modifiedAtText = toDateString(data.modifiedAt);
      data.deletedAtText = toDateString(data.deletedAt);
      data.sentAtText = toDateString(data.sentAt);
      data.receiptedAtText = toDateString(data.receiptedAt);
      data.closedAtText = toDateString(data.closedAt);

      data.invoiceClosingDateText = toDateString(data.invoiceClosingDate);
      data.invoiceReceiptingDateText = toDateString(data.invoiceReceiptingDate);

      data.createdByText = toDisplayName(data.createdBy);
      data.modifiedByText = toDisplayName(data.modifiedBy);
      data.deletedByText = toDisplayName(data.deletedBy);
      data.sentByText = toDisplayName(data.sentBy);
      data.receiptedByText = toDisplayName(data.receiptedBy);
      data.closedByText = toDisplayName(data.closedBy);

      data.invoiceGrossTotalText = toAmountString(data.invoiceGrossTotal);
      data.invoiceDiscountTotalText = toAmountString(data.invoiceDiscountTotal);
      data.invoiceBeforeGstTotalText = toAmountString(
        data.invoiceBeforeGstTotalText
      );
      data.invoiceAdditionalDiscountText = toAmountString(
        data.invoiceAdditionalDiscount
      );
      data.invoiceNetBeforeGstTotalText = toAmountString(
        data.invoiceNetBeforeGstTotal
      );
      data.invoiceGstAmountText = toAmountString(data.invoiceGstAmount);
      data.invoiceAfterGstTotalText = toAmountString(data.invoiceAfterGstTotal);
      data.invoiceDepositText = toAmountString(data.invoiceDeposit);
      data.invoiceBalanceDueText = toAmountString(data.invoiceBalanceDue);

      data.invoiceNumberText = `GA-INV-${data.invoiceNumber}`;

      data.salesorderIdText = data.salesorderReference ?? "-";
      data.salesorderNumberText =
        data.invoicingType === "From Completed Sales Order"
          ? getSalesorderNumber(data.salesorderIdText)
          : "-";

      const {
        latestAction,
        canSend,
        canReceipt,
        canClose,
        canPrint,
        canEdit,
        canDelete,
      } = getActionState(data);

      data.canSend = canSend;
      data.canReceipt = canReceipt;
      data.canClose = canClose;
      data.canPrint = canPrint;
      data.canEdit = canEdit;
      data.canDelete = canDelete;

      switch (latestAction.name) {
        case "created":
          data.status = "Pending Send";
          break;
        case "modified":
          data.status = "Pending Send";
          break;
        case "sent":
          data.status = "Invoiced";
          break;
        case "receipted":
          data.status = "Received";
          break;
        case "closed":
          data.status = "Closed";
          break;
        case "deleted":
          data.status = "Deleted";
          break;
        default:
          data.status = "-";
      }

      return data;
    } catch (err) {
      dispatchError(err);
    }
  };

  const toExportValue = (data) => {
    try {
      const toDateString = (value) => {
        return value ? value.toDate().toLocaleDateString("en-Sg") : "-";
      };

      const toDisplayName = (userId) => {
        const user = usersData.find((document) => document.id === userId);
        return userId ? (user ? user.data.displayName : "Unknown") : "-";
      };

      const getSalesorderNumber = (salesorderId) => {
        const salesorder = salesordersData.find(
          (document) => document.id === salesorderId
        );
        return salesorder ? `GA-SO-${salesorder.data.salesorderNumber}` : "-";
      };

      let salesorderReference = "";
      if (data.invoicingType === "From Completed Sales Order") {
        salesorderReference = getSalesorderNumber(data.salesorderReference);
      }

      return {
        createdBy: toDisplayName(data.createdBy),
        modifiedBy: toDisplayName(data.modifiedBy),
        invoicingType: data.invoicingType,
        salesorderReference: salesorderReference,
        invoiceRemarks: data.invoiceRemarks,
        invoiceTerms: data.invoiceTerms,
        invoiceReceiptingDate: toDateString(data.invoiceReceiptingDate),
        receiptedAt: toDateString(data.receiptedAt),
        receiptedBy: toDisplayName(data.receiptedBy),
        invoiceClosingDate: toDateString(data.invoiceClosingDate),
        closedAt: toDateString(data.closedAt),
        closedBy: toDisplayName(data.closedBy),
        customerName: data.customerName,
        billingContactName: data.billingContactName,
        paymentType: data.paymentType,
        invoiceDate: toDateString(data.invoiceDate),
        invoiceNumber: `GA-INV-${data.invoiceNumber}`,
        invoiceDiscountType: data.invoiceDiscountType,
        invoiceGrossTotal: data.invoiceGrossTotal,
        invoiceDiscountTotal: data.invoiceDiscountTotal,
        invoiceBeforeGstTotal: data.invoiceBeforeGstTotal,
        invoiceAdditionalDiscount: data.invoiceAdditionalDiscount,
        invoiceNetBeforeGstTotal: data.invoiceNetBeforeGstTotal,
        invoiceGstAmount: data.invoiceGstAmount,
        invoiceAfterGstTotal: data.invoiceAfterGstTotal,
        invoiceDeposit: data.invoiceDeposit,
        invoiceBalanceDue: data.invoiceBalanceDue,
        serviceTeams: data.serviceTeams
          .map((team) => team.serviceTeamName)
          .join("/"),
        serviceTypes: data.serviceTypes
          .map((type) => type.serviceTypeName)
          .join("/"),
      };
    } catch (err) {
      dispatchError(err);
    }
  };

  const setupTable = useCallback(async () => {
    const userData = user && (await retrieveDoc("users", user.uid));
    const preferredInvoiceColumnsKey =
      userData.data?.preferredInvoiceColumns?.map((elem) => elem.key);
    const defaultInvoiceColumnsKey = defaultInvoiceColumns.map(
      (elem) => elem.key
    );

    const selectedColumns =
      preferredInvoiceColumnsKey ?? defaultInvoiceColumnsKey;
    const allColumnsKey = columnOptions.map((elem) => elem.key);
    const unselectedColumns = allColumnsKey.filter(
      (columnKey) => !selectedColumns.includes(columnKey)
    );

    setUserData(userData);
    setHiddenColumns(unselectedColumns);
    setIsDataReady(true);
  }, [retrieveDoc, user]);

  useEffect(() => {
    try {
      setupTable();
    } catch (err) {
      dispatchError(err);
    }
    return () => {
      setIsUnmounted(true);
    };
  }, [dispatchError, setupTable, isChanged]);

  const handleUserPreference = async (newdata) => {
    try {
      await updateDoc("users", user.uid, {
        preferredInvoiceColumns: newdata.preferredInvoiceColumns,
        modifiedAt: serverTimestamp(),
        modifiedBy: user.uid,
      });
      setIsChanged(isChanged + 1);
    } catch (err) {
      dispatchError(err);
    }
  };

  const handleExport = async (permission) => {
    try {
      dispatchIfNotUnmounted({ type: "IS_PENDING" });
      if (userHasPermissions(permission)) {
        const fields = [
          "createdBy",
          "modifiedBy",
          "invoicingType",
          "salesorderReference",
          "invoiceRemarks",
          "invoiceTerms",
          "invoiceReceiptingDate",
          "receiptedAt",
          "receiptedBy",
          "invoiceClosingDate",
          "closedAt",
          "closedBy",
          "customerName",
          "billingContactName",
          "paymentType",
          "invoiceDate",
          "invoiceNumber",
          "invoiceDiscountType",
          "invoiceGrossTotal",
          "invoiceDiscountTotal",
          "invoiceBeforeGstTotal",
          "invoiceAdditionalDiscount",
          "invoiceNetBeforeGstTotal",
          "invoiceGstAmount",
          "invoiceAfterGstTotal",
          "invoiceDeposit",
          "invoiceBalanceDue",
          "serviceTeams",
          "serviceTypes",
        ];
        const opts = { fields };

        const data =
          invoicesData &&
          invoicesData.map((salesorder) => toExportValue(salesorder.data));

        try {
          const csv = parse(data, opts);
          let csvContent = `data:text/csv;charset=utf-8,${encodeURIComponent(
            csv
          )}`;
          const encodedUri = csvContent;
          window.open(encodedUri);
        } catch (err) {
          console.error(err);
        }
        dispatchIfNotUnmounted({
          type: "EXPORTED_INVOICES",
          payload: null,
        });
      } else {
        let error = new Error(
          "Permission Denied. You are not allowed to export invoices."
        );
        error.name = "PermissionDeniedError";
        throw error;
      }
    } catch (err) {
      dispatchError(err);
    }
  };

  const rows =
    invoicesData &&
    invoicesData.map((invoice) => {
      return toPresentationValue({ id: invoice.id, ...invoice.data });
    });

  const tooltipCell = (accessor) => {
    return (
      <Tooltip title={accessor ?? "-"}>
        <MDBox>
          {accessor ? accessor.substring(0, 30) : "-"}
          {accessor && accessor.length > 30 ? "..." : ""}
        </MDBox>
      </Tooltip>
    );
  };

  const columns = [
    {
      Header: "canSend",
      Footer: "canSend",
      accessor: "canSend",
    },
    {
      Header: "canReceipt",
      Footer: "canReceipt",
      accessor: "canReceipt",
    },
    {
      Header: "canClose",
      Footer: "canClose",
      accessor: "canClose",
    },
    {
      Header: "canPrint",
      Footer: "canPrint",
      accessor: "canPrint",
    },
    {
      Header: "canEdit",
      Footer: "canEdit",
      accessor: "canEdit",
    },
    {
      Header: "canDelete",
      Footer: "canDelete",
      accessor: "canDelete",
    },
    {
      Header: "Id",
      Footer: "Id",
      accessor: "id",
    },
    {
      Header: "Invoice",
      Footer: "Invoice",
      accessor: "invoiceNumberText",
      Cell: ({ cell }) => {
        const invoiceNumberText = cell.row.values.invoiceNumberText;
        return invoiceNumberText !== "GA-INV-000000-00" ? (
          <>{invoiceNumberText}</>
        ) : (
          <MDBox display="flex">
            <CircularProgress color="info" size={24} />
          </MDBox>
        );
      },
    },
    {
      Header: "Inv Date",
      Footer: "Inv Date",
      accessor: "invoiceDateText",
    },
    {
      Header: "Title",
      Footer: "Title",
      accessor: "invoiceTitle",
      Cell: ({ cell }) => {
        const invoiceTitle = cell.row.values.invoiceTitle;
        return tooltipCell(invoiceTitle);
      },
    },
    {
      Header: "Pymt Terms",
      Footer: "Pymt Terms",
      accessor: "invoiceTerms",
    },
    {
      Header: "Remarks",
      Footer: "Remarks",
      accessor: "invoiceRemarks",
      Cell: ({ cell }) => {
        const invoiceRemarks = cell.row.values.invoiceRemarks;
        return tooltipCell(invoiceRemarks);
      },
    },
    {
      Header: "Remind Days",
      Footer: "Remind Days",
      accessor: "reminderIntervalDays",
    },
    {
      Header: "Customer",
      Footer: "Customer",
      accessor: "customerName",
    },
    {
      Header: "Customer Address",
      Footer: "Customer Address",
      accessor: "customerAddress",
      Cell: ({ cell }) => {
        const customerAddress = cell.row.values.customerAddress;
        return tooltipCell(customerAddress);
      },
    },
    {
      Header: "Bill Cntct",
      Footer: "Bill Cntct",
      accessor: "billingContactName",
      Cell: ({ cell }) => {
        const billingContactName = cell.row.values.billingContactName;
        return tooltipCell(billingContactName);
      },
    },
    {
      Header: "LL No.",
      Footer: "LL No.",
      accessor: "billingContactLandlineNumber",
      Cell: ({ cell }) => {
        const billingContactLandlineNumber =
          cell.row.values.billingContactLandlineNumber;
        return tooltipCell(billingContactLandlineNumber);
      },
    },
    {
      Header: "Mobile No.",
      Footer: "Mobile No.",
      accessor: "billingContactMobileNumber",
      Cell: ({ cell }) => {
        const billingContactMobileNumber =
          cell.row.values.billingContactMobileNumber;
        return tooltipCell(billingContactMobileNumber);
      },
    },
    {
      Header: "Email",
      Footer: "Email",
      accessor: "billingContactEmail",
      Cell: ({ cell }) => {
        const billingContactEmail = cell.row.values.billingContactEmail;
        return tooltipCell(billingContactEmail);
      },
    },
    {
      Header: "Instl Prd",
      Footer: "Instl Prd",
      accessor: "installationPeriod",
      Cell: ({ cell }) => {
        const installationPeriod = cell.row.values.installationPeriod;
        return tooltipCell(installationPeriod);
      },
    },
    {
      Header: "Instl Start",
      Footer: "Instl Start",
      accessor: "installationStartDateText",
    },
    {
      Header: "Instl End",
      Footer: "Instl End",
      accessor: "installationEndDateText",
    },
    {
      Header: "Payment Type",
      Footer: "Payment Type",
      accessor: "paymentType",
      Cell: ({ cell }) => {
        const paymentType = cell.row.values.paymentType;
        return tooltipCell(paymentType);
      },
    },
    {
      Header: "Created At",
      Footer: "Created At",
      accessor: "createdAtText",
    },
    {
      Header: "Created By",
      Footer: "Created By",
      accessor: "createdByText",
      Cell: ({ cell }) => {
        const modifiedByText = cell.row.values.modifiedByText;
        return tooltipCell(modifiedByText);
      },
    },
    {
      Header: "Modified At",
      Footer: "Modified At",
      accessor: "modifiedAtText",
    },
    {
      Header: "Modified By",
      Footer: "Modified By",
      accessor: "modifiedByText",
      Cell: ({ cell }) => {
        const modifiedByText = cell.row.values.modifiedByText;
        return tooltipCell(modifiedByText);
      },
    },
    {
      Header: "Disc Type",
      Footer: "Disc Type",
      accessor: "invoiceDiscountType",
      Cell: ({ cell }) => {
        const invoiceDiscountType = cell.row.values.invoiceDiscountType;
        return tooltipCell(invoiceDiscountType);
      },
    },
    {
      Header: "Ttl Amt",
      Footer: "Ttl Amt",
      accessor: "invoiceGrossTotal",
    },
    {
      Header: "Ttl Amt",
      Footer: "Ttl Amt",
      accessor: "invoiceGrossTotalText",
    },
    {
      Header: "Discount",
      Footer: "Discount",
      accessor: "invoiceDiscountTotalText",
    },
    {
      Header: "Ttl w/o GST",
      Footer: "Ttl w/o GST",
      accessor: "invoiceBeforeGstTotalText",
    },
    {
      Header: "Addtl Disc",
      Footer: "Addtl Disc",
      accessor: "invoiceAdditionalDiscountText",
    },
    {
      Header: "Net w/o GST",
      Footer: "Net w/o GST",
      accessor: "invoiceNetBeforeGstTotalText",
    },
    {
      Header: "GST Amt",
      Footer: "GST Amt",
      accessor: "invoiceGstAmountText",
    },
    {
      Header: "Fnl w/ GST",
      Footer: "Fnl w/ GST",
      accessor: "invoiceAfterGstTotalText",
    },
    {
      Header: "Deposit",
      Footer: "Deposit",
      accessor: "invoiceDepositText",
    },
    {
      Header: "Bal Due",
      Footer: "Bal Due",
      accessor: "invoiceBalanceDueText",
    },
    {
      Header: "Inv Type",
      Footer: "Inv Type",
      accessor: "invoicingType",
      Cell: ({ cell }) => {
        const invoicingType = cell.row.values.invoicingType;
        return tooltipCell(invoicingType);
      },
    },
    {
      Header: "Inv Ref",
      Footer: "Inv Ref",
      accessor: "invoicingReference",
      Cell: ({ cell }) => {
        const invoicingReference = cell.row.values.invoicingReference;
        return tooltipCell(invoicingReference);
      },
    },
    {
      Header: "Sales Order Id",
      Footer: "Sales Order Id",
      accessor: "salesorderIdText",
    },
    {
      Header: "Sales Order",
      Footer: "Sales Order",
      accessor: "salesorderNumberText",
      Cell: ({ cell }) => {
        const salesorderNumberText = cell.row.values.salesorderNumberText;
        const salesorderIdText = cell.row.values.salesorderIdText;
        return (
          <>
            <AllowedTo
              perform={Permission.READ_SALESORDER}
              no={() => (
                <MDTypography variant="body2" color="info">
                  <BlurLinearIcon />
                </MDTypography>
              )}
            >
              {salesorderIdText !== "-" ? (
                <MDButton
                  variant="outlined"
                  color="info"
                  size="small"
                  onClick={() =>
                    navigate(`/invoices/manage/view/${salesorderIdText}`)
                  }
                >
                  {salesorderNumberText}
                </MDButton>
              ) : (
                <>{salesorderNumberText}</>
              )}
            </AllowedTo>
          </>
        );
      },
    },
    {
      Header: "Sent At",
      Footer: "Sent At",
      accessor: "sentAtText",
    },
    {
      Header: "Sent By",
      Footer: "Sent By",
      accessor: "sentByText",
      Cell: ({ cell }) => {
        const canSend = cell.row.values.canSend;
        const sentByText = cell.row.values.sentByText;
        return canSend ? (
          <>
            <AllowedTo
              perform={Permission.SEND_INVOICE}
              no={() => (
                <MDTypography variant="body2" color="info">
                  <BlurLinearIcon />
                </MDTypography>
              )}
            >
              <MDButton
                variant="gradient"
                color="success"
                iconOnly
                onClick={() =>
                  navigate(`/invoices/pdf/send/${cell.row.values.id}`)
                }
              >
                <EmailIcon />
              </MDButton>
            </AllowedTo>
          </>
        ) : (
          <>{sentByText}</>
        );
      },
    },
    {
      Header: "Recved At",
      Footer: "Recved At",
      accessor: "receiptedAtText",
    },
    {
      Header: "Recved By",
      Footer: "Recved By",
      accessor: "receiptedByText",
      Cell: ({ cell }) => {
        const canReceipt = cell.row.values.canReceipt;
        const receiptedByText = cell.row.values.receiptedByText;
        return canReceipt ? (
          <>
            <AllowedTo
              perform={Permission.RECEIPT_INVOICE}
              no={() => (
                <MDTypography variant="body2" color="info">
                  <BlurLinearIcon />
                </MDTypography>
              )}
            >
              <MDButton
                variant="gradient"
                color="success"
                iconOnly
                onClick={() =>
                  navigate(`/invoices/manage/receipt/${cell.row.values.id}`)
                }
              >
                <MonetizationOnIcon />
              </MDButton>
            </AllowedTo>
          </>
        ) : (
          tooltipCell(receiptedByText)
        );
      },
    },
    {
      Header: "Recpt Ref",
      Footer: "Recpt Ref",
      accessor: "receiptingReference",
      Cell: ({ cell }) => {
        const receiptingReference = cell.row.values.receiptingReference;
        return tooltipCell(receiptingReference);
      },
    },
    {
      Header: "Recpt Date",
      Footer: "Recpt Date",
      accessor: "invoiceReceiptingDateText",
    },
    {
      Header: "Closed At",
      Footer: "Closed At",
      accessor: "closedAtText",
    },
    {
      Header: "Closed By",
      Footer: "Closed By",
      accessor: "closedByText",
      Cell: ({ cell }) => {
        const canClose = cell.row.values.canClose;
        const closedByText = cell.row.values.closedByText;
        return canClose ? (
          <>
            <AllowedTo
              perform={Permission.CLOSE_INVOICE}
              no={() => (
                <MDTypography variant="body2" color="info">
                  <BlurLinearIcon />
                </MDTypography>
              )}
            >
              <MDButton
                variant="gradient"
                color="success"
                iconOnly
                onClick={() =>
                  navigate(`/invoices/manage/close/${cell.row.values.id}`)
                }
              >
                <CreditScoreIcon />
              </MDButton>
            </AllowedTo>
          </>
        ) : (
          tooltipCell(closedByText)
        );
      },
    },
    {
      Header: "Closing Ref",
      Footer: "Closing Ref",
      accessor: "closingReference",
      Cell: ({ cell }) => {
        const closingReference = cell.row.values.closingReference;
        return tooltipCell(closingReference);
      },
    },
    {
      Header: "Close Date",
      Footer: "Close Date",
      accessor: "invoiceClosingDateText",
    },
    {
      Header: "Status",
      Footer: "Status",
      accessor: "status",
    },
    {
      Header: "Deleted At",
      Footer: "Deleted At",
      accessor: "deletedAtText",
    },
    {
      Header: "Deleted By",
      Footer: "Deleted By",
      accessor: "deletedByText",
      Cell: ({ cell }) => {
        const deletedByText = cell.row.values.deletedByText;
        return tooltipCell(deletedByText);
      },
    },
    {
      Header: "Actions",
      Footer: "Actions",
      accessor: "actions",
      disableFilters: true,
      disableGlobalFilter: true,
      disableSortBy: true,
      Cell: ({ cell }) => {
        const canPrint = cell.row.values.canPrint;
        const canEdit = cell.row.values.canEdit;
        const canDelete = cell.row.values.canDelete;
        return (
          <>
            <AllowedTo perform={Permission.READ_INVOICE}>
              <MDButton
                variant="gradient"
                color="info"
                iconOnly
                onClick={() =>
                  navigate(`/invoices/manage/view/${cell.row.values.id}`)
                }
              >
                <VisibilityIcon />
              </MDButton>
              &nbsp;&nbsp;
            </AllowedTo>
            {canPrint && (
              <AllowedTo perform={Permission.PRINT_INVOICE}>
                <MDButton
                  variant="gradient"
                  color="info"
                  iconOnly
                  onClick={() =>
                    navigate(`/invoices/pdf/print/${cell.row.values.id}`)
                  }
                >
                  <PrintIcon />
                </MDButton>
                &nbsp;&nbsp;
              </AllowedTo>
            )}
            {canEdit && (
              <AllowedTo perform={Permission.UPDATE_INVOICE}>
                <MDButton
                  variant="gradient"
                  color="info"
                  iconOnly
                  onClick={() =>
                    navigate(`/invoices/manage/edit/${cell.row.values.id}`)
                  }
                >
                  <EditIcon />
                </MDButton>
                &nbsp;&nbsp;
              </AllowedTo>
            )}
            {canDelete && (
              <AllowedTo perform={Permission.DELETE_INVOICE}>
                <MDButton
                  variant="gradient"
                  color="warning"
                  iconOnly
                  onClick={() =>
                    navigate(`/invoices/manage/delete/${cell.row.values.id}`)
                  }
                >
                  <DeleteIcon />
                </MDButton>
              </AllowedTo>
            )}
          </>
        );
      },
    },
  ];

  // const hiddenColumns = [
  //   "id",
  //   "createdAtText",
  //   "modifiedAtText",
  //   "sentAtText",
  //   "receiptedAtText",
  //   "closedAtText",
  // ];

  return {
    columns,
    rows,
    hiddenColumns,
    response,
    userData,
    handleExport,
    handleUserPreference,
    dispatchDismiss,
    dispatchError,
    isDataReady,
  };
};
