import PropTypes from "prop-types";

import { useState, useEffect } from "react";

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";

import MDBox from "components/atoms/MDBox";
import MDButton from "components/atoms/MDButton";
import MDTypography from "components/atoms/MDTypography";
import FormField from "components/molecules/FormField";

import DebounceAutocomplete from "components/molecules/Debounce/DebounceAutocomplete";
import DebounceTextField from "components/molecules/Debounce/DebounceTextField";

import { Formik } from "formik";

import FormRadioGroup from "components/molecules/Formik/FormRadioGroup";
import FormAutocompleteFast from "components/molecules/Formik/FormAutocompleteFast";
import FormTextFieldFast from "components/molecules/Formik/FormTextFieldFast";
import FormTextField from "components/molecules/Formik/FormTextField";

import { quoteItemsForm } from "pages/quotations/manage/schemas/form";
import { quoteItemsInitialValues } from "pages/quotations/manage/schemas/initialValues";
import { quoteItemsValidation } from "pages/quotations/manage/schemas/validations";

import currency from "currency.js";

import {
  quoteItemServiceOptions,
  quoteItemServiceTypeOptions,
  quoteItemServiceTeamOptions,
  discountTypeOptions,
  defaultDiscountType,
  gstPercentage,
} from "pages/quotations/manage/schemas/setups";

function PriceQuote({
  mainForm,
  mainFormField,
  modeDisabled,
  dispatchMainError,
}) {
  const { values: mainFormValues, setFieldValue: setMainFormFieldValue } =
    mainForm;
  const {
    quoteItems,
    quoteDiscountType,
    quoteGrossTotal,
    quoteDiscountTotal,
    quoteBeforeGstTotal,
    quoteGstAmount,
    quoteAfterGstTotal,
  } = mainFormField;

  // destructure from Quote Items Form for current section
  const { formId: quoteItemsFormId, formField: quoteItemsFormField } =
    quoteItemsForm;
  const {
    itemOrdinal,
    itemNo,
    itemSubNo,
    itemServiceType,
    itemDescription,
    itemServiceTeam,
    itemQuantity,
    itemUnitCost,
    itemGrossAmount,
    itemDiscount,
    itemNetAmount,
  } = quoteItemsFormField;

  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [showAddForm, setShowAddForm] = useState(false);
  const [itemizedDiscount, setItemizedDiscount] = useState(true);
  const [discountTotalType, setDiscountTotalType] = useState("$");
  const [discountTotalValue, setDiscountTotalValue] = useState("");
  const [quoteItemStatementOptions, setQuoteItemStatementOptions] = useState(
    []
  );
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    try {
      mainFormValues.quoteDiscountType === "Itemized" &&
        setItemizedDiscount(true);
      mainFormValues.quoteDiscountType === "Non-Itemized" &&
        setItemizedDiscount(false);
    } catch (err) {
      dispatchMainError(err);
    }
  }, [dispatchMainError, mainFormValues.quoteDiscountType]);

  const handleTableRowClick = (index, setSectionFormFieldValue) => {
    try {
      !modeDisabled && !isEditing && setSelectedIndex(index);
    } catch (err) {
      dispatchMainError(err);
    }
  };

  const handleAddQuoteItem = () => {
    try {
      setSelectedIndex(-1);
      setShowAddForm(true);
      setIsEditing(true);
    } catch (err) {
      dispatchMainError(err);
    }
  };

  const publishQuoteItem = (index, setSectionFormFieldValue) => {
    try {
      const quoteItem = mainFormValues.quoteItems[index];
      setSectionFormFieldValue(itemOrdinal.name, quoteItem.itemOrdinal);
      setSectionFormFieldValue(itemNo.name, quoteItem.itemNo);
      setSectionFormFieldValue(itemSubNo.name, quoteItem.itemSubNo);
      setSectionFormFieldValue(itemServiceType.name, quoteItem.itemServiceType);
      setSectionFormFieldValue(itemDescription.name, quoteItem.itemDescription);
      setSectionFormFieldValue(itemServiceTeam.name, quoteItem.itemServiceTeam);
      setSectionFormFieldValue(itemQuantity.name, quoteItem.itemQuantity);
      setSectionFormFieldValue(itemUnitCost.name, quoteItem.itemUnitCost);
      setSectionFormFieldValue(itemGrossAmount.name, quoteItem.itemGrossAmount);
      setSectionFormFieldValue(itemDiscount.name, quoteItem.itemDiscount);
      setSectionFormFieldValue(itemNetAmount.name, quoteItem.itemNetAmount);
    } catch (err) {
      dispatchMainError(err);
    }
  };

  const handleRemoveQuoteItem = (index) => {
    try {
      mainFormValues.quoteItems.splice(selectedIndex, 1);
      setMainFormFieldValue(quoteItems.name, mainFormValues.quoteItems);

      handleCalculateTotalPrice(
        mainFormValues.quoteItems,
        mainFormValues.quoteDiscountTotal
      );
    } catch (err) {
      dispatchMainError(err);
    }
  };

  const handleEditQuoteItem = (index, setSectionFormFieldValue) => {
    try {
      publishQuoteItem(index, setSectionFormFieldValue);
      setIsEditing(true);
    } catch (err) {
      dispatchMainError(err);
    }
  };

  const handleCancelEditingQuoteItem = (resetForm) => {
    try {
      resetForm({ values: quoteItemsInitialValues });
      setSelectedIndex(-1);
      setShowAddForm(false);
      setIsEditing(false);
    } catch (err) {
      dispatchMainError(err);
    }
  };

  const handleMoveUpQuoteItem = (event, index) => {
    try {
      event.stopPropagation();
      !isEditing && setSelectedIndex(index - 1);
      setSelectedIndex(index - 1);
      const items = mainFormValues.quoteItems;
      [items[index - 1].itemOrdinal, items[index].itemOrdinal] = [
        index,
        index - 1,
      ];
      [items[index - 1], items[index]] = [items[index], items[index - 1]];
      setMainFormFieldValue(quoteItems.name, items);
    } catch (err) {
      dispatchMainError(err);
    }
  };

  const handleMoveDownQuoteItem = (event, index) => {
    try {
      event.stopPropagation();
      !isEditing && setSelectedIndex(index + 1);
      const items = mainFormValues.quoteItems;
      [items[index].itemOrdinal, items[index + 1].itemOrdinal] = [
        index + 1,
        index,
      ];
      [items[index], items[index + 1]] = [items[index + 1], items[index]];
      setMainFormFieldValue(quoteItems.name, items);
    } catch (err) {
      dispatchMainError(err);
    }
  };

  const handleCalculateDiscountTotal = (
    discountTotalType,
    discountTotalValue
  ) => {
    try {
      const grossTotal = currency(mainFormValues.quoteGrossTotal, {
        symbol: "",
        separator: "",
      });
      let discountTotalAmount = "0.00";
      if (discountTotalValue && !isNaN(discountTotalValue)) {
        const discountTotal = currency(discountTotalValue, {
          symbol: "",
          separator: "",
        });
        if (discountTotalType === "$") {
          discountTotalAmount = discountTotal.format();
          setMainFormFieldValue(quoteDiscountTotal.name, discountTotalAmount);
        }
        if (discountTotalType === "%") {
          discountTotalAmount = grossTotal
            .multiply(discountTotal / 100)
            .format();
          setMainFormFieldValue(quoteDiscountTotal.name, discountTotalAmount);
        }
      }
      return discountTotalAmount;
    } catch (err) {
      dispatchMainError(err);
    }
  };

  const handleCalculateTotalPrice = (quoteItems, discount = "0.00") => {
    try {
      if (itemizedDiscount) {
        const grossTotal = quoteItems.reduce(
          (total, item) => {
            return currency(item.itemGrossAmount, {
              symbol: "",
              separator: "",
            }).add(total);
          },
          currency(0, {
            symbol: "",
            separator: "",
          })
        );
        const beforeGstTotal = quoteItems.reduce(
          (total, item) => {
            return currency(item.itemNetAmount, {
              symbol: "",
              separator: "",
            }).add(total);
          },
          currency(0, {
            symbol: "",
            separator: "",
          })
        );
        const discountTotal = grossTotal.subtract(beforeGstTotal);
        const gstAmount = beforeGstTotal.multiply(gstPercentage / 100);
        const afterGstTotal = beforeGstTotal.add(gstAmount);

        const formattedGrossTotal = grossTotal.format();
        const formattedDiscountTotal = discountTotal.format();
        const formattedBeforeGstTotal = beforeGstTotal.format();
        const formattedGstAmount = gstAmount.format();
        const formattedAfterGstTotal = afterGstTotal.format();
        setMainFormFieldValue(quoteGrossTotal.name, formattedGrossTotal);
        setMainFormFieldValue(quoteDiscountTotal.name, formattedDiscountTotal);
        setMainFormFieldValue(
          quoteBeforeGstTotal.name,
          formattedBeforeGstTotal
        );
        setMainFormFieldValue(quoteGstAmount.name, formattedGstAmount);
        setMainFormFieldValue(quoteAfterGstTotal.name, formattedAfterGstTotal);
      }
      if (!itemizedDiscount) {
        const grossTotal = quoteItems.reduce(
          (total, item) => {
            return currency(item.itemNetAmount, {
              symbol: "",
              separator: "",
            }).add(total);
          },
          currency(0, {
            symbol: "",
            separator: "",
          })
        );

        const discountTotal = currency(discount, {
          symbol: "",
          separator: "",
        });

        const beforeGstTotal = grossTotal.subtract(discountTotal);
        const gstAmount = beforeGstTotal.multiply(gstPercentage / 100);
        const afterGstTotal = beforeGstTotal.add(gstAmount);

        const formattedGrossTotal = grossTotal.format();
        const formattedDiscountTotal = discountTotal.format();
        const formattedBeforeGstTotal = beforeGstTotal.format();
        const formattedGstAmount = gstAmount.format();
        const formattedAfterGstTotal = afterGstTotal.format();
        setMainFormFieldValue(quoteGrossTotal.name, formattedGrossTotal);
        setMainFormFieldValue(quoteDiscountTotal.name, formattedDiscountTotal);
        setMainFormFieldValue(
          quoteBeforeGstTotal.name,
          formattedBeforeGstTotal
        );
        setMainFormFieldValue(quoteGstAmount.name, formattedGstAmount);
        setMainFormFieldValue(quoteAfterGstTotal.name, formattedAfterGstTotal);
      }
    } catch (err) {
      dispatchMainError(err);
    }
  };

  const handleCalculateItemPrice = (
    quantity,
    unitCost,
    discount,
    setSectionFormFieldValue
  ) => {
    try {
      if (quantity && !isNaN(quantity) && quantity === "0") {
        // reset unit cost, gross amount, discount, net amount to zero, use this item as title
        setSectionFormFieldValue(itemUnitCost.name, "0.00");
        setSectionFormFieldValue(itemGrossAmount.name, "0.00");
        setSectionFormFieldValue(itemDiscount.name, "0");
        setSectionFormFieldValue(itemNetAmount.name, "0.00");
      } else if (quantity && !isNaN(quantity) && unitCost && !isNaN(unitCost)) {
        const grossAmount = calculateGrossAmount(quantity, unitCost);
        setSectionFormFieldValue(itemGrossAmount.name, grossAmount);

        if (
          discount &&
          !isNaN(discount) &&
          grossAmount &&
          !isNaN(grossAmount)
        ) {
          const netAmount = calculateNetAmount(grossAmount, discount);
          setSectionFormFieldValue(itemNetAmount.name, netAmount);
        }
      }
    } catch (err) {
      dispatchMainError(err);
    }
  };

  const calculateGrossAmount = (quantity, unitCost) => {
    try {
      return currency(unitCost, {
        symbol: "",
        separator: "",
      })
        .multiply(quantity)
        .format();
    } catch (err) {
      dispatchMainError(err);
    }
  };

  const calculateNetAmount = (grossAmount, discount) => {
    try {
      return currency(grossAmount, {
        symbol: "",
        separator: "",
      })
        .multiply((100 - discount) / 100)
        .format();
    } catch (err) {
      dispatchMainError(err);
    }
  };

  const submitQuoteItem = (values, { resetForm, setSubmitting }) => {
    try {
      if (selectedIndex !== -1 && isEditing) {
        // Edit existing item
        const currentQuoteItems = mainFormValues.quoteItems;
        currentQuoteItems[selectedIndex] = values;
        handleCalculateTotalPrice(
          currentQuoteItems,
          mainFormValues.quoteDiscountTotal
        );
        setMainFormFieldValue(quoteItems.name, currentQuoteItems);
        setIsEditing(false);
      } else if (selectedIndex === -1 && isEditing) {
        // Add new item
        const newIndex = mainFormValues.quoteItems.length;
        values.itemOrdinal = newIndex;
        const currentQuoteItems = [...mainFormValues.quoteItems, values];
        handleCalculateTotalPrice(
          currentQuoteItems,
          mainFormValues.quoteDiscountTotal
        );
        setShowAddForm(false);
        setIsEditing(false);
        setMainFormFieldValue(quoteItems.name, currentQuoteItems);
      } else {
        // recalculate
      }
      resetForm({ values: quoteItemsInitialValues });
    } catch (err) {
      dispatchMainError(err);
      setSubmitting(false);
    }
  };

  const renderItem = (selectedIndex, formik, quoteItem, index = -1) => {
    const {
      values,
      handleChange,
      handleSubmit,
      setFieldValue: setSectionFormFieldValue,
      resetForm,
    } = formik;
    return (
      <TableRow
        key={`quote-item-${index}`}
        selected={selectedIndex === index}
        onClick={() => handleTableRowClick(index, setSectionFormFieldValue)}
      >
        <TableCell align="center">
          <MDBox display="flex" alignItems="center" columnGap="5px">
            {!modeDisabled && (
              <>
                <MDBox>
                  {index > -1 && (
                    <IconButton
                      edge="start"
                      size="small"
                      color="success"
                      disabled={index !== selectedIndex || isEditing}
                      onClick={() =>
                        handleEditQuoteItem(index, setSectionFormFieldValue)
                      }
                    >
                      <EditIcon />
                    </IconButton>
                  )}
                  <IconButton
                    edge="start"
                    size="small"
                    color="success"
                    disabled={index !== selectedIndex || !isEditing}
                    onClick={handleSubmit}
                  >
                    <SaveIcon />
                  </IconButton>
                  <IconButton
                    edge="start"
                    size="small"
                    color="warning"
                    disabled={index !== selectedIndex || !isEditing}
                    onClick={() => handleCancelEditingQuoteItem(resetForm)}
                  >
                    <DoNotDisturbIcon />
                  </IconButton>
                </MDBox>
                <MDBox>
                  <IconButton
                    edge="start"
                    size="small"
                    color="info"
                    disabled={
                      index !== selectedIndex || index === 0 || isEditing
                    }
                    onClick={(event) => handleMoveUpQuoteItem(event, index)}
                  >
                    <ArrowCircleUpIcon />
                  </IconButton>
                  <IconButton
                    edge="start"
                    size="small"
                    color="info"
                    disabled={
                      index !== selectedIndex ||
                      index === mainFormValues.quoteItems.length - 1 ||
                      isEditing
                    }
                    onClick={(event) => handleMoveDownQuoteItem(event, index)}
                  >
                    <ArrowCircleDownIcon />
                  </IconButton>
                </MDBox>
              </>
            )}
          </MDBox>
        </TableCell>
        <TableCell align="center">
          {isEditing && index === selectedIndex ? (
            <FormTextFieldFast
              hideStatus={true}
              form={formik}
              field={itemNo}
              variant="standard"
              disabled={modeDisabled}
            />
          ) : (
            quoteItem.itemNo
          )}
        </TableCell>
        <TableCell align="center">
          {isEditing && index === selectedIndex ? (
            <FormTextFieldFast
              hideStatus={true}
              form={formik}
              field={itemSubNo}
              variant="standard"
              disabled={modeDisabled}
            />
          ) : (
            quoteItem.itemSubNo
          )}
        </TableCell>
        <TableCell align="left">
          {isEditing && index === selectedIndex ? (
            <>
              <FormAutocompleteFast
                hideStatus={true}
                form={formik}
                field={itemServiceType}
                options={quoteItemServiceTypeOptions}
                variant="standard"
                onInputChange={(e, value) => {
                  if (quoteItemServiceTypeOptions) {
                    setSectionFormFieldValue(itemServiceType.name, value);
                    const quoteItemService = quoteItemServiceOptions.find(
                      (element) => {
                        return element.serviceType === value;
                      }
                    );
                    setQuoteItemStatementOptions(quoteItemService.statements);
                  }
                }}
                disabled={modeDisabled}
              />
              <FormAutocompleteFast
                hideStatus={true}
                freeSolo
                form={formik}
                field={itemDescription}
                options={quoteItemStatementOptions}
                variant="standard"
                disabled={modeDisabled}
              />
              <FormAutocompleteFast
                hideStatus={true}
                freeSolo
                form={formik}
                field={itemServiceTeam}
                options={quoteItemServiceTeamOptions}
                variant="standard"
                disabled={modeDisabled}
              />
            </>
          ) : (
            <MDBox>
              <MDTypography variant="h6">
                {quoteItem.itemServiceType}
              </MDTypography>
              <MDTypography variant="body2">
                {quoteItem.itemDescription}
              </MDTypography>
              <MDTypography variant="caption">
                (
                {!!quoteItem.itemServiceTeam
                  ? quoteItem.itemServiceTeam
                  : "None"}
                )
              </MDTypography>
            </MDBox>
          )}
        </TableCell>
        <TableCell align="center">
          {isEditing && index === selectedIndex ? (
            <FormTextField
              hideStatus={true}
              form={formik}
              field={itemQuantity}
              variant="standard"
              disabled={modeDisabled}
              onChange={(e) => {
                handleChange(e);
                const quantity = e.target.value;
                const unitCost = values.itemUnitCost;
                const discount = values.itemDiscount;
                handleCalculateItemPrice(
                  quantity,
                  unitCost,
                  discount,
                  setSectionFormFieldValue
                );
              }}
            />
          ) : quoteItem.itemQuantity !== "0" ? (
            quoteItem.itemQuantity
          ) : (
            ""
          )}
        </TableCell>
        <TableCell align="center">
          {isEditing && index === selectedIndex ? (
            <FormTextField
              hideStatus={true}
              form={formik}
              field={itemUnitCost}
              variant="standard"
              disabled={modeDisabled}
              onChange={(e) => {
                handleChange(e);
                const unitCost = e.target.value;
                const quantity = values.itemQuantity;
                const discount = values.itemDiscount;
                handleCalculateItemPrice(
                  quantity,
                  unitCost,
                  discount,
                  setSectionFormFieldValue
                );
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
            />
          ) : quoteItem.itemQuantity !== "0" ? (
            currency(quoteItem.itemUnitCost).format()
          ) : (
            ""
          )}
        </TableCell>
        <TableCell
          align="center"
          sx={itemizedDiscount ? {} : { display: "none" }}
        >
          {isEditing && index === selectedIndex ? (
            <FormTextField
              hideStatus={true}
              form={formik}
              field={itemGrossAmount}
              variant="standard"
              disabled={modeDisabled}
              InputProps={{
                readOnly: true,
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
            />
          ) : quoteItem.itemQuantity !== "0" ? (
            currency(quoteItem.itemGrossAmount).format()
          ) : (
            ""
          )}
        </TableCell>
        <TableCell
          align="center"
          sx={itemizedDiscount ? {} : { display: "none" }}
        >
          {isEditing && index === selectedIndex ? (
            <FormTextField
              hideStatus={true}
              form={formik}
              field={itemDiscount}
              variant="standard"
              disabled={modeDisabled}
              onChange={(e) => {
                handleChange(e);
                const quantity = values.itemQuantity;
                const unitCost = values.itemUnitCost;
                const discount = e.target.value;
                handleCalculateItemPrice(
                  quantity,
                  unitCost,
                  discount,
                  setSectionFormFieldValue
                );
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
            />
          ) : quoteItem.itemQuantity !== "0" ? (
            quoteItem.itemDiscount + " %"
          ) : (
            ""
          )}
        </TableCell>
        <TableCell align="center">
          {isEditing && index === selectedIndex ? (
            <FormTextField
              hideStatus={true}
              form={formik}
              field={itemNetAmount}
              variant="standard"
              disabled={modeDisabled}
              onChange={(e) => {
                handleChange(e);
                const quantity = values.itemQuantity;
                const unitCost = values.itemUnitCost;
                const discount = e.target.value;
                handleCalculateItemPrice(
                  quantity,
                  unitCost,
                  discount,
                  setSectionFormFieldValue
                );
              }}
              InputProps={{
                readOnly: true,
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
            />
          ) : quoteItem.itemQuantity !== "0" ? (
            currency(quoteItem.itemNetAmount).format()
          ) : (
            ""
          )}
        </TableCell>
        <TableCell align="center">
          {!modeDisabled && index > -1 && (
            <IconButton
              edge="start"
              size="small"
              color="error"
              disabled={index !== selectedIndex || isEditing}
              onClick={() => handleRemoveQuoteItem(index)}
            >
              <DeleteIcon />
            </IconButton>
          )}
        </TableCell>
      </TableRow>
    );
  };

  return (
    <Card id="price-quote" sx={{ overflow: "visible" }}>
      <MDBox m={3}>
        <MDBox mb={3}>
          <MDTypography variant="h5">Price Quote</MDTypography>
        </MDBox>
        <Grid
          container
          rowSpacing={{ xs: 1, sm: 2 }}
          columnSpacing={{ xs: 1, sm: 3 }}
        >
          <Grid item xs={12} sm={2}>
            <MDTypography variant="body3" fontWeight="bold">
              {quoteDiscountType.label}
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={10}>
            <FormRadioGroup
              row
              showTitle={false}
              form={mainForm}
              field={quoteDiscountType}
              options={discountTypeOptions}
              defaultValue={defaultDiscountType}
              onChange={(event) => {
                const value = event.target.value;
                setMainFormFieldValue(quoteDiscountType.name, value);
                const isItemized = value === "Itemized";
                if (mainFormValues.quoteItems.length > 0) {
                  if (!isItemized) {
                    const updatedQuoteItems = mainFormValues.quoteItems;
                    updatedQuoteItems.forEach((quoteItem) => {
                      quoteItem.itemDiscount = "0";
                      quoteItem.itemNetAmount = calculateNetAmount(
                        quoteItem.itemGrossAmount,
                        quoteItem.itemDiscount
                      );
                    });
                    // reset discount
                    handleCalculateTotalPrice(updatedQuoteItems, "0.00");
                    setMainFormFieldValue(quoteItems.name, updatedQuoteItems);
                  }
                  if (isItemized) {
                    // reset discount
                    handleCalculateTotalPrice(
                      mainFormValues.quoteItems,
                      "0.00"
                    );
                  }
                }
                setItemizedDiscount(isItemized); //set state and rerender
              }}
              disabled={modeDisabled}
            />
          </Grid>
        </Grid>

        <Formik
          initialValues={quoteItemsInitialValues}
          validationSchema={quoteItemsValidation}
          onSubmit={submitQuoteItem}
        >
          {(formik) => (
            <MDBox id={quoteItemsFormId} autoComplete="off">
              <TableContainer sx={{ boxShadow: "none" }}>
                <Table sx={{ minWidth: "40rem" }}>
                  <TableHead>
                    <TableRow key="head">
                      <TableCell align="center" width="4%" />
                      <TableCell align="center" width="6%">
                        {itemNo.label}
                      </TableCell>
                      <TableCell align="center" width="8%">
                        {itemSubNo.label}
                      </TableCell>
                      <TableCell align="center" width="28%">
                        {itemDescription.label}
                      </TableCell>
                      <TableCell align="center" width="6%">
                        {itemQuantity.label}
                      </TableCell>
                      <TableCell align="center" width="11%">
                        {itemUnitCost.label}
                      </TableCell>
                      <TableCell align="center" width="11%">
                        {itemizedDiscount
                          ? itemGrossAmount.label
                          : "Total Amount"}
                      </TableCell>
                      <TableCell
                        align="center"
                        width="11%"
                        sx={itemizedDiscount ? {} : { display: "none" }}
                      >
                        {itemDiscount.label}
                      </TableCell>
                      <TableCell
                        align="center"
                        width="11%"
                        sx={itemizedDiscount ? {} : { display: "none" }}
                      >
                        {itemNetAmount.label}
                      </TableCell>
                      <TableCell align="center" width="4%" />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {mainFormValues.quoteItems.map((quoteItem, index) =>
                      renderItem(selectedIndex, formik, quoteItem, index)
                    )}
                    {showAddForm && renderItem(selectedIndex, formik)}
                    <TableRow>
                      <TableCell
                        rowSpan={itemizedDiscount ? 3 : 5}
                        colSpan={itemizedDiscount ? 4 : 2}
                        sx={{ verticalAlign: "top" }}
                      >
                        {!modeDisabled && (
                          <MDButton
                            size="small"
                            onClick={handleAddQuoteItem}
                            color="success"
                            disabled={
                              mainFormValues.quoteItems.length >= 100 ||
                              isEditing ||
                              formik.isSubmitting
                            }
                            iconOnly
                          >
                            <AddIcon />
                          </MDButton>
                        )}
                      </TableCell>
                      <TableCell
                        align="right"
                        colSpan={itemizedDiscount ? 2 : 4}
                        sx={{ fontWeight: "bold" }}
                      >
                        Total Amount
                      </TableCell>
                      {itemizedDiscount ? (
                        <>
                          <TableCell align="right" sx={{ fontWeight: "bold" }}>
                            <FormTextField
                              hideLabel={true}
                              form={mainForm}
                              field={quoteGrossTotal}
                              defaultValue={"0.00"}
                              variant="standard"
                              disabled={modeDisabled}
                              InputProps={{
                                readOnly: true,
                                startAdornment: (
                                  <InputAdornment position="start">
                                    $
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </TableCell>
                          <TableCell align="right" sx={{ fontWeight: "bold" }}>
                            <FormTextField
                              hideLabel={true}
                              form={mainForm}
                              field={quoteDiscountTotal}
                              defaultValue={"0.00"}
                              variant="standard"
                              disabled={modeDisabled}
                              InputProps={{
                                readOnly: true,
                                startAdornment: (
                                  <InputAdornment position="start">
                                    $
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </TableCell>
                          <TableCell align="right" sx={{ fontWeight: "bold" }}>
                            <FormTextField
                              hideLabel={true}
                              form={mainForm}
                              field={quoteBeforeGstTotal}
                              defaultValue={"0.00"}
                              variant="standard"
                              disabled={modeDisabled}
                              InputProps={{
                                readOnly: true,
                                startAdornment: (
                                  <InputAdornment position="start">
                                    $
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </TableCell>
                        </>
                      ) : (
                        <TableCell align="right" sx={{ fontWeight: "bold" }}>
                          <FormTextField
                            hideLabel={true}
                            form={mainForm}
                            field={quoteGrossTotal}
                            defaultValue={"0.00"}
                            variant="standard"
                            disabled={modeDisabled}
                            InputProps={{
                              readOnly: true,
                              startAdornment: (
                                <InputAdornment position="start">
                                  $
                                </InputAdornment>
                              ),
                            }}
                          />
                        </TableCell>
                      )}
                    </TableRow>
                    {!itemizedDiscount && (
                      <>
                        <TableRow>
                          <TableCell align="right"></TableCell>
                          <TableCell colSpan={2}>
                            <MDBox
                              display="flex"
                              justifyContent="flex-end"
                              alignItems="center"
                              flexWrap="nowrap"
                            >
                              <DebounceAutocomplete
                                disablePortal
                                size="small"
                                label="Type"
                                value={discountTotalType}
                                options={["$", "%"]}
                                sx={{ width: 80 }}
                                onChange={(event, newValue) => {
                                  const discountTotalAmount =
                                    handleCalculateDiscountTotal(newValue, 0);
                                  handleCalculateTotalPrice(
                                    mainFormValues.quoteItems,
                                    discountTotalAmount
                                  );
                                  setDiscountTotalValue("");
                                  setDiscountTotalType(newValue);
                                }}
                                onInputChange={(e, value) => {
                                  if (["$", "%"].includes(value)) {
                                    setDiscountTotalType(value);
                                  }
                                }}
                                disabled={modeDisabled}
                              />
                              <DebounceTextField
                                size="small"
                                label="Amount"
                                value={discountTotalValue}
                                disabled={modeDisabled}
                                onChange={(e) => {
                                  const newValue = e.target.value;

                                  const discountTotalAmount =
                                    handleCalculateDiscountTotal(
                                      discountTotalType,
                                      newValue
                                    );
                                  handleCalculateTotalPrice(
                                    mainFormValues.quoteItems,
                                    discountTotalAmount
                                  );
                                  setDiscountTotalValue(newValue);
                                }}
                              />
                            </MDBox>
                          </TableCell>
                          <TableCell align="right" sx={{ fontWeight: "bold" }}>
                            {quoteDiscountTotal.label}
                          </TableCell>
                          <TableCell align="right" sx={{ fontWeight: "bold" }}>
                            <FormTextField
                              hideLabel={true}
                              form={mainForm}
                              field={quoteDiscountTotal}
                              defaultValue={"0.00"}
                              variant="standard"
                              disabled={modeDisabled}
                              InputProps={{
                                readOnly: true,
                                startAdornment: (
                                  <InputAdornment position="start">
                                    $
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            align="right"
                            colSpan={4}
                            sx={{ fontWeight: "bold" }}
                          >
                            {quoteBeforeGstTotal.label}
                          </TableCell>
                          <TableCell align="right" sx={{ fontWeight: "bold" }}>
                            <FormTextField
                              hideLabel={true}
                              hideStatus={true}
                              form={mainForm}
                              field={quoteBeforeGstTotal}
                              defaultValue={"0.00"}
                              variant="standard"
                              disabled={modeDisabled}
                              InputProps={{
                                readOnly: true,
                                startAdornment: (
                                  <InputAdornment position="start">
                                    $
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </TableCell>
                        </TableRow>
                      </>
                    )}

                    <TableRow>
                      <TableCell
                        align="right"
                        colSpan={4}
                        sx={{ fontWeight: "bold" }}
                      >
                        {quoteGstAmount.label}
                      </TableCell>
                      <TableCell align="right" sx={{ fontWeight: "bold" }}>
                        <FormTextField
                          hideLabel={true}
                          hideStatus={true}
                          form={mainForm}
                          field={quoteGstAmount}
                          defaultValue={"0.00"}
                          variant="standard"
                          disabled={modeDisabled}
                          InputProps={{
                            readOnly: true,
                            startAdornment: (
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                            ),
                          }}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        align="right"
                        colSpan={4}
                        sx={{ fontWeight: "bold" }}
                      >
                        {quoteAfterGstTotal.label}
                      </TableCell>
                      <TableCell align="right" sx={{ fontWeight: "bold" }}>
                        <FormTextField
                          hideLabel={true}
                          notTouchable={true}
                          form={mainForm}
                          field={quoteAfterGstTotal}
                          defaultValue={"0.00"}
                          variant="standard"
                          disabled={modeDisabled}
                          InputProps={{
                            readOnly: true,
                            startAdornment: (
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                            ),
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </MDBox>
          )}
        </Formik>
        <FormField
          type={quoteItems.type}
          label={quoteItems.label}
          name={quoteItems.name}
        />
      </MDBox>
    </Card>
  );
}

PriceQuote.propTypes = {
  mainForm: PropTypes.object.isRequired,
  mainFormField: PropTypes.object.isRequired,
  modeDisabled: PropTypes.bool.isRequired,
};

export default PriceQuote;
