import PropTypes from "prop-types";

import { useEffect, useCallback, useState } from "react";

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

import MDBox from "components/atoms/MDBox";
import MDTypography from "components/atoms/MDTypography";

import FormRadioGroup from "components/molecules/Formik/FormRadioGroup";
import FormCheckboxGroup from "components/molecules/Formik/FormCheckboxGroup";
import FormAttachments from "components/molecules/Formik/FormAttachments";
import FormTextFieldFast from "components/molecules/Formik/FormTextFieldFast";

import { invoicingAttachmentsForm } from "pages/invoices/manage/schemas/form";
import { invoicingAttachmentsInitialValues } from "pages/invoices/manage/schemas/initialValues";
import { invoicingAttachmentsValidation } from "pages/invoices/manage/schemas/validations";

import { useDocument } from "hooks/useDocument";

import {
  invoicingTypeOptions,
  newInvoicingTypeOptions,
  convertInvoicingTypeOptions,
  defaultInvoicingType,
} from "pages/invoices/manage/schemas/setups";

function Invoicing({
  mainForm,
  mainFormField,
  modeDisabled,
  defaultReadOnly,
  mode,
  dispatchMainError,
}) {
  const { values } = mainForm;
  const {
    invoicingType,
    invoicingReference,
    quotationRefForInvoice,
    servicereportRefForInvoice,
    purchaseorderRefForInvoice,
    invoicingAttachments,
    supportingAttachments,
  } = mainFormField;
  const { retrieveDoc } = useDocument();

  const [supportingAttachmentOptions, setSupportingAttachmentOptions] =
    useState([]);

  let invTypeOptions = invoicingTypeOptions;
  if (mode === "new") {
    invTypeOptions = newInvoicingTypeOptions;
  } else if (mode === "convert") {
    invTypeOptions = convertInvoicingTypeOptions;
  } else {
    invTypeOptions = [values.invoicingType];
  }

  // destructure from Attachments Form for current section
  const {
    formId: invoicingAttachmentsFormId,
    formField: invoicingAttachmentsFormField,
  } = invoicingAttachmentsForm;
  const { invoicingAttachmentFile, attachmentName, attachmentURL } =
    invoicingAttachmentsFormField;

  const initializeSupportingAttachments = useCallback(async () => {
    try {
      const salesorder =
        values.salesorderReference &&
        (await retrieveDoc("salesorders", values.salesorderReference));
      const commencementAttachments = salesorder
        ? salesorder.data.commencementAttachments
        : [];
      const completionAttachments = salesorder
        ? salesorder.data.completionAttachments
        : [];
      const depositAttachments = salesorder
        ? salesorder.data.depositAttachments
        : [];

      const attachmentOptions = [
        ...commencementAttachments,
        ...completionAttachments,
        ...depositAttachments,
      ];

      setSupportingAttachmentOptions(attachmentOptions);
    } catch (err) {
      dispatchMainError(err);
    }
  }, [values, retrieveDoc, dispatchMainError]);

  useEffect(() => {
    try {
      initializeSupportingAttachments();
    } catch (err) {
      dispatchMainError(err);
    }
  }, [dispatchMainError, initializeSupportingAttachments]);

  return (
    <Card id="invoicing" sx={{ overflow: "visible" }}>
      <MDBox m={3}>
        <MDBox mb={3}>
          <MDTypography variant="h5">Invoicing</MDTypography>
        </MDBox>

        <MDBox>
          <Grid
            container
            rowSpacing={{ xs: 1, sm: 2 }}
            columnSpacing={{ xs: 1, sm: 3 }}
          >
            <Grid item xs={12} sm={2}>
              <MDTypography variant="body3" fontWeight="bold">
                {invoicingType.label}
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={10}>
              <FormRadioGroup
                row
                showTitle={false}
                form={mainForm}
                field={invoicingType}
                options={invTypeOptions}
                defaultValue={defaultInvoicingType}
                disabled={modeDisabled}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <MDTypography variant="body3" fontWeight="bold">
                {invoicingReference.label}
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormTextFieldFast
                multiline
                form={mainForm}
                field={invoicingReference}
                variant="standard"
                disabled={modeDisabled}
              />
            </Grid>
            <Grid item xs={12} sm={4}></Grid>
            <Grid item xs={12} sm={2}>
              <MDTypography variant="body3" fontWeight="bold">
                {quotationRefForInvoice.label}
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormTextFieldFast
                multiline
                form={mainForm}
                field={quotationRefForInvoice}
                variant="standard"
                disabled={modeDisabled}
              />
            </Grid>
            <Grid item xs={12} sm={4}></Grid>
            <Grid item xs={12} sm={2}>
              <MDTypography variant="body3" fontWeight="bold">
                {purchaseorderRefForInvoice.label}
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormTextFieldFast
                multiline
                form={mainForm}
                field={purchaseorderRefForInvoice}
                variant="standard"
                disabled={modeDisabled}
              />
            </Grid>
            <Grid item xs={12} sm={4}></Grid>
            <Grid item xs={12} sm={2}>
              <MDTypography variant="body3" fontWeight="bold">
                {servicereportRefForInvoice.label}
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormTextFieldFast
                multiline
                form={mainForm}
                field={servicereportRefForInvoice}
                variant="standard"
                disabled={modeDisabled}
              />
            </Grid>
            <Grid item xs={12} sm={4}></Grid>
            <Grid item xs={12} sm={2}>
              <MDTypography variant="body3" fontWeight="bold">
                {invoicingAttachments.label}
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormAttachments
                showTitle={false}
                form={mainForm}
                field={invoicingAttachments}
                max={20}
                disabled={modeDisabled}
                dispatchError={dispatchMainError}
                itemFormId={invoicingAttachmentsFormId}
                itemInitialValues={invoicingAttachmentsInitialValues}
                itemValidation={invoicingAttachmentsValidation}
                itemFileField={invoicingAttachmentFile}
                itemKeyField={attachmentURL}
                itemDisplayFields={[attachmentName]}
              />
            </Grid>
            <Grid item xs={12} sm={4}></Grid>
            <Grid item xs={12} sm={2}>
              <MDTypography variant="body3" fontWeight="bold">
                {supportingAttachments.label}
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormCheckboxGroup
                showTitle={false}
                form={mainForm}
                field={supportingAttachments}
                options={supportingAttachmentOptions}
                disabled={modeDisabled}
                nameProperty="attachmentName"
                valueProperty="attachmentName"
                linkProperty="attachmentURL"
              />
            </Grid>
            <Grid item xs={12} sm={4}></Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Card>
  );
}

Invoicing.propTypes = {
  mainForm: PropTypes.object.isRequired,
  mainFormField: PropTypes.object.isRequired,
  modeDisabled: PropTypes.bool.isRequired,
  mode: PropTypes.string.isRequired,
};

export default Invoicing;
