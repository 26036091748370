import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Grid from "@mui/material/Grid";

import MDBox from "components/atoms/MDBox";
import MDButton from "components/atoms/MDButton";

import ErrorSnackbar from "components/molecules/Notifications/ErrorSnackbar";
import SuccessSnackbar from "components/molecules/Notifications/SuccessSnackbar";

import DashboardLayout from "components/templates/DashboardLayout";
import Topnav from "components/organisms/Topnav";
import DashboardFooter from "components/organisms/Footer/DashboardFooter";
import PermissionDenied from "components/organisms/PermissionDenied";
import InvalidOperation from "components/organisms/InvalidOperation";
import SubmissionProgress from "components/organisms/SubmissionProgress";

// PurchaseOrder page components
import AuditTrail from "pages/purchaseorders/manage/components/AuditTrail";
import SupplierInformation from "pages/purchaseorders/manage/components/SupplierInformation";
import BillingDetails from "pages/purchaseorders/manage/components/BillingDetails";
import PricePurchaseOrder from "pages/purchaseorders/manage/components/PricePurchaseOrder";
import Attachments from "pages/purchaseorders/manage/components/Attachments";
import Sidenav from "pages/purchaseorders/manage/components/Sidenav";

import { Formik, Form } from "formik";
import form from "pages/purchaseorders/manage/schemas/form";

import { AllowedTo } from "react-abac";

import { usePurchaseOrderManager } from "pages/purchaseorders/hooks/usePurchaseOrderManager";

function PurchaseOrder() {
  const [isProgressing, setIsProgressing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const { mode, purchaseorderId } = useParams();
  const {
    modeTitle,
    modeSubmit,
    modeFieldDisabled,
    modePermission,
    modeValidation,
    submitNew,
    submitNewDraft,
    submitEditDraft,
    submitDelete,
    response,
    dispatchError,
  } = usePurchaseOrderManager(mode, purchaseorderId);

  const navigate = useNavigate();
  const navigateAfter = (path, millisecond) => {
    return new Promise(() => {
      setTimeout(() => {
        navigate(path);
      }, millisecond);
    });
  };

  const { formId, formField } = form;

  const handleDraft = async (values) => {
    try {
      setIsSaving(true);
      setIsProgressing(true);
      switch (mode) {
        case "new":
          await submitNewDraft(values);
          await navigateAfter("/purchaseorders/records", 3000);
          break;
        case "draft":
          await submitEditDraft(values);
          await navigateAfter("/purchaseorders/records", 3000);
          break;
        default:
          setIsSaving(false);
          break;
      }
    } catch (err) {
      dispatchError(err);
    } finally {
      setIsSaving(false);
      setIsProgressing(false);
    }
  };

  const handleSubmit = async (values, actions) => {
    try {
      setIsProgressing(true);
      switch (mode) {
        case "copy":
        case "new":
        case "draft":
          await submitNew(values);
          await navigateAfter("/purchaseorders/records", 3000);
          break;
        case "edit":
          await submitNew(values);
          await navigateAfter("/purchaseorders/records", 3000);
          break;
        case "view":
          navigate("/purchaseorders/records");
          break;
        case "delete":
          await submitDelete();
          await navigateAfter("/purchaseorders/records", 3000);
          break;
        default:
          actions.setSubmitting(false);
          break;
      }
    } catch (err) {
      dispatchError(err);
      actions.setSubmitting(false);
    } finally {
      actions.setSubmitting(false);
      setIsProgressing(false);
    }
  };

  return (
    <DashboardLayout>
      <Topnav title={modeTitle} />
      <AllowedTo perform={modePermission} no={() => <PermissionDenied />} />
      <MDBox pt={3}>
        {isProgressing && <SubmissionProgress />}
        {response.error && (
          <>
            {response.error?.name === "OperationInvalidError" && (
              <InvalidOperation />
            )}
            <ErrorSnackbar
              title={modeTitle}
              dateTime=""
              message={response.error?.message}
              autoDismiss
            />
          </>
        )}
        {response.success && (
          <SuccessSnackbar
            title={modeTitle}
            dateTime=""
            message={response.success}
            autoDismiss
          />
        )}
        <MDBox>
          {response.error?.name !== "OperationInvalidError" && (
            <Grid container spacing={2}>
              <Grid item xs={12} lg={2}>
                <Sidenav />
              </Grid>

              <Grid item xs={12} lg={10}>
                <MDBox mb={3}>
                  <Formik
                    enableReinitialize
                    initialValues={response.data}
                    validationSchema={modeValidation}
                    onSubmit={handleSubmit}
                  >
                    {(formik) => (
                      <Form id={formId} autoComplete="off">
                        <Grid container spacing={3}>
                          <Grid item xs={12}>
                            <AuditTrail
                              mainForm={formik}
                              mainFormField={formField}
                              modeDisabled={modeFieldDisabled}
                              mode={mode}
                              respondedPurchaseOrderNumber={
                                response.data.purchaseorderNumber
                              }
                              dispatchMainError={dispatchError}
                            />
                          </Grid>

                          <Grid item xs={12}>
                            <SupplierInformation
                              mainForm={formik}
                              mainFormField={formField}
                              modeDisabled={modeFieldDisabled}
                              dispatchMainError={dispatchError}
                            />
                          </Grid>

                          <Grid item xs={12}>
                            <BillingDetails
                              mainForm={formik}
                              mainFormField={formField}
                              modeDisabled={modeFieldDisabled}
                              dispatchMainError={dispatchError}
                            />
                          </Grid>

                          <Grid item xs={12}>
                            <PricePurchaseOrder
                              mainForm={formik}
                              mainFormField={formField}
                              modeDisabled={modeFieldDisabled}
                              dispatchMainError={dispatchError}
                            />
                          </Grid>

                          <Grid item xs={12}>
                            <Attachments
                              mainForm={formik}
                              mainFormField={formField}
                              modeDisabled={modeFieldDisabled}
                              dispatchMainError={dispatchError}
                            />
                          </Grid>

                          <Grid item xs={12}>
                            <MDBox
                              width="100%"
                              display="flex"
                              justifyContent="space-between"
                            >
                              {mode === "new" || mode === "draft" ? (
                                <MDButton
                                  disabled={formik.isSubmitting || isSaving}
                                  variant="gradient"
                                  color="light"
                                  onClick={() =>
                                    handleDraft(
                                      formik.values,
                                      formik.actions,
                                      formik.setSubmitting
                                    )
                                  }
                                >
                                  Save as Draft
                                </MDButton>
                              ) : (
                                <MDBox></MDBox>
                              )}
                              <MDButton
                                disabled={formik.isSubmitting || isSaving}
                                type="submit"
                                variant="gradient"
                                color="dark"
                              >
                                {modeSubmit}
                              </MDButton>
                            </MDBox>
                          </Grid>
                        </Grid>
                      </Form>
                    )}
                  </Formik>
                </MDBox>
              </Grid>
            </Grid>
          )}
        </MDBox>
      </MDBox>
      <DashboardFooter />
    </DashboardLayout>
  );
}

export default PurchaseOrder;
