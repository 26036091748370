import React from "react";
import { Text, View } from "@react-pdf/renderer";
import styles from "pages/invoices/pdf/styles";
import currency from "currency.js";
import { gstPercentage } from "pages/invoices/manage/schemas/setups";

const nonItemizedRow = (title, amount, titleStyles = [], amountStyles = []) => (
  <View style={styles.containerRow}>
    <View style={[styles.tablefooter, ...titleStyles, { flexBasis: "85%" }]}>
      <Text>{title}</Text>
    </View>
    <View style={[styles.tablefooter, ...amountStyles, { flexBasis: "15%" }]}>
      <View style={styles.price}>
        <Text>$</Text>
        <Text>
          {currency(amount, {
            symbol: "",
          }).format()}
        </Text>
      </View>
    </View>
  </View>
);

const itemizedRow = (title, amount, titleStyles = [], amountStyles = []) => (
  <View style={styles.containerRow}>
    <View style={[styles.tablefooter, ...titleStyles, { flexBasis: "88%" }]}>
      <Text>{title}</Text>
    </View>
    <View style={[styles.tablefooter, ...amountStyles, { flexBasis: "12%" }]}>
      <View style={styles.price}>
        <Text>$</Text>
        <Text>
          {currency(amount, {
            symbol: "",
          }).format()}
        </Text>
      </View>
    </View>
  </View>
);

const itemizedFirstRow = (values) => (
  <View style={styles.containerRow}>
    <View
      style={[
        styles.tablefooter,
        styles.leftmost,
        styles.topmost,
        { flexBasis: "64%", fontWeight: "bold" },
      ]}
    >
      <Text>TOTAL AMOUNT</Text>
    </View>
    <View style={[styles.tablefooter, styles.topmost, { flexBasis: "12%" }]}>
      <View style={styles.price}>
        <Text>$</Text>
        <Text>
          {currency(values.invoiceGrossTotal, {
            symbol: "",
          }).format()}
        </Text>
      </View>
    </View>
    <View style={[styles.tablefooter, styles.topmost, { flexBasis: "12%" }]}>
      <View style={styles.price}>
        <Text>$</Text>
        <Text>
          {currency(values.invoiceDiscountTotal, {
            symbol: "",
          }).format()}
        </Text>
      </View>
    </View>
    <View style={[styles.tablefooter, styles.topmost, { flexBasis: "12%" }]}>
      <View style={styles.price}>
        <Text>$</Text>
        <Text>
          {currency(values.invoiceBeforeGstTotal, {
            symbol: "",
          }).format()}
        </Text>
      </View>
    </View>
  </View>
);

function PriceQuoteTableFooter({ values }) {
  return values.invoiceDiscountType === "Itemized" ? (
    <View wrap={false}>
      {itemizedFirstRow(values)}
      {values.invoiceAdditionalDiscount !== "0.00" ? (
        itemizedRow("ADDITIONAL DISCOUNT", values.invoiceAdditionalDiscount, [
          styles.leftmost,
          { fontWeight: "bold" },
        ])
      ) : (
        <></>
      )}
      {itemizedRow(gstPercentage + "% GST", values.invoiceGstAmount, [
        styles.leftmost,
        { fontWeight: "bold" },
      ])}
      {itemizedRow(
        "FINAL AMOUNT INCLUSIVE OF GST",
        values.invoiceAfterGstTotal,
        [styles.leftmost, { fontWeight: "bold" }]
      )}

      {itemizedRow("DEPOSIT", values.invoiceDeposit, [
        styles.leftmost,
        { fontWeight: "bold" },
      ])}

      {itemizedRow("BALANCE DUE", values.invoiceBalanceDue, [
        styles.leftmost,
        { fontWeight: "bold" },
      ])}
    </View>
  ) : (
    <View wrap={false}>
      {nonItemizedRow(
        "TOTAL AMOUNT",
        values.invoiceGrossTotal,
        [styles.topmost, styles.leftmost, { fontWeight: "bold" }],
        [styles.topmost]
      )}
      {nonItemizedRow("DISCOUNT", values.invoiceDiscountTotal, [
        styles.leftmost,
        { fontWeight: "bold" },
      ])}
      {nonItemizedRow("TOTAL AMOUNT BEFORE GST", values.invoiceBeforeGstTotal, [
        styles.leftmost,
        { fontWeight: "bold" },
      ])}
      {values.invoiceAdditionalDiscount !== "0.00" ? (
        nonItemizedRow(
          "ADDITIONAL DISCOUNT",
          values.invoiceAdditionalDiscount,
          [styles.leftmost, { fontWeight: "bold" }]
        )
      ) : (
        <></>
      )}
      {nonItemizedRow(gstPercentage + "% GST", values.invoiceGstAmount, [
        styles.leftmost,
        { fontWeight: "bold" },
      ])}

      {nonItemizedRow(
        "FINAL AMOUNT INCLUSIVE OF GST",
        values.invoiceAfterGstTotal,
        [styles.leftmost, { fontWeight: "bold" }]
      )}

      {nonItemizedRow("DEPOSIT", values.invoiceDeposit, [
        styles.leftmost,
        { fontWeight: "bold" },
      ])}

      {nonItemizedRow("BALANCE DUE", values.invoiceBalanceDue, [
        styles.leftmost,
        { fontWeight: "bold" },
      ])}
    </View>
  );
}

export default PriceQuoteTableFooter;
