import React from "react";
import { Text, View } from "@react-pdf/renderer";
import styles from "pages/invoices/pdf/styles";

function PriceQuoteTableHeader({ values }) {
  return values.invoiceDiscountType === "Itemized" ? (
    <View style={styles.containerRow}>
      <Text
        style={[
          styles.tableheader,
          styles.topmost,
          styles.leftmost,
          { flexBasis: "5%" },
        ]}
      >
        NO.
      </Text>
      <Text style={[styles.tableheader, styles.topmost, { flexBasis: "5%" }]}>
        SUB NO.
      </Text>
      <Text style={[styles.tableheader, styles.topmost, { flexBasis: "37%" }]}>
        DESCRIPTION
      </Text>
      <Text style={[styles.tableheader, styles.topmost, { flexBasis: "5%" }]}>
        QTY
      </Text>
      <Text style={[styles.tableheader, styles.topmost, { flexBasis: "12%" }]}>
        UNIT COST
      </Text>
      <Text style={[styles.tableheader, styles.topmost, { flexBasis: "12%" }]}>
        GROSS AMOUNT
      </Text>
      <Text style={[styles.tableheader, styles.topmost, { flexBasis: "12%" }]}>
        DISCOUNT
      </Text>
      <Text style={[styles.tableheader, styles.topmost, { flexBasis: "12%" }]}>
        NET AMOUNT
      </Text>
    </View>
  ) : (
    <View style={styles.containerRow}>
      <Text
        style={[
          styles.tableheader,
          styles.topmost,
          styles.leftmost,
          { flexBasis: "5%" },
        ]}
      >
        NO
      </Text>
      <Text style={[styles.tableheader, styles.topmost, { flexBasis: "5%" }]}>
        SUB NO.
      </Text>
      <Text style={[styles.tableheader, styles.topmost, { flexBasis: "55%" }]}>
        DESCRIPTION
      </Text>
      <Text style={[styles.tableheader, styles.topmost, { flexBasis: "5%" }]}>
        QTY
      </Text>
      <Text style={[styles.tableheader, styles.topmost, { flexBasis: "15%" }]}>
        UNIT COST
      </Text>
      <Text style={[styles.tableheader, styles.topmost, { flexBasis: "15%" }]}>
        TOTAL AMOUNT
      </Text>
    </View>
  );
}

export default PriceQuoteTableHeader;
