import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

import MDBox from "components/atoms/MDBox";
import MDTypography from "components/atoms/MDTypography";
import MDAvatar from "components/atoms/MDAvatar";

import logo from "assets/images/gate/logo.png";

function Profile({ mainForm, mainFormField, modeDisabled, dispatchMainError }) {
  const { values } = mainForm;

  return (
    <Card id="company">
      <MDBox p={2}>
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            <MDAvatar src={logo} size="xl" variant="rounded"></MDAvatar>
          </Grid>
          <Grid item>
            <MDBox height="100%" mt={0.5} lineHeight={1}>
              <MDTypography variant="h5" fontWeight="medium">
                {values?.displayName ?? "-"}
              </MDTypography>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
}

export default Profile;
