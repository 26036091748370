import PropTypes from "prop-types";

import { useEffect, useCallback, useState } from "react";

import Card from "@mui/material/Card";

import MDBox from "components/atoms/MDBox";
import MDTypography from "components/atoms/MDTypography";
import MDButton from "components/atoms/MDButton";
import PrintIcon from "@mui/icons-material/Print";
import CircularProgress from "@mui/material/CircularProgress";

import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import printJS from "print-js";

import { useSalesOrderPdfManager } from "pages/salesorders/hooks/useSalesOrderPdfManager";

import "pages/salesorders/pdf/styles/styles.css";

function SalesOrderPreview({
  mainForm,
  mainFormField,
  mode,
  response,
  dispatchMainError,
}) {
  const { setFieldValue: setMainFormFieldValue } = mainForm;
  const { emailAttachments } = mainFormField;

  const { generateSalesOrderPdf } = useSalesOrderPdfManager();

  const [fileRef, setFileRef] = useState(null);
  const [numPages, setNumPages] = useState(null);

  const getSalesOrderPdf = useCallback(async () => {
    const salesorderFileRef = await generateSalesOrderPdf(response.data);
    setFileRef(salesorderFileRef);

    if (mode === "send") {
      setMainFormFieldValue(emailAttachments.name, [
        {
          attachmentPath: salesorderFileRef.path,
          attachmentName: salesorderFileRef.name,
          attachmentURL: salesorderFileRef.url,
        },
      ]);
    }
  }, [
    emailAttachments.name,
    generateSalesOrderPdf,
    mode,
    response.data,
    setMainFormFieldValue,
  ]);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  useEffect(() => {
    try {
      getSalesOrderPdf();
    } catch (err) {
      dispatchMainError(err);
    }
  }, [dispatchMainError, getSalesOrderPdf, mode]);

  return (
    <Card id="salesorder-preview" sx={{ overflow: "visible" }}>
      <MDBox m={3}>
        {mode === "print" && (
          <MDBox mb={-5} display="flex" justifyContent="flex-end">
            <MDButton
              variant="gradient"
              color="success"
              onClick={() => printJS(fileRef?.url)}
            >
              <PrintIcon /> &nbsp; Print
            </MDButton>
          </MDBox>
        )}
        <MDBox mb={3}>
          <MDTypography variant="h5">Sales Order Preview</MDTypography>
        </MDBox>

        <MDBox
          height="65vh"
          overflow="auto"
          justifyContent="center"
          className="all-page-container"
        >
          <Document
            file={fileRef?.url}
            options={{ workerSrc: "/pdf.worker.js" }}
            loading={<CircularProgress />}
            noData={<CircularProgress />}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            {Array.from(new Array(numPages), (el, index) => (
              <Page key={`page_${index + 1}`} pageNumber={index + 1} />
            ))}
          </Document>
        </MDBox>
      </MDBox>
    </Card>
  );
}

SalesOrderPreview.propTypes = {
  mode: PropTypes.string.isRequired,
};

export default SalesOrderPreview;
