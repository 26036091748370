import React from "react";
import { Text, View } from "@react-pdf/renderer";
import styles from "pages/quotations/pdf/styles";

function TermsAndConditions({ values }) {
  return (
    <>
      <View style={[styles.containerCol, styles.section]}>
        <Text style={[styles.emailContent, styles.tncheader]}>
          TERMS AND CONDITIONS
        </Text>
        <Text style={styles.emailContent}>{values.beginingStatement}</Text>
      </View>
      <View style={[styles.containerCol, styles.section]}>
        <View style={[styles.containerRow, styles.emailContent]}>
          <Text style={styles.property}>Installation Period</Text>
          <Text style={styles.colon}>:</Text>
          {values.installationPeriod === "TBC" ? (
            <Text>{values.installationPeriod}</Text>
          ) : (
            <Text>
              {values.installationStartDate} to {values.installationEndDate}
            </Text>
          )}
        </View>
        <View style={[styles.containerRow, styles.emailContent]}>
          <Text style={styles.property}>Payment Term</Text>
          <Text style={styles.colon}>:</Text>
          <Text>{values.paymentTerm}</Text>
        </View>
        <View style={[styles.containerRow, styles.emailContent]}>
          <Text style={styles.property}>Validity</Text>
          <Text style={styles.colon}>:</Text>
          <Text>{values.validity}</Text>
        </View>
        <View style={[styles.containerRow, styles.emailContent]}>
          <Text style={styles.property}>Exclusions</Text>
          <Text style={styles.colon}>:</Text>
          <Text>
            {values.exclusions
              .map(
                (element, index) => `. ${element.exclusionClause}`
              )
              .join("\n")}
          </Text>
        </View>
        <View style={[styles.containerRow, styles.emailContent]}>
          <Text style={styles.property}>Note</Text>
          <Text style={styles.colon}>:</Text>
          <Text>{values.note}</Text>
        </View>
      </View>

      <View style={[styles.containerCol, styles.section]}>
        <View
          style={[
            styles.containerRow,
            styles.emailContent,
            { fontWeight: "bold" },
          ]}
        >
          <Text>{values.endingStatement}</Text>
        </View>
      </View>
    </>
  );
}

export default TermsAndConditions;
