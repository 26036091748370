import * as Yup from "yup";
import form, {
  contactPersonForm,
  deliverySiteForm,
  billingContactForm,
} from "pages/suppliers/manage/schemas/form";

const {
  formField: {
    supplierName,
    supplierUEN,
    supplierType,
    supplierStatus,
    supplierAddress,
    supplierBuildingName,
    // supplierPostalCode,
    // supplierLandlineNumber,
    // supplierMobileNumber,
    supplierWebsite,
    // supplierPreferredContact,
    contactPersons,
    deliverySites,
    billingContacts,
  },
} = form;

const noValidation = [
  Yup.object().shape({}),
  Yup.object().shape({}),
  Yup.object().shape({}),
  Yup.object().shape({}),
];

const validations = [
  Yup.object().shape({
    [supplierName.name]: Yup.string()
      .required(supplierName.errorMsg)
      .max(200, supplierName.invalidMsg),
    [supplierUEN.name]: Yup.string().max(200, supplierUEN.invalidMsg),
    [supplierType.name]: Yup.string()
      .required(supplierType.errorMsg)
      .max(200, supplierType.invalidMsg)
      .nullable(true),
    [supplierStatus.name]: Yup.string()
      .required(supplierStatus.errorMsg)
      .max(200, supplierStatus.invalidMsg)
      .nullable(true),
    [supplierAddress.name]: Yup.string()
      .required(supplierAddress.errorMsg)
      .max(200, supplierAddress.invalidMsg),
    [supplierBuildingName.name]: Yup.string().max(
      200,
      supplierBuildingName.invalidMsg
    ),
    /*[supplierPostalCode.name]: Yup.string()
      .required(supplierPostalCode.errorMsg)
      .matches("^\\d{6}$", supplierPostalCode.invalidMsg),*/
    /*[supplierLandlineNumber.name]: Yup.string()
      .when(supplierMobileNumber.name, {
        is: (supplierMobileNumberValue) => !supplierMobileNumberValue?.length,
        then: Yup.string().required(supplierLandlineNumber.errorMsg),
      })
      .matches(
        "^[+]?([0-9]*[\\-\\s.])*([0-9]*[(]+[0-9]{1,3}[)])*([0-9]*[\\-\\s.])*[0-9]{4,15}$",
        supplierLandlineNumber.invalidMsg
      ),*/
    /*[supplierMobileNumber.name]: Yup.string().matches(
      "^[+]?([0-9]*[\\-\\s.])*([0-9]*[(]+[0-9]{1,3}[)])*([0-9]*[\\-\\s.])*[0-9]{4,15}$",
      supplierMobileNumber.invalidMsg
    ),*/
    [supplierWebsite.name]: Yup.string().max(200, supplierWebsite.invalidMsg),
    /*[supplierPreferredContact.name]: Yup.string()
      .required(supplierPreferredContact.errorMsg)
      .max(200, supplierPreferredContact.invalidMsg)
      .nullable(true),*/
  }),
  Yup.object().shape({
    [contactPersons.name]: Yup.array()
      .min(1, contactPersons.min1Msg)
      .max(100, contactPersons.max100Msg),
  }),
  Yup.object().shape({
    [deliverySites.name]: Yup.array()
      .min(1, deliverySites.min1Msg)
      .max(100, deliverySites.max100Msg),
  }),
  Yup.object().shape({
    [billingContacts.name]: Yup.array()
      .min(1, billingContacts.min1Msg)
      .max(100, billingContacts.max100Msg),
  }),
];

const {
  formField: {
    contactPersonName,
    contactPersonEmail,
    contactPersonLandlineNumber,
    contactPersonMobileNumber,
  },
} = contactPersonForm;

const contactPersonsValidation = Yup.object().shape({
  [contactPersonName.name]: Yup.string()
    .required(contactPersonName.errorMsg)
    .max(200, contactPersonName.invalidMsg),
  [contactPersonEmail.name]: Yup.string()
    //.required(contactPersonEmail.errorMsg)
    .max(200, contactPersonEmail.invalidMsg)
    .email(contactPersonEmail.invalidMsg),
  [contactPersonLandlineNumber.name]: Yup.string()
    // .when(contactPersonMobileNumber.name, {
    //   is: (contactPersonMobileNumberValue) =>
    //     !contactPersonMobileNumberValue?.length,
    //   then: Yup.string().required(contactPersonLandlineNumber.errorMsg),
    // })
    .matches(
      "^[+]?([0-9]*[\\-\\s.])*([0-9]*[(]+[0-9]{1,3}[)])*([0-9]*[\\-\\s.])*[0-9]{4,15}$",
      contactPersonLandlineNumber.invalidMsg
    ),
  [contactPersonMobileNumber.name]: Yup.string().matches(
    "^[+]?([0-9]*[\\-\\s.])*([0-9]*[(]+[0-9]{1,3}[)])*([0-9]*[\\-\\s.])*[0-9]{4,15}$",
    contactPersonMobileNumber.invalidMsg
  ),
});

const {
  formField: {
    deliverySiteName,
    //siteSupervisorName,
    //deliverySiteEmail,
    deliverySiteAddress,
    deliverySiteBlkBuildingName,
    deliverySitePostalCode,
    //deliverySiteLandlineNumber,
    //deliverySiteMobileNumber,
  },
} = deliverySiteForm;

const deliverySitesValidation = Yup.object().shape({
  [deliverySiteName.name]: Yup.string()
    .required(deliverySiteName.errorMsg)
    .max(200, deliverySiteName.invalidMsg),
  //[siteSupervisorName.name]: Yup.string()
  //  .required(siteSupervisorName.errorMsg)
  //  .max(200, siteSupervisorName.invalidMsg),
  //[deliverySiteEmail.name]: Yup.string()
  //  .required(deliverySiteEmail.errorMsg)
  //  .max(200, deliverySiteEmail.invalidMsg)
  //  .email(deliverySiteEmail.invalidMsg),
  [deliverySiteAddress.name]: Yup.string()
    .required(deliverySiteAddress.errorMsg)
    .max(200, deliverySiteAddress.invalidMsg),
  [deliverySiteBlkBuildingName.name]: Yup.string()
    .required(deliverySiteBlkBuildingName.errorMsg)
    .max(200, deliverySiteBlkBuildingName.invalidMsg),
  [deliverySitePostalCode.name]: Yup.string()
    .required(deliverySitePostalCode.errorMsg)
    .matches("^\\d{6}$", deliverySitePostalCode.invalidMsg),
  // [deliverySiteLandlineNumber.name]: Yup.string()
  //   .optional()
  //   .matches(
  //     "^[+]?([0-9]*[\\-\\s.])*([0-9]*[(]+[0-9]{1,3}[)])*([0-9]*[\\-\\s.])*[0-9]{4,15}$",
  //     deliverySiteLandlineNumber.invalidMsg
  //   ),
  // [deliverySiteMobileNumber.name]: Yup.string()
  //   .when(deliverySiteLandlineNumber.name, {
  //     is: (deliverySiteLandlineNumberValue) =>
  //       !deliverySiteLandlineNumberValue?.length,
  //     then: Yup.string().required(deliverySiteMobileNumber.errorMsg),
  //   })
  //   .optional()
  //   .matches(
  //     "^[+]?([0-9]*[\\-\\s.])*([0-9]*[(]+[0-9]{1,3}[)])*([0-9]*[\\-\\s.])*[0-9]{4,15}$",
  //     deliverySiteMobileNumber.invalidMsg
  //   ),
});

const {
  formField: {
    billingContactName,
    billingContactEmail,
    billingContactLandlineNumber,
    billingContactMobileNumber,
  },
} = billingContactForm;

const billingContactsValidation = Yup.object().shape({
  [billingContactName.name]: Yup.string()
    .required(billingContactName.errorMsg)
    .max(200, billingContactName.invalidMsg),
  [billingContactEmail.name]: Yup.string()
    //.required(billingContactEmail.errorMsg)
    .email(billingContactEmail.invalidMsg)
    .max(200, billingContactEmail.invalidMsg),
  [billingContactLandlineNumber.name]: Yup.string()
    // .when(billingContactMobileNumber.name, {
    //   is: (billingContactMobileNumberValue) =>
    //     !billingContactMobileNumberValue?.length,
    //   then: Yup.string().required(billingContactLandlineNumber.errorMsg),
    // })
    .matches(
      "^[+]?([0-9]*[\\-\\s.])*([0-9]*[(]+[0-9]{1,3}[)])*([0-9]*[\\-\\s.])*[0-9]{4,15}$",
      billingContactLandlineNumber.invalidMsg
    ),
  [billingContactMobileNumber.name]: Yup.string().matches(
    "^[+]?([0-9]*[\\-\\s.])*([0-9]*[(]+[0-9]{1,3}[)])*([0-9]*[\\-\\s.])*[0-9]{4,15}$",
    billingContactMobileNumber.invalidMsg
  ),
});

export {
  validations as default,
  noValidation,
  contactPersonsValidation,
  deliverySitesValidation,
  billingContactsValidation,
};
