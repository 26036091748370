import { Text, View } from "@react-pdf/renderer";

import styles from "pages/quotations/pdf/styles";

function AuditTrail({ values }) {
  return (
    <View
      style={[styles.containerRow, styles.spaceBetween, { marginTop: 10 }]}
      fixed
    >
      <Text fixed>Date: {values.quotationDate}</Text>
      <Text style={{ fontWeight: "bold" }} fixed>
        QUOTATION REF:{" "}
        <Text style={styles.samewidth}>
          {values.quotationRevision === "0"
            ? `GA-Q-${values.quotationNumber}`
            : `GA-Q-${values.quotationNumber}R${values.quotationRevision}`}
        </Text>
      </Text>
    </View>
  );
}

export default AuditTrail;
