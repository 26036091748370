import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Grid from "@mui/material/Grid";

import MDBox from "components/atoms/MDBox";
import MDButton from "components/atoms/MDButton";

import ErrorSnackbar from "components/molecules/Notifications/ErrorSnackbar";
import SuccessSnackbar from "components/molecules/Notifications/SuccessSnackbar";

import DashboardLayout from "components/templates/DashboardLayout";
import Topnav from "components/organisms/Topnav";
import DashboardFooter from "components/organisms/Footer/DashboardFooter";
import PermissionDenied from "components/organisms/PermissionDenied";
import InvalidOperation from "components/organisms/InvalidOperation";
import IncompleteOperation from "components/organisms/IncompleteOperation";
import SubmissionProgress from "components/organisms/SubmissionProgress";

// Quotation page components
import InternalReference from "pages/quotations/manage/components/InternalReference";
import AuditTrail from "pages/quotations/manage/components/AuditTrail";
import CustomerInformation from "pages/quotations/manage/components/CustomerInformation";
import Subject from "pages/quotations/manage/components/Subject";
import PriceQuote from "pages/quotations/manage/components/PriceQuote";
import TermsAndConditions from "pages/quotations/manage/components/TermsAndConditions";
import Attachments from "pages/quotations/manage/components/Attachments";
import Sidenav from "pages/quotations/manage/components/Sidenav";

import { Formik, Form } from "formik";
import form from "pages/quotations/manage/schemas/form";

import { AllowedTo } from "react-abac";

import { useQuotationManager } from "pages/quotations/hooks/useQuotationManager";

function Quotation() {
  const [isProgressing, setIsProgressing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const { mode, quotationId } = useParams();
  const {
    modeTitle,
    modeSubmit,
    modeFieldDisabled,
    modePermission,
    modeValidation,
    submitNew,
    submitNewDraft,
    submitEditDraft,
    submitRevise,
    submitDelete,
    response,
    dispatchError,
  } = useQuotationManager(mode, quotationId);

  const navigate = useNavigate();
  const navigateAfter = (path, millisecond) => {
    return new Promise(() => {
      setTimeout(() => {
        navigate(path);
      }, millisecond);
    });
  };

  const { formId, formField } = form;

  const handleDraft = async (values) => {
    try {
      setIsSaving(true);
      setIsProgressing(true);
      switch (mode) {
        case "new":
          await submitNewDraft(values);
          await navigateAfter("/quotations/records", 3000);
          break;
        case "draft":
          await submitEditDraft(values);
          await navigateAfter("/quotations/records", 3000);
          break;
        default:
          setIsSaving(false);
          break;
      }
    } catch (err) {
      dispatchError(err);
    } finally {
      setIsSaving(false);
      setIsProgressing(false);
    }
  };

  const handleSubmit = async (values, actions) => {
    try {
      setIsProgressing(true);
      switch (mode) {
        case "new":
        case "draft":
        case "edit":
          await submitNew(values);
          await navigateAfter("/quotations/records", 3000);
          break;
        case "revise":
          await submitRevise(values);
          await navigateAfter("/quotations/records", 3000);
          break;
        case "view":
          navigate("/quotations/records");
          break;
        case "delete":
          await submitDelete();
          await navigateAfter("/quotations/records", 3000);
          break;
        default:
          actions.setSubmitting(false);
          break;
      }
    } catch (err) {
      dispatchError(err);
      actions.setSubmitting(false);
    } finally {
      actions.setSubmitting(false);
      setIsProgressing(false);
    }
  };

  return (
    <DashboardLayout>
      <Topnav title={modeTitle} />
      <AllowedTo perform={modePermission} no={() => <PermissionDenied />} />
      {response.isPending ? (
        <SubmissionProgress />
      ) : (
        <>
          <MDBox pt={3}>
            {isProgressing && <SubmissionProgress />}
            {response.error && (
              <>
                {response.error?.name === "OperationInvalidError" && (
                  <InvalidOperation />
                )}
                {response.error?.name === "OperationIncompleteError" && (
                  <IncompleteOperation />
                )}
                <ErrorSnackbar
                  title={modeTitle}
                  dateTime=""
                  message={response.error?.message}
                  autoDismiss
                />
              </>
            )}
            {response.success && (
              <SuccessSnackbar
                title={modeTitle}
                dateTime=""
                message={response.success}
                autoDismiss
              />
            )}
            <MDBox>
              {response.error?.name !== "OperationInvalidError" && (
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={2}>
                    <Sidenav />
                  </Grid>

                  <Grid item xs={12} lg={10}>
                    <MDBox mb={3}>
                      <Formik
                        //enableReinitialize
                        initialValues={response.data}
                        validationSchema={modeValidation}
                        onSubmit={handleSubmit}
                      >
                        {(formik) => {
                          return (
                            <Form id={formId} autoComplete="off">
                              <Grid container spacing={3}>
                                <Grid item xs={12}>
                                  <InternalReference
                                    mainForm={formik}
                                    mainFormField={formField}
                                    modeDisabled={modeFieldDisabled}
                                    dispatchMainError={dispatchError}
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <AuditTrail
                                    mainForm={formik}
                                    mainFormField={formField}
                                    modeDisabled={modeFieldDisabled}
                                    mode={mode}
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <CustomerInformation
                                    mainForm={formik}
                                    mainFormField={formField}
                                    modeDisabled={modeFieldDisabled}
                                    dispatchMainError={dispatchError}
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <Subject
                                    mainForm={formik}
                                    mainFormField={formField}
                                    modeDisabled={modeFieldDisabled}
                                    dispatchMainError={dispatchError}
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <PriceQuote
                                    mainForm={formik}
                                    mainFormField={formField}
                                    modeDisabled={modeFieldDisabled}
                                    dispatchMainError={dispatchError}
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <TermsAndConditions
                                    mainForm={formik}
                                    mainFormField={formField}
                                    modeDisabled={modeFieldDisabled}
                                    dispatchMainError={dispatchError}
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <Attachments
                                    mainForm={formik}
                                    mainFormField={formField}
                                    modeDisabled={modeFieldDisabled}
                                    dispatchMainError={dispatchError}
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <MDBox
                                    width="100%"
                                    display="flex"
                                    justifyContent="space-between"
                                  >
                                    {mode === "new" || mode === "draft" ? (
                                      <MDButton
                                        disabled={
                                          formik.isSubmitting || isSaving
                                        }
                                        variant="gradient"
                                        color="light"
                                        onClick={() =>
                                          handleDraft(
                                            formik.values,
                                            formik.actions,
                                            formik.setSubmitting
                                          )
                                        }
                                      >
                                        Save as Draft
                                      </MDButton>
                                    ) : (
                                      <MDBox></MDBox>
                                    )}
                                    <MDButton
                                      disabled={formik.isSubmitting || isSaving}
                                      type="submit"
                                      variant="gradient"
                                      color="dark"
                                    >
                                      {modeSubmit}
                                    </MDButton>
                                  </MDBox>
                                </Grid>
                              </Grid>
                            </Form>
                          );
                        }}
                      </Formik>
                    </MDBox>
                  </Grid>
                </Grid>
              )}
            </MDBox>
          </MDBox>
          <DashboardFooter />
        </>
      )}
    </DashboardLayout>
  );
}

export default Quotation;
