import format from "date-fns/format";
import {
  itemServiceOptions,
  itemServiceTypeOptions,
  itemServiceTeamOptions,
  exclusionClauseOptions,
} from "schema/setups";

const defaultQuotationDate = format(new Date(), "dd/MM/yyyy");

const defaultQuotationNumber = "---------";
const defaultQuotationRevision = "-";

const quotationMessageOptions = [
  "Thank you for inviting us to provide a quotation for the job as described above. We are pleased to submit herewith our quotation for your evaluation and confirmation as follows:-",
  "Thank you for asking us to provide a quotation for the job as described above. Xxx xxx xxx",
];
const defaultQuotationMessage = quotationMessageOptions[0];

const quoteItemServiceOptions = itemServiceOptions;

const quoteItemServiceTypeOptions = itemServiceTypeOptions;

const quoteItemServiceTeamOptions = itemServiceTeamOptions;

const beginingStatementOptions = [
  `The above is quoted in Singapore Dollars.
The amount quoted is based on normal working hours: Mon to Fri 9:00am to 6:00pm, Sat 9:00am to 1:00pm unless stated.
Please notify us immediately in the event of non-compliance of terms and conditions.`,
];
const defaultBeginingStatement = beginingStatementOptions[0];

const paymentTermOptions = [
  `Thirty (30) days upon submission of service report.
An interest of 3% per month will be levied on the outstanding amount after due date.`,
];
const defaultPaymentTerm = paymentTermOptions[0];

const validityOptions = [`Thirty (30) days from date hereof.`];
const defaultValidity = validityOptions[0];

const noteOptions = [`Items not stated in quotation will be excluded.`];
const defaultNote = noteOptions[0];

const endingStatementOptions = [
  `We trust that the above will meet your requirement and should you require further information, please do not hesitate to contact the undersigned.`,
];
const defaultEndingStatement = endingStatementOptions[0];

const installationPeriodOptions = ["TBC", "Duration"];
const defaultInstallationPeriod = installationPeriodOptions[0];

const defaultExclusions = exclusionClauseOptions.map((option) => {
  return { exclusionClause: option };
});

const discountTypeOptions = ["Itemized", "Non-Itemized"];
const defaultDiscountType = discountTypeOptions[0];

const paymentTypeOptions = ["Non-progressive", "Progressive"];
const defaultPaymentType = paymentTypeOptions[0];

const gstPercentage = 7;

const defaultQuotationColumns = [
  { key: "quotationNumberRevision", name: "Quotation" },
  { key: "quotationDateText", name: "Quotation Date" },
  { key: "quotationTitle", name: "Quotation Title" },
  { key: "customerName", name: "Customer Name" },
  { key: "deliverySiteName", name: "Delivery Site Name" },
  { key: "createdByText", name: "Created By" },
  { key: "modifiedByText", name: "Modified By" },
  { key: "quoteAfterGstTotalText", name: "Final Amount Inclusive of GST" },
  { key: "approvedByText", name: "Approved By" },
  { key: "rejectedByText", name: "Rejected By" },
  { key: "sentByText", name: "Sent By" },
  { key: "revisedByText", name: "Revised By" },
  { key: "convertedByText", name: "Converted By" },
  { key: "status", name: "Status" },
  { key: "salesorderNumberText", name: "Salesorder Number" },
  { key: "actions", name: "Actions" },
];

const columnOptions = [
  { key: "canApprove", name: "canApprove" },
  { key: "canReject", name: "canReject" },
  { key: "canSend", name: "canSend" },
  { key: "canRevise", name: "canRevise" },
  { key: "canConvert", name: "canConvert" },
  { key: "canPrint", name: "canPrint" },
  { key: "canEdit", name: "canEdit" },
  { key: "canDelete", name: "canDelete" },
  { key: "id", name: "ID" },
  { key: "quotationNumber", name: "Quotation Number" },
  { key: "quotationRevision", name: "Quotation Revision" },
  { key: "quotationNumberRevision", name: "Quotation" },
  { key: "quotationDateText", name: "Quotation Date" },
  { key: "quotationTitle", name: "Quotation Title" },
  { key: "customerName", name: "Customer Name" },
  { key: "customerAddress", name: "Customer Address" },
  { key: "contactPersonName", name: "Contact Person Name" },
  {
    key: "contactPersonLandlineNumber",
    name: "Contact Person Landline Number",
  },
  { key: "contactPersonMobileNumber", name: "Contact Person Mobile Number" },
  { key: "contactPersonEmail", name: "Contact Person Email" },
  { key: "deliverySiteName", name: "Delivery Site Name" },
  { key: "deliverySiteAddress", name: "Delivery Site Address" },
  { key: "installationPeriod", name: "Installation Period" },
  { key: "installationStartDateText", name: "Installation Start Date" },
  { key: "installationEndDateText", name: "Installation End Date" },
  { key: "paymentType", name: "Payment Type" },
  { key: "createdAtText", name: "Created At" },
  { key: "createdBy", name: "Created By Value" },
  { key: "createdByText", name: "Created By" },
  { key: "modifiedAtText", name: "Modified At" },
  { key: "modifiedByText", name: "Modified By" },
  { key: "quoteDiscountType", name: "Discount Type" },
  { key: "quoteGrossTotal", name: "Total Amount Value" },
  { key: "quoteGrossTotalText", name: "Total Amount" },
  { key: "quoteDiscountTotalText", name: "Discount" },
  { key: "quoteBeforeGstTotalText", name: "Total Amount Before GST" },
  { key: "quoteGstAmountText", name: "GST Amount" },
  { key: "quoteAfterGstTotalText", name: "Final Amount Inclusive of GST" },
  { key: "approvedAtText", name: "Approved At" },
  { key: "approvedByText", name: "Approved By" },
  { key: "rejectedAtText", name: "Rejected At" },
  { key: "rejectedByText", name: "Rejected By" },
  { key: "sentAtText", name: "Sent At" },
  { key: "sentByText", name: "Sent By" },
  { key: "revisedAtText", name: "Revised At" },
  { key: "revisedByText", name: "Revised By" },
  { key: "convertedAtText", name: "Converted At" },
  { key: "convertedByText", name: "Converted By" },
  { key: "status", name: "Status" },
  { key: "salesorderIdText", name: "Salesorder Id" },
  { key: "salesorderNumberText", name: "Salesorder Number" },
  { key: "deletedAtText", name: "Deleted At" },
  { key: "deletedByText", name: "Deleted By" },
  { key: "actions", name: "Actions" },
];

export {
  serviceTeamNameOptions,
  serviceTypeNameOptions,
  exclusionClauseOptions,
} from "schema/setups";
export {
  quotationMessageOptions,
  defaultQuotationDate,
  defaultQuotationNumber,
  defaultQuotationRevision,
  defaultQuotationMessage,
  quoteItemServiceOptions,
  quoteItemServiceTypeOptions,
  quoteItemServiceTeamOptions,
  beginingStatementOptions,
  defaultBeginingStatement,
  paymentTermOptions,
  defaultPaymentTerm,
  validityOptions,
  defaultValidity,
  noteOptions,
  defaultNote,
  endingStatementOptions,
  defaultEndingStatement,
  installationPeriodOptions,
  defaultInstallationPeriod,
  defaultExclusions,
  discountTypeOptions,
  defaultDiscountType,
  paymentTypeOptions,
  defaultPaymentType,
  gstPercentage,
  columnOptions,
  defaultQuotationColumns,
};
