import PropTypes from "prop-types";

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

import MDBox from "components/atoms/MDBox";
import MDTypography from "components/atoms/MDTypography";

import FormAttachments from "components/molecules/Formik/FormAttachments";
import FormTextFieldFast from "components/molecules/Formik/FormTextFieldFast";
import FormDatePicker from "components/molecules/Formik/FormDatePicker";

import { receiptingAttachmentsForm } from "pages/invoices/manage/schemas/form";
import { receiptingAttachmentsInitialValues } from "pages/invoices/manage/schemas/initialValues";
import { receiptingAttachmentsValidation } from "pages/invoices/manage/schemas/validations";

import format from "date-fns/format";
import parse from "date-fns/parse";
function Receipting({
  mainForm,
  mainFormField,
  modeDisabled,
  mode,
  dispatchMainError,
}) {
  const { invoiceReceiptingDate, receiptingReference, receiptingAttachments } =
    mainFormField;

  // destructure from Attachments Form for current section
  const {
    formId: receiptingAttachmentsFormId,
    formField: receiptingAttachmentsFormField,
  } = receiptingAttachmentsForm;
  const { receiptingAttachmentFile, attachmentName, attachmentURL } =
    receiptingAttachmentsFormField;

  return (
    <Card id="receipting" sx={{ overflow: "visible" }}>
      <MDBox m={3}>
        <MDBox mb={3}>
          <MDTypography variant="h5">Receipting</MDTypography>
        </MDBox>
        <MDBox>
          <Grid
            container
            rowSpacing={{ xs: 1, sm: 2 }}
            columnSpacing={{ xs: 1, sm: 3 }}
          >
            <Grid item xs={12} sm={12}>
              <FormDatePicker
                form={mainForm}
                field={invoiceReceiptingDate}
                inputFormat={"dd/MM/yyyy"}
                variant="standard"
                disabled={modeDisabled}
                maxDate={parse(
                  format(new Date(), "dd/MM/yyyy"),
                  "dd/MM/yyyy",
                  new Date()
                )}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <MDTypography variant="body3" fontWeight="bold">
                {receiptingReference.label}
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormTextFieldFast
                multiline
                form={mainForm}
                field={receiptingReference}
                variant="standard"
                disabled={modeDisabled}
              />
            </Grid>
            <Grid item xs={12} sm={4}></Grid>

            <Grid item xs={12} sm={2}>
              <MDTypography variant="body3" fontWeight="bold">
                {receiptingAttachments.label}
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormAttachments
                showTitle={false}
                form={mainForm}
                field={receiptingAttachments}
                max={20}
                disabled={modeDisabled}
                dispatchError={dispatchMainError}
                itemFormId={receiptingAttachmentsFormId}
                itemInitialValues={receiptingAttachmentsInitialValues}
                itemValidation={receiptingAttachmentsValidation}
                itemFileField={receiptingAttachmentFile}
                itemKeyField={attachmentURL}
                itemDisplayFields={[attachmentName]}
              />
            </Grid>
            <Grid item xs={12} sm={4}></Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Card>
  );
}

Receipting.propTypes = {
  mainForm: PropTypes.object.isRequired,
  mainFormField: PropTypes.object.isRequired,
  modeDisabled: PropTypes.bool.isRequired,
  mode: PropTypes.string.isRequired,
};

export default Receipting;
