const form = {
  formId: "supplier-form",
  formField: {
    supplierName: {
      name: "supplierName",
      label: "Supplier Company Name",
      type: "text",
      errorMsg: "Organization / Individual Name is required.",
      invalidMsg: "Organization / Individual Name is invalid.",
    },
    supplierUEN: {
      name: "supplierUEN",
      label: "UEN",
      type: "text",
      errorMsg: "UEN is required.",
      invalidMsg: "UEN is invalid.",
    },
    supplierType: {
      name: "supplierType",
      label: "Supplier Type",
      type: "text",
      errorMsg: "Supplier Type is required.",
    },
    supplierStatus: {
      name: "supplierStatus",
      label: "Supplier Status",
      type: "text",
      errorMsg: "Supplier Status is required.",
    },
    supplierAddress: {
      name: "supplierAddress",
      label: "Address",
      type: "text",
      errorMsg: "Address is required.",
      invalidMsg: "Address is invalid.",
    },
    supplierBuildingName: {
      name: "supplierBuildingName",
      label: "Building Name",
      type: "text",
      errorMsg: "Building Name is required.",
      invalidMsg: "Building Name is invalid.",
    },
    supplierPostalCode: {
      name: "supplierPostalCode",
      label: "Postal Code",
      type: "text",
      errorMsg: "Postal Code is required.",
      invalidMsg: "Postal Code is invalid.",
    },
    supplierLandlineNumber: {
      name: "supplierLandlineNumber",
      label: "Office / Home Contact Number",
      type: "text",
      errorMsg: "At least one contact number is required.",
      invalidMsg: "Office / Home Contact Number is invalid.",
    },
    supplierMobileNumber: {
      name: "supplierMobileNumber",
      label: "Mobile Contact Number",
      type: "text",
      errorMsg: "At least one contact number is required.",
      invalidMsg: "Mobile Contact Number is invalid.",
    },
    supplierEmail: {
      name: "supplierEmail",
      label: "Email",
      type: "text",
      errorMsg: "Email is required.",
      invalidMsg: "Email is invalid.",
    },
    supplierWebsite: {
      name: "supplierWebsite",
      label: "Website",
      type: "text",
      invalidMsg: "Website is invalid.",
    },
    supplierPreferredContact: {
      name: "supplierPreferredContact",
      label: "Preferred Contact",
      type: "text",
      errorMsg: "Supplier Preferred Contact is required.",
    },
    contactPersons: {
      name: "contactPersons",
      label: "Supplier Contact Persons",
      type: "box",
      min1Msg: "Must have at least 1 Contact Person",
      max100Msg: "Must have at most 100 Contact Persons",
    },
    deliverySites: {
      name: "deliverySites",
      label: "Supplier Delivery Sites",
      type: "box",
      min1Msg: "Must have at least 1 Delivery Site",
      max100Msg: "Must have at most 100 Delivery Sites",
    },
    billingContacts: {
      name: "billingContacts",
      label: "Supplier Billing Contacts",
      type: "box",
      min1Msg: "Must have at least 1 Account Contact",
      max100Msg: "Must have at most 100 Account Contacts",
    },
  },
};

const contactPersonForm = {
  formId: "contact-person-form",
  formField: {
    contactPersonName: {
      name: "contactPersonName",
      label: "Contact Person Name",
      type: "text",
      errorMsg: "Contact Person Name is required.",
      invalidMsg: "Contact Person Name is invalid.",
    },
    contactPersonEmail: {
      name: "contactPersonEmail",
      label: "Email",
      type: "text",
      errorMsg: "Email is required.",
      invalidMsg: "Email is invalid.",
    },
    contactPersonLandlineNumber: {
      name: "contactPersonLandlineNumber",
      label: "Office / Home Contact Number",
      type: "text",
      errorMsg: "At least one contact number is required.",
      invalidMsg: "Office / Home Contact Number is invalid.",
    },
    contactPersonMobileNumber: {
      name: "contactPersonMobileNumber",
      label: "Mobile Contact Number",
      type: "text",
      errorMsg: "At least one contact number is required.",
      invalidMsg: "Mobile Contact Number is invalid.",
    },
  },
};

const deliverySiteForm = {
  formId: "delivery-sites-form",
  formField: {
    deliverySiteName: {
      name: "deliverySiteName",
      label: "Delivery Site Name",
      type: "text",
      errorMsg: "Delivery Site Name is required.",
      invalidMsg: "Delivery Site Name is invalid.",
    },
    siteSupervisorName: {
      name: "siteSupervisorName",
      label: "Site Supervisor Name",
      type: "text",
      errorMsg: "Site Supervisor Name is required.",
      invalidMsg: "Site Supervisor Name is invalid.",
    },
    deliverySiteAddress: {
      name: "deliverySiteAddress",
      label: "Delivery Site Address",
      type: "text",
      errorMsg: "Delivery Site Address is required.",
      invalidMsg: "Delivery Site Address is invalid.",
    },
    deliverySiteBlkBuildingName: {
      name: "deliverySiteBlkBuildingName",
      label: "Blk / Building Name",
      type: "text",
      errorMsg: "Blk / Building Name is required.",
      invalidMsg: "Blk / Building Name is invalid.",
    },
    deliverySitePostalCode: {
      name: "deliverySitePostalCode",
      label: "Postal Code",
      type: "text",
      errorMsg: "Postal Code is required.",
      invalidMsg: "Postal Code is invalid.",
    },
    deliverySiteEmail: {
      name: "deliverySiteEmail",
      label: "Email",
      type: "text",
      errorMsg: "Email is required.",
      invalidMsg: "Email is invalid.",
    },
    deliverySiteLandlineNumber: {
      name: "deliverySiteLandlineNumber",
      label: "Office / Home Contact Number",
      type: "text",
      errorMsg: "At least one contact number is required.",
      invalidMsg: "Office / Home Contact Number is invalid.",
    },
    deliverySiteMobileNumber: {
      name: "deliverySiteMobileNumber",
      label: "Mobile Contact Number",
      type: "text",
      errorMsg: "At least one contact number is required.",
      invalidMsg: "Mobile Contact Number is invalid.",
    },
  },
};

const billingContactForm = {
  formId: "billing-contacts-form",
  formField: {
    billingContactName: {
      name: "billingContactName",
      label: "Billing Contact Name",
      type: "text",
      errorMsg: "Billing Contact Name is required.",
      invalidMsg: "Billing Contact Name is invalid.",
    },
    billingContactEmail: {
      name: "billingContactEmail",
      label: "Email",
      type: "text",
      errorMsg: "Email is required.",
      invalidMsg: "Email is invalid.",
    },
    billingContactLandlineNumber: {
      name: "billingContactLandlineNumber",
      label: "Office / Home Contact Number",
      type: "text",
      errorMsg: "At least one contact number is required.",
      invalidMsg: "Office / Home Contact Number is invalid.",
    },
    billingContactMobileNumber: {
      name: "billingContactMobileNumber",
      label: "Mobile Contact Number",
      type: "text",
      errorMsg: "At least one contact number is required.",
      invalidMsg: "Mobile Contact Number is invalid.",
    },
  },
};

export {
  form as default,
  contactPersonForm,
  deliverySiteForm,
  billingContactForm,
};
