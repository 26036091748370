import PropTypes from "prop-types";

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

import MDBox from "components/atoms/MDBox";
import MDTypography from "components/atoms/MDTypography";
//import FormTextFieldFast from "components/molecules/Formik/FormTextFieldFast";
import FormAutocompleteFast from "components/molecules/Formik/FormAutocompleteFast";

import { billingDetailsOptions } from "pages/purchaseorders/manage/schemas/setups";

function BillingDetails({ mainForm, mainFormField, modeDisabled }) {
  const { billingDetails } = mainFormField;

  return (
    <Card id="billing-details" sx={{ overflow: "visible" }}>
      <MDBox m={3}>
        <MDBox mb={3}>
          <MDTypography variant="h5">Billing Details</MDTypography>
        </MDBox>
        <MDBox>
          <Grid
            container
            rowSpacing={{ xs: 1, sm: 2 }}
            columnSpacing={{ xs: 1, sm: 3 }}
          >
            <Grid item xs={12} sm={12}>
              <FormAutocompleteFast
                freeSolo
                form={mainForm}
                field={billingDetails}
                options={billingDetailsOptions}
                disabled={modeDisabled}
              />
            </Grid>
            
          </Grid>
        </MDBox>
      </MDBox>
    </Card>
  );
}

BillingDetails.propTypes = {
  mainForm: PropTypes.object.isRequired,
  mainFormField: PropTypes.object.isRequired,
  modeDisabled: PropTypes.bool.isRequired,
};

export default BillingDetails;
