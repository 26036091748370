import React from "react";
import { Text, View } from "@react-pdf/renderer";
import styles from "pages/quotations/pdf/styles";

function CustomerInformation({ values }) {
  return (
    <View style={[styles.containerCol, styles.section]}>
      <View style={styles.containerRow}>
        <Text style={styles.property}>Attention</Text>
        <Text style={styles.colon}>:</Text>
        <Text>{values.contactPersonName}</Text>
      </View>
      <View style={styles.containerRow}>
        <Text style={styles.property}>Company Name</Text>
        <Text style={styles.colon}>:</Text>
        <Text>{values.customerName}</Text>
      </View>
      {values.contactPersonMobileNumber ? (
        <View style={styles.containerRow}>
          <Text style={styles.property}>Contact (Mobile)</Text>
          <Text style={styles.colon}>:</Text>
          <Text>{values.contactPersonMobileNumber}</Text>
        </View>
      ) : (
        <></>
      )}
      {values.contactPersonLandlineNumber ? (
        <View style={styles.containerRow}>
          <Text style={styles.property}>Contact (Landline)</Text>
          <Text style={styles.colon}>:</Text>
          <Text>{values.contactPersonLandlineNumber}</Text>
        </View>
      ) : (
        <></>
      )}
      <View style={styles.containerRow}>
        <Text style={styles.property}>Email</Text>
        <Text style={styles.colon}>:</Text>
        <Text>{values.contactPersonEmail}</Text>
      </View>
      <View style={styles.containerRow}>
        <Text style={styles.property}>Address</Text>
        <Text style={styles.colon}>:</Text>
        <Text>{values.customerAddress}</Text>
      </View>
      <View style={styles.containerRow}>
        <Text style={styles.property}>Job Site</Text>
        <Text style={styles.colon}>:</Text>
        <Text>{values.deliverySiteName}</Text>
      </View>
      <View style={styles.containerRow}>
        <Text style={styles.property}>Job Site Address</Text>
        <Text style={styles.colon}>:</Text>
        <Text>{values.deliverySiteAddress}</Text>
      </View>
    </View>
  );
}

export default CustomerInformation;
