import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Grid from "@mui/material/Grid";

import MDBox from "components/atoms/MDBox";
import MDButton from "components/atoms/MDButton";

import ErrorSnackbar from "components/molecules/Notifications/ErrorSnackbar";
import SuccessSnackbar from "components/molecules/Notifications/SuccessSnackbar";

import DashboardLayout from "components/templates/DashboardLayout";
import Topnav from "components/organisms/Topnav";
import DashboardFooter from "components/organisms/Footer/DashboardFooter";
import PermissionDenied from "components/organisms/PermissionDenied";
import InvalidOperation from "components/organisms/InvalidOperation";
import IncompleteOperation from "components/organisms/IncompleteOperation";
import SubmissionProgress from "components/organisms/SubmissionProgress";

import Sidenav from "pages/purchaseorders/pdf/components/Sidenav";
import Attachments from "pages/purchaseorders/pdf/components/Attachments";
import PurchaseOrderPreview from "pages/purchaseorders/pdf/components/PurchaseOrderPreview";
import Email from "pages/purchaseorders/pdf/components/Email";
import EmailPaymentMade from "pages/purchaseorders/pdf/components/EmailPaymentMade";
import EmailCancellation from "pages/purchaseorders/pdf/components/EmailCancellation";
import PurchaseOrderCancel from "pages/purchaseorders/pdf/components/PurchaseOrderCancel";
import PaymentDetails from "pages/purchaseorders/pdf/components/PaymentDetails";
import GoodsReceived from "pages/purchaseorders/pdf/components/GoodsReceived";

import { Formik, Form } from "formik";
import form from "pages/purchaseorders/pdf/schemas/form";
import initialValues, {
  goodsReceivedInitialValues,
  paymentMadeInitialValues,
} from "pages/purchaseorders/pdf/schemas/initialValues";

import { AllowedTo } from "react-abac";

import { usePurchaseOrderManager } from "pages/purchaseorders/hooks/usePurchaseOrderManager";

function PurchaseOrderPDFView() {
  const [isProgressing, setIsProgressing] = useState(false);
  const { mode, purchaseorderId } = useParams();

  const {
    modeTitle,
    modeSubmit,
    modeFieldDisabled,
    modePermission,
    modeValidation,
    submitSend,
    submitSendPaymentMade,
    submitSendCancellation,
    submitSendGoodsReceived,
    response,
    dispatchError,
  } = usePurchaseOrderManager(mode, purchaseorderId);

  const navigate = useNavigate();
  const navigateAfter = (path, millisecond) => {
    return new Promise(() => {
      setTimeout(() => {
        navigate(path);
      }, millisecond);
    });
  };

  const { formId, formField } = form;

  const handleSubmit = async (values, actions) => {
    try {
      setIsProgressing(true);
      switch (mode) {
        case "send":
          await submitSend(values);
          await navigateAfter("/purchaseorders/records", 3000);
          break;
        case "print":
          navigate("/purchaseorders/records");
          break;
        case "complete":
          await submitSendPaymentMade(values);
          await navigateAfter("/purchaseorders/records", 3000);
          break;
        case "cancel":
          await submitSendCancellation(values);
          await navigateAfter("/purchaseorders/records", 3000);
          break;
        case "receive":
          await submitSendGoodsReceived(values);
          await navigateAfter("/purchaseorders/records", 3000);
          break;
        default:
          await submitSend(values);
          actions.setSubmitting(false);
          break;
      }
    } catch (err) {
      dispatchError(err);
    } finally {
      actions.setSubmitting(false);
      setIsProgressing(false);
    }
  };

  let formikInitialValues;
  switch (mode) {
    case "send":
      formikInitialValues = initialValues;
      break;
    case "receive":
      formikInitialValues = goodsReceivedInitialValues;
      break;
    case "complete":
      formikInitialValues = paymentMadeInitialValues;
      break;
    default:
      formikInitialValues = initialValues;
      break;
  }

  return (
    <DashboardLayout>
      <Topnav title={modeTitle} />
      <AllowedTo perform={modePermission} no={() => <PermissionDenied />} />
      <MDBox pt={3}>
        {response.isPending && <SubmissionProgress />}
        {isProgressing && <SubmissionProgress />}
        {response.error && (
          <>
            {response.error?.name === "OperationInvalidError" && (
              <InvalidOperation />
            )}
            {response.error?.name === "OperationIncompleteError" && (
              <IncompleteOperation />
            )}
            <ErrorSnackbar
              title={modeTitle}
              dateTime=""
              message={response.error?.message}
              autoDismiss
            />
          </>
        )}
        {response.success && (
          <SuccessSnackbar
            title={modeTitle}
            dateTime=""
            message={response.success}
            autoDismiss
          />
        )}
        <MDBox>
          {response.error?.name !== "OperationInvalidError" &&
            response.data && (
              <Grid container spacing={2}>
                <Grid item xs={12} lg={2}>
                  <Sidenav mode={mode} />
                </Grid>
                {
                  <Grid item xs={12} lg={10}>
                    <MDBox mb={3}>
                      <Formik
                        enableReinitialize
                        initialValues={formikInitialValues}
                        validationSchema={modeValidation}
                        onSubmit={handleSubmit}
                      >
                        {(formik) => (
                          <Form id={formId} autoComplete="off">
                            <Grid container spacing={3}>
                              {mode === "receive" && (
                                <Grid item xs={12}>
                                  <GoodsReceived
                                    mainForm={formik}
                                    mainFormField={formField}
                                    modeDisabled={modeFieldDisabled}
                                    response={response}
                                    dispatchMainError={dispatchError}
                                  />
                                </Grid>
                              )}
                              {mode === "cancel" && (
                                <>
                                  <Grid item xs={12}>
                                    <PurchaseOrderCancel
                                      mainForm={formik}
                                      mainFormField={formField}
                                      modeDisabled={modeFieldDisabled}
                                      response={response}
                                      dispatchMainError={dispatchError}
                                    />
                                  </Grid>
                                  <Grid item xs={12}>
                                    <EmailCancellation
                                      mainForm={formik}
                                      mainFormField={formField}
                                      modeDisabled={modeFieldDisabled}
                                      response={response}
                                      dispatchMainError={dispatchError}
                                    />
                                  </Grid>
                                </>
                              )}
                              {mode === "complete" && (
                                <>
                                  <Grid item xs={12}>
                                    <PaymentDetails
                                      mainForm={formik}
                                      mainFormField={formField}
                                      modeDisabled={modeFieldDisabled}
                                      response={response}
                                      dispatchMainError={dispatchError}
                                    />
                                  </Grid>
                                  <Grid item xs={12}>
                                    <EmailPaymentMade
                                      mainForm={formik}
                                      mainFormField={formField}
                                      modeDisabled={modeFieldDisabled}
                                      response={response}
                                      dispatchMainError={dispatchError}
                                    />
                                  </Grid>
                                </>
                              )}
                              {mode === "send" && (
                                <Grid item xs={12}>
                                  <Email
                                    mainForm={formik}
                                    mainFormField={formField}
                                    modeDisabled={modeFieldDisabled}
                                    response={response}
                                    dispatchMainError={dispatchError}
                                  />
                                </Grid>
                              )}

                              <>
                                <Grid item xs={12}>
                                  <Attachments
                                    mainForm={formik}
                                    mainFormField={formField}
                                    modeDisabled={modeFieldDisabled}
                                    mode={mode}
                                    dispatchMainError={dispatchError}
                                  />
                                </Grid>
                              </>

                              <Grid item xs={12}>
                                <PurchaseOrderPreview
                                  mainForm={formik}
                                  mainFormField={formField}
                                  mode={mode}
                                  response={response}
                                  dispatchMainError={dispatchError}
                                />
                              </Grid>

                              <Grid item xs={12}>
                                <MDBox
                                  width="100%"
                                  display="flex"
                                  justifyContent="space-between"
                                >
                                  <MDBox></MDBox>
                                  <MDButton
                                    disabled={formik.isSubmitting}
                                    type="submit"
                                    variant="gradient"
                                    color="dark"
                                  >
                                    {modeSubmit}
                                  </MDButton>
                                </MDBox>
                              </Grid>
                            </Grid>
                          </Form>
                        )}
                      </Formik>
                    </MDBox>
                  </Grid>
                }
              </Grid>
            )}
        </MDBox>
      </MDBox>
      <DashboardFooter />
    </DashboardLayout>
  );
}

export default PurchaseOrderPDFView;
